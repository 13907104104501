import { Component, OnDestroy, OnInit } from "@angular/core";
import { CrudPagedListComponent } from "../../_directives";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AlertService,
  FunnelService,
  SideNavService,
  UserService,
} from "../../_services";
import { Location } from "@angular/common";
import { stepLayout, steps } from "../step-data";
import { Breadcrumb, OfferTypeLabels, OfferTypes } from "../../_models/steps";

@Component({
  selector: "app-step-category-selection",
  templateUrl: "./step-category-selection.component.html",
  styleUrls: ["./step-category-selection.component.scss"],
})
export class StepCategorySelectionComponent
  extends CrudPagedListComponent
  implements OnInit, OnDestroy
{
  offerType: OfferTypes;
  offerName: string;
  steps = steps;
  title: string;
  funnelId: string = "";
  funnelName: string = "";
  breadcrumbs: Breadcrumb[] = [];
  categoryTitles = {
    [OfferTypes.Downsell]: "Add a new downsell",
    [OfferTypes.Upsell]: "Add  a new upsell",
    [OfferTypes.Survey]: "Select survey step",
    [OfferTypes.Support]: "Add new step",
  };
  protected returnUrl = null;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    private sideNav: SideNavService,
    protected funnelService: FunnelService,
    protected alertService: AlertService,
    protected userService: UserService
  ) {
    super(router, location, route, funnelService, alertService);
    this.sideNav.setShowSideNavToggle(true);
    this.sideNav.setSideNavExpanded(false);

    this.route.params.subscribe((params) => {
      this.funnelId = params["offer_id"];
      this.offerType = Number(params["offer_type"]);
      this.offerName = OfferTypeLabels[this.offerType];
    });
  }

  ngOnInit() {
    if (this.funnelId) {
      this.fetchFunnel();
    }
    this.filterSteps();
    this.generateBreadcrumbs();
  }

  fetchFunnel() {
    this.funnelService.list({}, this.funnelId).subscribe(
      (response) => {
        this.funnelName = response.slug;
        this.breadcrumbs[1].label = response.slug;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  filterSteps() {
    this.steps = steps.filter((step) => step.offer_type === this.offerType);
    this.title = this.categoryTitles[this.offerType];
  }

  generateBreadcrumbs() {
    if (this.offerType === OfferTypes.Survey) {
      this.breadcrumbs = [
        {
          label:
            this.offerName.charAt(0).toUpperCase() + this.offerName.slice(1),
          url: ["steps", "survey"],
        },
        {
          label: "Choose a Template",
        },
      ];
    } else {
      this.breadcrumbs = [
        {
          label:
            this.offerName.charAt(0).toUpperCase() +
            this.offerName.slice(1) +
            " Offers",
          url: ["steps", this.offerName],
        },
        {
          label: "",
          url: [
            "steps",
            this.offerName,
            this.offerType,
            "details",
            this.funnelId,
          ],
        },
        {
          label: "Choose a Template",
        },
      ];
    }
  }

  selectTemplate(stepType: number) {
    const selectedLayout = stepLayout[stepType];

    if (selectedLayout) {
      if (this.offerType === OfferTypes.Survey) {
        this.router.navigate([
          "steps",
          this.offerName,
          "category",
          this.offerType,
          "folders",
          stepType,
        ]);
      } else {
        this.router.navigate([
          "steps",
          this.offerName,
          this.funnelId,
          "category",
          this.offerType,
          "folders",
          stepType,
        ]);
      }
    }
  }

  navigate(url: any[]) {
    this.router.navigate(url);
  }

  ngOnDestroy(): void {
    this.sideNav.setShowSideNavToggle(true);
    this.sideNav.setSideNavExpanded(true);
  }
}
