import { OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import * as moment from "moment-timezone";
import { NgxSmartModalService } from "ngx-smart-modal";
import {
  AlertService,
  CampaignService,
  FunnelService,
  LoaderService,
  StepTemplateService,
} from "../../_services";
import { CrudPagedListComponent } from "../../_directives";
import {
  CustomStepCategoryEnum,
  Pager,
  StepCategoryLabels,
  OfferIntentLabels,
  OfferIntents,
  OfferTypes,
  StepTableStatusLabels,
  StepDateTypes,
  StepPopupActions,
  StepTableFilters,
  StepTableStatuses,
} from "../../_models";
import { takeUntil } from "rxjs/operators";
import { config } from "../../../config/config";

export abstract class OffersAbstractComponent
  extends CrudPagedListComponent
  implements OnInit
{
  campaignId: number;
  showModal: boolean = false;
  showThreeDotMenu: boolean = false;
  isOffersPage: boolean = true;
  selectedRow: number = -1;
  selectedStatus: StepTableStatuses = StepTableStatuses.All;
  tableStatuses = StepTableStatuses;
  selectedFilter: StepTableFilters = StepTableFilters.None;
  tableFilters = StepTableFilters;
  selectedDate: StepDateTypes = StepDateTypes.LastModified;
  tableDateTypes = StepDateTypes;
  searchQuery: string;
  showAddButton: boolean = true;
  showViewButton: boolean = true;
  selectedOfferId: number | null = null;
  selectedIndex: number | null = null;
  tableData: any[] = [];
  offerType: OfferTypes;
  offerTypeOptions = [];
  popupTitle: string = "";
  popupData = {
    name: "",
    is_survey: false,
    offer_id: null,
    step_id: null,
    offer_intent: null,
    offer_type: null,
    offer_type_options: [],
    popupType: null,
  };
  stepPopupData = {
    name: "",
    is_survey: false,
    offer_id: null,
    step_id: null,
    offer_intent: null,
    offer_type: null,
    offer_type_options: [],
    popupType: null,
  };
  originalTableData: any[] = [...this.tableData];
  stepOfferList: any[];
  isSurvey = false;
  isLoading = false;
  areSupportOffers: boolean = false;
  selectedSurveyStep: any;
  stepPopupActions = StepPopupActions;
  offerIntents = OfferIntents;
  stepStatusLabel = StepTableStatusLabels;
  stepCategories = CustomStepCategoryEnum;
  offerPopupTitleMap = {
    [StepPopupActions.Create]: "Create a new Offer",
    [StepPopupActions.Update]: "Rename Offer",
    [StepPopupActions.Duplicate]: "Duplicate Offer",
    [StepPopupActions.Delete]: "Delete Offer",
  };
  surveyPopupTitleMap = {
    [StepPopupActions.Update]: "Rename Survey",
    [StepPopupActions.Duplicate]: "Duplicate Survey",
    [StepPopupActions.Delete]: "Delete Survey",
  };

  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    protected funnelService: FunnelService,
    protected stepService: StepTemplateService,
    protected campaignService: CampaignService,
    protected loader: LoaderService,
    public ngxSmartModalService: NgxSmartModalService,
    protected route: ActivatedRoute
  ) {
    super(router, location, route, funnelService, alertService);
    this.objectName = "offers";
  }

  ngOnInit() {
    this.loader.show();
    this.fetchCampaign();
    this.fetchOfferData();
  }

  fetchCampaign() {
    this.campaignService
      .list(Object.assign({ page: 1, page_size: config.maxPageSize }))
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: Pager) => {
          this.campaignId = data.results[0].id;
        },
        (error) => {
          this.alertService.error(error);
        }
      );
  }

  fetchOfferData() {
    if (!this.isSurvey) {
      this.funnelService
        .listOffers(this.offerType, this.areSupportOffers ? 0 : undefined)
        .subscribe(
          (response: Pager) => {
            this.loader.hide();
            this.tableData = response.results.map((item: any) => {
              const lastModifiedMoment = moment(item.last_modified).tz("EST");
              const createdMoment = moment(item.created).tz("EST");

              const lastModified = {
                date: lastModifiedMoment.format("DD MMM, YYYY"),
                time: lastModifiedMoment.format("HH:mm:ss z"),
              };

              const created = {
                date: createdMoment.format("DD MMM, YYYY"),
                time: createdMoment.format("HH:mm:ss z"),
              };

              return {
                id: item.id,
                stepName: item.name,
                status: item.is_modified
                  ? StepTableStatuses.Draft
                  : item.paths > 0
                  ? StepTableStatuses.Active
                  : StepTableStatuses.Inactive,
                typeLabel: OfferIntentLabels[item.offer_intent] || "--",
                type: item.offer_intent,
                lastModified,
                created,
                paths: item.path_names.length > 0 ? item.path_names : [],
                steps: item.steps || [],
                showAllOffers: item.steps.length ? true : false,
              };
            });
            this.originalTableData = this.tableData;
          },
          (error) => {
            this.loader.hide();
            this.alertService.error(error.message);
          }
        );
    } else {
      this.stepService
        .list({ folder__isnull: true, is_template: false })
        .subscribe(
          (response) => {
            this.loader.hide();
            this.stepOfferList = response.results;
            this.populateSurveySteps();
          },
          (error) => {
            this.loader.hide();
            this.alertService.error(error.message);
          }
        );
    }
  }

  populateSurveySteps() {
    this.tableData = this.stepOfferList.map((item: any) => {
      const lastModifiedMoment = moment(item.last_modified).tz("EST");
      const createdMoment = moment(item.created).tz("EST");

      const lastModified = {
        date: lastModifiedMoment.format("DD MMM, YYYY"),
        time: lastModifiedMoment.format("HH:mm:ss z"),
      };

      const created = {
        date: createdMoment.format("DD MMM, YYYY"),
        time: createdMoment.format("HH:mm:ss z"),
      };

      return {
        id: item.id,
        stepName: item.name,
        status: item.is_modified
          ? StepTableStatuses.Draft
          : item.paths > 0
          ? StepTableStatuses.Active
          : StepTableStatuses.Inactive,
        typeLabel: StepCategoryLabels[item.category] || "--",
        type: item.offer_intent,
        lastModified,
        created,
        paths: item.path_names.length > 0 ? item.path_names : [],
        category: item.category,
        previewLoading: false,
      };
    });
    this.originalTableData = this.tableData;
  }

  navigateToStepBuilder(id: number, category: number) {
    this.router.navigate([
      "steps",
      "survey",
      "category",
      this.offerType,
      category,
      "step",
      id,
    ]);
  }

  openOfferPopup(
    popupType: number,
    offerId?: number | string,
    offerName?: string,
    offerIntent?: OfferIntents
  ): void {
    this.popupTitle = this.offerPopupTitleMap[popupType] || "";
    this.popupData = {
      ...this.popupData,
      name: offerName || "",
      offer_id: offerId || null,
      offer_type: this.offerType,
      popupType: popupType,
      offer_intent: offerIntent || this.popupData.offer_intent,
      offer_type_options: this.offerTypeOptions,
    };
    this.openPopup();
  }
  openStepPopup(
    popupType: number,
    stepId?: number | string,
    stepName?: string,
    offerIntent?: OfferIntents
  ): void {
    this.popupTitle = this.surveyPopupTitleMap[popupType] || "";

    this.stepPopupData = {
      ...this.stepPopupData,
      name: stepName || "",
      step_id: stepId || null,
      is_survey: true,
      popupType: popupType,
      offer_intent: offerIntent || null,
    };

    this.ngxSmartModalService.getModal("stepPopup").open();
  }

  openPopup() {
    this.ngxSmartModalService.getModal("offerPopup").open();
  }

  closeOfferPopup() {
    this.ngxSmartModalService.getModal("offerPopup").close();
  }

  closeStepPopup() {
    this.ngxSmartModalService.getModal("stepPopup").close();
  }

  saveOffer(): void {
    this.fetchOfferData();
    this.ngxSmartModalService.getModal("offerPopup").close();
  }
  saveStep(): void {
    this.fetchOfferData();
    this.ngxSmartModalService.getModal("stepPopup").close();
  }

  filterData() {
    this.tableData =
      this.selectedStatus == StepTableStatuses.All
        ? [...this.originalTableData]
        : this.originalTableData.filter(
            (item) => item.status == this.selectedStatus
          );
  }

  filterTableData() {
    const filteredData = [...this.originalTableData];
    switch (Number(this.selectedFilter)) {
      case StepTableFilters.Name:
        filteredData.sort((a, b) => a.stepName.localeCompare(b.stepName));
        break;
      case StepTableFilters.Type:
        filteredData.sort((a, b) => a.typeLabel.localeCompare(b.typeLabel));
        break;
      case StepTableFilters.LastModified:
        this.selectedDate = StepDateTypes.LastModified;
        filteredData.sort((a, b) => {
          const dateA = moment(
            a.lastModified.date + " " + a.lastModified.time,
            "DD MMM, YYYY HH:mm"
          );
          const dateB = moment(
            b.lastModified.date + " " + b.lastModified.time,
            "DD MMM, YYYY HH:mm"
          );
          return dateB.diff(dateA);
        });
        break;
      case StepTableFilters.DateCreated:
        this.selectedDate = StepDateTypes.DateCreated;
        filteredData.sort((a, b) => {
          const dateA = moment(
            a.created.date + " " + a.created.time,
            "DD MMM, YYYY HH:mm"
          );
          const dateB = moment(
            b.created.date + " " + b.created.time,
            "DD MMM, YYYY HH:mm"
          );
          return dateB.diff(dateA);
        });
        break;
    }
    this.tableData = filteredData;
  }

  searchData() {
    this.tableData = this.originalTableData.filter((item) =>
      item.stepName.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }

  closeOtherMenus(currentItem: any): void {
    this.tableData.forEach((item) => {
      if (item.id !== currentItem.id) {
        item.showThreeDotMenu = false;
      }
    });
  }

  getSurveyStepPreview(id: string | number) {
    const step = this.tableData.find((item) => item.id === id);

    step.previewLoading = true;

    this.stepService.get(id).subscribe((resp: any) => {
      if (resp) {
        const stepDetails = {
          ...resp,
          static_data: {
            ...resp.static_data,
            image: resp.static_data.image ? resp.static_data.image.id : null,
            image_mobile: resp.static_data.image_mobile
              ? resp.static_data.image_mobile.id
              : null,
            image_tablet: resp.static_data.image_tablet
              ? resp.static_data.image_tablet.id
              : null,
          },
        };

        this.funnelService.previewStep(this.campaignId, stepDetails).subscribe(
          (data) => {
            window.open(data.toString(), "_blank");
            step.previewLoading = false;
          },
          (error) => {
            this.alertService.error(error);
            step.previewLoading = false;
          }
        );
      }
    });
  }

  toggleThreeDotMenu(item: any): void {
    this.closeOtherMenus(item);
    item.showThreeDotMenu = !item.showThreeDotMenu;
  }

  closeThreeDotMenu(item: any) {
    item.showThreeDotMenu = false;
  }
  toggleShowAllOffers(item: any): void {
    item.showAllOffers = !item.showAllOffers;
  }

  togglePathList(index: number): void {
    if (this.selectedRow === index) {
      this.selectedRow = -1;
    } else {
      this.selectedRow = index;
    }
  }

  closePathList() {
    this.selectedRow = -1;
    this.tableData.forEach((item) => {
      item.showThreeDotMenu = false;
    });
  }

  popupIconMap: Partial<Record<StepPopupActions, string>> = {
    [StepPopupActions.Delete]: "/assets/stepAssets/delete-icon.svg",
    [StepPopupActions.Duplicate]: "/assets/stepAssets/duplicate.svg",
    [StepPopupActions.Update]: "/assets/stepAssets/rename.svg",
  };
}
