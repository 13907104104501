import { Component, OnDestroy, OnInit } from "@angular/core";
import { CrudPagedListComponent } from "../../_directives";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AlertService,
  CampaignService,
  FunnelService,
  LoaderService,
  SideNavService,
  StepTemplateService,
  TemplateFolderService,
  UserService,
} from "../../_services";
import { Location } from "@angular/common";
import {
  Breadcrumb,
  OfferTypeLabels,
  OfferTypes,
  StepCategoryLabels,
  CustomStepCategoryEnum,
  StepPopupActions,
  User,
  OrderType,
  OrderTypeLabels,
  Pager,
} from "../../_models";
import { NgxSmartModalService } from "ngx-smart-modal";
import { takeUntil } from "rxjs/operators";
import { config } from "../../../config/config";

@Component({
  selector: "app-step-templates",
  templateUrl: "./step-templates.component.html",
  styleUrls: ["./step-templates.component.scss"],
})
export class StepTemplates
  extends CrudPagedListComponent
  implements OnInit, OnDestroy
{
  offerType: OfferTypes;
  offerName: string;
  stepType: CustomStepCategoryEnum;
  stepTypeName: string;
  funnelId = "";
  folderId = "";
  funnelName = "";
  folderDetails: any;
  templates: any;
  folderTemplates: any;
  breadcrumbs: Breadcrumb[] = [];
  stepPopupActions = StepPopupActions;
  popupTitle = "";
  popupData = {
    name: "",
    is_template: false,
    folder_id: null,
    step_id: null,
    popupType: StepPopupActions.Duplicate,
  };
  searchQuery = "";
  user: User;
  selectedFilter: OrderType | null = null;
  orderTypes = OrderType;
  orderTypeLabels = OrderTypeLabels;
  campaignId: number;
  popupTitleMap = {
    [StepPopupActions.Delete]: "Delete Template",
    [StepPopupActions.Duplicate]: "Duplicate Template",
  };

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    private sideNav: SideNavService,
    public ngxSmartModalService: NgxSmartModalService,
    protected loader: LoaderService,
    protected funnelService: FunnelService,
    protected templateFolderService: TemplateFolderService,
    protected campaignService: CampaignService,
    protected stepService: StepTemplateService,
    protected alertService: AlertService,
    protected userService: UserService
  ) {
    super(router, location, route, funnelService, alertService);
    this.sideNav.setShowSideNavToggle(true);
    this.sideNav.setSideNavExpanded(false);

    this.route.params.subscribe((params) => {
      this.funnelId = params["offer_id"];
      this.offerType = Number(params["offer_type"]);
      this.offerName = OfferTypeLabels[this.offerType];
      this.stepType = params["step_type"];
      this.folderId = params["folder_id"];
      this.stepTypeName = StepCategoryLabels[this.stepType];
    });
  }

  ngOnInit() {
    this.fetchUser();
    this.fetchCampaign();
    this.fetchFolderDetails();
    if (this.funnelId) {
      this.fetchFunnel();
    }
    this.generateBreadcrumbs();
  }

  fetchUser() {
    this.userService
      .getCurrent()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (user: User) => {
          this.user = user;
        },
        (error) => {
          this.handleError(error);
        }
      );
  }

  fetchCampaign() {
    this.campaignService
      .list(Object.assign({ page: 1, page_size: config.maxPageSize }))
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: Pager) => {
          this.campaignId = data.results[0].id;
        },
        (error) => {
          this.alertService.error(error);
        }
      );
  }

  fetchFolderDetails() {
    this.loader.show();
    this.templateFolderService.get(this.folderId).subscribe(
      (response: any) => {
        this.folderDetails = response;
        if (this.offerType === OfferTypes.Survey) {
          this.breadcrumbs[2].label = response.name;
        } else {
          this.breadcrumbs[3].label = response.name;
        }
      },
      (error) => {
        this.alertService.error(error);
      }
    );

    this.templateFolderService.getFolderDetails(this.folderId).subscribe(
      (response) => {
        this.templates = response.map((template) => ({
          ...template,
          previewLoading: false,
        }));
        this.folderTemplates = this.templates.map((folder) => ({...folder, showThreeDotMenu: false}))
        this.loader.hide();
      },
      (error) => {
        this.loader.hide();
        this.alertService.error(error);
      }
    );
  }

  fetchFunnel() {
    this.funnelService.list({}, this.funnelId).subscribe(
      (response) => {
        this.funnelName = response.slug;
        this.breadcrumbs[1].label = response.slug;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  generateBreadcrumbs() {
    if (this.offerType === OfferTypes.Survey) {
      this.breadcrumbs = [
        {
          label:
            this.offerName.charAt(0).toUpperCase() + this.offerName.slice(1),
          url: ["steps", "survey"],
        },
        {
          label: "Choose a Template",
          url: [
            "steps",
            this.offerName,
            "category",
            this.offerType,
            "folders",
            this.stepType,
          ],
        },
        {
          label: "",
        },
      ];
    } else {
      this.breadcrumbs = [
        {
          label:
            this.offerName.charAt(0).toUpperCase() +
            this.offerName.slice(1) +
            " Offers",
          url: ["steps", this.offerName],
        },
        {
          label: "",
          url: [
            "steps",
            this.offerName,
            this.offerType,
            "details",
            this.funnelId,
          ],
        },
        {
          label: "Choose a Template",
          url: [
            "steps",
            this.offerName,
            this.funnelId,
            "category",
            this.offerType,
            "folders",
            this.stepType,
          ],
        },
        {
          label: "",
        },
      ];
    }
  }

  selectTemplate(templateId?: string | number): void {
    if (!templateId) {
      if (this.funnelId) {
        this.router.navigate([
          "steps",
          this.offerName,
          this.funnelId,
          "category",
          this.offerType,
          "step",
          this.stepType,
          "folder",
          this.folderId,
        ]);
      } else {
        this.router.navigate([
          "steps",
          this.offerName,
          "category",
          this.offerType,
          "step",
          this.stepType,
          "folder",
          this.folderId,
        ]);
      }
    } else {
      if (this.funnelId) {
        this.router.navigate([
          "steps",
          this.offerName,
          this.funnelId,
          "category",
          this.offerType,
          "step",
          this.stepType,
          "template",
          templateId,
        ]);
      } else {
        this.router.navigate([
          "steps",
          this.offerName,
          "category",
          this.offerType,
          "step",
          this.stepType,
          "template",
          templateId,
        ]);
      }
    }
  }

  getStepPreview(e: MouseEvent, id: string | number) {
    e.stopPropagation();
    const template = this.folderTemplates.find((item) => item.id === id);
    template.previewLoading = true;

    this.stepService.get(id).subscribe((resp: any) => {
      if (resp) {
        const stepDetails = {
          ...resp,
          static_data: {
            ...resp.static_data,
            image: resp.static_data.image ? resp.static_data.image.id : null,
            image_mobile: resp.static_data.image_mobile
              ? resp.static_data.image_mobile.id
              : null,
            image_tablet: resp.static_data.image_tablet
              ? resp.static_data.image_tablet.id
              : null,
          },
        };

        this.funnelService.previewStep(this.campaignId, stepDetails).subscribe(
          (data) => {
            window.open(data.toString(), "_blank");
            template.previewLoading = false;
          },
          (error) => {
            this.alertService.error(error);
            template.previewLoading = false;
          }
        );
      }
    });
  }

  openStepPopup(
    e: MouseEvent,
    popupType: number,
    step: any
  ): void {
    this.popupTitle = this.popupTitleMap[popupType] || "";

    this.popupData = {
      ...this.popupData,
      name: step.name || "",
      step_id: step.id || null,
      folder_id: this.folderId,
      is_template: true,
      popupType: popupType,
    };

    this.closeThreeDotMenu(e, step)

    this.ngxSmartModalService.getModal("stepPopup").open();
  }

  saveStep() {
    this.fetchFolderDetails();
    this.ngxSmartModalService.getModal("stepPopup").close();
  }

  closeStepPopup() {
    this.ngxSmartModalService.getModal("stepPopup").close();
  }

  editTemplate(templateId: string | number) {
    if (this.funnelId) {
      this.router.navigate([
        "steps",
        this.offerName,
        this.funnelId,
        "category",
        this.offerType,
        "step",
        this.stepType,
        "folder",
        this.folderId,
        "template",
        templateId,
      ]);
    } else {
      this.router.navigate([
        "steps",
        this.offerName,
        "category",
        this.offerType,
        "step",
        this.stepType,
        "folder",
        this.folderId,
        "template",
        templateId,
      ]);
    }
  }

  searchData() {
    if (this.searchQuery) {
      this.folderTemplates = this.templates.filter((template) =>
        template.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    } else {
      this.folderTemplates = this.templates;
    }
  }

  selectFilter(filter: OrderType | null) {
    if (!filter) {
      this.selectedFilter = null;
      this.folderTemplates = this.templates;
    } else {
      this.selectedFilter = filter;
      this.folderTemplates = this.templates.filter(
        (template) => template.order_type === filter
      );
    }
  }

  navigate(url: any[]) {
    this.router.navigate(url);
  }

  get isOneTime() {
    return this.selectedFilter === OrderType.OneTime;
  }

  get isSubscription() {
    return this.selectedFilter === OrderType.Subscription;
  }

  get isTrial() {
    return this.selectedFilter === OrderType.Trial;
  }

  get isPurchaseType(): boolean {
    return [
      CustomStepCategoryEnum.PercentageDownsell,
      CustomStepCategoryEnum.FixedAmountDownsell,
      CustomStepCategoryEnum.RefundWithDiscount,
      CustomStepCategoryEnum.ReturnOrderRMA,
    ].includes(+this.stepType);
  }

  ngOnDestroy(): void {
    this.sideNav.setShowSideNavToggle(true);
    this.sideNav.setSideNavExpanded(true);
  }

  closeOtherMenus(currentItem: any): void {
    this.folderTemplates.forEach((template) => {
      if (template.id !== currentItem.id) {
        template.showThreeDotMenu = false;
      }
    });
  }

  toggleThreeDotMenu(e: MouseEvent, item: any): void {
    e.stopPropagation()
    this.closeOtherMenus(item);
    item.showThreeDotMenu = !item.showThreeDotMenu;
  }

  closeOutsideMenu() {
    if (this.folderTemplates && this.folderTemplates.length) {     
      this.folderTemplates.forEach((template) => {
        template.showThreeDotMenu = false;
      });
    }
  }

  closeThreeDotMenu(e: MouseEvent, item: any) {
    e.stopPropagation();
    item.showThreeDotMenu = false;
  }

  popupIconMap: Partial<Record<StepPopupActions, string>> = {
    [StepPopupActions.Delete]: '/assets/stepAssets/delete-icon.svg',
    [StepPopupActions.Duplicate]: '/assets/stepAssets/duplicate.svg'
  };
}
