import { ImageGalleryComponent } from "../../../image/image-gallery.component";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  StepMedia,
  StepImagesLayout,
  StepMediaCategory,
  StepEmulatorDevices,
  StepStaticDataImageType,
  StepFixedMediaCategory,
  StepMediaSubCategory,
  StepElements,
} from "../../../_models";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Location } from "@angular/common";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Subscription } from "rxjs";
import {
  StepEmulatorService,
  StepTemplateService,
  AlertService,
  ImageService,
} from "../../../_services";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { FormControlStatus } from "../../../_forms";
import { getEmbeddedVideoUrl } from "../../step-data";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "step-media",
  templateUrl: "./step-media.component.html",
  styleUrls: ["./step-media.component.scss"],
  animations: [
    trigger("fadeInOut", [
      state("void", style({ opacity: 0 })),
      transition(":enter", [animate("500ms ease-in", style({ opacity: 1 }))]),
      transition(":leave", [animate("300ms ease-out", style({ opacity: 0 }))]),
    ]),
  ],
})
export class StepMediaComponent
  extends ImageGalleryComponent
  implements OnInit, OnDestroy
{
  @Input() layout: StepImagesLayout;
  @Input() stepForm: FormGroup;
  @Input() enableFormErrors: boolean;
  stepMedia: StepMedia;
  stepElements = StepElements;
  private subscription: Subscription;
  stepMediaCategory = StepMediaCategory;
  stepMediaSubCategory = StepMediaSubCategory;
  emulatorDevices = StepEmulatorDevices;
  stepId: string = "";
  templateId: string = "";

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected stepService: StepTemplateService,
    protected stepEmulatorService: StepEmulatorService,
    protected imageService: ImageService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected modalService: NgxSmartModalService,
    private sanitizer: DomSanitizer
  ) {
    super(
      router,
      location,
      route,
      imageService,
      alertService,
      formBuilder,
      modalService
    );
    this.route.params.subscribe((params) => {
      this.stepId = params["step_id"];
      this.templateId = params["template_id"];
    });
  }

  ngOnInit() {
    this.pageSize = 14;
    this.subscription = this.stepEmulatorService.stepMedia$.subscribe(
      (data) => {
        this.stepMedia = data;
      }
    );

    if (!this.templateId && !this.stepId && !this.layout.enabled) {
      this.stepMedia.isEnabled = false;
    }

    super.ngOnInit();
    this.setDefaultImage();
  }

  setDefaultImage() {
    if (this.layout.placeholderImage) {
      this.stepMedia.placeholderImage = this.layout.placeholderImage;
    }
    this.updateStepMedia();
  }

  updateStepMedia() {
    if (!this.stepMedia.isEnabled) {
      this.stepForm.setControl(
        "static_data",
        this.formBuilder.group({
          image: null,
          image_tablet: null,
          image_mobile: null,
          embed_code: null,
          image_type: StepStaticDataImageType.None,
        })
      );
      return;
    }

    switch (this.stepMedia.category) {
      case StepMediaCategory.Product:
        if (this.isProductSub) {
          this.stepForm.setControl(
            "static_data",
            this.formBuilder.group({
              image: null,
              image_tablet: null,
              image_mobile: null,
              embed_code: null,
              image_type: StepStaticDataImageType.Product,
            })
          );
        } else if (this.isTagged) {
          this.stepForm.setControl(
            "static_data",
            this.formBuilder.group({
              image: null,
              image_tablet: null,
              image_mobile: null,
              embed_code: null,
              image_type: StepStaticDataImageType.Tagged,
            })
          );
        }
        break;

      case StepMediaCategory.Fixed:
        switch (this.stepMedia.fixedImage.category) {
          case StepFixedMediaCategory.Upload:
            const desktopImageId =
              this.stepMedia.fixedImage[StepEmulatorDevices.Desktop].id;
            const tabletImageId =
              this.stepMedia.fixedImage[StepEmulatorDevices.Tablet].id;
            const mobileImageId =
              this.stepMedia.fixedImage[StepEmulatorDevices.Mobile].id;

            this.stepForm.setControl(
              "static_data",
              this.formBuilder.group({
                image: [
                  desktopImageId || mobileImageId || tabletImageId,
                  Validators.required,
                ],
                image_tablet: tabletImageId,
                image_mobile: mobileImageId,
                embed_code: null,
                image_type: StepStaticDataImageType.Fixed,
              })
            );

            break;

          case StepFixedMediaCategory.Gallery:
            const image =
              (this.stepMedia.fixedImage.productImage &&
                this.stepMedia.fixedImage.productImage.id) ||
              null;

            this.stepForm.setControl(
              "static_data",
              this.formBuilder.group({
                image: [image, Validators.required],
                image_tablet: [image, Validators.required],
                image_mobile: [image, Validators.required],
                embed_code: null,
                image_type: StepStaticDataImageType.Gallery,
              })
            );

            break;
        }
        break;

      case StepMediaCategory.Embed:
        const embed_code = this.stepMedia.embedVideo.isURLValid
          ? this.stepMedia.embedVideo.sanitizedURL[
              "changingThisBreaksApplicationSecurity"
            ]
          : null;

        this.stepForm.setControl(
          "static_data",
          this.formBuilder.group({
            image: null,
            image_tablet: null,
            image_mobile: null,
            embed_code: [embed_code, Validators.required],
            image_type: StepStaticDataImageType.Embed,
          })
        );
        break;
    }

    this.stepEmulatorService.updateStepMedia(this.stepMedia);
  }

  checkEmbedURL() {
    this.stepMedia.embedVideo.sanitizedURL = getEmbeddedVideoUrl(
      this.stepMedia.embedVideo.url,
      this.sanitizer
    );

    this.stepMedia.embedVideo.isURLValid = this.stepMedia.embedVideo
      .sanitizedURL
      ? true
      : false;

    this.updateStepMedia();
  }

  toggleElement(element: StepElements) {
    this.stepEmulatorService.toggleElement(element);
  }

  isElementVisible(element: StepElements): boolean {
    return this.stepEmulatorService.isElementVisible(element);
  }

  get isProduct() {
    return this.stepMedia.category === StepMediaCategory.Product;
  }

  get isProductSub() {
    return this.stepMedia.subCategory === StepMediaSubCategory.Product;
  }

  get isTagged() {
    return this.stepMedia.subCategory === StepMediaSubCategory.Tagged;
  }

  get isFixed() {
    return this.stepMedia.category === StepMediaCategory.Fixed;
  }

  get isUpload() {
    return this.stepMedia.fixedImage.category === StepFixedMediaCategory.Upload;
  }

  get isEmbed() {
    return this.stepMedia.category === StepMediaCategory.Embed;
  }

  get mediaInvalidity() {
    return (
      this.enableFormErrors &&
      this.stepForm.controls.static_data.status === FormControlStatus.Invalid
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
