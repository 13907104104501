import {Component, Input} from '@angular/core';
import {CampaignProductSelectComponent} from './campaign-product-select.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {
  AccountService,
  AlertService,
  CampaignProductService, CampaignService,
  CRMCampaignService,
  CRMService,
  FunnelService,
  ProductCategoryService,
  ProductService, UserService
} from '../_services';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {FormBuilder} from '@angular/forms';

@Component({
  moduleId: module.id.toString(),
  selector: 'campaign-product-select-paginated',
  templateUrl: './campaign-product.component.html'
})
export class CampaignProductSelectPaginatedComponent extends CampaignProductSelectComponent {
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected productService: ProductService,
    protected campaignProductService: CampaignProductService,
    protected alertService: AlertService,
    protected crmService: CRMService,
    protected crmCampaignService: CRMCampaignService,
    protected categoryService: ProductCategoryService,
    protected funnelService: FunnelService,
    protected modalService: NgxSmartModalService,
    protected formBuilder: FormBuilder,
    protected userService: UserService,
    protected accountService: AccountService,
    protected campaignService: CampaignService
  ) {
    super(router, location, route, productService, campaignProductService, alertService, crmService,
      crmCampaignService, categoryService, funnelService, modalService, formBuilder, userService, accountService,
      campaignService);
  }

  postFetchCampaignProductIds() {
    if (this.useBaseProduct) {
      this.selectedProductsMap = {...this.selectedBaseProductMap}
    }
  }

  save() {
    const productIds = Object.keys(this.selectedProductsMap)
    this.onSelectProductsEvent.emit(productIds);
  }
}
