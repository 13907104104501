import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  private loader: boolean = false;
  private backgroundColor = "rgba(255, 255, 255, 0.7)";
  private text = "";

  constructor() {}

  setLoading(state: boolean) {
    this.loader = state;
  }

  show(backgroundColor?: string, text?: string) {
    this.loader = true;
    if (backgroundColor) this.backgroundColor = backgroundColor;
    if (text) this.text = text;
  }

  hide() {
    this.loader = false;
    this.backgroundColor = "rgba(255, 255, 255, 0.7)";
    this.text = "";
  }

  getLoaderState(): boolean {
    return this.loader;
  }

  getLoaderBackground(): string {
    return this.backgroundColor;
  }

  getLoaderText(): string {
    return this.text;
  }
}
