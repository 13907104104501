import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {FormBuilder} from '@angular/forms';
import {AlertService, FunnelService, CampaignService, UserService} from '../_services';
import {FunnelComponent} from "./funnel.component";
import {NgxSmartModalService} from "ngx-smart-modal";
import {OfferTypes, FunnelType, OfferIntents} from '../_models';

@Component({
  moduleId: module.id.toString(),
  templateUrl: '../funnel/funnel.component.html',
  styleUrls: ['../funnel/funnel.component.css']
})
export class ProductFunnelComponent extends FunnelComponent implements OnInit {
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected funnelService: FunnelService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected campaignService: CampaignService,
    protected userService: UserService,
    protected modalService: NgxSmartModalService
  ) {
    super(router, location, route, funnelService, alertService, formBuilder, campaignService, userService,
      modalService);
    this.objectName = 'Subpath';
    this.title = 'My Subpaths';
    this.defaultFunnelType = FunnelType.Product;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected initializeFilter() {
    this.filter = {resourcetype: 'ProductFunnel', offer_intent: OfferIntents.Legacy}; //only show product funnels
  }

}
