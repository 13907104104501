import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  StepEmulatorDevices,
  StepLayout,
  StepButtons,
  StepMedia,
  StepMediaCategory,
  OfferTypes,
  OfferTypeLabels,
  Breadcrumb,
  StepEmulationTypes,
  OfferIntents,
  OrderType,
  CustomStepCategoryEnum,
  FunnelInputTypeEnum,
  StepCategoryLabels,
  ActionType,
  DiscountTypeEnum,
  ExecutionTypeEnum,
  StepStaticDataImageType,
  StepFixedMediaCategory,
  StepButtonControlTypes,
  Campaign,
  defaultThemeColor,
  StepButtonShapes,
  transparent,
  StepMediaSubCategory,
  StepTextual,
  StepButtonDetails,
  StepTextualBanner,
  CampaignProduct,
  Image,
} from "../../_models";
import {
  AlertService,
  CampaignService,
  FunnelService,
  FunnelStepService,
  LoaderService,
  StepEmulatorService,
  StyleService,
  StepTemplateService,
  SideNavService,
  TemplateFolderService,
} from "../../_services";
import { Subscription } from "rxjs";
import {
  addDataAttributes,
  getEmbeddedVideoUrl,
  normalizeObject,
  stepLayout,
  surveyResourceMapping,
} from "../step-data";
import { config } from "../../../config/config";
import { getCampaignProductImageUrl } from "../../_helpers";
import { Form } from "../../_forms";
import { Location } from "@angular/common";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { NgxSmartModalService } from "ngx-smart-modal";
import { getTruncatedProductName } from "../step-data";

@Component({
  selector: "app-step-builder",
  templateUrl: "./step-builder.component.html",
  styleUrls: ["./step-builder.component.scss"],
})
export class StepBuilderComponent
  extends Form
  implements OnInit, OnDestroy, AfterViewInit
{
  activeDevice = StepEmulatorDevices.Mobile;
  stepLayout: StepLayout[] = [];
  stepTextual: StepTextual;
  stepMedia: StepMedia;
  stepButtons: StepButtons;
  private subscriptions: Subscription[] = [];
  stepTitle: string = "";
  stepType: CustomStepCategoryEnum;
  stepId: string = "";
  funnelId: string = "";
  folderId: string = "";
  templateId: string = "";
  funnel: any;
  offerType: OfferTypes;
  offerName: string;
  category: string = "";
  isStepFormActive: boolean = true;
  isDropdownActive: boolean = true;
  showResponseButton: boolean = true;
  isResponseScreen: boolean = false;
  isStepPreview: boolean = true;
  isStepSaving: boolean = false;
  isStepDraftSaving: boolean = false;
  isNew: boolean = true;
  breadcrumbs: Breadcrumb[] = [];
  campaigns: any = [];
  selectedBrandId: string | null = null;
  selectedProduct: CampaignProduct = {} as CampaignProduct;
  productPlaceholderUrl: string = "";
  campaignsLoading: boolean = true;
  themes: any;
  theme: string = defaultThemeColor;
  useExternalButtons = false;
  stepEmulationTypes = StepEmulationTypes;
  categoryToOfferIntentMapping = {};
  isPurchaseType = false;
  enableFormErrors = false;
  folderDetails: any;

  constructor(
    private route: ActivatedRoute,
    protected location: Location,
    private stepService: StepTemplateService,
    private stepEmulatorService: StepEmulatorService,
    private funnelService: FunnelService,
    private funnelStepService: FunnelStepService,
    private sideNav: SideNavService,
    protected alertService: AlertService,
    protected router: Router,
    private loader: LoaderService,
    private campaignService: CampaignService,
    protected styleService: StyleService,
    protected templateFolderService: TemplateFolderService,
    protected formBuilder: FormBuilder,
    public ngxSmartModalService: NgxSmartModalService,
    private sanitizer: DomSanitizer
  ) {
    super(alertService, router, location);
    this.sideNav.setShowSideNavToggle(false);
    this.sideNav.setSideNavExpanded(false);
    this.route.params.subscribe((params) => {
      this.stepType = parseInt(params["step_type"]);
      this.offerType = parseInt(params["offer_type"]);
      this.offerName = OfferTypeLabels[this.offerType];
      this.stepId = params["step_id"];
      this.funnelId = params["offer_id"];
      this.folderId = params["folder_id"];
      this.templateId = params["template_id"];
      this.isNew = !this.stepId;
      this.stepLayout = stepLayout[this.stepType] || [];
      this.stepTitle = this.getTitleForStepType(this.stepType, this.isNew);
      this.isPurchaseType = this.purchaseType;
    });

    this.categoryToOfferIntentMapping[
      CustomStepCategoryEnum.CancelOrderSurvey
    ] = OfferIntents.CancelOrder;
    this.categoryToOfferIntentMapping[
      CustomStepCategoryEnum.CancelTrialSurvey
    ] = OfferIntents.CancelTrial;
    this.categoryToOfferIntentMapping[CustomStepCategoryEnum.CancelSubSurvey] =
      OfferIntents.CancelSubscription;
    this.categoryToOfferIntentMapping[CustomStepCategoryEnum.ReturnSurvey] =
      OfferIntents.ReturnOrder;
  }

  ngOnInit() {
    this.loader.show("white", "Gearing up the builder", 998);
    this.initializeForm();
    this.getStyles();
    this.getCampaigns();
    this.generateBreadcrumbs();

    const textualSubscription = this.stepEmulatorService.stepTextual$.subscribe(
      (data) => {
        this.stepTextual = data;
      }
    );
    this.addSubscription(textualSubscription);

    const mediaSubscription = this.stepEmulatorService.stepMedia$.subscribe(
      (data) => {
        this.stepMedia = data;
      }
    );
    this.addSubscription(mediaSubscription);

    const buttonsSubscription = this.stepEmulatorService.stepButtons$.subscribe(
      (data) => {
        this.stepButtons = data;
      }
    );
    this.addSubscription(buttonsSubscription);

    if (this.folderId) {
      this.fetchFolderDetails();
    }

    if (this.templateId) {
      this.fetchTemplate();
    }

    if (this.stepId && this.funnelId) {
      this.isNew = false;
      this.funnelStepService.setFunnelId(this.funnelId);
      this.fetchFunnel();
      this.fetchOfferStep();
    } else if (this.funnelId) {
      this.fetchFunnel();
      this.funnelStepService.setFunnelId(this.funnelId);
    } else if (this.stepId) {
      this.isNew = false;
      this.fetchStep();
    }
  }

  ngAfterViewInit() {
    if (this.isNew && !this.templateId) {
      setTimeout(() => {
        this.stepEmulatorService.hideAllElements();
        this.loader.hide();
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 3000);
    } else {
      this.loader.loader$.subscribe((state) => {
        if (!state) {
          this.stepEmulatorService.hideAllElements();
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      });
    }
  }

  initializeForm() {
    this.form = this.formBuilder.group({
      id: null,
      subtitle: null,
      style: null,
      classes: [[]],
      is_popup: false,
      show_step_link: false,
      contact_type: null,
      enhanced_content: null,
      enhanced_mode: false,
      fail_if_any_input_invalid: false,
      invalid_message: null,
      hide_status: false,
      action_holdoffs: null,
      allow_undo:
        this.offerType !== OfferTypes.Downsell &&
        this.offerType !== OfferTypes.Upsell,
      mobile_icon_button_type: null,
      use_bootstrap_spacing: true,
      enhanced_content_components: null,
      icon: null,
      help: null,
      enabled: null,
      enter_product_funnel: false,
      enter_troubleshooter: false,
      error_lifeline: null,
      exit_lifeline: null,
      faq_topic: null,
      is_first_step: false,
      is_template: false,
      is_global: false,
      item_statuses: null,
      lifeline: null,
      lifeline_enabled: true,
      slug: null,
      sms_prompt: null,
      voice_file: null,
      voice_prompt: null,
      num_digits: null,
      offer_intent: OfferIntents.Legacy,
      phone_loop_count: 1,
      previous_inputs: [],
      group: null,
      forward_to_call_center: false,
      hide_if_invalid: true,
      campaign_products: [[]],
      product_funnels: [[]],
      is_alt_child_exit: false,
      matched_step_key: null,
      autopick_next_step: null,
      requires_active_item: true,
      requires_item: true,
      requires_customer: true,
      type: FunnelInputTypeEnum.Choice,
      category: [this.stepType],
      banner: [null],
      label: [null, Validators.required],
      name: [null, Validators.required],
      content: [null, Validators.required],
      button_styles: null,
      static_data: this.formBuilder.group({
        image: null,
        image_tablet: null,
        image_mobile: null,
        embed_code: null,
        image_type: null,
      }),
    });

    let inputs, response_data;

    switch (this.stepType) {
      case CustomStepCategoryEnum.Survey:
      case CustomStepCategoryEnum.ReturnSurvey:
      case CustomStepCategoryEnum.CancelSubSurvey:
      case CustomStepCategoryEnum.CancelOrderSurvey:
      case CustomStepCategoryEnum.CancelTrialSurvey:
        this.useExternalButtons = true;
        this.form.patchValue({
          type: FunnelInputTypeEnum.Radio,
        });

        this.form.setControl(
          "inputs",
          this.formBuilder.array([], Validators.required)
        );

        this.form.setControl(
          "button_styles",
          this.formBuilder.group({
            confirm: this.formBuilder.group({
              label: [null, Validators.required],
              style: [null],
              control_type: [null],
              style_type: [null],
            }),
            back: this.formBuilder.group({
              label: [null, Validators.required],
              style: [null],
              control_type: [null],
              style_type: [null],
            }),
          })
        );
        break;

      case CustomStepCategoryEnum.AdjustShippingFrequency:
      case CustomStepCategoryEnum.PauseSurvey:
        this.useExternalButtons = true;
        this.form.patchValue({
          type: FunnelInputTypeEnum.Radio,
        });
        response_data = this.getResponseData();

        this.form.setControl(
          "inputs",
          this.formBuilder.array([], Validators.required)
        );
        this.form.setControl("response_data", response_data);
        this.form.setControl(
          "button_styles",
          this.formBuilder.group({
            confirm: this.formBuilder.group({
              label: [null, Validators.required],
              style: [null],
              control_type: [null],
              style_type: [null],
            }),
            back: this.formBuilder.group({
              label: [null, Validators.required],
              style: [null],
              control_type: [null],
              style_type: [null],
            }),
          })
        );
        break;
      default:
        inputs = this.getDownsellInputs();
        response_data = this.getResponseData();

        this.form.setControl("inputs", inputs);
        this.form.setControl("response_data", response_data);
        break;
    }

    if (this.folderId) {
      this.form.addControl("folder", this.formBuilder.control(+this.folderId));
      this.form.addControl(
        "order_type",
        this.formBuilder.control(OrderType.OneTime)
      );
    } else {
      this.form.addControl("funnel", this.formBuilder.control(null));
    }
  }

  getSurveyInputs(): FormGroup {
    const input = this.formBuilder.group({
      id: null,
      label: [null, Validators.required],
      help: null,
      next_step: null,
      type: FunnelInputTypeEnum.Radio,
      values: [[]],
      actions:
        this.offerType === OfferTypes.Survey
          ? this.getSurveyActions()
          : this.getDownsellSurveyActions(),
      icon: null,
      hide_if_invalid: true,
      product_funnels: [[]],
      classes: [[]],
      is_alt_child_exit: false,
      matched_step_key: null,
      autopick_next_step: null,
      requires_active_item: true,
      requires_item: true,
      requires_customer: true,
      has_response_step: true,
    });

    if (this.offerType === OfferTypes.Survey) {
      input.addControl(
        "reason_category",
        new FormControl(null, Validators.required)
      );
    }

    return input;
  }

  getSurveyActions(): FormArray {
    const resourcetype = surveyResourceMapping[this.stepType],
      cancel_subscription =
        this.stepType === CustomStepCategoryEnum.CancelSubSurvey ||
        this.stepType === CustomStepCategoryEnum.CancelTrialSurvey;

    return this.formBuilder.array([
      this.formBuilder.group({
        id: null,
        execution_type: ExecutionTypeEnum.ValidationOnly,
        resourcetype,
        cancel_subscription,
        reason: [null, Validators.required],
      }),
    ]);
  }

  getDownsellSurveyActions(): FormArray {
    return this.formBuilder.array([
      this.formBuilder.group({
        id: null,
        execution_type: ExecutionTypeEnum.Immediate,
        resourcetype: ActionType.BillingCycle,
        billing_interval_days: null,
        check_last_discount: true,
        pause: false,
        final_billing_cycle_offset: 0,
        bill_now: false,
        next_bill_date_delay: null,
        max_bill_date_delay: null,
      }),
      this.formBuilder.group({
        id: null,
        resourcetype: ActionType.CancelDelayed,
      }),
    ]);
  }

  getDownsellInputs(): FormArray {
    return this.formBuilder.array([
      this.formBuilder.group({
        id: null,
        label: [null, Validators.required],
        help: null,
        next_step: null,
        step: null,
        type: FunnelInputTypeEnum.Choice,
        values: [[]],
        actions: this.getDownsellInputActions(),
        icon: null,
        hide_if_invalid: true,
        product_funnels: [[]],
        classes: [[]],
        is_alt_child_exit: true,
        matched_step_key: null,
        autopick_next_step: null,
        has_response_step: true,
        requires_active_item: true,
        requires_item: true,
        requires_customer: true,
        builder_id: null,
        enter_product_funnel: false,
        enter_troubleshooter: false,
        item_statuses: null,
        button_styles: this.formBuilder.group({
          style: null,
          control_type: null,
          style_type: null,
        }),
      }),
      this.formBuilder.group({
        id: null,
        label: [null, Validators.required],
        help: null,
        next_step: null,
        step: null,
        type: FunnelInputTypeEnum.Choice,
        values: this.formBuilder.array([]),
        actions: this.formBuilder.array([]),
        icon: null,
        hide_if_invalid: true,
        product_funnels: [[]],
        classes: [[]],
        is_alt_child_exit: true,
        matched_step_key: null,
        autopick_next_step: null,
        requires_active_item: true,
        requires_item: true,
        requires_customer: true,
        has_response_step: false,
        builder_id: null,
        enter_product_funnel: false,
        enter_troubleshooter: false,
        item_statuses: null,
        button_styles: this.formBuilder.group({
          style: null,
          control_type: null,
          style_type: null,
        }),
      }),
    ]);
  }

  getDownsellInputActions(): FormArray {
    let discount_index = 0,
      upsale_index = 0,
      action1,
      action2,
      resourcetype,
      final_billing_cycle_offset = 0,
      discount = 20, // % or $
      next_bill_date_delay = 32, // days
      holdoff_delay = 2; // hours

    action2 = this.formBuilder.group({
      id: null,
      resourcetype: ActionType.CancelDelayed,
    });

    switch (this.stepType) {
      case CustomStepCategoryEnum.FixedAmountDownsell:
        action1 = this.formBuilder.group({
          id: null,
          execution_type: ExecutionTypeEnum.Immediate,
          resourcetype: ActionType.Discount,
          discount: [discount, [Validators.required, Validators.min(1)]],
          discount_type: [DiscountTypeEnum.Fixed, Validators.required],
        });

        break;

      case CustomStepCategoryEnum.PercentageDownsell:
        action1 = this.formBuilder.group({
          id: null,
          execution_type: ExecutionTypeEnum.Immediate,
          resourcetype: ActionType.Discount,
          discount: [
            discount,
            [Validators.required, Validators.min(1), Validators.max(100)],
          ],
          discount_type: [DiscountTypeEnum.Percent, Validators.required],
        });

        break;

      case CustomStepCategoryEnum.PauseSub:
      case CustomStepCategoryEnum.ExtendTrial:
        action1 = this.formBuilder.group({
          id: null,
          execution_type: ExecutionTypeEnum.Immediate,
          resourcetype: ActionType.BillingCycle,
          billing_interval_days: null,
          check_last_discount: true,
          pause: true,
          final_billing_cycle_offset,
          bill_now: false,
          next_bill_date_delay: [next_bill_date_delay, Validators.required],
          max_bill_date_delay: null,
        });
        break;

      case CustomStepCategoryEnum.OfferDetails:
      case CustomStepCategoryEnum.LossAversion:
      case CustomStepCategoryEnum.Testimonial:
        action1 = this.formBuilder.group({
          id: null,
          resourcetype: ActionType.CancelDelayed,
          execution_type: ExecutionTypeEnum.Immediate,
        });

        break;

      case CustomStepCategoryEnum.RefundWithDiscount:
      case CustomStepCategoryEnum.DiscountFutureOrder:
      case CustomStepCategoryEnum.ReturnOrderRMA:
        resourcetype =
          this.stepType === CustomStepCategoryEnum.RefundWithDiscount ||
          this.stepType === CustomStepCategoryEnum.ReturnOrderRMA
            ? ActionType.Refund
            : ActionType.Discount;

        action1 = this.formBuilder.group({
          id: null,
          execution_type: ExecutionTypeEnum.Delayed,
          resourcetype: resourcetype,
          discount: [discount, Validators.required],
          discount_index,
          discount_type: [DiscountTypeEnum.Fixed, Validators.required],
          check_last_discount: false,
          bill_now: true,
          include_tax: true,
          holdoff_delay: [holdoff_delay, Validators.required],
          final_billing_cycle_offset,
          requires_active_item: true,
          requires_item: true,
          requires_customer: true,
        });
        break;

      case CustomStepCategoryEnum.SwapProduct:
        action1 = this.formBuilder.group({
          id: null,
          resourcetype: ActionType.ChangeProduct,
          requires_active_item: true,
          requires_item: true,
          requires_customer: true,
          exchangeable_set_types: [[], Validators.required],
          exchangeable_set: [[], Validators.required],
        });
        break;

      case CustomStepCategoryEnum.UpsellOrder:
        action1 = this.formBuilder.group({
          id: null,
          execution_type: ExecutionTypeEnum.Immediate,
          resourcetype: ActionType.AddProduct,
          upsale_index,
          holdoff_delay: [holdoff_delay, Validators.required],
          requires_active_item: true,
          requires_item: true,
          requires_customer: true,
        });
        break;

      case CustomStepCategoryEnum.ReActivateSubscriptionWithDiscount:
        action1 = this.formBuilder.group({
          id: null,
          execution_type: ExecutionTypeEnum.Immediate,
          resourcetype: ActionType.Reactivate,
          discount: [discount, Validators.required],
          discount_index,
          discount_type: [DiscountTypeEnum.Fixed, Validators.required],
          check_last_discount: false,
          final_billing_cycle_offset,
          billing_interval_days: null,
          next_bill_date_delay: null,
          max_bill_date_delay: null,
          bill_now: true,
          requires_active_item: true,
          requires_item: true,
          requires_customer: true,
        });

        break;
    }

    return this.formBuilder.array([action1, action2]);
  }

  getResponseData(): FormGroup {
    const label = "continue";

    return this.formBuilder.group({
      label: [null, Validators.required],
      help: null,
      name: [null],
      type: FunnelInputTypeEnum.InputActionStatus,
      category: CustomStepCategoryEnum.Response,
      banner: null,
      subtitle: null,
      content: [null, Validators.required],
      offer_intent: OfferIntents.Other,
      style: null,
      classes: [[]],
      is_popup: false,
      allow_undo: false,
      show_step_link: false,
      contact_type: null,
      enhanced_content: null,
      enhanced_mode: false,
      fail_if_any_input_invalid: false,
      invalid_message: null,
      hide_status: false,
      action_holdoffs: null,
      mobile_icon_button_type: null,
      use_bootstrap_spacing: true,
      enhanced_content_components: null,
      inputs: this.formBuilder.array([
        {
          label,
          help: null,
          next_step: null,
          type: FunnelInputTypeEnum.InputActionStatus,
          values: [],
          actions: [],
          icon: null,
          hide_if_invalid: true,
          product_funnels: [],
          classes: [],
          is_alt_child_exit: true,
          matched_step_key: null,
          autopick_next_step: null,
          requires_active_item: false,
          requires_item: true,
          requires_customer: true,
          allow_undo: false,
        },
      ]),
    });
  }

  getCampaigns() {
    this.campaignService.list({}).subscribe(
      (data) => {
        this.campaigns = data.results;
        if (this.campaigns.length === 1) {
          this.selectedBrandId = this.campaigns[0].id;
          this.updateBrandTheme();
        }
        this.campaignsLoading = false;
      },
      (error) => {
        this.campaignsLoading = false;
        this.alertService.error(error);
      }
    );
  }

  generateBreadcrumbs() {
    if (this.offerType === OfferTypes.Survey) {
      this.breadcrumbs = [
        {
          label:
            this.offerName.charAt(0).toUpperCase() + this.offerName.slice(1),
          url: ["steps", "survey"],
        },
        ...(this.isNew
          ? [
              {
                label: "Choose a Template",
                url: ["steps", "survey", "category", this.offerType],
              },
            ]
          : []),
        {
          label: this.stepTitle,
        },
      ];
    } else {
      this.breadcrumbs = [
        {
          label:
            this.offerName.charAt(0).toUpperCase() +
            this.offerName.slice(1) +
            " Offers",
          url: ["steps", this.offerName],
        },
        {
          label: "",
          url: [
            "steps",
            this.offerName,
            this.offerType,
            "details",
            this.funnelId,
          ],
        },
        ...(this.isNew
          ? [
              {
                label: "Choose a Template",
                url: [
                  "steps",
                  this.offerName,
                  this.funnelId,
                  "category",
                  this.offerType,
                ],
              },
            ]
          : []),
        {
          label: this.stepTitle,
        },
      ];
    }
  }

  getStyles() {
    this.styleService
      .list({ page: 1, page_size: config.maxPageSize })
      .subscribe((resp) => {
        this.themes = resp.results;
      }),
      (err) => this.alertService.error(err.message);
  }

  scroll() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  fetchFunnel() {
    this.funnelService.get(this.funnelId).subscribe(
      (response: any) => {
        this.funnel = response;
        this.breadcrumbs[1].label = response.slug;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  fetchTemplate() {
    this.loader.show("white", "Gearing up the builder", 998);

    this.stepService.get(this.templateId).subscribe(
      (response: any) => {
        if (response) {
          const data =
            !this.folderId && this.templateId
              ? normalizeObject(response)
              : response;
          this.patchInputs(data);

          if (data.static_data) {
            this.updateStepMedia(data.static_data);
          }
        }
        this.loader.hide();
      },
      (error) => {
        this.alertService.error(error);
        this.loader.hide();
      }
    );
  }

  fetchOfferStep() {
    this.loader.show("white", "Gearing up the builder", 998);

    // Offer Step Fetching
    this.funnelStepService.get(this.stepId).subscribe(
      (data: any) => {
        if (data) {
          this.patchInputs(data);

          if (data.static_data) {
            this.updateStepMedia(data.static_data);
          }
        }
        this.loader.hide();
      },
      (error) => {
        this.alertService.error(error);
        this.loader.hide();
      }
    );
  }

  fetchStep() {
    this.loader.show("white", "Gearing up the builder", 998);

    //Survey Step Fetching
    this.stepService.get(this.stepId).subscribe(
      (data: any) => {
        if (data) {
          if (data.inputs && data.inputs.length > 0) {
            const inputs = data.inputs.map(() => {
              return this.getSurveyInputs();
            });
            if (inputs) {
              this.form.setControl(
                "inputs",
                this.formBuilder.array(inputs, Validators.required)
              );
            }
          }

          this.form.patchValue(data);
          if (data.static_data) {
            this.updateStepMedia(data.static_data);
          }
        }
        this.loader.hide();
      },
      (error) => {
        this.alertService.error(error);
        this.loader.hide();
      }
    );
  }

  fetchFolderDetails() {
    this.templateFolderService.get(this.folderId).subscribe(
      (response: any) => {
        this.folderDetails = response;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  updateStepMedia(static_data) {
    const { image, image_mobile, image_tablet, image_type, embed_code } =
      static_data;

    switch (image_type) {
      /*  Product image */
      case StepStaticDataImageType.Product:
        this.stepMedia.category = StepMediaCategory.Product;
        this.stepMedia.subCategory = StepMediaSubCategory.Product;
        break;

      /*  Tagged image */
      case StepStaticDataImageType.Tagged:
        this.stepMedia.category = StepMediaCategory.Product;
        this.stepMedia.subCategory = StepMediaSubCategory.Tagged;
        break;

      /* Fixed Image */
      case StepStaticDataImageType.Fixed:
        const setFixedImage = (device: StepEmulatorDevices, image: Image) => {
          if (image) {
            this.stepMedia.category = StepMediaCategory.Fixed;
            this.stepMedia.fixedImage.category = StepFixedMediaCategory.Upload;
            this.stepMedia.fixedImage[device] = image;
          }
        };

        // Set images for each device
        setFixedImage(StepEmulatorDevices.Mobile, image_mobile);
        setFixedImage(StepEmulatorDevices.Tablet, image_tablet);
        setFixedImage(StepEmulatorDevices.Desktop, image);
        break;

      /* Gallery Image */
      case StepStaticDataImageType.Gallery:
        if (image) {
          this.stepMedia.category = StepMediaCategory.Fixed;
          this.stepMedia.fixedImage.category = StepFixedMediaCategory.Gallery;

          this.stepMedia.fixedImage.productImage = image;
        }
        break;

      /* Embed Video */
      case StepStaticDataImageType.Embed:
        this.stepMedia.category = StepMediaCategory.Embed;
        this.stepMedia.embedVideo.url = embed_code;
        this.stepMedia.embedVideo.sanitizedURL = getEmbeddedVideoUrl(
          this.stepMedia.embedVideo.url,
          this.sanitizer
        );

        this.stepMedia.embedVideo.isURLValid = this.stepMedia.embedVideo
          .sanitizedURL
          ? true
          : false;
        break;

      /* Media: Disabled */
      case StepStaticDataImageType.None:
        this.stepMedia.isEnabled = false;
        break;
    }

    this.stepEmulatorService.updateStepMedia(this.stepMedia);
  }

  updateBrandTheme() {
    if (!this.selectedBrandId) {
      this.theme = defaultThemeColor;
      return;
    }

    let styleId, theme;
    this.campaignService.get(this.selectedBrandId).subscribe(
      (campaign: Campaign) => {
        if (campaign) {
          if (campaign.styles && campaign.styles.length) {
            styleId = campaign.styles[0];
          }
        }

        if (styleId && this.themes.length > 0) {
          theme = this.themes.find((theme) => theme.id === styleId).variables
            .theme_color;
        }

        if (theme) {
          this.theme = theme;
        }
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  updateProductImageUrl() {
    this.productPlaceholderUrl = this.selectedProduct.id
      ? getCampaignProductImageUrl(this.selectedProduct)
      : null;
  }

  saveResponse(draft = false) {
    if (this.form.invalid) {
      this.enableFormErrors = true;
      return;
    }

    this.enableFormErrors = false;
    switch (this.stepType) {
      case CustomStepCategoryEnum.CancelSubSurvey:
      case CustomStepCategoryEnum.CancelOrderSurvey:
      case CustomStepCategoryEnum.CancelTrialSurvey:
      case CustomStepCategoryEnum.Survey:
      case CustomStepCategoryEnum.ReturnSurvey:
        this.saveSurveyStep(draft);
        break;
      default:
        this.saveOfferStep(draft);
        break;
    }
  }

  saveSurveyStep(draft = false) {
    if (draft) {
      this.isStepDraftSaving = true;
    } else {
      this.isStepSaving = true;
    }

    let body;
    body = this.form.value;

    this.manipulateHTMLContent(body);

    if (body.category in this.categoryToOfferIntentMapping) {
      body.offer_intent = this.categoryToOfferIntentMapping[body.category];
    }

    if (this.isNew) {
      this.stepService.create(body).subscribe(
        () => {
          this.alertService.success("Saved Successfully.", true);
          setTimeout(() => {
            this.navigate(["steps", "survey"]);
          }, 3000);
        },
        (err) => {
          if (draft) {
            this.isStepDraftSaving = false;
          } else {
            this.isStepSaving = false;
          }

          if (err.status === 400) {
            this.alertService.error(err.error.name);
          } else {
            this.alertService.error(err.message);
          }
        }
      );
    } else {
      this.stepService.update(this.stepId, body).subscribe(
        () => {
          this.alertService.success("Saved Successfully.", true);
          setTimeout(() => {
            this.navigate(["steps", "survey"]);
          }, 3000);
        },
        (err) => {
          if (draft) {
            this.isStepDraftSaving = false;
          } else {
            this.isStepSaving = false;
          }
          if (err.status === 400) {
            this.alertService.error(err.error.name);
          } else {
            this.alertService.error(err.message);
          }
        }
      );
    }
  }

  saveOfferStep(draft = false) {
    if (draft) {
      this.isStepDraftSaving = true;
    } else {
      this.isStepSaving = true;
    }

    let body;
    body = this.form.value;
    body = {
      ...body,
      response_data: {
        ...body.response_data,
        name: `${body.name} response`,
      },
    };

    this.manipulateHTMLContent(body);

    if (this.isNew) {
      if (this.funnel.offer_steps && this.funnel.offer_steps.length > 0) {
        const lastStepIndex = this.funnel.offer_steps.length - 1;
        if (
          this.funnel.offer_steps[lastStepIndex].inputs &&
          this.funnel.offer_steps[lastStepIndex].inputs.length > 0
        ) {
          this.funnel.offer_steps[lastStepIndex].inputs.forEach((input) => {
            if (!input.has_response_step) {
              body = {
                ...body,
                parent_input: input.id,
              };
            }
          });
        }
      }

      this.funnelStepService.create(body).subscribe(
        () => {
          this.alertService.success("Saved Successfully.", true);
          setTimeout(() => {
            this.navigate([
              "steps",
              this.offerName,
              this.offerType,
              "details",
              this.funnelId,
            ]);
          }, 3000);
        },
        (err) => {
          if (draft) {
            this.isStepDraftSaving = false;
          } else {
            this.isStepSaving = false;
          }
          if (err.status === 400) {
            this.alertService.error(err.error.name);
          } else {
            this.alertService.error(err.message);
          }
        }
      );
    } else {
      this.funnelStepService.update(this.stepId, body).subscribe(
        () => {
          this.alertService.success("Saved Successfully.", true);
          setTimeout(() => {
            this.navigate([
              "steps",
              this.offerName,
              this.offerType,
              "details",
              this.funnelId,
            ]);
          }, 3000);
        },
        (err) => {
          if (draft) {
            this.isStepDraftSaving = false;
          } else {
            this.isStepSaving = false;
          }

          if (err.status === 400) {
            this.alertService.error(err.error.name);
          } else {
            this.alertService.error(err.message);
          }
        }
      );
    }
  }

  saveTemplate() {
    if (this.form.invalid) {
      this.enableFormErrors = true;
      return;
    }

    this.isStepSaving = true;
    this.enableFormErrors = false;

    let body;
    body = {
      ...this.form.value,
      is_template: true,
      is_global: this.folderDetails.is_global,
    };
    if (this.funnelId) {
      body = {
        ...body,
        response_data: {
          ...body.response_data,
          name: `${body.name} response`,
        },
      };
    }

    this.manipulateHTMLContent(body);

    if (body.category in this.categoryToOfferIntentMapping) {
      body.offer_intent = this.categoryToOfferIntentMapping[body.category];
    }

    if (this.folderId && !this.templateId) {
      this.stepService.create(body).subscribe(
        () => {
          this.alertService.success("Saved Successfully.", true);
          setTimeout(() => {
            if (this.funnelId) {
              this.router.navigate([
                "steps",
                this.offerName,
                this.funnelId,
                "category",
                this.offerType,
                "folders",
                this.stepType,
                "folder",
                this.folderId,
              ]);
            } else {
              this.router.navigate([
                "steps",
                this.offerName,
                "category",
                this.offerType,
                "folders",
                this.stepType,
                "folder",
                this.folderId,
              ]);
            }
          }, 3000);
        },
        (err) => {
          this.isStepSaving = false;

          if (err.status === 400) {
            this.alertService.error(err.error.name);
          } else {
            this.alertService.error(err.message);
          }
        }
      );
    } else {
      this.stepService.update(this.templateId, body).subscribe(
        () => {
          this.alertService.success("Saved Successfully.", true);
          setTimeout(() => {
            if (this.funnelId) {
              this.router.navigate([
                "steps",
                this.offerName,
                this.funnelId,
                "category",
                this.offerType,
                "folders",
                this.stepType,
                "folder",
                this.folderId,
              ]);
            } else {
              this.router.navigate([
                "steps",
                this.offerName,
                "category",
                this.offerType,
                "folders",
                this.stepType,
                "folder",
                this.folderId,
              ]);
            }
          }, 3000);
        },
        (err) => {
          this.isStepSaving = false;

          if (err.status === 400) {
            this.alertService.error(err.error.name);
          } else {
            this.alertService.error(err.message);
          }
        }
      );
    }
  }

  manipulateHTMLContent(body: any) {
    if (this.useExternalButtons) {
      body.button_styles.confirm = this.addButtonStyles(
        this.stepButtons.confirmButton
      );
      body.button_styles.back = this.addButtonStyles(
        this.stepButtons.backButton
      );
    } else {
      body.inputs[0].label = addDataAttributes(body.inputs[0].label);
      body.inputs[0].button_styles = this.addButtonStyles(
        this.stepButtons.confirmButton
      );

      body.inputs[1].label = addDataAttributes(body.inputs[1].label);
      body.inputs[1].button_styles = this.addButtonStyles(
        this.stepButtons.backButton
      );
    }

    if (body.banner) {
      body.banner = this.addBannerStyle(this.stepTextual.banner);
    }

    body.banner = addDataAttributes(body.banner);
    body.label = addDataAttributes(body.label);
    body.content = addDataAttributes(body.content);

    if (body.response_data) {
      body.response_data = {
        ...body.response_data,
        label: addDataAttributes(body.response_data.label),
        content: addDataAttributes(body.response_data.content),
      };
    }
  }

  patchInputs(data: any) {
    if (this.useExternalButtons) {
      const inputs = data.inputs.map(() => {
        return this.getSurveyInputs();
      });
      if (inputs) {
        this.form.setControl(
          "inputs",
          this.formBuilder.array(inputs, Validators.required)
        );
      }
    }

    this.form.patchValue(data);
  }

  addButtonStyles(details: StepButtonDetails) {
    let styleString;
    const outlineShapes = [
      StepButtonShapes.FlatOutline,
      StepButtonShapes.ModernOutline,
      StepButtonShapes.PillOutline,
    ];
    const isOutlineShape = outlineShapes.includes(+details.style);
    const isTextOnly = +details.style === StepButtonShapes.TextOnly;
    const themeString = "var(--theme_color)",
      color =
        +details.type === StepButtonControlTypes.Theme &&
        (isOutlineShape || isTextOnly)
          ? themeString
          : details.fontColor,
      background =
        +details.type === StepButtonControlTypes.Theme
          ? themeString
          : details.backgroundColor,
      borderColor =
        +details.type === StepButtonControlTypes.Theme
          ? themeString
          : details.borderColor,
      borderRadius = details.borderRadius;

    switch (details.style) {
      case StepButtonShapes.Flat:
      case StepButtonShapes.Modern:
      case StepButtonShapes.Pill:
        styleString =
          `color: ${color}; background-color: ${background}; border-color: ${borderColor}; border-radius: ${borderRadius}; min-height: 72px; padding: 16px 10px;`.trim();
        break;
      case StepButtonShapes.FlatOutline:
      case StepButtonShapes.ModernOutline:
      case StepButtonShapes.PillOutline:
        styleString =
          `color: ${color}; background-color: ${transparent}; border-color: ${borderColor}; border-radius: ${borderRadius}; min-height: 72px; padding: 16px 10px;`.trim();
        break;
      case StepButtonShapes.TextOnly:
        styleString =
          `color: ${color}; background-color: ${transparent}; border-color: ${transparent};`.trim();
        break;
    }

    if (this.useExternalButtons) {
      return {
        label: addDataAttributes(details.content),
        style: styleString,
        style_type: details.style,
        control_type: details.type,
      };
    } else
      return {
        style: styleString,
        style_type: details.style,
        control_type: details.type,
      };
  }

  addBannerStyle(banner: StepTextualBanner) {
    if (!banner.value) return null;

    const backgroundColorStyle = `background-color: ${banner.background};`;

    const styleRegex = /style=['"]([^'"]*)['"]/i;
    const match = banner.value.match(styleRegex);

    if (match && match[1]) {
      const existingStyles = match[1];
      const updatedStyles = existingStyles.includes("background-color")
        ? existingStyles.replace(
            /background-color:\s*[^;]+;/i,
            backgroundColorStyle
          )
        : `${existingStyles} ${backgroundColorStyle}`;

      return banner.value.replace(
        styleRegex,
        `style="${updatedStyles.trim()}"`
      );
    } else {
      const tagRegex = /^<\w+/;
      const tagMatch = banner.value.match(tagRegex);
      if (tagMatch) {
        const openingTag = tagMatch[0];
        return banner.value.replace(
          openingTag,
          `${openingTag} style="${backgroundColorStyle}"`
        );
      }
      return banner.value;
    }
  }

  openMobile() {
    this.activeDevice = StepEmulatorDevices.Mobile;
    this.isStepFormActive = true;
    this.isDropdownActive = true;
  }

  openTablet() {
    this.activeDevice = StepEmulatorDevices.Tablet;
    this.isStepFormActive = true;
    this.isDropdownActive = true;
  }

  openDesktop() {
    this.activeDevice = StepEmulatorDevices.Desktop;
    this.isStepFormActive = false;
    this.isDropdownActive = false;
  }

  changePreview(config: StepEmulationTypes) {
    if (config === StepEmulationTypes.Preview) {
      this.isStepPreview = true;
      this.isResponseScreen = false;
    } else if (config === StepEmulationTypes.Response) {
      this.isStepPreview = false;
      this.isResponseScreen = true;
    }
  }

  openProductSelector() {
    this.ngxSmartModalService.getModal("selectProductsDialog").open();
  }

  onProductSelected(product: CampaignProduct) {
    if (product) {
      this.selectedProduct = product;
      this.updateProductImageUrl();
    }
    this.ngxSmartModalService.getModal("selectProductsDialog").close();
  }

  getTitleForStepType(
    stepType: CustomStepCategoryEnum,
    isNew: boolean
  ): string {
    let action = isNew ? "Create" : "Edit";
    if (this.folderId && this.templateId) action = "Edit";
    else if (this.folderId && !this.templateId) action = "Create";

    return `${action} ${StepCategoryLabels[stepType]}`;
  }

  navigate(url: any[]) {
    this.router.navigate(url);
  }

  get purchaseType(): boolean {
    return [
      CustomStepCategoryEnum.PercentageDownsell,
      CustomStepCategoryEnum.FixedAmountDownsell,
      CustomStepCategoryEnum.RefundWithDiscount,
      CustomStepCategoryEnum.ReturnOrderRMA,
    ].includes(+this.stepType);
  }

  get isMobile() {
    return this.activeDevice === StepEmulatorDevices.Mobile;
  }

  get isTablet() {
    return this.activeDevice === StepEmulatorDevices.Tablet;
  }

  get isDesktop() {
    return this.activeDevice === StepEmulatorDevices.Desktop;
  }

  getProductName(productName: string): string {
    return getTruncatedProductName(productName);
  }

  addSubscription(subscription: Subscription): void {
    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    this.sideNav.setShowSideNavToggle(true);
    this.sideNav.setSideNavExpanded(true);
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
    this.stepEmulatorService.resetStepData();
  }
}
