import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
} from "@angular/platform-browser";
import {
  StepButtonControlTypes,
  StepButtonShapes,
  StepButtons,
  StepElementTypes,
  StepEmulatorDeviceWidths,
  StepEmulatorDevices,
  StepFixedMediaCategory,
  StepLayout,
  StepMedia,
  StepMediaCategory,
  StepProductsPreview,
  StepResponseScreen,
  StepTextual,
  StepTextualLayout,
  transparent,
} from "../../../_models";
import { FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import {
  StepEmulatorService,
  StepTemplateService,
} from "../../../../app/_services";
import { pxToVw, vwToPx } from "../../step-data";

@Component({
  selector: "app-step-emulator",
  templateUrl: "./step-emulator.component.html",
  styleUrls: ["./step-emulator.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class StepEmulatorComponent implements OnInit, OnDestroy, OnChanges {
  @Input() layout: StepLayout[] = [];
  @Input() form: FormGroup;
  @Input() isResponseScreen: boolean = false;
  @Input() activeDevice: StepEmulatorDevices;
  @Input() productPlaceholderUrl: string;
  @Input() theme: string;
  stepTextual: StepTextual;
  stepMedia: StepMedia;
  stepButtons: StepButtons;
  stepResponseScreen: StepResponseScreen;
  stepProductsPreview: StepProductsPreview;
  private subscriptions: Subscription[] = [];
  hasBanner: boolean;
  hasSurveyOptions: boolean;
  hasTimeOptions: boolean;
  selectedImage: string;
  videoUrl: SafeResourceUrl;
  loading: boolean = true;
  stepMediaCategory = StepMediaCategory;
  stepElements = StepElementTypes;
  stepEmulatorDevices = StepEmulatorDevices;
  noMediaSelected = false;
  placeholder: string = "";
  outlineShapes = [
    StepButtonShapes.FlatOutline,
    StepButtonShapes.ModernOutline,
    StepButtonShapes.PillOutline,
  ];

  constructor(
    private sanitizer: DomSanitizer,
    protected stepService: StepTemplateService,
    private stepEmulatorService: StepEmulatorService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.loading = false;
    }, 4000);

    const textual = this.layout.find(
      (element) => element.type === StepElementTypes.Textual
    );
    this.hasBanner = (textual.layout as StepTextualLayout).banner
      ? true
      : false;

    const surveyOptions = this.layout.find(
      (element) => element.type === StepElementTypes.SurveyOptions
    );
    this.hasSurveyOptions = surveyOptions ? true : false;

    const timeOptions = this.layout.find(
      (element) => element.type === StepElementTypes.TimeOptions
    );
    this.hasTimeOptions = timeOptions ? true : false;

    const textSubscription = this.stepEmulatorService.stepTextual$.subscribe(
      (data) => {
        this.stepTextual = data;
      }
    );
    this.addSubscription(textSubscription);

    const mediaSubscription = this.stepEmulatorService.stepMedia$.subscribe(
      (data) => {
        this.stepMedia = data;
        this.extractMedia();
      }
    );
    this.addSubscription(mediaSubscription);

    const buttonsSubscription = this.stepEmulatorService.stepButtons$.subscribe(
      (data) => {
        this.stepButtons = data;
      }
    );
    this.addSubscription(buttonsSubscription);

    const responseScreenSubscription =
      this.stepEmulatorService.stepResponseScreen$.subscribe((data) => {
        this.stepResponseScreen = data;
      });
    this.addSubscription(responseScreenSubscription);

    const productsPreviewSubscription =
      this.stepEmulatorService.stepProductsPreview$.subscribe((data) => {
        this.stepProductsPreview = data;
      });
    this.addSubscription(productsPreviewSubscription);

    this.applyThemeColor(this.theme);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeDevice) {
      if (this.stepMedia) {
        if (this.stepMedia.fixedImage[this.activeDevice].file) {
          this.noMediaSelected = false;
        } else if (this.stepMedia.fixedImage.productImage) {
          this.noMediaSelected = false;
        } else if (
          this.stepMedia.embedVideo.url &&
          this.stepMedia.embedVideo.sanitizedURL
        ) {
          this.noMediaSelected = false;
        } else {
          this.noMediaSelected = true;
        }
      }
    }

    if (changes.theme) {
      this.applyThemeColor(changes.theme.currentValue);
    }

    if (this.stepMedia) {
      this.extractMedia();
    }
  }

  addSubscription(subscription: Subscription): void {
    this.subscriptions.push(subscription);
  }

  extractMedia() {
    switch (this.stepMedia.category) {
      case StepMediaCategory.Fixed:
        switch (this.stepMedia.fixedImage.category) {
          case StepFixedMediaCategory.Upload:
            if (this.stepMedia.fixedImage[this.activeDevice].file) {
              this.noMediaSelected = false;
              return;
            }
            break;

          case StepFixedMediaCategory.Gallery:
            if (this.stepMedia.fixedImage.productImage) {
              this.selectedImage =
                this.stepMedia.fixedImage.productImage.file.original ||
                this.stepMedia.fixedImage.productImage.file;
              this.noMediaSelected = false;
              return;
            } else {
              this.selectedImage = null;
            }
        }

        break;

      case StepMediaCategory.Embed:
        if (this.stepMedia.embedVideo.url) {
          this.noMediaSelected = false;
          return;
        } else {
          this.videoUrl = null;
        }
        break;
    }
    this.noMediaSelected = true;

    if (this.noMediaSelected) {
      if (this.productPlaceholderUrl) {
        this.placeholder = this.productPlaceholderUrl;
      } else if (this.stepMedia.placeholderImage) {
        this.placeholder = this.stepMedia.placeholderImage;
      } else this.placeholder = null;
    }
  }

  get banner(): SafeHtml {
    return this.getHtml(this.stepTextual.banner.value);
  }

  get label() {
    return this.getHtml(this.stepTextual.body.label);
  }

  get content() {
    return this.getHtml(this.stepTextual.body.content);
  }

  get confirmButton() {
    return this.getHtml(this.stepButtons.confirmButton.content);
  }

  get backButton() {
    return this.getHtml(this.stepButtons.backButton.content);
  }

  get responseTitle() {
    return this.getHtml(this.stepResponseScreen.titleContent);
  }

  get response() {
    return this.getHtml(this.stepResponseScreen.responseContent);
  }

  getHtml(content: string) {
    return this.sanitizer.bypassSecurityTrustHtml(
      this.updateFontSizes(content || "")
    );
  }

  updateFontSizes(html: string): string {
    const deviceWidth = this.getDeviceWidth();
    let updatedHtml = html;

    const fontSizeRegex = /font-size:\s*(\d+px);/g;

    let match;
    while ((match = fontSizeRegex.exec(html)) !== null) {
      const originalFontSize = match[1];
      const sizeInVw = pxToVw(
        originalFontSize,
        StepEmulatorDeviceWidths.Mobile
      );
      const updatedFontSize = vwToPx(sizeInVw, deviceWidth);
      let sizeInPx;
      if (deviceWidth === StepEmulatorDeviceWidths.Tablet) {
        sizeInPx = updatedFontSize - 15 + "px"; //15 is the tested number that we are subtracting from tablet design to balance font size
      } else if (deviceWidth === StepEmulatorDeviceWidths.Desktop) {
        sizeInPx = updatedFontSize - 21 + "px"; //21 is the tested number that we are subtracting from tablet design to balance font size
      } else {
        sizeInPx = updatedFontSize + "px";
      }

      updatedHtml = updatedHtml.replace(originalFontSize, sizeInPx);
    }

    return updatedHtml;
  }

  get isFixed() {
    return this.stepMedia.category === StepMediaCategory.Fixed;
  }

  get isUpload() {
    return this.stepMedia.fixedImage.category === StepFixedMediaCategory.Upload;
  }

  get isGallery() {
    return (
      this.stepMedia.fixedImage.category === StepFixedMediaCategory.Gallery
    );
  }

  get isEmbed() {
    return this.stepMedia.category === StepMediaCategory.Embed;
  }

  get isMobile() {
    return this.activeDevice === StepEmulatorDevices.Mobile;
  }

  get isTablet() {
    return this.activeDevice === StepEmulatorDevices.Tablet;
  }

  get isDesktop() {
    return this.activeDevice === StepEmulatorDevices.Desktop;
  }

  getDeviceWidth(): number {
    if (this.isMobile) {
      return StepEmulatorDeviceWidths.Mobile;
    } else if (this.isTablet) {
      return StepEmulatorDeviceWidths.Tablet;
    }
    return StepEmulatorDeviceWidths.Desktop;
  }

  applyStyles() {
    let styles = {},
      padding = "20px";
    if (this.isDesktop) padding = "50px";

    if (!this.hasBanner || !this.stepTextual.banner.value) {
      styles = {
        "padding-top": padding,
      };
    }

    return styles;
  }

  applyThemeColor(color: string) {
    document.documentElement.style.setProperty(
      "--label-border-color",
      color || "#2eb3c9"
    );
  }

  exchangeableProductDiscount(product) {
    return Math.round(
      100 - ((+product.price * product.quantity) / +product.retail_price) * 100
    );
  }

  selectExchangeable(id: string) {
    const checkbox = document.getElementById(id) as HTMLInputElement;
    checkbox.checked = true;
  }

  get getConfirmFontColor() {
    if (
      this.stepButtons.confirmButton.type === StepButtonControlTypes.Theme &&
      (this.outlineShapes.includes(this.stepButtons.confirmButton.style) ||
        this.stepButtons.confirmButton.style === StepButtonShapes.TextOnly)
    ) {
      return this.stepButtons.confirmButton.brandFont;
    } else {
      return this.stepButtons.confirmButton.fontColor;
    }
  }

  get getBackFontColor() {
    if (
      this.stepButtons.backButton.type === StepButtonControlTypes.Theme &&
      (this.outlineShapes.includes(this.stepButtons.backButton.style) ||
        this.stepButtons.backButton.style === StepButtonShapes.TextOnly)
    ) {
      return this.stepButtons.backButton.brandFont;
    } else {
      return this.stepButtons.backButton.fontColor;
    }
  }

  get getConfirmBorderColor() {
    if (this.stepButtons.confirmButton.type === StepButtonControlTypes.Custom) {
      return this.stepButtons.confirmButton.borderColor;
    } else {
      if (this.stepButtons.confirmButton.isBorderDisabled) return transparent;
      return this.stepButtons.confirmButton.brandBorder;
    }
  }

  get getBackBorderColor() {
    if (this.stepButtons.backButton.type === StepButtonControlTypes.Custom) {
      return this.stepButtons.backButton.borderColor;
    } else {
      if (this.stepButtons.backButton.isBorderDisabled) return transparent;
      return this.stepButtons.backButton.brandBorder;
    }
  }

  get getConfirmBackgroundColor() {
    if (this.stepButtons.confirmButton.type === StepButtonControlTypes.Custom) {
      return this.stepButtons.confirmButton.backgroundColor;
    } else {
      if (this.stepButtons.confirmButton.isBackgroundDisabled)
        return transparent;
      return this.stepButtons.confirmButton.brandBackground;
    }
  }

  get getBackBackgroundColor() {
    if (this.stepButtons.backButton.type === StepButtonControlTypes.Custom) {
      return this.stepButtons.backButton.backgroundColor;
    } else {
      if (this.stepButtons.backButton.isBackgroundDisabled) return transparent;
      return this.stepButtons.backButton.brandBackground;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
