import {Component, OnInit, Input, Output, EventEmitter, OnChanges} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {
  AlertService, CampaignProductService, CRMService, CRMCampaignService,
  ProductCategoryService, FunnelService, ProductService, UserService, AccountService, CampaignService
} from '../_services';
import {FormBuilder} from '@angular/forms';
import {Product, CampaignProduct, CallCenterAction, Pager} from '../_models';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {CampaignProductComponent} from "./campaign-product.component";
import {takeUntil} from "rxjs/operators";
import {config} from "../../config/config";

@Component({
  moduleId: module.id.toString(),
  selector: 'campaign-product-select',
  templateUrl: './campaign-product.component.html'
})
export class CampaignProductSelectComponent extends CampaignProductComponent implements OnInit, OnChanges {
  @Input('selections') selections: any[] = [];
  @Input('multiple') multiple = false;
  @Input('useBaseProduct') useBaseProduct = false;
  @Input('appendSelection') appendSelection = true;
  @Output('selectProductsEvent') onSelectProductsEvent = new EventEmitter<any[]>();
  @Output('cancel') onCancel: EventEmitter<any> = new EventEmitter<any>();
  public allowExportCampaignProducts: boolean = false;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected productService: ProductService,
    protected campaignProductService: CampaignProductService,
    protected alertService: AlertService,
    protected crmService: CRMService,
    protected crmCampaignService: CRMCampaignService,
    protected categoryService: ProductCategoryService,
    protected funnelService: FunnelService,
    protected modalService: NgxSmartModalService,
    protected formBuilder: FormBuilder,
    protected userService: UserService,
    protected accountService: AccountService,
    protected campaignService: CampaignService
  ) {
    super(router, location, route, productService, campaignProductService, alertService, crmService, crmCampaignService,
      categoryService, funnelService, modalService, formBuilder, userService, accountService, campaignService);
    this.objectName = 'product';
    this.selectProductsFlag = true;
    this.editEnabled = false;
    this.idPrefix = 'select_'
  }

  ngOnInit() {
    super.ngOnInit();
    this.updateForm();
  }

  ngOnChanges() {
    this.fetchCRMRelatedData();
    this.updateForm();
  }

  private updateForm() {
    this.removeFilter('funnels'); //make sure this filter is removed so we can select from all funnels

    if (this.form) {
      const defaultCampaigns = this.defaultCRMId ? this.getDefaultCampaignValues() : null;

      this.form.patchValue({
        account: this.accountId,
        crm: this.defaultCRMId || null,
        crm_campaigns: defaultCampaigns,
        product__categories: null,
        product__name__icontains: null,
        campaign_product_id: null,
        product__product_id: null
      });
    }

    // get the initial selections
    let productsMap = {};

    if (this.selections) {
      this.selections.forEach((product: CampaignProduct | Product) => {
        productsMap[product.id] = product;
      });
    }

    this.selectedProductsMap = productsMap;
  }

  // override this so we ignore the router params in the filter and initialize the filter from the input
  protected initializeFilter() {
    if (this.defaultCRMCampaigns && this.defaultCRMCampaigns.length && !!this.defaultCRMId) {
      this.filter = Object.assign(this.filter,
        {crm: this.defaultCRMId, crm_campaigns: this.defaultCRMCampaigns});
    }

    if (this.useAccountIdInFilter) {
      let accountId = this.accountId;
      if (Array.isArray(accountId)) {
        let accounts = [];
        accountId.forEach(item => {
          accounts.push(item.id);
        });
        accountId = accounts;
      }
      this.filter = {...this.filter, account_id: accountId};
    }
  }

  protected onRouteParams(params: {}) {

  }

  protected setTitleFromCRM(crm) {

  }

  isEnabled(campaignProduct: CampaignProduct) {
    const id = this.useBaseProduct ? campaignProduct.product.id : campaignProduct.id;

    return id in this.selectedProductsMap;
  }

  enable(campaignProduct: CampaignProduct, event) {
    let enabled: boolean = event.target.checked;
    const target = this.useBaseProduct ? campaignProduct.product : campaignProduct;

    if (enabled) {
      if (this.multiple) {
        this.selectedProductsMap[target.id] = target;
      }
      else {
        this.selectedProductsMap = {};
        this.selectedProductsMap[target.id] = target;
        this.save(); // save on selection if we're selecting a single product
      }
    } else if (target.id in this.selectedProductsMap) {
      delete this.selectedProductsMap[target.id];
      const index = this.selections.findIndex((selection) => selection.id == target.id);
      this.selections.splice(index, 1);
    }
  }

  save() {
    let loadedSelections = [];
    let unloadedSelectionIds = [];

    this.selections.forEach(selection => {
      if (this.appendSelection && !(selection.id in this.selectedProductsMap)) {
        this.selectedProductsMap[selection.id] = selection
      }
    });

    // go through the selection map and determine if we need to load any objects
    Object.entries(this.selectedProductsMap).forEach(([id, product]) => {
      if (product) {
        loadedSelections.push(product);
      } else {
        unloadedSelectionIds.push(id);
      }
    });

    if (unloadedSelectionIds && unloadedSelectionIds.length) {
      // we got objects to load, so load them and add to our selection
      const service = this.useBaseProduct ? this.productService : this.campaignProductService;
      this.loading = true;

      service.list({id__in: unloadedSelectionIds.join(','), page: 1, page_size: config.maxPageSize})
        .pipe(takeUntil(this.destroy$))
        .subscribe((products: Pager) => {
            products.results.forEach(product => {
              loadedSelections.push(product);
            });

            this.loading = false;
            this.onSelectProductsEvent.emit(loadedSelections);
          },
          error => {
            this.handleError(error);
            this.loading = false;
          }
        );
    } else {
      // selected objects already loaded, so just return them
      this.onSelectProductsEvent.emit(loadedSelections);
    }
  }

  cancel() {
    this.onCancel.emit();
  }

  protected getQueryFilter(): {} {
    let filter = super.getQueryFilter();
    if (this.useAccountIdInFilter) {
      let accountId = this.accountId;
      if (Array.isArray(accountId)) {
        let accounts = [];
        accountId.forEach(item => {
          accounts.push(item.id);
        });
        accountId = accounts;
      }
      filter['account_id'] = accountId;
      if ('crm' in filter) {
        delete filter['crm'];
      }
    }
    return filter;
  }

}
