import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard, CanDeactivateGuard } from "../_guards";
import {
  DownsellOffersComponent,
  OfferDetailsComponent,
  StepBuilderComponent,
  StepCategorySelectionComponent,
  StepTemplateFolders,
  StepTemplates,
  SupportOffersComponent,
  SurveyStepsComponent,
  UpsellOffersComponent,
  CustomerPortalOffersComponent
} from ".";

const routes: Routes = [
  {
    path: "steps",
    canActivate: [AuthGuard],
    children: [
      {
        path: "downsell",
        canActivate: [AuthGuard],
        children: [
          {
            path: ":offer_id/category/:offer_type/:step_type/step/:step_id",
            component: StepBuilderComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_id/category/:offer_type/step/:step_type",
            component: StepBuilderComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_id/category/:offer_type/step/:step_type/folder/:folder_id",
            component: StepBuilderComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_id/category/:offer_type/step/:step_type/folder/:folder_id/template/:template_id",
            component: StepBuilderComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_id/category/:offer_type/step/:step_type/template/:template_id",
            component: StepBuilderComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_id/category/:offer_type/folders/:step_type",
            component: StepTemplateFolders,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_id/category/:offer_type/folders/:step_type/folder/:folder_id",
            component: StepTemplates,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_id/category/:offer_type",
            component: StepCategorySelectionComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_type/details/:offer_id",
            component: OfferDetailsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "",
            component: DownsellOffersComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "upsell",
        canActivate: [AuthGuard],
        children: [
          {
            path: ":offer_id/category/:offer_type/:step_type/step/:step_id",
            component: StepBuilderComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_id/category/:offer_type/step/:step_type",
            component: StepBuilderComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_id/category/:offer_type/step/:step_type/folder/:folder_id",
            component: StepBuilderComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_id/category/:offer_type/step/:step_type/folder/:folder_id/template/:template_id",
            component: StepBuilderComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_id/category/:offer_type/step/:step_type/template/:template_id",
            component: StepBuilderComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_id/category/:offer_type/folders/:step_type",
            component: StepTemplateFolders,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_id/category/:offer_type/folders/:step_type/folder/:folder_id",
            component: StepTemplates,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_id/category/:offer_type",
            component: StepCategorySelectionComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_type/details/:offer_id",
            component: OfferDetailsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "",
            component: UpsellOffersComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "support",
        canActivate: [AuthGuard],
        children: [
          {
            path: ":offer_id/category/:offer_type/:step_type/step/:step_id",
            component: StepBuilderComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_id/category/:offer_type/step/:step_type",
            component: StepBuilderComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_id/category/:offer_type/step/:step_type/folder/:folder_id",
            component: StepBuilderComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_id/category/:offer_type/step/:step_type/folder/:folder_id/template/:template_id",
            component: StepBuilderComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_id/category/:offer_type/step/:step_type/template/:template_id",
            component: StepBuilderComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_id/category/:offer_type/folders/:step_type",
            component: StepTemplateFolders,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_id/category/:offer_type/folders/:step_type/folder/:folder_id",
            component: StepTemplates,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_id/category/:offer_type",
            component: StepCategorySelectionComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_type/details/:offer_id",
            component: OfferDetailsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "",
            component: SupportOffersComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "customer-portal",
        canActivate: [AuthGuard],
        children: [
          {
            path: ":offer_id/category/:offer_type/:step_type/step/:step_id",
            component: StepBuilderComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_id/category/:offer_type/step/:step_type",
            component: StepBuilderComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_id/category/:offer_type/step/:step_type/folder/:folder_id",
            component: StepBuilderComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_id/category/:offer_type/step/:step_type/folder/:folder_id/template/:template_id",
            component: StepBuilderComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_id/category/:offer_type/step/:step_type/template/:template_id",
            component: StepBuilderComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_id/category/:offer_type/folders/:step_type",
            component: StepTemplateFolders,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_id/category/:offer_type/folders/:step_type/folder/:folder_id",
            component: StepTemplates,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_id/category/:offer_type",
            component: StepCategorySelectionComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ":offer_type/details/:offer_id",
            component: OfferDetailsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "",
            component: CustomerPortalOffersComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "survey",
        canActivate: [AuthGuard],
        children: [
          {
            path: "category/:offer_type/:step_type/step/:step_id",
            component: StepBuilderComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: "category/:offer_type/step/:step_type",
            component: StepBuilderComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: "category/:offer_type/step/:step_type/folder/:folder_id",
            component: StepBuilderComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: "category/:offer_type/step/:step_type/folder/:folder_id/template/:template_id",
            component: StepBuilderComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: "category/:offer_type/step/:step_type/template/:template_id",
            component: StepBuilderComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: "category/:offer_type/folders/:step_type",
            component: StepTemplateFolders,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: "category/:offer_type/folders/:step_type/folder/:folder_id",
            component: StepTemplates,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: "category/:offer_type",
            component: StepCategorySelectionComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: "",
            component: SurveyStepsComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class StepsRoutingModule {}
