import { Component, Input, OnInit } from "@angular/core";
import {
  StepTimeOptionsLayout,
  StepTimeOptionTypes,
  StepDurationUnits,
  ActionType,
  ExecutionTypeEnum,
  FunnelInputTypeEnum,
  StepElements,
} from "../../../_models";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { StepEmulatorService } from "../../../_services";

@Component({
  selector: "step-time-options",
  templateUrl: "./step-time-options.component.html",
  styleUrls: ["./step-time-options.component.scss"],
  animations: [
    trigger("fadeInOut", [
      state("void", style({ opacity: 0 })),
      transition(":enter", [animate("500ms ease-in", style({ opacity: 1 }))]),
      transition(":leave", [animate("300ms ease-out", style({ opacity: 0 }))]),
    ]),
  ],
})
export class StepTimeOptionsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() layout: StepTimeOptionsLayout;
  @Input() isNew: boolean;
  @Input() templateId: string;
  stepElements = StepElements;
  options: {
    value: number;
    unit: StepDurationUnits;
  }[] = [];
  timeOptionsType: StepTimeOptionTypes;
  stepDurationUnits = StepDurationUnits;
  inputs: FormArray;
  isShippingFreq: boolean;
  isPauseSurvey: boolean;

  constructor(
    protected formBuilder: FormBuilder,
    protected stepEmulatorService: StepEmulatorService
  ) {}

  ngOnInit() {
    this.timeOptionsType = this.layout.type;
    this.isShippingFreq =
      this.timeOptionsType === StepTimeOptionTypes.ShippingFreq;
    this.isPauseSurvey =
      this.timeOptionsType === StepTimeOptionTypes.PauseOptions;

    if (!this.templateId && this.isNew) {
      const formGroups = this.layout.items.map((item) => {
        return this.getNewInput(item);
      });

      this.options = this.layout.items;
      this.form.setControl("inputs", this.formBuilder.array(formGroups));
    }

    this.inputs = this.form.get("inputs") as FormArray;
    this.options = this.inputs.value.map((input) => {
      let value = input.actions[0].next_bill_date_delay;

      const unit = input.label.toLowerCase().includes("week(s)")
        ? StepDurationUnits.Weeks
        : StepDurationUnits.Days;
      if (unit === StepDurationUnits.Weeks) {
        value = value / 7;
      }
      return {
        value,
        unit,
      };
    });
  }

  getNewInput(item: any): FormGroup {
    let label,
      unit,
      pause,
      billing_interval_days,
      next_bill_date_delay,
      final_billing_cycle_offset = 0,
      check_last_discount = true,
      bill_now = false,
      max_bill_date_delay = null;

    switch (item.unit) {
      case StepDurationUnits.Days:
        unit = "Day(s)";
        break;
      case StepDurationUnits.Weeks:
        unit = "Week(s)";
        break;
    }

    if (this.isShippingFreq) {
      label = `Every ${item.value} ${unit}`;
      pause = false;
    } else if (this.isPauseSurvey) {
      label = `Pause for ${item.value} ${unit}`;
      pause = true;
    }

    billing_interval_days = null;
    next_bill_date_delay = [
      item.unit === StepDurationUnits.Weeks ? item.value * 7 : item.value,
      Validators.required,
    ];

    return this.formBuilder.group({
      label,
      help: null,
      next_step: null,
      type: FunnelInputTypeEnum.Radio,
      values: [[]],
      actions: this.formBuilder.array([
        this.formBuilder.group({
          execution_type: ExecutionTypeEnum.Immediate,
          resourcetype: ActionType.BillingCycle,
          billing_interval_days,
          check_last_discount,
          pause,
          final_billing_cycle_offset,
          bill_now,
          next_bill_date_delay,
          max_bill_date_delay,
        }),
        this.formBuilder.group({
          resourcetype: ActionType.CancelDelayed,
        }),
      ]),
      icon: null,
      hide_if_invalid: true,
      product_funnels: [[]],
      classes: [[]],
      is_alt_child_exit: false,
      matched_step_key: null,
      autopick_next_step: null,
      requires_active_item: true,
      requires_item: true,
      requires_customer: true,
      has_response_step: true,
    });
  }

  updateTime(index: number) {
    const action = this.inputs.at(index).get("actions") as FormArray;
    let unit;
    switch (Number(this.options[index].unit)) {
      case StepDurationUnits.Days:
        unit = "Day(s)";
        break;
      case StepDurationUnits.Weeks:
        unit = "Week(s)";
        break;
    }

    if (this.isShippingFreq) {
      this.inputs.at(index).patchValue({
        label: `Every ${this.options[index].value} ${unit}`,
      });
    } else if (this.isPauseSurvey) {
      this.inputs.at(index).patchValue({
        label: `Pause for ${this.options[index].value} ${unit}`,
      });
    }

    action.at(0).patchValue({
      next_bill_date_delay:
        Number(this.options[index].unit) === StepDurationUnits.Weeks
          ? this.options[index].value * 7
          : this.options[index].value,
    });
  }

  addItem() {
    const item = {
      value: 1,
      unit: StepDurationUnits.Days,
    };
    this.options.push(item);
    this.inputs.push(this.getNewInput(item));
  }

  removeItem(index: number) {
    if (index >= 0 && index < this.inputs.length) {
      this.inputs.removeAt(index);
    }

    this.options = this.options.filter((_, i) => i !== index);
  }

  toggleElement(element: StepElements) {
    this.stepEmulatorService.toggleElement(element);
  }

  isElementVisible(element: StepElements): boolean {
    return this.stepEmulatorService.isElementVisible(element);
  }

  get inputControls() {
    return (this.form.get("inputs") as FormArray).controls;
  }

  get inputsLength(): number {
    const inputs = this.form.get("inputs") as FormArray;
    return inputs ? inputs.length : 0;
  }
}
