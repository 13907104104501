import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import {
  AlertService,
  CancelReasonCategoriesService,
  FunnelStepService,
  StepTemplateService,
} from "../../_services";
import { CrudSavePopupComponent } from "../../_directives";
import { FormBuilder, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { StepPopupActions } from "../../_models/steps";
import { stepBody } from "../step-data";

@Component({
  moduleId: module.id.toString(),
  selector: "step-popup",
  templateUrl: "./popup.component.html",
  styleUrls: ["./../steps.component.scss"],
})
export class StepPopupComponent
  extends CrudSavePopupComponent
  implements OnInit
{
  ngModelOptions = { standalone: true };
  operation: Observable<Object>;
  StepPopupActions = StepPopupActions;
  title = 'step';

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected formBuilder: FormBuilder,
    protected stepService: StepTemplateService,
    protected funnelStepService: FunnelStepService,
    protected cancelReasonCategoriesService: CancelReasonCategoriesService,
    protected alertService: AlertService
  ) {
    super(router, location, route, stepService, alertService);
    this.objectName = "Offer";
    this.isNew = false;
    this.isPartial = true;
  }

  ngOnInit() {
    this.setForm(
      this.formBuilder.group({
        name: [null, Validators.required],
      })
    );

    super.ngOnInit();
  }

  onSubmit() {
    this.submitted = true;

    if (
      this.form.valid ||
      this.inputData.popupType === StepPopupActions.Delete
    ) {
      this.loading = true;
      const {
        popupType,
        step_id,
        offer_id,
        folder_id,
        category_id,
        is_survey,
        is_step,
        is_category,
        is_template,
        step_response,
        ...formData
      } = this.getFormData();

      let operationTitle = "";
      if (offer_id) this.funnelStepService.setFunnelId(offer_id);

      switch (popupType) {
        case StepPopupActions.Create:
          this.crudService.create(formData).subscribe(
            (data) => {
              if ("id" in data) {
                //@ts-ignore
                this.id = data["id"];
              }

              if (this.showSuccessMessage) {
                this.alertService.success(
                  `${is_survey ? "Survey" : "Offer"} Created Successfully.`,
                  true
                );
              }

              this.canDeactivateService.setFormDirty(false);
              this.onSaveComplete(data);
            },
            (error) => {
              this.handleSubmitError(error);
              this.loading = false;
            }
          );
          return;

        case StepPopupActions.Update:
          this.operation = is_step
            ? this.funnelStepService.update(step_id, {
                ...step_response,
                name: formData.name,
              })
            : this.crudService.patch(step_id, formData);
          operationTitle = "Updated";
          this.performOperation(operationTitle);
          break;

        case StepPopupActions.Duplicate:
          operationTitle = "Duplicated";

          if (is_step) {
            this.funnelStepService.copyFunnelStep(step_id).subscribe(
              (data) => {
                this.operation = this.funnelStepService.pasteFunnelStep({
                  ...data,
                  name: formData.name,
                });
                this.performOperation(operationTitle);
              },
              (error) => {
                this.handleSubmitError(error);
                this.loading = false;
              }
            );
          } else {
            this.stepService.copy(step_id).subscribe(
              (data) => {
                this.operation = this.stepService.paste({
                  ...data,
                  name: formData.name,
                  folder: is_template ? +folder_id : null,
                });
                this.performOperation(operationTitle);
              },
              (error) => {
                this.handleSubmitError(error);
                this.loading = false;
              }
            );
          }

          break;

        case StepPopupActions.Delete:
          if (is_category) {
            this.operation =
              this.cancelReasonCategoriesService.delete(category_id);
            operationTitle = "Category Deleted";
          } else {
            this.operation = is_step
              ? this.funnelStepService.delete(step_id)
              : this.crudService.delete(step_id);
            operationTitle = "Deleted";
          }
          this.performOperation(operationTitle);
          break;

        default:
          this.loading = false;
          return;
      }
    }
  }

  performOperation(operationTitle) {
    this.operation.subscribe(
      (data) => {
        if (this.showSuccessMessage) {
          this.alertService.success(`${operationTitle} Successfully.`, true);
        }

        this.canDeactivateService.setFormDirty(false);
        this.onSaveComplete(data);
      },
      (error) => {
        this.handleSubmitError(error);
        this.loading = false;
      }
    );
  }

  protected getFormData() {
    const data = {
      ...stepBody,
      ...this.form.value,
      offer_id: this.inputData.offer_id,
      step_id: this.inputData.step_id,
      folder_id: this.inputData.folder_id,
      category_id: this.inputData.category_id,
      offer_type: this.inputData.offer_type,
      offer_intent: this.form.value.offer_intent,
      popupType: this.inputData.popupType,
      is_survey: this.inputData.is_survey,
      is_step: this.inputData.is_step,
      is_template: this.inputData.is_template,
      is_category: this.inputData.is_category,
      step_response: this.inputData.step_response,
    };
    return data;
  }

  popupTypeTextMap: Partial<Record<StepPopupActions, string>> = {
    [StepPopupActions.Delete || StepPopupActions.DeleteFolder]: 'Delete',
    [StepPopupActions.Duplicate]: 'Duplicate',
    [StepPopupActions.Update]: 'Rename'
  };
}
