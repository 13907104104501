import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {FormBuilder} from '@angular/forms';
import {AlertService, FunnelService, CampaignService, UserService} from '../_services';
import {FunnelComponent} from './funnel.component';
import {NgxSmartModalService} from "ngx-smart-modal";
import {Funnel, FunnelNode, OfferTypes, FunnelRelationship, OfferIntents, Pager, SessionType} from '../_models';
import {config} from "../../config/config";
import {forkJoin} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './funnel.component.html',
  styleUrls: ['./funnel.component.css']
})
export class VisualFunnelComponent extends FunnelComponent implements OnInit {
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected funnelService: FunnelService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected campaignService: CampaignService,
    protected userService: UserService,
    protected modalService: NgxSmartModalService
  ) {
    super(router, location, route, funnelService, alertService, formBuilder, campaignService,
      userService, modalService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected initializeFilter() {
    this.filter = {is_visual: true, session_type: SessionType.Support,
      resourcetype__in: 'VisualFunnel,ProductFunnel', offer_intent: OfferIntents.Legacy}; // visual and product funnels
  }

  protected queryData() {
    this.loading = true;
    let params = Object.assign({}, this.getQueryFilter(),
      {page: this.page, page_size: config.maxPageSize});

    forkJoin([
      this.funnelService.list(params),
      this.funnelService.getHierarchy()
    ]).pipe(takeUntil(this.destroy$)).subscribe(
      (data: [Pager, FunnelRelationship[]]) => {
        const allFunnels = data[0].results;
        const hierarchy = data[1];
        let funnels: Funnel[] = [];
        let templates: Funnel[] = [];
        let funnelMap = {};
        let parentMap = {};
        let unassignedProductFunnels: Funnel[] = [];
        let unassignedProductTemplates: Funnel[] = [];

        // build map of funnel ids to their objects and initialize child arrays
        allFunnels.forEach((funnel: Funnel) => {
          funnelMap[funnel.id.toString()] = funnel;
          funnel.children = [];
        });

        // build map of child funnel ids to their parent id
        hierarchy.forEach((relationship: FunnelRelationship) => {
          const id = relationship.funnel_id.toString();

          if (id in parentMap) {
            parentMap[id].push(relationship.parent_id.toString());
          }
          else {
            parentMap[id] = [relationship.parent_id.toString()];
          }
        });

        // build hierarchy of funnels
        allFunnels.forEach((funnel: Funnel) => {
          if (this.isProductFunnel(funnel)) {
            const funnelId = funnel.id.toString();

            if (funnelId in parentMap) {
              parentMap[funnelId].forEach(parentId => {
                if (parentId in funnelMap) {
                  funnelMap[parentId].children.push(funnel);
                }
              });
            }
            else {
              if (funnel.is_template) {
                unassignedProductTemplates.push(funnel);
              } else {
                unassignedProductFunnels.push(funnel);
              }
            }
          }
          else {
            if (funnel.is_template) {
              templates.push(funnel);
            } else {
              funnels.push(funnel);
            }
          }
        });

        if (unassignedProductFunnels.length > 0) {
          let funnel: Funnel = {
            id: 0,
            name: 'Unassigned Subpaths',
            children: unassignedProductFunnels
          } as unknown as Funnel;

          funnels.push(funnel);
        }

        if (unassignedProductTemplates.length > 0) {
          let funnel: Funnel = {
            id: 0,
            name: 'Unassigned Subpaths',
            children: unassignedProductTemplates
          } as unknown as Funnel;

          templates.push(funnel);
        }

        this._data$.next(funnels); // change this from allFunnels to funnels
        this.templates = templates;
        this.dataSource.data = [...funnels] as FunnelNode[];
        this.templatesDataSource.data = [...templates] as FunnelNode[];
        this.setPagerData(data[0]);
        this.loading = false;
      },
      error => {
        this.loading = false;
        this.handleError(error);
        this._data$.error(error);
        this.templates = [];
      });
  }

  protected onFunnelPasted(funnel: Funnel) {
    this.queryData();
  }

}
