import { OfferTypes, StepTemplate } from "../../_models/steps";
import { CustomStepCategoryEnum } from "../../_models/funnel";

export const steps: StepTemplate[] = [
  /* SURVEYS */
  {
    title: "Cancel Subscription Survey",
    description:
      "Collect feedback on Subscription cancellations. Multiple-choice for insights.",
    type: CustomStepCategoryEnum.CancelSubSurvey,
    icon: "survey-icon.svg",
    offer_type: OfferTypes.Survey,
  },
  {
    title: "Cancel Order Survey",
    description:
      "Collect feedback on Order cancellations. Multiple-choice for insights.",
    type: CustomStepCategoryEnum.CancelOrderSurvey,
    icon: "survey-icon.svg",
    offer_type: OfferTypes.Survey,
  },
  {
    title: "Cancel Trial Survey",
    description:
      "Collect feedback on Trial cancellations. Multiple-choice for insights.",
    type: CustomStepCategoryEnum.CancelTrialSurvey,
    icon: "survey-icon.svg",
    offer_type: OfferTypes.Survey,
  },
  {
    title: "Return Order Survey",
    description:
      "Gather feedback on return requests. Multiple-choice for insights.",
    type: CustomStepCategoryEnum.ReturnSurvey,
    icon: "survey-icon.svg",
    offer_type: OfferTypes.Survey,
  },
  {
    title: "Basic Survey",
    description:
      "Standard survey for customer feedback. Various response options.",
    type: CustomStepCategoryEnum.Survey,
    icon: "bacic-survey.svg",
    offer_type: OfferTypes.Survey,
    disabled: true,
  },
  {
    title: "Feedback",
    description: "Encourage opinions and comments through a feedback survey.",
    type: CustomStepCategoryEnum.Feedback,
    icon: "feedback.svg",
    offer_type: OfferTypes.Survey,
    disabled: true,
  },

  /* DOWNSELLS */
  {
    title: "Fixed Amount",
    description: "Offer fixed amount refund to retain customers.",
    type: CustomStepCategoryEnum.FixedAmountDownsell,
    icon: "fixed-amount.svg",
    offer_type: OfferTypes.Downsell,
  },
  {
    title: "Percentage Discount",
    description: "Offer fixed percentage refund to retain customers.",
    type: CustomStepCategoryEnum.PercentageDownsell,
    icon: "percentage-discount.svg",
    offer_type: OfferTypes.Downsell,
  },
  {
    title: "Discount Future Order",
    description:
      "Downsell with future order discount to encourage subscription continuation.",
    type: CustomStepCategoryEnum.DiscountFutureOrder,
    icon: "discount-future.svg",
    offer_type: OfferTypes.Downsell,
  },
  {
    title: "Discount Refund",
    description: "Partial refund downsell for customers canceling their order.",
    type: CustomStepCategoryEnum.RefundWithDiscount,
    icon: "discount-refund.svg",
    offer_type: OfferTypes.Downsell,
  },
  {
    title: "Loss Aversion",
    description:
      "Downsell using loss aversion strategy to prevent cancellations.",
    type: CustomStepCategoryEnum.LossAversion,
    icon: "loss-aversion.svg",
    offer_type: OfferTypes.Downsell,
    disabled: true,
  },
  {
    title: "Time for free",
    description:
      "Downsell with free service or product usage for a specified period.",
    icon: "time-for-free.svg",
    offer_type: OfferTypes.Downsell,
    disabled: true,
  },
  {
    title: "Offer Details",
    description:
      "Communicate details of a special downsell offer, providing clear information and incentives.",
    type: CustomStepCategoryEnum.OfferDetails,
    icon: "offer-details.svg",
    offer_type: OfferTypes.Downsell,
  },
  {
    title: "Testimonial",
    description:
      "Use customer testimonials in a downsell to build trust and influence positive decisions.",
    type: CustomStepCategoryEnum.Testimonial,
    icon: "testimonial.svg",
    offer_type: OfferTypes.Downsell,
    disabled: true,
  },
  {
    title: "Pause Subscription Offer",
    description:
      "Downsell allowing customers to temporarily pause subscriptions as an alternative to cancellation.",
    type: CustomStepCategoryEnum.PauseSub,
    icon: "pause-subscription.svg",
    offer_type: OfferTypes.Downsell,
  },
  {
    title: "Extend Trial",
    description:
      "Encourage extension of trial period through a downsell offer.",
    type: CustomStepCategoryEnum.ExtendTrial,
    icon: "extend-trial.svg",
    offer_type: OfferTypes.Downsell,
  },
  {
    title: "Return Downsell",
    description:
      "Tailored downsell for customers initiating returns, providing a partial refund.",
    type: CustomStepCategoryEnum.ReturnOrderRMA,
    icon: "return-downsell.svg",
    offer_type: OfferTypes.Downsell,
  },

  /* UPSELLS */
  {
    title: "Upsell",
    description: "Offering a free product to enhance customer experience.",
    type: CustomStepCategoryEnum.UpsellOrder,
    icon: "upsell.svg",
    offer_type: OfferTypes.Upsell,
  },
  {
    title: "Discounted Upsell",
    description: "Upsell Product with discounted rate.",
    type: CustomStepCategoryEnum.UpsellOrder,
    icon: "discounted-upsell.svg",
    offer_type: OfferTypes.Upsell,
  },
  {
    title: "Special Offer",
    description:
      "Upsell featuring a special offer to entice customers for upgrades or additional purchases.",
    icon: "special-offer.svg",
    offer_type: OfferTypes.Upsell,
    disabled: true,
  },

  /* SUPPORTS */
  {
    title: "Schedule Support",
    description:
      "Support step enabling customers to schedule sessions for personalized assistance.",
    type: CustomStepCategoryEnum.ScheduleSupport,
    icon: "survey-icon.svg",
    offer_type: OfferTypes.Support,
    disabled: true,
  },
  {
    title: "Lifeline step",
    description:
      "Offer a lifeline for immediate connection with live agent support in critical situations.",
    icon: "lifeline.svg",
    offer_type: OfferTypes.Support,
    disabled: true,
  },
  {
    title: "Hand-off",
    description:
      "Smooth transition from automated service to live agent assistance for complex queries.",
    icon: "survey-icon.svg",
    offer_type: OfferTypes.Support,
    disabled: true,
  },
  {
    title: "Contact Form",
    description: "",
    icon: "survey-icon.svg",
    offer_type: OfferTypes.Support,
    disabled: true,
  },

  /* CUSTOMER PORTAL */
  {
    title: "Shipping Frequency Offer",
    description:
      "Downsell related to shipping frequency, offering an alternative plan.",
    type: CustomStepCategoryEnum.AdjustShippingFrequency,
    icon: "shipping-frequency.svg",
    offer_type: OfferTypes.CustomerPortal,
  },
  {
    title: "Pause Options",
    description:
      "Downsell allowing customers to temporarily pause subscriptions as an alternative to cancellation.",
    type: CustomStepCategoryEnum.PauseSurvey,
    icon: "pause-subscription.svg",
    offer_type: OfferTypes.CustomerPortal,
  },
  {
    title: "Swap Products",
    description:
      "Downsell allowing customers to exchange or swap their product/package.",
    type: CustomStepCategoryEnum.SwapProduct,
    icon: "swap-products.svg",
    offer_type: OfferTypes.CustomerPortal,
  },
  {
    title: "Re-Activate Subscription",
    description: "lorem ipsum",
    type: CustomStepCategoryEnum.ReActivateSubscriptionWithDiscount,
    icon: "reactivate-sub.svg",
    offer_type: OfferTypes.CustomerPortal,
  },
];
