import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import {
  AlertService,
  ExchangeableProductsSetService,
  RelatedProductSetService,
  StepEmulatorService,
} from "../../../_services";
import { config } from "../../../../config/config";
import {
  ExchangeableProductsSet,
  Pager,
  RelatedProductSet,
  StepElements,
  StepProductsPreview,
} from "../../../_models";
import { takeUntil } from "rxjs/operators";
import { Form, FormControlStatus } from "../../../_forms";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { Subscription } from "rxjs";

@Component({
  selector: "step-exchangeable-products",
  templateUrl: "./step-exchangeable-products.component.html",
  styleUrls: ["./step-exchangeable-products.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("fadeInOut", [
      state("void", style({ opacity: 0 })),
      transition(":enter", [animate("500ms ease-in", style({ opacity: 1 }))]),
      transition(":leave", [animate("300ms ease-out", style({ opacity: 0 }))]),
    ]),
  ],
})
export class StepExchangeableProductsComponent
  extends Form
  implements OnInit, OnDestroy
{
  @Input() form: FormGroup;
  @Input() enableFormErrors: boolean;
  actions: FormGroup;
  stepElements = StepElements;
  private subscription: Subscription;
  exchangeableSets: ExchangeableProductsSet[] = [];
  relatedProductSets: { id: number | string; text: string }[];
  exchangeableSetTypes: { id: number | string; text: string }[] = [];
  unassignedExchangeableSets: ExchangeableProductsSet[] = [];
  dropDownSelected: boolean = true;
  previewProducts: StepProductsPreview;
  public defaultFilterSettings = {
    singleSelection: false,
    allowSearchFilter: true,
    enableCheckAll: true,
  };

  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected exchangeablesSetService: ExchangeableProductsSetService,
    protected relatedProductSetService: RelatedProductSetService,
    protected stepEmulatorService: StepEmulatorService
  ) {
    super(alertService, router, location);
  }
  ngOnInit() {
    const inputs = this.form.get("inputs") as FormArray;
    const firstInput = inputs.at(0) as FormGroup;
    this.actions = (firstInput.get("actions") as FormArray).at(0) as FormGroup;

    this.subscription = this.stepEmulatorService.stepProductsPreview$.subscribe(
      (data) => {
        this.previewProducts = data;
      }
    );

    this.getProductSets();
  }

  getProductSets() {
    this.relatedProductSetService
      .list({ page: 1, page_size: config.maxPageSize })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: Pager) => {
          let relatedProductSets: { id: number | string; text: string }[] = [];
          data.results.forEach((item: RelatedProductSet) => {
            relatedProductSets.push({ id: item.id, text: item.name });
          });
          this.relatedProductSets = relatedProductSets;

          if (this.actions.value.exchangeable_set_types.length) {
            this.exchangeableSetTypes = this.relatedProductSets.filter(
              (productSet) =>
                this.actions.value.exchangeable_set_types.includes(
                  productSet.id
                )
            );
            this.exchangeableSetTypeSelected();
          }
        },
        (error) => {
          this.handleError(error);
        }
      );
  }

  exchangeableSetTypeSelected() {
    if (this.dropDownSelected) {
      this.dropDownSelected = false;

      let ids = [];
      if (this.exchangeableSetTypes && this.exchangeableSetTypes.length) {
        this.exchangeableSetTypes.forEach((item) => {
          ids.push(item.id);
        });

        this.actions.patchValue({
          exchangeable_set_types: ids,
        });
      } else {
        this.exchangeableSetTypes = [];
      }
      this.getExchangeableSets(ids, this.exchangeableSetTypes);
    }
  }

  getExchangeableSets(set_type_ids?: string[] | number[], filterData = []) {
    if (set_type_ids) {
      const params = {
        page: 1,
        page_size: config.maxPageSize,
        set_type__in: set_type_ids.join(","),
      };
      this.loading = true;
      this.exchangeablesSetService
        .list(params)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: Pager) => {
            this.loading = false;
            if (filterData.length > 0) {
              let exchangeableSets = [];
              filterData.forEach((id) => {
                let es = data.results.filter((item) => item.id === id);
                if (es.length > 0) {
                  exchangeableSets.push(es[0]);
                }
              });
              this.exchangeableSets = exchangeableSets;
            }
            this.unassignedExchangeableSets = data.results.slice();

            if (this.actions.value.exchangeable_set.length) {
              this.exchangeableSets = this.unassignedExchangeableSets.filter(
                (set) => this.actions.value.exchangeable_set.includes(set.id)
              );

              if (this.exchangeableSets.length) {
                this.getPreview(this.exchangeableSets[0].id);
              }
            }
          },
          (error) => {
            this.handleError(error);
          }
        );
    }
  }

  onExchangeableSetsChange(data: Array<any>) {
    this.actions.patchValue({
      exchangeable_set: data.map((set) => set.id),
    });

    if (data.length) {
      this.getPreview(data[0].id);
    } else {
      this.previewProducts = {
        data: [],
        loading: false,
      };
      this.stepEmulatorService.updatePreviewProducts(this.previewProducts);
    }
  }

  getPreview(id: string | number) {
    if (!this.previewProducts.data.length) {
      this.previewProducts.loading = true;
    }

    this.exchangeablesSetService
      .preview(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: any) => {
          this.previewProducts = { data, loading: false };
          this.stepEmulatorService.updatePreviewProducts(this.previewProducts);
        },
        (error) => {
          this.previewProducts = {
            data: [],
            loading: false,
          };
          this.stepEmulatorService.updatePreviewProducts(this.previewProducts);
          this.handleError(error);
        }
      );
  }

  toggleElement(element: StepElements) {
    this.stepEmulatorService.toggleElement(element);
  }

  isElementVisible(element: StepElements): boolean {
    return this.stepEmulatorService.isElementVisible(element);
  }


  get exchangeablesInvalidity() {
    return (
      this.enableFormErrors &&
      this.actions.controls.exchangeable_set.status ===
        FormControlStatus.Invalid
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
