import { SessionSource } from "../../_models/session-enums";
import { TranMatchedStatus } from "../../_models/chargeback";
import { MultiOptions } from "../../custom-multi-select/multi-select";
import { cloneDeep } from "lodash";

export const fields: MultiOptions = [
  {
    model: "dimensions",
    label: "Dimensions",
    selected: false,
    disabled: false,
    activeCount: 1,
    searchTerm: "",
    type: "DROPDOWN",
    options: [
      {
        model: "product_path",
        label: "Product Path",
        selected: false,
        disabled: false,
      },
      {
        model: "product_name",
        label: "Product Name",
        selected: false,
        disabled: false,
      },
      {
        model: "step_name",
        label: "Step Impressions",
        selected: false,
        disabled: false,
      },
      {
        model: "cancel_reason",
        label: "Cancel reason",
        selected: false,
        disabled: false,
      },
      {
        model: "campaign_name",
        label: "Brand",
        selected: false,
        disabled: false,
      },
      {
        model: "input_name",
        label: "Input Name",
        selected: false,
        disabled: false,
      },
      {
        model: "crm_campaign_name",
        label: "CRM campaign",
        selected: false,
        disabled: false,
      },
      {
        model: "crm_campaign_id",
        label: "CRM campaign ID",
        selected: false,
        disabled: false,
      },
      {
        model: "campaign_product_id",
        label: "Campaign product id",
        selected: false,
        disabled: false,
      },
      {
        model: "username",
        label: "Session ID",
        selected: false,
        disabled: false,
      },
      {
        model: "customer_id",
        label: "Customer ID",
        selected: false,
        disabled: false,
      },
      {
        model: "order_id",
        label: "Order ID",
        selected: false,
        disabled: false,
      },
    ],
  },
  {
    model: "timeInterval",
    label: "Time Interval",
    selected: false,
    disabled: false,
    activeCount: 0,
    searchTerm: "",
    type: "DROPDOWN",
    options: [
      { model: "hour", label: "Hours", selected: false, disabled: false },
      { model: "week", label: "Week", selected: false, disabled: false },
      { model: "month", label: "Month", selected: false, disabled: false },
      { model: "year", label: "Year", selected: false, disabled: false },
    ],
  },
  {
    model: "statistics",
    label: "Statistics",
    selected: false,
    disabled: false,
    activeCount: 0,
    searchTerm: "",
    type: "DROPDOWN",
    options: [
      {
        model: "refund_quantity",
        label: "Refund quantity",
        selected: false,
        disabled: false,
      },
      {
        model: "amount_paid",
        label: "Total billed",
        selected: false,
        disabled: false,
      },
      {
        model: "refund_amount",
        label: "Refund amount",
        selected: false,
        disabled: false,
      },
      {
        model: "partial_refund_amount",
        label: "Partial refund amount",
        selected: false,
        disabled: false,
      },
      {
        model: "full_refund_amount",
        label: "Full refund amount",
        selected: false,
        disabled: false,
      },
      {
        model: "customer_count",
        label: "Customer Quantity",
        selected: false,
        disabled: false,
      },
      {
        model: "orders_count",
        label: "Orders",
        selected: false,
        disabled: false,
      },
    ],
  },
];

export const filters: MultiOptions = [
  {
    model: "product_id",
    label: "Products",
    selected: false,
    disabled: false,
    loading: true,
    activeCount: 0,
    options: [],
    pagination: true,
    type: "DROPDOWN",
  },
  {
    model: "product_path",
    label: "Product Path",
    selected: false,
    disabled: false,
    loading: true,
    activeCount: 0,
    options: [],
    searchTerm: "",
    type: "DROPDOWN",
  },
  {
    model: "step_slug",
    label: "Step Impressions",
    selected: false,
    disabled: false,
    loading: true,
    activeCount: 0,
    options: [],
    searchTerm: "",
    type: "DROPDOWN",
  },
  {
    model: "cancel_reason",
    label: "Cancel Reason",
    selected: false,
    disabled: false,
    loading: true,
    activeCount: 0,
    options: [],
    searchTerm: "",
    type: "DROPDOWN",
  },
  {
    model: "input_name",
    label: "Input Name",
    selected: false,
    disabled: false,
    loading: true,
    activeCount: 0,
    options: [],
    searchTerm: "",
    type: "DROPDOWN",
  },
  {
    model: "campaign",
    label: "Brand",
    selected: false,
    disabled: false,
    loading: true,
    activeCount: 0,
    options: [],
    searchTerm: "",
    type: "DROPDOWN",
  },
  {
    model: "crm_campaign",
    label: "CRM Campaign",
    selected: false,
    disabled: false,
    loading: true,
    activeCount: 0,
    options: [],
    searchTerm: "",
    type: "DROPDOWN",
  },
];

export const paymentUpdaterFields: MultiOptions = [
  {
    model: "dimensions",
    label: "Dimensions",
    selected: false,
    disabled: false,
    activeCount: 1,
    searchTerm: "",
    type: "DROPDOWN",
    options: [
      {
        model: "username",
        label: "Session ID",
        selected: false,
        disabled: false,
      },
      {
        model: "source",
        label: "Path channel",
        selected: false,
        disabled: false,
      },
      {
        model: "tracking_source",
        label: "Traffic source",
        selected: false,
        disabled: false,
      },
      {
        model: "sid1",
        label: "Source sub 1",
        selected: false,
        disabled: false,
      },
      {
        model: "sid2",
        label: "Source sub 2",
        selected: false,
        disabled: false,
      },
      {
        model: "sid3",
        label: "Source sub 3",
        selected: false,
        disabled: false,
      },
      {
        model: "customer_name",
        label: "Customer Name",
        selected: false,
        disabled: false,
      },
      {
        model: "email",
        label: "Customer Email",
        selected: false,
        disabled: false,
      },
      {
        model: "phone",
        label: "Customer Phone",
        selected: false,
        disabled: false,
      },
      {
        model: "campaign_name",
        label: "Brand",
        selected: false,
        disabled: false,
      },
      {
        model: "error",
        label: "Error Message",
        selected: false,
        disabled: false,
      },
      {
        model: "note",
        label: "CRM Note",
        selected: false,
        disabled: false,
      },
      {
        model: "path_name",
        label: "Path Name",
        selected: false,
        disabled: false,
      },
      {
        model: "recording_url",
        label: "Video Recording",
        selected: false,
        disabled: false,
      },
      {
        model: "aff_id",
        label: "Affiliate",
        selected: false,
        disabled: false,
      },
      {
        model: "sub1",
        label: "Aff sub 1",
        selected: false,
        disabled: false,
      },
      {
        model: "sub2",
        label: "Aff sub 2",
        selected: false,
        disabled: false,
      },
      {
        model: "sub3",
        label: "Aff sub 3",
        selected: false,
        disabled: false,
      },
      {
        model: "country",
        label: "Country",
        selected: false,
        disabled: false,
      },
      {
        model: "device_type",
        label: "Device type",
        selected: false,
        disabled: false,
      },
      {
        model: "browser_name",
        label: "Browser",
        selected: false,
        disabled: false,
      },
      {
        model: "os_version",
        label: "Device OS version",
        selected: false,
        disabled: false,
      },
      {
        model: "mobile_vendor",
        label: "Device brand",
        selected: false,
        disabled: false,
      },
      {
        model: "mobile_model",
        label: "Device model",
        selected: false,
        disabled: false,
      },
      {
        model: "ip_address",
        label: "IP Address",
        selected: false,
        disabled: false,
      },
      {
        model: "action_result",
        label: "Session Action Result",
        selected: false,
        disabled: false,
      },
      {
        model: "result",
        label: "Session result",
        selected: false,
        disabled: false,
      },
      {
        model: "session_action_status",
        label: "Session Action Status",
        selected: false,
        disabled: false,
      },
    ],
  },
  {
    model: "statistics",
    label: "Statistics",
    selected: false,
    disabled: false,
    activeCount: 0,
    searchTerm: "",
    type: "DROPDOWN",
    options: [
      {
        model: "session_count",
        label: "Session Quantity",
        selected: false,
        disabled: false,
      },
      {
        model: "customer_count",
        label: "Customer Quantity",
        selected: false,
        disabled: false,
      },
      {
        model: "failed_tries",
        label: "Failed Tries",
        selected: false,
        disabled: false,
      },
    ],
  },
  {
    model: "exclude",
    label: "Exclude",
    selected: false,
    disabled: false,
    activeCount: 0,
    searchTerm: "",
    type: "DROPDOWN",
    options: [
      {
        model: "exclude_tests",
        label: "Tests",
        selected: false,
        disabled: false,
      },
      {
        model: "exclude_zeroes",
        label: "All Zeroes",
        selected: false,
        disabled: false,
      },
    ],
  },
];

export const paymentUpdaterFilters: MultiOptions = [
  {
    model: "campaign",
    label: "Brand",
    selected: false,
    disabled: false,
    loading: true,
    activeCount: 0,
    type: "DROPDOWN",
    options: [],
  },
  {
    model: "tracking_source",
    label: "Traffic source",
    selected: false,
    disabled: false,
    loading: true,
    activeCount: 0,
    type: "DROPDOWN",
    options: [],
    pagination: true,
  },
  {
    model: "source",
    label: "Channel source",
    selected: false,
    disabled: false,
    loading: false,
    activeCount: 0,
    type: "DROPDOWN",
    options: [
      { model: "Web", label: "Web", selected: false, disabled: false },
      { model: "Email", label: "Email", selected: false, disabled: false },
      { model: "Phone", label: "Phone", selected: false, disabled: false },
      {
        model: "Social Media",
        label: "Social Media",
        selected: false,
        disabled: false,
      },
    ],
    pagination: true,
  },
];

export const chargebackFields: MultiOptions = [
  {
    model: "dimensions",
    label: "Dimensions",
    selected: false,
    disabled: false,
    activeCount: 1,
    searchTerm: "",
    type: "DROPDOWN",
    options: [
      {
        model: "affiliate",
        label: "Affiliate",
        disabled: false,
      },
      { model: "sub1", label: "Aff sub 1", disabled: false },
      { model: "sub2", label: "Aff sub 2", disabled: false },
      { model: "sub3", label: "Aff sub 3", disabled: false },
      {
        model: "alert_source",
        label: "Alert Source",
        disabled: false,
      },
      {
        model: "billing_cycle",
        label: "Billing Cycle",
        disabled: false,
      },
      {
        model: "campaign",
        label: "Brand",
        disabled: false,
      },
      { model: "card_bin", label: "Card BIN", disabled: false },
      {
        model: "card_type",
        label: "Card Type",
        disabled: false,
      },
      {
        model: "card_last4",
        label: "Card Last 4",
        disabled: false,
      },
      {
        model: "chargeback_date",
        label: "Chargeback Date",
        disabled: false,
      },
      {
        model: "crm_campaign",
        label: "CRM campaign",
        disabled: false,
      },
      {
        model: "customer_id",
        label: "Customer ID",
        disabled: false,
      },
      {
        model: "merchant_id",
        label: "Merchant Processor",
        disabled: false,
      },
      {
        model: "mid_number",
        label: "MID number",
        disabled: false,
      },
      {
        model: "payment_source",
        label: "Payment Method",
        disabled: false,
      },
      {
        model: "refund_date",
        label: "Refund Date",
        disabled: false,
      },
      {
        model: "request_type",
        label: "Request Type",
        disabled: false,
      },
      {
        model: "sp_session_id",
        label: "Solvpath Session ID",
        disabled: false,
      },
      {
        model: "sp_session_source",
        label: "Session Source",
        disabled: false,
      },
      {
        model: "matched_status",
        label: "Solvpath Matched Type",
        disabled: false,
      },
      {
        model: "amount",
        label: "Transaction Amount",
        disabled: false,
      },
      {
        model: "transaction_id",
        label: "Transaction ID",
        disabled: false,
      },
      {
        model: "created",
        label: "Transaction Date",
        disabled: false,
      },
      {
        model: "transaction_type",
        label: "Transaction Type",
        disabled: false,
      },
    ],
  },
  {
    model: "statistics",
    label: "Statistics",
    selected: false,
    disabled: false,
    activeCount: 0,
    searchTerm: "",
    type: "DROPDOWN",
    options: [
      {
        model: "alert_ratio",
        label: "Alert Ratio",
        disabled: false,
      },
      {
        model: "chargeback_ratio",
        label: "Chargeback Ratio",
        disabled: false,
      },
      {
        model: "dispute_count",
        label: "Dispute Count",
        disabled: false,
      },
      {
        model: "dispute_ratio",
        label: "Dispute Ratio",
        disabled: false,
      },
      {
        model: "time_period",
        label: "Time Period",
        disabled: false,
      },
    ],
  },
];

export const chargebackFilters: MultiOptions = [
  {
    model: "alert_source",
    label: "Alert Source",
    selected: false,
    disabled: false,
    loading: false,
    activeCount: 0,
    type: "DROPDOWN",
    options: [
      {
        model: 0,
        label: "NONE",
        disabled: false,
        selected: false,
      },
      {
        model: 1,
        label: "ETHOCA",
        disabled: false,
        selected: false,
      },
      {
        model: 2,
        label: "VERIFI",
        disabled: false,
        selected: false,
      },
      {
        model: 3,
        label: "RDR",
        disabled: false,
        selected: false,
      },
      {
        model: 4,
        label: "OTHER",
        disabled: false,
        selected: false,
      },
    ],
  },
  {
    model: "billing_cycle",
    label: "Billing Cycle",
    selected: false,
    disabled: false,
    loading: false,
    activeCount: 0,
    selectedOption: "",
    type: "COMPARISON",
    options: [
      {
        model: "e",
        label: "Equals to",
        disabled: false,
        selected: false,
      },
      {
        model: "gt",
        label: "Greater than",
        disabled: false,
        selected: false,
      },
      {
        model: "gte",
        label: "Greater than or equals to",
        disabled: false,
        selected: false,
      },
      {
        model: "lt",
        label: "Less than",
        disabled: false,
        selected: false,
      },
      {
        model: "lte",
        label: "Less than or equals to",
        disabled: false,
        selected: false,
      },
      {
        model: "range",
        label: "Between",
        disabled: false,
        selected: false,
      },
    ],
  },
  {
    model: "card_bin",
    label: "Card Bin",
    selected: false,
    disabled: false,
    loading: false,
    activeCount: 0,
    inputType: "number",
    placeholder: "Please Enter Card Bin",
    type: "INPUT",
  },
  {
    model: "card_type",
    label: "Card Type",
    selected: false,
    disabled: false,
    loading: false,
    activeCount: 0,
    type: "DROPDOWN",
    options: [
      {
        model: 0,
        label: "NONE",
        disabled: false,
        selected: false,
      },
      {
        model: 1,
        label: "VISA",
        disabled: false,
        selected: false,
      },
      {
        model: 2,
        label: "MASTERCARD",
        disabled: false,
        selected: false,
      },
      {
        model: 3,
        label: "AMEX",
        disabled: false,
        selected: false,
      },
      {
        model: 4,
        label: "DISCOVER",
        disabled: false,
        selected: false,
      },
      {
        model: 5,
        label: "OTHER",
        disabled: false,
        selected: false,
      },
    ],
  },
  {
    model: "card_last4",
    label: "Card Last 4",
    selected: false,
    disabled: false,
    loading: false,
    activeCount: 0,
    inputType: "number",
    placeholder: "Please Enter Card Last 4",
    type: "INPUT",
  },
  {
    model: "campaign",
    label: "Brand",
    selected: false,
    disabled: false,
    loading: true,
    activeCount: 0,
    options: [],
    searchTerm: "",
    type: "DROPDOWN",
  },
  {
    model: "crm_campaign",
    label: "CRM Campaign",
    selected: false,
    disabled: false,
    loading: true,
    activeCount: 0,
    type: "DROPDOWN",
  },
  {
    model: "customer_email",
    label: "Customer Email",
    selected: false,
    disabled: false,
    loading: false,
    activeCount: 0,
    inputType: "text",
    placeholder: "Please Enter Customer Email",
    type: "INPUT",
  },
  {
    model: "customer_id",
    label: "Customer ID",
    selected: false,
    disabled: false,
    loading: false,
    activeCount: 0,
    inputType: "number",
    placeholder: "Please Enter Customer ID",
    type: "INPUT",
  },
  {
    model: "merchant_id",
    label: "Merchant Processor",
    selected: false,
    disabled: false,
    loading: true,
    options: [],
    activeCount: 0,
    type: "DROPDOWN",
  },
  {
    model: "mid_number",
    label: "MID Number",
    selected: false,
    disabled: false,
    loading: false,
    activeCount: 0,
    inputType: "number",
    placeholder: "Please Enter MID Number",
    type: "INPUT",
  },
  {
    model: "matched_status",
    label: "Solvpath Matched Type",
    selected: false,
    disabled: false,
    loading: false,
    activeCount: 0,
    type: "DROPDOWN",
    options: [
      {
        model: 0,
        label: "No Match",
        disabled: false,
        selected: false,
      },
      {
        model: 1,
        label: "Match By Customer",
        disabled: false,
        selected: false,
      },
      {
        model: 2,
        label: "Match By Session",
        disabled: false,
        selected: false,
      },
      {
        model: 3,
        label: "Match By Order",
        disabled: false,
        selected: false,
      },
    ],
  },
  {
    model: "amount",
    label: "Transaction Amount",
    selected: false,
    disabled: false,
    loading: false,
    activeCount: 0,
    selectedOption: "",
    type: "COMPARISON",
    options: [
      {
        model: "e",
        label: "Equals to",

        disabled: false,
        selected: false,
      },
      {
        model: "gt",
        label: "Greater than",

        disabled: false,
        selected: false,
      },
      {
        model: "gte",
        label: "Greater than or equals to",

        disabled: false,
        selected: false,
      },
      {
        model: "lt",
        label: "Less than",

        disabled: false,
        selected: false,
      },
      {
        model: "lte",
        label: "Less than or equals to",

        disabled: false,
        selected: false,
      },
      {
        model: "range",
        label: "Between",

        disabled: false,
        selected: false,
      },
    ],
  },
  {
    model: "transaction_id",
    label: "Transaction ID",
    selected: false,
    disabled: false,
    loading: false,
    activeCount: 0,
    inputType: "number",
    placeholder: "Please Enter Transaction ID",
    type: "INPUT",
  },
];

export const spTouchedFields: MultiOptions = cloneDeep(chargebackFields);
const dimensionsModel = spTouchedFields.find(
  (field) => field.model === "dimensions"
);

dimensionsModel.options.push(
  {
    model: "sp_session_path",
    label: "Product Path",
    disabled: false,
  },
  { model: "sp_session_step", label: "Step", disabled: false },
  {
    model: "sp_session_status",
    label: "Session Status",
    disabled: false,
  },
  {
    model: "sp_session_result",
    label: "Session Result",
    disabled: false,
  },
  {
    model: "sp_session_latest_result",
    label: "Latest Result",
    disabled: false,
  },
  {
    model: "sp_session_steps_count",
    label: "Step Count",
    disabled: false,
  }
);

export const spTouchedFilters = {
  matched_status: [
    TranMatchedStatus.MatchByOrder,
    TranMatchedStatus.MatchBySession,
    TranMatchedStatus.MatchByCustomer,
  ],
};

export const ChatbotFields: MultiOptions = [
  {
    model: "dimensions",
    label: "Dimensions",
    selected: false,
    disabled: false,
    activeCount: 1,
    searchTerm: "",
    type: "DROPDOWN",
    options: [
      {
        model: "customer_name",
        label: "Customer Name",
        selected: false,
        disabled: false,
      },
      {
        model: "session_source",
        label: "Session Source",
        selected: false,
        disabled: false,
      },
      {
        model: "chatbot_session_id",
        label: "Chatbot Session ID",
        selected: false,
        disabled: false,
      },
    ],
  },
  {
    model: "statistics",
    label: "Statistics",
    selected: false,
    disabled: false,
    activeCount: 0,
    searchTerm: "",
    type: "DROPDOWN",
    options: [
      {
        model: "message_count",
        label: "Message Count",
        selected: true,
        disabled: false,
      },
    ],
  },
];

export const ChatbotPopupFields = [
  "customer_name",
  "session_source",
  "chatbot_session_id",
  "exclude_zeroes",
  "question_at",
  "question",
  "answer_at",
  "answer",
  "is_success"
]

export const ChatbotFilters: MultiOptions = [
  {
    model: "chatbot_session_id",
    label: "Chatbot Session ID",
    selected: false,
    disabled: false,
    loading: false,
    activeCount: 0,
    inputType: "number",
    placeholder: "Please Enter Chatbot Session ID",
    type: "INPUT",
  },
  {
    model: "message_count",
    label: "Message Count",
    selected: false,
    disabled: false,
    loading: false,
    activeCount: 0,
    selectedOption: "",
    type: "COMPARISON",
    options: [
      {
        model: "e",
        label: "Equals to",
        disabled: false,
        selected: false,
      },
      {
        model: "gt",
        label: "Greater than",
        disabled: false,
        selected: false,
      },
      {
        model: "gte",
        label: "Greater than or equals to",
        disabled: false,
        selected: false,
      },
      {
        model: "lt",
        label: "Less than",
        disabled: false,
        selected: false,
      },
      {
        model: "lte",
        label: "Less than or equals to",
        disabled: false,
        selected: false,
      },
      {
        model: "range",
        label: "Between",
        disabled: false,
        selected: false,
      },
    ],
  },
  {
    model: "session_source",
    label: "Session Source",
    selected: false,
    disabled: false,
    loading: false,
    activeCount: 0,
    type: "DROPDOWN",
    options: [
      { model: SessionSource.Web, label: "Web", selected: false, disabled: false },
      { model: SessionSource.Email, label: "Email", selected: false, disabled: false },
      { model: SessionSource.Phone, label: "Phone", selected: false, disabled: false },
      {
        model: SessionSource.SMS,
        label: "SMS",
        selected: false,
        disabled: false,
      },
      {
        model: SessionSource.API,
        label: "API",
        selected: false,
        disabled: false,
      },
    ],
    pagination: true,
  },
];

export const amountKeys = [
  "SAVE AMOUNT",
  "REFUND AMOUNT",
  "AMOUNT PAID",
  "PARTIAL REFUND AMOUNT",
  "FULL REFUND AMOUNT",
];
export const percentageKeys = [
  "CANCEL SUBSCRIPTION RETENTION RATE",
  "RETURN RETENTION RATE",
  "CANCEL ORDER RETENTION RATE",
  "CHARGEBACK RATIO",
  "ALERT RATIO",
  "DISPUTE RATIO",
];

export const rateKeys = [
  "SAVE SUBSCRIPTION RATE",
  "RMA SAVE RATE",
  "SAVE HOLD RATE",
];

export const ignoredSummaryFields = [
  "extra_for_grouping",
  "exclude_tests",
  "exclude_zeroes",
  "exclude_hangups",
  "exclude_unknowns",
  "exclude_child_sessions",
  "exclude_child_orders",
];
