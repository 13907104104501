import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Location } from "@angular/common";
import {
  StepEmulatorDevices,
  StepMediaCategory,
  StepMedia,
  StepImageSizes,
  StepFixedMediaCategory,
  Image,
} from "../../../../_models";
import { ImageUploadComponent } from "../../../../image/image-upload.component";
import { ActivatedRoute, Router } from "@angular/router";
import { UploadOutput } from "ngx-uploader";
import {
  AlertService,
  ImageService,
  StepEmulatorService,
  StorageService,
} from "../../../../_services";
import { DomSanitizer } from "@angular/platform-browser";
import { Subscription } from "rxjs";

@Component({
  selector: "app-step-fixed-images",
  templateUrl: "./step-fixed-images.component.html",
  styleUrls: ["./step-fixed-images.component.scss"],
})
export class StepFixedImagesComponent
  extends ImageUploadComponent
  implements OnInit
{
  @Output() stepEmitImagesData = new EventEmitter<StepMedia>();
  @ViewChild("squareImageFileInput", { static: false })
  squareImageFileInput!: ElementRef<HTMLInputElement>;
  @ViewChild("rectangleImageFileInput", { static: false })
  rectangleImageFileInput!: ElementRef<HTMLInputElement>;
  activeDevice: StepEmulatorDevices = StepEmulatorDevices.Mobile;
  stepMedia: StepMedia;
  private subscription: Subscription;
  emulatorDevices = StepEmulatorDevices;
  stepMediaCategory = StepMediaCategory;
  draggedItem: StepImageSizes | null = null;
  stepImageSizes = StepImageSizes;
  imageUploading = { loading: false, progress: 0 };
  error: string | null = null;
  imageSizes = StepImageSizes;
  selectedCategory: StepFixedMediaCategory = StepFixedMediaCategory.Upload;
  stepFixedMediaCategory = StepFixedMediaCategory;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected imageService: ImageService,
    protected alertService: AlertService,
    protected storageService: StorageService,
    protected stepEmulatorService: StepEmulatorService,
    protected sanitizer: DomSanitizer
  ) {
    super(
      router,
      location,
      route,
      imageService,
      alertService,
      storageService,
      sanitizer
    );
  }

  ngOnInit() {
    this.subscription = this.stepEmulatorService.stepMedia$.subscribe(
      (data) => {
        this.stepMedia = data;
        this.selectedCategory = this.stepMedia.fixedImage.category;
      }
    );
  }

  updateStepMedia() {
    this.stepEmitImagesData.emit();
  }

  setActiveDevice(device: StepEmulatorDevices) {
    this.activeDevice = device;
  }

  async onUploadImage(
    fileInput: ElementRef<HTMLInputElement>,
    minSize: number
  ) {
    const fileInputElement = fileInput.nativeElement;
    let file: File | null = null;

    if (fileInputElement.files && fileInputElement.files[0]) {
      file = fileInputElement.files[0];
    }

    const validMimeTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
    ];

    if (!validMimeTypes.includes(file.type)) {
      this.error =
        "Invalid file type. Only JPEG, JPG, PNG, and GIF files are allowed.";
      return window.alert(this.error);
    }

    if (file) {
      if (file.type.startsWith("image/")) {
        if (await this.isImageSizeValid(file, minSize)) {
          this.imageUploading.loading = true;

          const reader = new FileReader();
          reader.onload = (e) => {
            const result = (e.target as FileReader).result;

            if (result && typeof result === "string") {
              this.error = null;

              this.onImageUpload({
                type: "addedToQueue",
                nativeFile: file,
              });
            } else {
              window.alert("Error reading image file.");
            }
          };

          reader.onprogress = (event) => {
            if (event.lengthComputable) {
              this.imageUploading.progress = (event.loaded / event.total) * 100;
            }
          };

          reader.readAsDataURL(file);
        } else {
          window.alert("Image size is below the minimum required size.");
        }
      } else {
        window.alert("Selected file is not an image.");
      }
    }
    fileInput.nativeElement.value = "";
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  onImageUpload(output: UploadOutput) {
    this.onUploadOutput({
      type: "addedToQueue",
      file: this.uploaderService.makeUploadFile(output.nativeFile, 0),
    });
  }

  async onUploadOutput(output: UploadOutput) {
    const imageProps: any = await this.imageService.getImageAttributes(
      output.file
    );
    this.files.push({
      id: output.file.id,
      is_public: true,
      file: output.file.nativeFile,
      resourcetype: this.resourceType,
      path: output.file.name,
      ppoi: "0.5x0.5",
      height: imageProps.height,
      width: imageProps.width,
    });

    this.startUpload();
  }

  startUpload(): void {
    this.loading = true;
    this.imageService.create(this.files).subscribe(
      (images: Image) => {
        this.postUploadProcess([images]);
      },
      (error) => {
        this.handleSubmitError(error);
        this.loading = false;
      }
    );
  }

  postUploadProcess(images: Image[]) {
    if (!Array.isArray(images)) {
      images = [images];
    }
    this.imageService
      .postUploadProcess(this.files, images)
      .then((result: Image) => {
        this.alertService.success("Successfully uploaded new files");
        this.imageUploading = { loading: false, progress: 0 };
        this.stepMedia.fixedImage[this.activeDevice] = result[0];
        this.updateStepMedia();

        this.loading = false;
        this.previewFiles = [];
        this.files = [];
      });
  }

  updateCategory(category: StepFixedMediaCategory) {
    this.selectedCategory = category;
    this.stepMedia.fixedImage.category = category;
    this.updateStepMedia();
  }

  private async isImageSizeValid(
    file: File,
    minSize: number
  ): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const img = new Image();
      img.onload = () => {
        if (img.width && img.height) {
          if (img.width * img.height < minSize) {
            resolve(false);
          }
          resolve(true);
        }
        resolve(false);
      };

      img.src = URL.createObjectURL(file);
    });
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent, item: StepImageSizes) {
    event.preventDefault();
    this.draggedItem = item;

    const file =
      event.dataTransfer &&
      event.dataTransfer.files &&
      event.dataTransfer.files[0];
    if (file) {
      if (item === StepImageSizes.Square) {
        const fileList = new DataTransfer();
        fileList.items.add(file);
        this.squareImageFileInput.nativeElement.files = fileList.files;
        this.onSquareImageUpload();
      } else if (item === StepImageSizes.Rectangle) {
        const fileList = new DataTransfer();
        fileList.items.add(file);
        this.rectangleImageFileInput.nativeElement.files = fileList.files;
        this.onRectangleImageUpload();
      }
    }
  }

  async onRectangleImageUpload() {
    await this.onUploadImage(this.rectangleImageFileInput, 1200 * 627);
  }

  async onSquareImageUpload() {
    await this.onUploadImage(this.squareImageFileInput, 400 * 400);
  }

  clearImage(){
    this.stepMedia.fixedImage[this.activeDevice] = {} as Image;
    this.updateStepMedia();
  }

  get isMobile() {
    return this.activeDevice === StepEmulatorDevices.Mobile;
  }

  get isTablet() {
    return this.activeDevice === StepEmulatorDevices.Tablet;
  }

  get isDesktop() {
    return this.activeDevice === StepEmulatorDevices.Desktop;
  }

  get isGallery() {
    return this.selectedCategory === StepFixedMediaCategory.Gallery;
  }

  get isWidget() {
    return this.selectedCategory === StepFixedMediaCategory.Widget;
  }

  get isUpload() {
    return this.selectedCategory === StepFixedMediaCategory.Upload;
  }

  get selectedImage(){
    return this.stepMedia.fixedImage[this.activeDevice]
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
