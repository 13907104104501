import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {
  AlertService,
  CampaignProductService,
  CRMCampaignService,
  CRMService,
  FunnelService,
  ProductService,
  RegionService
} from '../_services';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {CrudSaveComponent} from '../_directives';
import {
  AvailableFunnelItemStatuses,
  CancelItemsSelection,
  CardType,
  CardTypeLabels,
  CRM,
  CRMCampaign,
  CRMTypeId,
  Funnel,
  FunnelFulfillmentType,
  FunnelType,
  FunnelTypeLabels,
  ImageSize,
  Pager,
  PhoneCarrierType,
  Product,
  Region,
  SessionType,
  SessionTypeLabels,
} from "../_models";
import {config} from "../../config/config";
import {takeUntil} from "rxjs/operators";
import {getCampaignProductImageUrl, getProductImageUrl} from "../_helpers";
import {NgxSmartModalService} from 'ngx-smart-modal';
import {forkJoin, of} from 'rxjs';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './funnel-edit.component.html'
})
export class FunnelNewComponent extends CrudSaveComponent implements OnInit {
  funnel: Funnel;
  defaultProductId = null;
  funnelType: FunnelType | null;
  products: any[] = null;
  productsModalInput: any[] = null;
  useMatchedWelcomeMessage = false;
  useUnmatchedWelcomeMessage = false;
  lifelines: Funnel[] = [];
  surveys: Funnel[] = [];
  availableItemStatuses: {id: number, text: string}[] = [];
  availableStates: { id: number, text: string }[] = [];
  itemStatusSettings: any = {};
  stateSettings = {
    singleSelection: false,
    allowSearchFilter: true,
    enableCheckAll: false
  };
  availableCardTypes: { id: number, text: string }[] = [];
  cardTypeSettings = {
    singleSelection: false,
    allowSearchFilter: false,
    enableCheckAll: false
  };
  showActions = false;
  createFromTemplate = false;
  canSelectType = false;
  copyLoading: boolean = false;
  selectedCRMCampaigns: {} = {};
  CRMCampaigns: {} = {};
  selectedCRMId = null;
  crms: CRM[];
  allCRMCampaigns: CRMCampaign[];

  voice_types = [
    {
      label: 'Woman',
      value: 'woman'
    },
    {
      label: 'Man',
      value: 'man'
    }
  ];

  phoneCarrierTypes = [
    {value: PhoneCarrierType.Unknown, label: 'Any'},
    {value: PhoneCarrierType.Mobile, label: 'Mobile'},
    {value: PhoneCarrierType.Landline, label: 'Landline'}
  ];

  funnelTypes = [
    {value: FunnelType.Visual, label: 'Visual'},
    {value: FunnelType.Product, label: 'Product'}
  ];

  cancelItemsSelections = [
    {value: CancelItemsSelection.UpsellsOnlyIfUpsell, label: 'Show Upsells Only if Upsell, Otherwise Show All'},
    {value: CancelItemsSelection.UpsellsOnly, label: 'Show Upsells Only'},
    {value: CancelItemsSelection.ShowAll, label: 'Show All'},
    {value: CancelItemsSelection.ShowNone, label: 'Show None'}
  ];

  fulfillmentTypes = [
    {value: FunnelFulfillmentType.Shippable, label: 'Shippable'},
    {value: FunnelFulfillmentType.NotShippable, label: 'Non-shippable'}
  ];

  sessionTypes = [
    {value: SessionType.Support, label: SessionTypeLabels[SessionType.Support]},
    {value: SessionType.Marketplace, label: SessionTypeLabels[SessionType.Marketplace]},
    {value: SessionType.PaymentUpdate, label: SessionTypeLabels[SessionType.PaymentUpdate]},
  ]

  // config for products pagination
  pageCount = 1;
  page = 1;
  pageSize = 15;
  totalResultCount = 0;

  rowsPerPageOptions = [
    {value: 15, label: "15"},
    {value: 25, label: "25"},
    {value: 50, label: "50"},
    {value: 100, label: "100"},
    {value: 250, label: "250"},
  ];

  displayProductsTable: boolean = false;

  protected itemStatusLabels = {};
  private defaultSessionType = SessionType.Support;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected funnelService: FunnelService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected productService: ProductService,
    protected campaignProductService: CampaignProductService,
    protected modalService: NgxSmartModalService,
    protected regionService: RegionService,
    protected crmCampaignService: CRMCampaignService,
    protected crmService: CRMService,
  ) {
    super(router, location, route, funnelService, alertService);
    this.isNew = true;
    this.objectName = 'Path';
    this.title = 'Create New Path';

    CardTypeLabels.forEach((label: string, cardType: CardType) => {
      this.availableCardTypes.push({id: cardType, text: label});
    });
  }

  ngOnInit() {
    let defaultCampaignId = this.route.snapshot.params['campaign_id'] || null;
    let defaultFunnelId = this.route.snapshot.params['funnel_id'] || null;
    let defaultErrorFunnelId = this.route.snapshot.params['error_funnel_id'] || null;
    let defaultExitFunnelId = this.route.snapshot.params['exit_funnel_id'] || null;
    let defaultSearchFunnelId = this.route.snapshot.params['search_funnel_id'] || null;
    let defaultStepId = this.route.snapshot.params['step_id'] || null;
    let defaultInputId = this.route.snapshot.params['input_id'] || null;
    let defaultResourceType = this.route.snapshot.params['resourcetype'] || null;
    this.defaultSessionType = this.route.snapshot.params['session_type'] || SessionType.Support;
    this.defaultProductId = this.route.snapshot.params['product_id'] || null;
    this.funnelType = defaultResourceType;

    if (this.isNew && (this.defaultSessionType.toString() === SessionType.Support.toString())) {
      this.canSelectType = !this.funnelType || this.isVisualFunnel();
    }

    this.form = this.formBuilder.group({
      resourcetype: [this.funnelType, Validators.required],
      id: [null],
      name: [null, Validators.required],
      is_visual: [true],
      is_voice: [false],
      is_sms: [false],
      campaign: [defaultCampaignId],
      funnel: [defaultFunnelId],
      error_funnel: [defaultErrorFunnelId],
      exit_funnel: [defaultExitFunnelId],
      search_funnel: [defaultSearchFunnelId],
      step: [defaultStepId],
      input: [defaultInputId],
    });
    super.ngOnInit();
    this.surveys = [];
    this.lifelines = [];
    this.updateControlsForFunnelType();
    this.buildItemStatusList();
    this.availableItemStatuses.forEach((value: {id: number, text: string}) => {
      this.itemStatusLabels[value.id] = value.text;
    });

    this.funnelService.list({resourcetype__in: 'Lifeline,Survey', page: 1, page_size: config.maxPageSize})
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: Pager) => {
          data.results.forEach((funnel: Funnel) => {
            if (funnel.resourcetype === FunnelType.Lifeline) {
              this.lifelines.push(funnel);
            } else {
              this.surveys.push(funnel);
            }
          });
        },
        error => {
          this.handleError(error);
        }
      );

    this.regionService.list('us')
      .subscribe(
        (states: Region[]) => {
          let availableStates = [];

          states.forEach((state: Region) => {
            availableStates.push({id: state.id, text: state.name});
          });

          this.availableStates = availableStates;
        }
      )

    forkJoin([
      this.crmService.list({page: 1, page_size: config.maxPageSize, 'type!': CRMTypeId.Test}),
      this.crmCampaignService.list({page: 1, page_size: config.maxPageSize,
        'crm__type!': CRMTypeId.Test})
    ]).subscribe(
      (data: [Pager, Pager]) => {
        this.crms = data[0].results;
        this.allCRMCampaigns = data[1].results;
        this.buildCRMCampaignLists();
      },
      error => {
        this.handleError(error);
      }
    );
  }

  protected buildCRMCampaignLists() {
    if (this.funnel && this.crms && this.allCRMCampaigns) {
      let selectedCRMCampaigns = {};

      //initialize empty crm campaign arrays for each crm
      this.crms.forEach((crm: CRM) => {
        this.selectedCRMCampaigns[crm.id] = [];
        this.CRMCampaigns[crm.id] = [];
      });

      if (this.crms.length == 1) {
        this.selectedCRMId = this.crms[0].id;
      }

      //build a map of the selected crm campaign ids for faster lookup
      if (this.funnel.crm_campaigns) {
        for (let campaign_id of this.funnel.crm_campaigns) {
          selectedCRMCampaigns[campaign_id] = true;
        }
      }

      //fill in the list of crm campaigns for each crm
      this.allCRMCampaigns.forEach((campaign: CRMCampaign) => {
        if (campaign.id in selectedCRMCampaigns) {
          this.selectedCRMCampaigns[campaign.crm].push(campaign);
        }

        this.CRMCampaigns[campaign.crm].push(campaign);
      });
    }
  }

  protected buildItemStatusList() {
    this.availableItemStatuses = AvailableFunnelItemStatuses.slice(0);
  }

  get supportsProducts() {
    return this.isProductFunnel() || this.isTroubleshooter();
  }

  protected getProducts() {
    if (this.funnel) {
      this.loading = true
      if (this.isProductFunnel()) {
        this.campaignProductService.list(
          {page: this.page, page_size: this.pageSize, funnels: this.funnel.id}
        ).pipe(takeUntil(this.destroy$))
          .subscribe(
            (page: Pager) => {
              this.loading = false
              this.products = page.results;
              this.funnel.campaign_products = page.results
              this.setPagerData(page)
            },
            error => {
              this.loading = false
              this.handleError(error);
            }
          );
      } else if (this.isTroubleshooter()) {
        this.productService.list(
          {page: this.page, page_size: this.pageSize, troubleshooters: this.funnel.id}
        ).pipe(takeUntil(this.destroy$))
          .subscribe(
            (page: Pager) => {
              this.loading = false
              this.products = page.results;
              this.funnel.products = page.results
              this.setPagerData(page)
            },
            error => {
              this.loading = false
              this.handleError(error);
            }
          );
      } else {
        this.loading = false; // nothing to do
      }
    }
  }

  private populateCampaignProductRequests(productIds: string[] | number[]) {
    let requests = []
    while (productIds.length > 0) {
      requests.push(this.funnelService.add_campaign_products(this.funnel.id, {campaign_products: productIds.splice(0, 200)}))
    }

    return requests
  }

  private populateProductRequests(productIds: string[] | number[]) {
    let requests = []
    while (productIds.length > 0) {
      requests.push(this.funnelService.add_products(this.funnel.id, {products: productIds.splice(0, 200)}))
    }

    return requests
  }

  addProductsToFunnel(productIds: string[] | number[]) {
    this.loading = true
    let requests = []
    if (this.isProductFunnel()) {
      requests = this.populateCampaignProductRequests(productIds)
    } else if (this.isTroubleshooter()) {
      requests = this.populateProductRequests(productIds)
    } else {
      this.loading = false
      return
    }

    forkJoin(requests)
      .subscribe(_ => {
        this.loading = false
        this.getProducts()
      }, error => {
        this.loading = false
        this.handleError(error)
      })
  }

  removeProductFromFunnel(productId) {
    let obs = of({})
    if (this.isProductFunnel()) {
      obs = this.funnelService.remove_campaign_product(this.funnel.id, {campaign_product: productId})
    } else if (this.isTroubleshooter()) {
      obs = this.funnelService.remove_product(this.funnel.id, {product: productId})
    } else {
      return
    }

    this.loading = true
    obs
      .subscribe(_ => {
        this.loading = false
        this.getProducts()
      }, error => {
        this.loading = false
        this.handleError(error)
      })
  }

  addProduct() {
    this.modalService.getModal('productsDialog').open();
  }

  onProductsSelected(productIds: string[] | number[]) {
    this.modalService.getModal('productsDialog').close();
    this.addProductsToFunnel(productIds)
  }

  getProductName(product) {
    return product.name;
  }

  getProductCRMId(product) {
    if (this.isTroubleshooter()) {
      return product.product_id;
    }

    return product.campaign_product_id;
  }

  getProductImageUrl(product) {
    if (!this.isTroubleshooter()) {
      return getCampaignProductImageUrl(product, ImageSize.small);
    }

    return getProductImageUrl(product, ImageSize.small);
  }

  copyProducts() {
    if (this.isTroubleshooter()) {
      this.funnelService.copyProducts(this.products);
    } else {
      this.funnelService.copyCampaignProducts(this.products);
    }
  }

  canPasteProducts() {
    return this.isTroubleshooter() ?
      this.funnelService.canPasteProducts() : this.funnelService.canPasteCampaignProducts();
  }

  pasteProducts() {
    this.products = this.isTroubleshooter() ?
      this.funnelService.pasteProducts() : this.funnelService.pasteCampaignProducts();
  }

  isProductFunnel() : boolean {
    return (this.funnelType === FunnelType.Product);
  }

  isVisualFunnel() : boolean {
    return (this.funnelType === FunnelType.Visual);
  }

  isVoiceFunnel() : boolean {
    return (this.funnelType === FunnelType.Voice);
  }

  isTroubleshooter() : boolean {
    return (this.funnelType === FunnelType.Troubleshooter);
  }

  isHybridFunnel() : boolean {
    return (this.funnelType === FunnelType.Hybrid);
  }

  getType() : string {
    return FunnelTypeLabels[this.funnelType];
  }

  selectType(event) {
    this.funnelType = event.target.value;
    this.updateControlsForFunnelType();
  }

  copy() {
    if (this.funnel) {
      this.funnelService.copy(this.funnel.id)
    }
  }

  canPasteStep() {
    return false;
  }

  pasteStep() {
  }

  enableMatchedWelcomeMessage(event) {
    this.useMatchedWelcomeMessage = event.target.checked;
  }

  enableUnmatchedWelcomeMessage(event) {
    this.useUnmatchedWelcomeMessage = event.target.checked;
  }

  enableShowActions(event) {
    this.showActions = event.target.checked;
  }

  preview() {
    if (this.funnel) {
      this.modalService.getModal('funnelPreviewDialog').open();
    }
  }

  onPreview(previewUrl: string) {
    this.modalService.getModal('funnelPreviewDialog').close();
    window.open(previewUrl, '_blank');
  }

  publish() {
    if (this.funnel && this.funnel.is_modified) {
      this.loading = true;
      this.funnelService.publish(this.funnel.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.funnel.is_modified = false;
            this.alertService.success('Path changes are published.', true);
            this.loading = false;
          },
          error => {
            this.handleError(error);
            this.loading = false;
          }
        );
    }
  }

  protected getFormData() {
    let itemStatuses = null;
    let merchantIds = null;
    let states = null;
    let cardTypes = null;
    let affIds = null;

    if (!this.useMatchedWelcomeMessage) {
      this.form.value.matched_welcome_msg = null;
    }

    if (!this.useUnmatchedWelcomeMessage) {
      this.form.value.unmatched_welcome_msg = null;
    }

    if (this.form.value.item_statuses && this.form.value.item_statuses.length) {
      itemStatuses = [];
      this.form.value.item_statuses.forEach(itemStatus => {
        itemStatuses.push(itemStatus.id);
      });
    }

    if (this.form.value.states && this.form.value.states.length) {
      states = [];
      this.form.value.states.forEach(state => {
        states.push(state.id);
      });
    }

    if (this.form.value.cc_types && this.form.value.cc_types.length) {
      cardTypes = [];
      this.form.value.cc_types.forEach(cardType => {
        cardTypes.push(cardType.id);
      });
    }

    if (this.form.value.merchant_ids && this.form.value.merchant_ids.length) {
      // create an array of merchant id strings, excluding whitespace
      merchantIds = this.form.value.merchant_ids.replace(/(\r?\n|;)/g, ",").split(',').map(value => value.trim())
        .filter(value => value.length);
    }

    if (this.form.value.aff_ids && this.form.value.aff_ids.length) {
      // create an array of affiliate id strings, excluding whitespace
      affIds = this.form.value.aff_ids.replace(/(\r?\n|;)/g, ",").split(',').map(value => value.trim())
        .filter(value => value.length);
    }

    let filters = {
      item_statuses: itemStatuses, merchant_ids: merchantIds, states: states, cc_types: cardTypes, aff_ids: affIds};

    if (this.form.value.use_crm_campaign_filter) {
      let campaignIds = [];

      this.crms.forEach((crm: CRM) => {
        this.selectedCRMCampaigns[crm.id].forEach((campaign: CRMCampaign) => {
          campaignIds.push(campaign.id);
        })
      });

      filters['crm_campaigns'] = campaignIds;
    }

    return Object.assign({}, this.form.value, filters);
  }

  protected onSaveComplete(data) {
    this.navigate(['/path', 'edit', this.id], { replaceUrl: true });
  }

  protected updateControlsForFunnelType() {
    let extraFields = {};
    extraFields[FunnelType.Hybrid] = {
      matched_verify_msg: [null],
      unmatched_verify_msg: [null],
      match_msg: [null],
      matched_welcome_msg: [null],
      unmatched_welcome_msg: [null],
      thank_you_msg: [null],
      auto_select_single_item: [false],
      lifeline_timeout: [0, Validators.min(0)],
      lifeline_undo_count: [0, Validators.min(0)],
      lifeline: [null],
      error_lifeline: [null],
      exit_lifeline: [null],
      search_lifeline: [null],
      exit_survey: [null],
      hidden_fees_label: ['Processing and Handling', Validators.required],
      cancel_items_selection: [CancelItemsSelection.UpsellsOnlyIfUpsell, Validators.required],
      return_items_selection: [CancelItemsSelection.ShowAll, Validators.required],
    };

    extraFields[FunnelType.Visual] = Object.assign({}, extraFields[FunnelType.Hybrid], {
      session_type: [this.defaultSessionType, Validators.required]
    });

    extraFields[FunnelType.Product] = {
      item_statuses: [null, Validators.required],
      use_product_filter: [false],
      is_3ds: [false],
      cancel_items_selection: [CancelItemsSelection.UpsellsOnlyIfUpsell, Validators.required],
      return_items_selection: [CancelItemsSelection.ShowAll, Validators.required],
      min_billing_cycle: [1, [Validators.required, Validators.min(1)]],
      merchant_ids: [null],
      fulfillment_type: [null],
      states: [null],
      cc_types: [null],
      use_crm_campaign_filter: [false],
      aff_ids: [null],
    };

    extraFields[FunnelType.Voice] = {
      is_after_hours: [false],
      match_required: [false],
      carrier_type: [PhoneCarrierType.Unknown, Validators.required],
      voice_type: ['woman', Validators.required]
    };

    extraFields[FunnelType.Troubleshooter] = {};
    extraFields[FunnelType.Lifeline] = {};
    extraFields[FunnelType.Survey] = {};

    //remove all the extra fields
    for (let field in this.form.controls) {
      Object.keys(extraFields).forEach((type: FunnelType) => {
        if (field in extraFields[type]) {
          this.form.removeControl(field);
        }
      });
    }

    //add the extra fields for this funnel type
    if (this.funnelType in extraFields) {
      Object.keys(extraFields[this.funnelType]).forEach((fieldName: string) => {
        let field = extraFields[this.funnelType][fieldName];

        this.form.addControl(fieldName, new FormControl(field[0], field[1]));
      });
    }

    this.updateTitle();
  }

  private updateTitle() {
    let title = '';

    if (this.isTroubleshooter() ||
        (this.funnelType === FunnelType.Lifeline) ||
        (this.funnelType === FunnelType.Survey)) {
      this.objectName = this.getType();
    } else if (this.funnelType === FunnelType.Hybrid) {
      this.objectName = 'Hybrid Path';
    } else {
      this.objectName = this.funnel ? this.getType() + ' Path' : 'Path';
    }

    if (this.isNew) {
      title = 'Create New ' + this.objectName;

      if (this.createFromTemplate) {
        title += ' From a Template';
      }
    } else {
      title = 'Edit Your ' + this.objectName;
    }

    this.setTitle(title);
  }

//  paginator controls
  onPageChange(params) {
    this.page = params.page;
    this.getProducts();
  }

  protected resetPager() {
    this.page = 1;
  }

  protected setPagerData(data: Pager) {
    this.pageCount = data.num_pages;
    this.page = data.page;

    if (this.page === 1) {
      this.totalResultCount = data.count;
    }
  }

  changeRowsPerPage(event) {
    this.pageSize = Number(event.target.value);
    this.resetPager();
    this.getProducts();
  }

  toggleProductsDisplay() {
    this.displayProductsTable = !this.displayProductsTable
    if (this.displayProductsTable) {
      this.getProducts()
    }
  }

  displayFormat(row) {
    return `${row.name} (${row.crm_campaign_id})`;
  }
}
