import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import {
  AlertService,
  CancelReasonCategoriesService,
  FunnelStepService,
  TemplateFolderService,
  UserService,
} from "../../_services";
import { CrudSavePopupComponent } from "../../_directives";
import { FormBuilder, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { StepPopupActions, User } from "../../_models";
import { takeUntil } from "rxjs/operators";

@Component({
  moduleId: module.id.toString(),
  selector: "template-popup",
  templateUrl: "./popup.component.html",
  styleUrls: ["./../steps.component.scss"],
})
export class TemplatePopupComponent
  extends CrudSavePopupComponent
  implements OnInit
{
  ngModelOptions = { standalone: true };
  operation: Observable<Object>;
  StepPopupActions = StepPopupActions;
  user: User;
  title = 'folder';

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected formBuilder: FormBuilder,
    protected templateFolderService: TemplateFolderService,
    protected funnelStepService: FunnelStepService,
    protected cancelReasonCategoriesService: CancelReasonCategoriesService,
    protected userService: UserService,
    protected alertService: AlertService
  ) {
    super(router, location, route, templateFolderService, alertService);
    this.objectName = "Offer";
    this.isNew = false;
    this.isPartial = true;
  }

  ngOnInit() {
    this.userService
      .getCurrent()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (user: User) => {
          this.user = user;
        },
        (error) => {
          this.handleError(error);
        }
      );

    this.setForm(
      this.formBuilder.group({
        name: [this.inputData.name || "", Validators.required],
        category: this.inputData.category,
        is_global: false,
      })
    );

    super.ngOnInit();
  }

  onSubmit() {
    this.submitted = true;

    if (
      this.form.valid ||
      this.inputData.popupType === StepPopupActions.Delete ||
      this.inputData.popupType === StepPopupActions.DeleteFolder
    ) {
      this.loading = true;
      const { popupType, folderId, ...formData } = this.getFormData();

      let operationTitle = "";

      switch (popupType) {
        case StepPopupActions.CreateFolder:
          this.crudService.create(formData).subscribe(
            (data) => {
              if ("id" in data) {
                //@ts-ignore
                this.id = data["id"];
              }

              if (this.showSuccessMessage) {
                this.alertService.success("Folder Created Successfully.", true);
              }

              this.canDeactivateService.setFormDirty(false);
              this.onSaveComplete(data);
            },
            (error) => {
              this.handleSubmitError(error);
              this.loading = false;
            }
          );
          return;

        case StepPopupActions.UpdateFolder:
          this.operation = this.crudService.patch(folderId, formData);
          operationTitle = "Updated";
          this.performOperation(operationTitle);
          break;

        case StepPopupActions.DeleteFolder:
          this.operation = this.crudService.delete(folderId);
          operationTitle = "Deleted";

          this.performOperation(operationTitle);
          break;

        default:
          this.loading = false;
          return;
      }
    }
  }

  performOperation(operationTitle) {
    this.operation.subscribe(
      (data) => {
        if (this.showSuccessMessage) {
          this.alertService.success(`${operationTitle} Successfully.`, true);
        }

        this.canDeactivateService.setFormDirty(false);
        this.onSaveComplete(data);
      },
      (error) => {
        this.handleSubmitError(error);
        this.loading = false;
      }
    );
  }

  protected getFormData() {
    const data = {
      ...this.form.value,
      popupType: this.inputData.popupType,
      category: +this.inputData.category,
      folderId: this.inputData.folderId,
    };
    return data;
  }

  popupTypeTextMap: Partial<Record<StepPopupActions, string>> = {
    [StepPopupActions.DeleteFolder]: 'Delete',
    [StepPopupActions.UpdateFolder]: 'Rename',
    [StepPopupActions.CreateFolder]: 'Create'
  };
}
