import {Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, FunnelStepService} from '../_services';
import {FormBuilder, Validators} from '@angular/forms';
import {
  Action,
  ActionType, ConfirmationStepActions,
  Funnel,
  FunnelInput,
  FunnelInputSystemTypes,
  FunnelInputTypeEnum,
  FunnelInputTypeLabels,
  FunnelStep,
  FunnelSystemInputChildTypes,
  FunnelType,
  getAlternateExitLabel,
  getExitLabel,
  getFunnelItemTypeLabel,
  IconFile
} from '../_models';
import {takeUntil} from 'rxjs/operators';
import {Form} from '../_forms';
import {FunnelActionFieldsComponent} from '../funnel-action';
import {NgxSmartModalService} from 'ngx-smart-modal';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './funnel-input-fields.component.html',
  selector: 'funnel-input-fields',
  styleUrls: ['./funnel-input.component.css']
})
export class FunnelInputFieldsComponent extends Form implements OnInit, OnChanges {
  getStepName = FunnelStepService.getStepName;
  icon: IconFile;
  actions: Action[] = [];
  selectedActionIndex: number = -1;
  ready = false;
  jumpSteps: FunnelStep[] = [];

  @Input('funnel') funnel: Funnel;
  @Input('stepType') stepType: FunnelInputTypeEnum;
  @Input('otherSteps') otherSteps: FunnelStep[] = [];
  @Input('input') input: FunnelInput;
  @Input('index') formIndex: number = 0;
  @Input('showActionsOnly') showActionsOnly: boolean = false;
  @ViewChild(FunnelActionFieldsComponent, { static: false }) actionComponent: FunnelActionFieldsComponent;

  private isNewAction = false;

  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    private formBuilder: FormBuilder,
    private modalService: NgxSmartModalService,
    private stepService: FunnelStepService
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    this.setForm(this.formBuilder.group({
      type: [FunnelInputTypeEnum.Choice, Validators.required],
      label: [null, Validators.required],
      help: [null],
      next_step: [null],
      autopick_next_step: [null],
      matched_step_key: [null],
      product_funnels: [[]],
      classes: [null],
      values: [null],
      hide_if_invalid: [true],
      actions: this.formBuilder.array([])
    }));

    super.ngOnInit();
    this.updateForm();
  }

  ngOnChanges(): void {
    this.updateForm();
  }

  private updateForm() {
    this.ready = false;

    if (this.form && this.funnel) {
      this.resetForm();

      if (this.input) {
        this.icon = this.input.icon ? this.input.icon : null;
        this.form.patchValue(this.input);
        this.actions = this.input.actions ? this.input.actions.slice() : [];
        this.addDefaultActions();

        if (this.isChildFunnel() && !this.input.next_step && this.input.is_alt_child_exit) {
          this.form.patchValue({next_step: 'alternate'});
        }

        if (this.input.classes) {
          // convert the classes array into a class string
          this.form.patchValue({classes: this.input.classes.join(' ')});
        }

        if (this.input.values) {
          // convert the values array into a comma separated string
          this.form.patchValue({values: this.input.values.join(',')});
        }
      }

      if (this.isHybridFunnel()) {
        this.stepService.getGlobalJumpSteps(true, {
          resourcetype: 'VisualFunnel'
        }).pipe(takeUntil(this.destroy$))
          .subscribe(
            (steps: FunnelStep[]) => {
              this.jumpSteps = steps;
            },
            error => {
              this.handleError(error);
            }
          );
      }

      setTimeout(() => this.ready = true, 0); // this is needed to force the tinymce editor to reload
    }
  }

  get type() : number {
    if (this.form) {
      const control = this.form.get('type');

      if (control) {
        return (control.value === null) ? null : parseInt(control.value);
      }
    }

    return null;
  }

  get nextStep() : number | null {
    if (this.form) {
      const control = this.form.get('next_step');

      if (control) {
        return control.value;
      }
    }

    return null;
  }

  get selectedAction() {
    return (this.selectedActionIndex >= 0) ? this.actions[this.selectedActionIndex] : null;
  }

  get exitLabel() {
    return getExitLabel(this.funnel.resourcetype);
  }

  get altExitLabel() {
    return getAlternateExitLabel(this.funnel.resourcetype);
  }

  isInputEditable() : boolean {
    let editable = true;

    if (this.isSystemInput()) {
      switch (this.stepType) {
        case FunnelInputTypeEnum.EnterProductFunnel:
        case FunnelInputTypeEnum.EnterTroubleshooter:
          editable = false;
          break;

        default:
          switch (this.type) {
            case FunnelInputTypeEnum.ShipmentReturnLabel:
            case FunnelInputTypeEnum.ShipmentReturnQRCode:
              editable = true;
              break;

            default:
              editable = (this.type === this.stepType);
              break;
          }
          break;
      }
    }

    return editable;
  }

  isSystemInput() : boolean {
    return (FunnelInputSystemTypes.indexOf(this.stepType) !== -1);
  }

  getTypeOptionLabel(type): string {
    return getFunnelItemTypeLabel(this.stepType, type, FunnelInputTypeLabels[type]);
  }

  getTypeLabel(type) : string {
    const label = this.getTypeOptionLabel(type);

    return this.isSystemInput() ? FunnelInputTypeLabels[this.stepType] + ' - ' + label : label;
  }

  getAllowedTypes() {
    let types = null;

    if (this.isSystemInput()) {
      if (this.stepType === FunnelInputTypeEnum.ItemStatus) {
        //you can select the type for item status child inputs (all other system inputs have fixed types)
        types = FunnelSystemInputChildTypes[this.stepType];
      }
    } else {
      //normal allowed types
      types = [FunnelInputTypeEnum.Choice];

      if (this.funnel) {
        if (this.funnel.is_visual) {
          types = [
            FunnelInputTypeEnum.Choice,
            FunnelInputTypeEnum.Radio,
            FunnelInputTypeEnum.Select,
            FunnelInputTypeEnum.RadioSelect,
            FunnelInputTypeEnum.Link,
          ];

          if (!this.isHybridFunnel()) {
            types.push(FunnelInputTypeEnum.ShipmentReturnLabel);
            types.push(FunnelInputTypeEnum.ShipmentReturnQRCode);
          }
        }

        if (this.funnel.is_voice) {
          types.push(FunnelInputTypeEnum.Phone);
        }
      }
    }

    return types;
  }

  isChildFunnel() : boolean {
    return ((this.funnel.resourcetype === FunnelType.Troubleshooter) ||
      (this.funnel.resourcetype === FunnelType.Lifeline) ||
      (this.funnel.resourcetype === FunnelType.Product))
  }

  isHybridFunnel() : boolean {
    return (this.funnel.resourcetype === FunnelType.Hybrid)
  }

  isEnterProductFunnel() : boolean {
    return (this.type === FunnelInputTypeEnum.EnterProductFunnel);
  }

  removeIcon() {
    this.icon = null;
  }

  openIconSelectDialog() {
    this.modalService.getModal('iconSelectDialog').removeData().open();
  }

  onIconSelected(icon: IconFile) {
    this.icon = icon;
    this.modalService.getModal('iconSelectDialog').close();
  }

  areValuesSupported() : boolean {
    if (this.funnel) {
      if (this.funnel.is_voice || this.funnel.is_sms) {
        return (this.type !== FunnelInputTypeEnum.Phone);
      }
    }

    return false;
  }

  areActionsSupported() : boolean {
    return [
      FunnelType.Visual,
      FunnelType.Product,
      FunnelType.Voice,
      FunnelType.SMS,
      FunnelType.Troubleshooter,
      FunnelType.Lifeline
    ].indexOf(this.funnel.resourcetype) !== -1 && [
      FunnelInputTypeEnum.ShipmentReturnLabel,
      FunnelInputTypeEnum.ShipmentReturnQRCode
    ].indexOf(this.type) === -1;
  }

  canSetNextStep() : boolean {
    return [
      FunnelInputTypeEnum.ShipmentReturnLabel,
      FunnelInputTypeEnum.ShipmentReturnQRCode
    ].indexOf(this.type) === -1;
  }

  protected addDefaultActions() {
    let defaultActions = [];
    let actionSet = new Set();

    if (this.stepType === FunnelInputTypeEnum.Checkout) {
      defaultActions = [{resourcetype: ActionType.Checkout} as Action];
    }

    this.actions.forEach((action: Action) => {
      if (action.resourcetype) {
        actionSet.add(action.resourcetype);
      }
    });

    defaultActions.forEach((action: Action) => {
      if (!actionSet.has(action.resourcetype)) {
        this.actions.push(action);
      }
    });
  }

  addAction(action?: Action) {
    if (this.areActionsSupported()) {
      this.actions.push(action);
      this.editAction(this.actions.length - 1);
      this.isNewAction = true;
    }
  }

  editAction(index: number) {
    this.selectedActionIndex = index;
    this.openActionDialog();
  }

  canDeleteAction(action: Action) {
    return (this.stepType !== FunnelInputTypeEnum.Checkout) || (action.resourcetype !== ActionType.Checkout);
  }

  removeAction(index: number) {
    this.actions.splice(index, 1);
  }

  getActionLabel(action: Action) : string {
    return FunnelActionFieldsComponent.getActionDescriptions(action).join('.  ');
  }

  protected openActionDialog() {
    this.modalService.getModal('actionDialog').open();
  }

  onCloseActionDialog() {
    this.selectedActionIndex = -1;

    if (this.isNewAction) {
      // adding new action got cancelled, so remove it
      this.actions.pop();
      this.isNewAction = false;
    }
  }

  updateActions() {
    if (this.actionComponent) {
      this.actionComponent.submitted = true;
      if (this.actionComponent.form.valid) {
        this.actions[this.selectedActionIndex] = this.actionComponent.getFormData();
        this.isNewAction = false;
        this.modalService.getModal('actionDialog').close()
      }
    }
  }

  getFormData() {
    let data = {classes: [], values: []};
    if (!this.form) {
      return data;
    }
    const classStr = this.form.value.classes ? this.form.value.classes.trim(): '';
    const valueStr = this.form.value.values ? this.form.value.values.trim(): '';

    if (!this.isInputEditable()) {
      data['label'] = getFunnelItemTypeLabel(this.stepType, this.form.value.type);
    } else if (this.funnel && !this.funnel.is_visual) {
      if (this.type === FunnelInputTypeEnum.Phone) {
        data['label'] = 'Enter Phone Number'
      } else {
        data['label'] = valueStr;
      }
    }

    data['icon'] = this.icon;

    // convert the class string into an array of unique values
    if (classStr.length) {
      data['classes'] = classStr.split(' ').filter(
        (value, index, self) => self.indexOf(value) === index);
    }

    // convert the values string into an array of unique values
    if (valueStr.length) {
      data['values'] = valueStr.split(',').filter(
        (value, index, self) => self.indexOf(value) === index);
    }

    this.addDefaultActions();
    data['actions'] = this.actions.slice();
    data['is_alt_child_exit'] = false;

    if (this.form.value.next_step === 'alternate') {
      data['is_alt_child_exit'] = true;
      data['next_step'] = null;
    }

    if (this.input && this.input.id) {
      data['id'] = this.input.id;
    }

    return Object.assign({}, this.form.value, data);
  }

  isDefaultAction(action: Action) {
    if (action) {
      if (this.stepType === FunnelInputTypeEnum.Confirmation && this.funnel) {
        let defaultActions = ConfirmationStepActions[this.funnel.offer_intent] || []
        return defaultActions.findIndex(da => da.resourcetype === action.resourcetype) > -1
      }
    }

    return false
  }
}
