import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PaginationService} from "./pagination.service";
import {ClipboardService} from "./clipboard.service";
import {CampaignProduct, Product, Funnel} from "../_models";
import {BehaviorSubject, from} from 'rxjs';
import {copyToClipboard} from "../_helpers/functions";
import {mergeMap} from "rxjs/operators";

@Injectable()
export class FunnelService extends PaginationService {

  copiedFunnel: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    protected http: HttpClient,
    protected clipboardService: ClipboardService
  ) {
    super(http, 'funnels');
  }

  copy(id: string | number) {
    this.clipboardService.copy('funnel', id.toString());
  }

  canPaste() : boolean {
    return this.clipboardService.canPaste('funnel');
  }

  paste() {
    return this.http.post(`${this.route}/${this.clipboardService.paste('funnel')}/generate/`, {});
  }

  copyCampaignProducts(products: CampaignProduct[]) {
    this.clipboardService.copy('campaign_products', products);
  }

  canPasteCampaignProducts() : boolean {
    return this.clipboardService.canPaste('campaign_products');
  }

  pasteCampaignProducts() {
    return this.clipboardService.paste('campaign_products');
  }

  copyProducts(products: Product[]) {
    this.clipboardService.copy('products', products);
  }

  canPasteProducts() : boolean {
    return this.clipboardService.canPaste('products');
  }

  pasteProducts() {
    return this.clipboardService.paste('products');
  }

  preview(campaignId: string | number, data: any) {
    const funnel: Funnel = data;

    return this.http.post(this.route + '/' + funnel.id.toString() + '/preview/', {campaign: campaignId});
  }

  publish(id: string | number) {
    return this.http.post(this.route + '/' + id.toString() + '/publish/', {});
  }

  getHierarchy() {
    return this.http.get(this.route + '/hierarchy/');
  }

  createTemplate(id: string | number, isPublic: boolean = false) {
    return this.http.post(this.route + '/' + id.toString() + '/template/', {is_public: isPublic});
  }

  clone(id: string | number) {
    return this.http.post(this.route + '/' + id.toString() + '/generate/', {});
  }

  add_products(id: string | number, data) {
    return this.http.post(this.route + '/' + id.toString() + '/add_products/', data)
  }

  remove_product(id: string | number, data) {
    return this.http.post(this.route + '/' + id.toString() + '/remove_product/', data)
  }

  add_campaign_products(id: string | number, data) {
    return this.http.post(this.route + '/' + id.toString() + '/add_campaign_products/', data)
  }

  remove_campaign_product(id: string | number, data) {
    return this.http.post(this.route + '/' + id.toString() + '/remove_campaign_product/', data)
  }

  copyToClipboard(id: string | number) {
    this.http.post(this.route + '/' + id.toString() + '/copy/', {}).subscribe(
      data => {
        copyToClipboard(JSON.stringify(data));
      }
    );
  }

  pasteFromClipboard() {
    return from(navigator.clipboard.readText()).pipe(
      mergeMap((text) => {
        return this.create(JSON.parse(text));
      }),
    )
  }

}
