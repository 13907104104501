import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import {
  StepMedia,
  StepButtons,
  StepResponseScreen,
  StepTextual,
  StepProductsPreview,
  StepElements,
} from "../_models/steps";
import { cloneDeep } from "lodash";
import {
  stepButtonsInitialData,
  stepMediaInitialData,
  stepProductPreviewInitialData,
  stepResponseScreenInitialData,
  stepTextualInitialData,
} from "../steps-library/step-data";

@Injectable({
  providedIn: "root",
})
export class StepEmulatorService {
  private stepTextualSubject = new BehaviorSubject<StepTextual>(
    cloneDeep(stepTextualInitialData)
  );
  stepTextual$: Observable<StepTextual> =
    this.stepTextualSubject.asObservable();

  private stepMediaSubject = new BehaviorSubject<StepMedia>(
    cloneDeep(stepMediaInitialData)
  );
  stepMedia$: Observable<StepMedia> = this.stepMediaSubject.asObservable();

  private stepButtonsSubject = new BehaviorSubject<StepButtons>(
    cloneDeep(stepButtonsInitialData)
  );
  stepButtons$: Observable<StepButtons> =
    this.stepButtonsSubject.asObservable();

  private stepResponseScreenSubject = new BehaviorSubject<StepResponseScreen>(
    cloneDeep(stepResponseScreenInitialData)
  );
  stepResponseScreen$: Observable<StepResponseScreen> =
    this.stepResponseScreenSubject.asObservable();

  private stepProductsPreviewSubject = new BehaviorSubject<StepProductsPreview>(
    cloneDeep(stepProductPreviewInitialData)
  );
  stepProductsPreview$: Observable<StepProductsPreview> =
    this.stepProductsPreviewSubject.asObservable();

  private elementStates: { [key in StepElements]: boolean } = {
    //Elements with editors are initialized as true here
    [StepElements.Banner]: true,
    [StepElements.Body]: true,
    [StepElements.Media]: false,
    [StepElements.Buttons]: true,
    [StepElements.Response]: true,
    [StepElements.Discount]: false,
    [StepElements.DiscountSettings]: false,
    [StepElements.Exchangeables]: false,
    [StepElements.Actions]: false,
    [StepElements.SurveyOptions]: false,
    [StepElements.TimeDelay]: false,
    [StepElements.TimeOptions]: false,
  };

  constructor() {}

  toggleElement(element: StepElements): void {
    this.elementStates[element] = !this.elementStates[element];
  }

  isElementVisible(element: StepElements): boolean {
    return this.elementStates[element];
  }

  hideAllElements(): void {
    Object.keys(this.elementStates).forEach((key) => {
      this.elementStates[key as StepElements] = false;
    });
  }

  updateStepTextual(data: StepTextual) {
    this.stepTextualSubject.next(data);
  }

  updateStepMedia(data: StepMedia) {
    this.stepMediaSubject.next(data);
  }

  updateStepButtons(data: StepButtons) {
    this.stepButtonsSubject.next(data);
  }

  updateStepResponseScreen(data: StepResponseScreen) {
    this.stepResponseScreenSubject.next(data);
  }

  updatePreviewProducts(data: StepProductsPreview) {
    this.stepProductsPreviewSubject.next(data);
  }

  resetStepData() {
    this.stepMediaSubject.next(cloneDeep(stepMediaInitialData));
    this.stepButtonsSubject.next(cloneDeep(stepButtonsInitialData));
    this.stepTextualSubject.next(cloneDeep(stepTextualInitialData));
    this.stepResponseScreenSubject.next(
      cloneDeep(stepResponseScreenInitialData)
    );
    this.stepProductsPreviewSubject.next(
      cloneDeep(stepProductPreviewInitialData)
    );
  }
}
