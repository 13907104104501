import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {
  AlertService,
  CallCenterActionService,
  ExchangeableProductsSetService,
  FunnelStepService,
  RelatedProductSetService
} from '../_services';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {takeUntil} from "rxjs/operators";
import {Form} from '../_forms';
import {
  Action,
  ActionType,
  ActionTypeLabels,
  BillDateDelayFrom,
  CallCenterAction,
  ContactTypeLabels,
  DiscountTypeEnum,
  ExchangeableProductsSet,
  ExecutionTypeEnum,
  Funnel,
  FunnelActionTypeLabels,
  FunnelType,
  getFunnelPathVariables,
  Pager,
  QuantityTypeEnum,
  RelatedProductSet,
} from '../_models';
import {getOrdinal, tinyMCETTextOnly} from "../_helpers";
import {config} from '../../config/config';

@Component({
  moduleId: module.id.toString(),
  templateUrl: './funnel-action-fields.component.html',
  selector: 'funnel-action-fields',
  styleUrls: ['./funnel-action.component.css']
})
export class FunnelActionFieldsComponent extends Form implements OnInit, OnChanges {
  discountLabel = 'Discount';
  voiceEditorInitCallback = tinyMCETTextOnly;
  showEditors = false;
  smsPathVariables = getFunnelPathVariables();
  discountTypes: any[] = [];
  quantityTypes: any[] = [];
  executionTypes: any[] = [];
  contactTypeLabels: any[] = [];
  userSelectNextBillDate = true;
  autoMaxBillDate = true;
  callCenterActions: CallCenterAction[] = [];
  discountRequired = false;
  billingIntervalType = 'none';
  exchangeableSets: ExchangeableProductsSet[] = [];
  relatedProductSets: { id: number | string, text: string }[]
  unassignedExchangeableSets: ExchangeableProductsSet[] = [];
  dropDownSelected: boolean = true;

  public defaultFilterSettings = {
    singleSelection: false,
    allowSearchFilter: true,
    enableCheckAll: true
  };

  @Input('action') action: Action;
  @Input('funnel') funnel: Funnel;
  @Input('index') formIndex: number = 0;

  actionTypes: any[] = [];

  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    private stepService: FunnelStepService,
    private actionService: CallCenterActionService,
    protected exchangeablesSetService: ExchangeableProductsSetService,
    protected relatedProductSetService: RelatedProductSetService
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      resourcetype: [null, Validators.required]
    });

    super.ngOnInit();
    this.updateForm();
    this.quantityTypes = this.getQuantityTypes();
    this.discountTypes = this.getDiscountTypes();
    this.executionTypes = this.getExecutionTypes();
    this.contactTypeLabels = this.getContactTypes();

    this.stepService.getGlobalJumpVariables({resourcetype: 'VisualFunnel'})
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        stepList => {
          this.smsPathVariables = this.smsPathVariables.concat(stepList);
          this.showEditors = true;
        },
        error => {
          this.handleError(error);
        }
      );

    this.actionService.list()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (actions: CallCenterAction[]) => {
          this.callCenterActions = actions;
        },
        error => {
          this.handleError(error);
        }
      );

    this.relatedProductSetService.list({page: 1, page_size: config.maxPageSize})
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: Pager) => {
        let relatedProductSets: { id: number | string, text: string }[] = []
        data.results.forEach((item: RelatedProductSet) => {
          relatedProductSets.push({id: item.id, text: item.name})
        })
        this.relatedProductSets = relatedProductSets
      }, error => {
        this.handleError(error)
      })
  }

  ngOnChanges() {
    this.updateForm();
  }

  get type() : string | null {
    if (this.form) {
      const control = this.form.get('resourcetype');

      if (control) {
        return control.value;
      }
    }

    return null;
  }

  get typeString() : string {
    return ActionTypeLabels[this.action.resourcetype];
  }

  get isNew() : boolean {
    return !this.action || !this.action.id;
  }

  isSMSAction() : boolean {
    return (this.type === ActionType.SMS)
  }

  isChangeProductAction() : boolean {
    return (this.type === ActionType.ChangeProduct)
  }

  onChangeBillingIntervalType(event) {
    this.billingIntervalType = event.target.value;

    if (this.billingIntervalType === 'custom') {
      if (this.getControlValue('billing_interval_days') === 0) {
        this.form.patchValue({billing_interval_days: 30});
      }
    }
  }

  private getExchangeableSetTypeValues() {
    if (this.action && this.action.exchangeable_set_types && this.action.exchangeable_set_types.length > 0) {
      const exchangeableSetTypeIdsSet = new Set<string | number>(this.action.exchangeable_set_types)
      return this.relatedProductSets.filter(item => exchangeableSetTypeIdsSet.has(item.id))
    }

    return []
  }

  private updateForm() {
    let actionTypes = [];

    if (this.funnel) {
      if (this.action && (this.action.resourcetype === ActionType.Checkout)) {  // checkout can't be changed
        actionTypes.push({value: ActionType.Checkout, label: FunnelActionTypeLabels.CheckoutAction});
      }
      else if (this.funnel.resourcetype === FunnelType.Lifeline) {
        actionTypes = [
          {value: ActionType.Note, label: FunnelActionTypeLabels.NoteAction},
          {value: ActionType.Notification, label: FunnelActionTypeLabels.NotificationAction},
        ];
      }
      else if (this.funnel.is_visual) {
        //get all the action types sorted alphabetically
        const actionsNotAllowed: string[] = [
          ActionType.Checkout, // checkout action is a fixed action only used by checkout step
          ActionType.SMS, // sms action not supported yet for visual path
        ]

        for (let key in FunnelActionTypeLabels) {
          if (actionsNotAllowed.indexOf(key) === -1) {
            actionTypes.push({value: key, label: ActionTypeLabels[key]});
          }
        }
      }
      else {
        // voice and sms funnels can only send sms responses
        actionTypes.push({value: ActionType.SMS, label: FunnelActionTypeLabels.SMSAction});
      }

      actionTypes.sort((a, b): number => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });
    }

    this.actionTypes = actionTypes;
    this.billingIntervalType = 'none';

    if (this.form) {
      this.form.reset();

      if (this.funnel && this.action) {
        this.updateControlsForActionType(this.action.resourcetype);
        this.updateControlsForDiscountType(this.action.discount_type);
        const holdoffDelay = this.action.holdoff_delay ? this.action.holdoff_delay / 3600 : this.action.holdoff_delay;

        this.form.patchValue(Object.assign({}, this.action, {holdoff_delay: holdoffDelay,
          exchangeable_set_types: this.getExchangeableSetTypeValues()}));
        this.userSelectNextBillDate = (this.action.next_bill_date_delay === null);
        this.autoMaxBillDate = (this.action.max_bill_date_delay === null);

        if (this.action.billing_interval_days) {
          this.billingIntervalType = 'custom';
        } else if (this.action.billing_interval_days === 0) {
          this.billingIntervalType = 'billing_model';
        }
      }
      else {
        this.resetControls();
        this.form.patchValue({resourcetype: null});
        this.userSelectNextBillDate = true;
        this.autoMaxBillDate = true;
      }
    }
  }

  updateActionType(event) {
    this.updateControlsForActionType(event.target.value);
  }

  protected resetControls() {
    //remove the form fields except for resource type
    for (let field in this.form.controls) {
      if (field !== 'resourcetype') {
        this.form.removeControl(field);
      }
    }
  }

  protected updateControlsForActionType(resourceType?: ActionType) {
    let fields = {};
    let canDelay = !!resourceType && this.funnel && this.funnel.is_visual; //default

    this.resetControls();
    this.discountRequired = false;

    switch (resourceType) {
      case ActionType.AddProduct:
        fields = {
          upsale_index: [null, Validators.required],
          holdoff_delay: [null, [Validators.min(0), Validators.max(10000)]],
        };
        break;

      case ActionType.Discount:
        this.discountRequired = true;
        fields = {
          discount_type: [null, Validators.required],
          final_billing_cycle_offset: [0, [Validators.min(0)]],
          bill_now: [false],
          holdoff_delay: [null, [Validators.min(0), Validators.max(10000)]],
        };
        break;

      case ActionType.BillingCycle:
        fields = {
          billing_interval_days: [null, [Validators.min(0)]],
          next_bill_date_delay: [null, [Validators.min(0)]],
          max_bill_date_delay: [null, [Validators.min(0)]],
          final_billing_cycle_offset: [0, [Validators.min(0)]],
          bill_now: [false],
          pause: [false],
          holdoff_delay: [null, [Validators.min(0), Validators.max(10000)]],
          bill_date_delay_from: [BillDateDelayFrom.Today],
        };
        break;

      case ActionType.Reactivate:
        fields = {
          discount_type: [null],
          billing_interval_days: [null, [Validators.min(0)]],
          next_bill_date_delay: [null, [Validators.min(0)]],
          max_bill_date_delay: [null, [Validators.min(0)]],
          final_billing_cycle_offset: [0, [Validators.min(0)]],
          bill_now: [false],
          bill_date_delay_from: [BillDateDelayFrom.Today],
        };
        break;

      case ActionType.Rebill:
        fields = {
          discount_type: [null],
          final_billing_cycle_offset: [0, [Validators.min(0)]]
        };
        break;

      case ActionType.Refund:
        this.discountRequired = true;
        fields = {
          discount_type: [null, Validators.required],
          holdoff_delay: [null, [Validators.min(0), Validators.max(10000)]],
          include_tax: [true],
        };
        break;

      case ActionType.Cancel:
        fields = {
          reason: [null],
          cancel_subscription: [true],
          apply_refund: [true],
          third_party: [false],
          call_center_action: [null]
        };
        break;

      case ActionType.CancelSubscription:
        fields = {
          reason: [null],
          call_center_action: [null],
          void_authorized_transaction: [false],
        };
        break;

      case ActionType.Note:
      case ActionType.SMS:
        fields = {
          message: [null, Validators.required]
        };
        break;

      case ActionType.Notification:
        fields = {
          message: [null, Validators.required],
          contact_type: [null]
        };
        break;

      case ActionType.RMA:
        fields = {
          reship: [false],
          refund_original_shipping: [false],
          charge_restock_fee: [true],
          return_shipping_refund: [null],
          reason: [null],
          call_center_action: [null]
        };
        break;

      case ActionType.ChangeVariant:
        fields = {
          bill_now: [false],
          subscription_only: [false],
        };
        break;

      case ActionType.ChangeQuantity:
        fields = {
          quantity_type: [QuantityTypeEnum.Any, Validators.required],
          holdoff_delay: [null, [Validators.min(0), Validators.max(10000)]],
          bill_now: [false],
          subscription_only: [false],
        };
        break;

      case ActionType.ChangeProduct:
        fields = {
          quantity_type: [QuantityTypeEnum.Any, Validators.required],
          holdoff_delay: [null, [Validators.min(0), Validators.max(10000)]],
          exchangeable_index: [null],
          exchangeable_set_types: [[]],
          exchangeable_set: [[]],
          bill_now: [false],
          subscription_only: [false],
        };
        break;

      case ActionType.ExtendReturn:
        fields = {
          return_days: [null, [Validators.min(0)]],
        };
        break;

      case ActionType.ExecuteDelayed:
      case ActionType.CancelDelayed:
        canDelay = false;
        break;
    }

    if (canDelay) {
      this.form.addControl('execution_type', new FormControl(ExecutionTypeEnum.Immediate, Validators.required));
    }

    for (let fieldName in fields) {
      let field = fields[fieldName];

      this.form.addControl(fieldName, new FormControl(field[0], (field.length > 1) ? field[1] : null));
    }

    this.quantityTypes = this.getQuantityTypes();
  }

  updateDiscountType(event) {
    this.updateControlsForDiscountType(parseInt(event.target.value));
  }

  protected updateControlsForDiscountType(discountType?: DiscountTypeEnum) {
    let fieldNames = ['discount', 'discount_index', 'check_last_discount'];

    //remove the existing discount fields
    fieldNames.forEach(field => {
      if (field in this.form.controls) {
        this.form.removeControl(field);
      }
    });

    //create the discount fields needed for the discount type
    switch (discountType) {
      case DiscountTypeEnum.Fixed:
        this.discountLabel = 'Discount';
        this.form.addControl('discount', new FormControl(null,
          [Validators.required, Validators.min(0)]));
        this.form.addControl('check_last_discount', new FormControl(true));
        break;

      case DiscountTypeEnum.Percent:
        this.discountLabel = 'Discount Percentage';
        this.form.addControl('discount',
          new FormControl(null,
            [Validators.required, Validators.min(0), Validators.max(100)]));
        this.form.addControl('check_last_discount', new FormControl(true));
        break;

      case DiscountTypeEnum.PercentOfOrder:
        this.discountLabel = 'Discount Percentage';
        this.form.addControl('discount',
          new FormControl(null,
            [Validators.required, Validators.min(0), Validators.max(100)]));
        break;

      case DiscountTypeEnum.Index:
        this.form.addControl('discount_index', new FormControl(null,
          [Validators.required]));
        this.form.addControl('check_last_discount', new FormControl(true));
        break;

      case DiscountTypeEnum.IndexOrFixed:
        this.discountLabel = 'Discount if index not found';
        this.form.addControl('discount_index', new FormControl(null,
          [Validators.required]));
        this.form.addControl('discount', new FormControl(null,
          [Validators.required, Validators.min(0)]));
        this.form.addControl('check_last_discount', new FormControl(true));
        break;

      case DiscountTypeEnum.IndexOrPercent:
        this.discountLabel = 'Discount Percentage if index not found';
        this.form.addControl('discount_index', new FormControl(null,
          [Validators.required]));
        this.form.addControl('discount',
          new FormControl(null,
            [Validators.required, Validators.min(0), Validators.max(100)]));
        this.form.addControl('check_last_discount', new FormControl(true));
        break;
    }
  }

  getDiscountTypes() {
    return [
      {value: DiscountTypeEnum.Fixed, label: 'Fixed'},
      {value: DiscountTypeEnum.Percent, label: 'Percent'},
      {value: DiscountTypeEnum.PercentOfOrder, label: 'Percent of Order'},
      {value: DiscountTypeEnum.Index, label: 'Index'},
      {value: DiscountTypeEnum.IndexOrFixed, label: 'Index or Fixed'},
      {value: DiscountTypeEnum.IndexOrPercent, label: 'Index or Percent'}
    ];
  }

  getExecutionTypes() {
    return [
      {value: ExecutionTypeEnum.Immediate, label: 'Immediate'},
      {value: ExecutionTypeEnum.Delayed, label: 'Delayed'},
      {value: ExecutionTypeEnum.ValidationOnly, label: 'Validation Only'}
    ];
  }

  getQuantityTypes() {
    let quantityTypes = [{value: QuantityTypeEnum.Any, label: 'Yes'}];

    if (this.type !== ActionType.ChangeQuantity)
    {
      quantityTypes.push({value: QuantityTypeEnum.None, label: 'No'});
    }

    quantityTypes.push({value: QuantityTypeEnum.LowerOnly, label: 'Only if Lower'});
    quantityTypes.push({value: QuantityTypeEnum.HigherOnly, label: 'Only if Higher'});

    return quantityTypes;
  }

  getContactTypes() {
    let types = [];

    ContactTypeLabels.forEach((value, index) => {
      if (index > 0) {
        types.push({id: index, label: value});
      }
    });

    return types;
  }

  getFormData() {
    let data = this.form.value;
    const allow_blank_fields = ['price', 'shipping_price', 'return_shipping_refund', 'holdoff_delay'];

    //convert empty strings to null for certain fields so the api will accept them
    allow_blank_fields.forEach((field: string) => {
      if (field in data) {
        let value = data[field];

        if (typeof(value) == 'string') {
          if (value.trim().length == 0) {
            data[field] = null;
          }
        }
      }
    });

    if (this.action) {
      data['id'] = this.action.id;
    }

    if (data['discount_type']) {
      data['discount_type'] = parseInt(data['discount_type']);
    }

    if (this.autoMaxBillDate) {
      data['max_bill_date_delay'] = null;
    }

    if (this.userSelectNextBillDate) {
      data['next_bill_date_delay'] = null;
    }

    if (this.billingIntervalType === 'none') {
      data['billing_interval_days'] = null;
    } else if (this.billingIntervalType === 'billing_model') {
      data['billing_interval_days'] = 0;
    }

    if (data['holdoff_delay']) {
      data['holdoff_delay'] *= 3600;
    }

    data['exchangeable_set'] = []
    this.exchangeableSets.forEach(set => {
      data['exchangeable_set'].push(set.id)
    })

    let exchangeable_set_types = data['exchangeable_set_types']
    if (exchangeable_set_types !== undefined) {
      data['exchangeable_set_types'] = []
      exchangeable_set_types.forEach(item => {
        data['exchangeable_set_types'].push(item['id'])
      })
    }

    return data;
  }

  private static getDiscountDescriptions(action: Action, suffix: string) {
    let discount: string | number = action.discount;
    let parts = [];

    if (discount !== null && discount !== undefined) {
      discount = discount.toString();
    } else {
      discount = '0';
    }

    if (action.check_last_discount) {
      suffix = ' if greater than last offer' + suffix
    }

    switch (action.discount_type)
    {
      case DiscountTypeEnum.Index:
        parts.push('Apply ' + getOrdinal(action.discount_index + 1) + ' product discount' + suffix);
        break;

      case DiscountTypeEnum.Fixed:
        parts.push('Apply $' + discount + ' discount' + suffix);
        break;

      case DiscountTypeEnum.IndexOrFixed:
        parts.push('Apply ' + getOrdinal(action.discount_index + 1) + ' product or $' +
          discount + ' discount' + suffix);
        break;

      case DiscountTypeEnum.Percent:
      case DiscountTypeEnum.PercentOfOrder:
        parts.push('Apply ' + discount + '% discount' + suffix);
        break;

      case DiscountTypeEnum.IndexOrPercent:
        parts.push('Apply ' + getOrdinal(action.discount_index + 1) + ' product or ' +
          discount + '% discount' + suffix);
        break;
    }

    return parts;
  }

  private static getBillingCycleDescriptions(action: Action, suffix: string) {
    let parts = [];

    if (action.billing_interval_days > 0) {
      parts.push('Ship every ' + action.billing_interval_days.toString() + ' days' + suffix);
    }
    else if (action.billing_interval_days === 0) {
      parts.push('Select CRM billing model' + suffix);
    }
    else {
      parts.push('Same shipping frequency');
    }

    if (action.pause) {
      parts.push('Pause subscription');
    }

    if (action.next_bill_date_delay > 0) {
      const from = (action.bill_date_delay_from == BillDateDelayFrom.NextBillDate) ? 'next bill date' : 'today';
      parts.push('Next bill date in ' + action.next_bill_date_delay.toString() + ' days from ' + from + suffix);
    }
    else if (action.next_bill_date_delay === 0) {
      parts.push('Same bill date' + suffix);
    }
    else {
      let maxDaysStr = null;

      if (action.max_bill_date_delay === null) {
        maxDaysStr = 'billing cycle days';
      }
      else {
        const maxDays = action.max_bill_date_delay;

        if (maxDays > 0) {
          maxDaysStr = maxDays.toString() + ((maxDays === 1) ? ' day' : ' days')
        }
      }

      if (maxDaysStr) {
        parts.push('Enter new bill date within ' + maxDaysStr + suffix);
      }
      else {
        parts.push('Same bill date' + suffix);
      }
    }

    parts = parts.concat(FunnelActionFieldsComponent.getFinalBillingCycleDescription(action, suffix));

    return parts;
  }

  private static getFinalBillingCycleDescription(action: Action, suffix: string) {
    let parts = [];

    if (action.final_billing_cycle_offset > 0) {
      let desc = action.final_billing_cycle_offset.toString() + ' more billing cycle';

      if (action.final_billing_cycle_offset > 1) {
        desc += 's';
      }

      parts.push(desc + suffix);
    }

    return parts;
  }

  static getActionDescriptions(action: Action) {
    let parts = [];
    let suffix = '';

    if (!action || !action.resourcetype) {
      return ['<New Action>'];
    }

    if (action.execution_type == ExecutionTypeEnum.Delayed) {
      suffix = ' (delayed)'
    }
    else if (action.execution_type == ExecutionTypeEnum.ValidationOnly) {
      suffix = ' (validation only)'
    }

    switch (action.resourcetype) {
      case ActionType.AddProduct:
        parts.push('Add ' + getOrdinal(action.upsale_index + 1) + ' upsale product' + suffix);
        break;

      case ActionType.Rebill:
        parts.push('Rebill declined subscription' + suffix)
        parts = parts.concat(FunnelActionFieldsComponent.getDiscountDescriptions(action, suffix));
        parts = parts.concat(FunnelActionFieldsComponent.getFinalBillingCycleDescription(action, suffix));
        break;

      case ActionType.Discount:
        parts = parts.concat(FunnelActionFieldsComponent.getDiscountDescriptions(action, suffix));
        parts = parts.concat(FunnelActionFieldsComponent.getFinalBillingCycleDescription(action, suffix));

        if (action.bill_now) {
          parts.push('Bill now');
        }
        break;

      case ActionType.BillingCycle:
        parts = parts.concat(FunnelActionFieldsComponent.getBillingCycleDescriptions(action, suffix));

        if (action.bill_now) {
          parts.push('Bill Now');
        }
        break;

      case ActionType.Reactivate:
        parts.push('Reactivate subscription' + suffix);
        parts = parts.concat(FunnelActionFieldsComponent.getDiscountDescriptions(action, suffix));
        parts = parts.concat(FunnelActionFieldsComponent.getBillingCycleDescriptions(action, suffix));

        if (action.bill_now) {
          parts.push('Bill Now');
        }
        break;

      case ActionType.Refund:
        if (action.check_last_discount) {
          suffix = ' if greater than last offer' + suffix
        }

        switch (action.discount_type)
        {
          case DiscountTypeEnum.Index:
            parts.push('Apply ' + getOrdinal(action.discount_index + 1) + ' product refund' + suffix);
            break;

          case DiscountTypeEnum.Fixed:
            parts.push('Apply $' + action.discount.toString() + ' refund' + suffix);
            break;

          case DiscountTypeEnum.IndexOrFixed:
            parts.push('Apply ' + getOrdinal(action.discount_index + 1) + ' product or $' +
              action.discount.toString() + ' refund' + suffix);
            break;

          case DiscountTypeEnum.Percent:
            parts.push('Apply ' + action.discount.toString() + '% refund to item' + suffix);
            break;

          case DiscountTypeEnum.PercentOfOrder:
            parts.push('Apply ' + action.discount.toString() + '% refund to order' + suffix);
            break;

          case DiscountTypeEnum.IndexOrPercent:
            parts.push('Apply ' + getOrdinal(action.discount_index + 1) + ' product or ' +
              action.discount.toString() + '% refund' + suffix);
            break;
        }

        if (action.include_tax) {
          parts.push('Include tax');
        } else {
          parts.push('Ignore tax');
        }

        break;

      case ActionType.Cancel:
        if (action.third_party) {
          parts.push('Mark order item as cancelled by third party' + suffix);
        }
        else {
          if (action.cancel_subscription) {
            parts.push('Cancel order item and its continuity' + suffix);
          } else {
            parts.push('Cancel order item but keep its continuity' + suffix);
          }

          if (action.apply_refund) {
            parts.push('Apply refund');
          } else {
            parts.push('No refund');
          }
        }

        if (action.reason) {
          parts.push('Reason is ' + action.reason);
        }
        break;

      case ActionType.CancelSubscription:
        parts.push('Cancel subscription' + suffix);

        if (action.void_authorized_transaction) {
          parts.push('Void auth transaction');
        }

        if (action.reason) {
          parts.push('Reason is ' + action.reason);
        }
        break;

      case ActionType.RMA:
        parts.push('Issue RMA for ' + (action.reship ? 'exchange' : 'refund') + suffix);

        if (action.reason) {
          parts.push('Reason is ' + action.reason);
        }
        break;

      case ActionType.ChangeVariant:
        parts.push(ActionTypeLabels[action.resourcetype] + suffix);

        if (action.subscription_only) {
          parts.push('Subscription Only');
        }
        if (action.bill_now) {
          parts.push('Bill Now');
        }
        break;

      case ActionType.ChangeQuantity:
        parts.push(ActionTypeLabels[action.resourcetype] + suffix);

        if (action.quantity_type === QuantityTypeEnum.HigherOnly) {
          parts.push('Can only be changed higher');
        }
        else if (action.quantity_type === QuantityTypeEnum.LowerOnly) {
          parts.push('Can only be changed lower');
        }

        if (action.subscription_only) {
          parts.push('Subscription Only');
        }

        if (action.bill_now) {
          parts.push('Bill Now');
        }

        break;

      case ActionType.ChangeProduct:
        if (action.exchangeable_index !== null) {
          parts.push('Change to ' + getOrdinal(action.exchangeable_index + 1) + ' exchangeable product' + suffix);
        }
        else {
          parts.push(ActionTypeLabels[action.resourcetype] + suffix);

          if (action.quantity_type === QuantityTypeEnum.None) {
            parts.push("Quantity change not allowed");
          } else {
            parts.push("Quantity change allowed");

            if (action.quantity_type == QuantityTypeEnum.HigherOnly) {
              parts.push("but only if higher");
            } else if (action.quantity_type == QuantityTypeEnum.LowerOnly) {
              parts.push('but only if lower');
            }
          }
        }

        if (action.subscription_only) {
          parts.push('Subscription Only');
        }

        if (action.bill_now) {
          parts.push('Bill Now');
        }

        break;

      case ActionType.ExtendReturn:
        parts.push(`Extend return date by ${action.return_days} days` + suffix);
        break;

      default:
        parts.push(ActionTypeLabels[action.resourcetype] + suffix);
        break;
    }

    return parts;
  }

  exchangeableSetTypeSelected() {
    if (!this.dropDownSelected) {
      return
    }
    this.dropDownSelected = false
    let ids = []
    this.form.value.exchangeable_set_types.forEach(item => {
      ids.push(item.id)
    })
    this.getExchangeableSets(ids, this.form.value.exchangeable_set)
  }

  getExchangeableSets(set_type_ids?: string[] | number[], filterData = []) {
    let params = {page: 1, page_size: config.maxPageSize}

    if (set_type_ids) {
      params['set_type__in'] = set_type_ids.join(',')
    } else {
      return
    }
    this.loading = true
    this.exchangeablesSetService.list(params)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: Pager) => {
        this.loading = false
        if (filterData.length > 0) {
          let exchangeableSets = []
          filterData.forEach(id => {
            let es = data.results.filter(item => item.id === id)
            if (es.length > 0) {
              exchangeableSets.push(es[0])
            }
          })
          this.exchangeableSets = exchangeableSets
        }
        this.unassignedExchangeableSets = data.results.slice()
      }, error => {
        this.handleError(error)
      })
  }

  onExchangeableSetsSorted(data: Array<any>) {
    let sortOrder = {}
    data.forEach((item, index) => {sortOrder[item.id] = index})
    this.exchangeableSets.sort((a, b): number => {
      if (sortOrder[a.id] < sortOrder[b.id]) return -1;
      if (sortOrder[a.id] > sortOrder[b.id]) return 1;
      return 0;
    });
  }

  protected readonly BillDateDelayFrom = BillDateDelayFrom;
}
