import { Component, Input, OnInit } from "@angular/core";
import {
  StepElementTypes,
  StepLayout,
  CustomStepCategoryEnum,
} from "../../../_models";
import {
  AlertService,
  FunnelStepService,
  LoaderService,
  StepEmulatorService,
  StepTemplateService,
} from "../../../../app/_services";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: "app-step-form",
  templateUrl: "./step-form.component.html",
  styleUrls: ["./step-form.component.scss"],
})
export class StepFormComponent implements OnInit {
  @Input() layout: StepLayout[] = [];
  @Input() stepType: CustomStepCategoryEnum;
  @Input() form: FormGroup;
  @Input() enableFormErrors: boolean;
  @Input() theme: string;
  @Input() isNew: boolean;
  @Input() stepId: string = "";
  @Input() funnelId: string = "";
  @Input() folderId: string = "";
  @Input() templateId: string = "";
  @Input() isPurchaseType: boolean;
  @Input() useExternalButtons: boolean;
  isLoading = false;
  stepElements = StepElementTypes;

  constructor(
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected loaderService: LoaderService,
    protected funnelStepService: FunnelStepService,
    protected stepEmulatorService: StepEmulatorService,
    protected stepService: StepTemplateService
  ) {}

  ngOnInit() {
    this.loaderService.loader$.subscribe((state) => {
      this.isLoading = state;
    });
  }
}
