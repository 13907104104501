import {Component, OnInit, ViewChild} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {
  AlertService,
  FunnelService,
  FunnelStepService,
  ProductService,
  CampaignProductService,
  RegionService,
  CRMCampaignService,
  CRMService
} from '../_services';
import {FormBuilder} from '@angular/forms';
import {FunnelNewComponent} from "./funnel-new.component";
import {FunnelStepsComponent} from "./funnel-steps.component";
import {Funnel, Region, CardTypeLabels} from "../_models";
import {NgxSmartModalService} from "ngx-smart-modal";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './funnel-edit.component.html',
  styleUrls: ['./funnel.component.css'],
})
export class FunnelEditComponent extends FunnelNewComponent implements OnInit {
  @ViewChild(FunnelStepsComponent, { static: false }) stepsComponent: FunnelStepsComponent;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected funnelService: FunnelService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected productService: ProductService,
    protected campaignProductService: CampaignProductService,
    protected modalService: NgxSmartModalService,
    protected regionService: RegionService,
    protected crmCampaignService: CRMCampaignService,
    protected crmService: CRMService,
    private stepService: FunnelStepService
  ) {
    super(router, location, route, funnelService, alertService, formBuilder, productService,
      campaignProductService, modalService, regionService, crmCampaignService, crmService);
    this.isNew = false;
    this.title = 'Edit Your Path';
  }

  ngOnInit() {
    super.ngOnInit();
    this.loading = true;
    this.data$.subscribe(
      (funnel: Funnel) => {
        if (funnel) {
          this.loading = false;
          this.funnel = funnel;
          this.funnelType = funnel.resourcetype;
          this.updateControlsForFunnelType();
          this.form.patchValue(funnel);

          if (funnel.item_statuses && funnel.item_statuses.length) {
            let itemStatuses = [];

            funnel.item_statuses.forEach(value => {
              itemStatuses.push({id: value, text: this.itemStatusLabels[value]});
            });

            this.buildItemStatusList();
            this.form.patchValue({item_statuses: itemStatuses});
            delete funnel.item_statuses;
          }

          if (funnel.states && funnel.states.length) {
            this.regionService.list('us')
              .subscribe(
                (states: Region[]) => {
                  let stateLabels = {};
                  let selectedStates = [];

                  states.forEach((state: Region) => {
                    stateLabels[state.id] = state.name;
                  });

                  funnel.states.forEach(value => {
                    selectedStates.push({id: value, text: stateLabels[value]});
                  });

                  this.form.patchValue({states: selectedStates});
                  delete funnel.states;
                }
              )
          }

          if (funnel.cc_types && funnel.cc_types.length) {
            let cardTypes = [];

            funnel.cc_types.forEach(value => {
              cardTypes.push({id: value, text: CardTypeLabels[value]});
            });

            this.form.patchValue({cc_types: cardTypes});
            delete funnel.cc_types;
          }

          if (funnel.merchant_ids && funnel.merchant_ids.length) {
            this.form.patchValue({merchant_ids: this.funnel.merchant_ids.join(",")});
          }

          if (funnel.aff_ids && funnel.aff_ids.length) {
            this.form.patchValue({aff_ids: this.funnel.aff_ids.join(",")});
          }

          this.useMatchedWelcomeMessage = !!(funnel.matched_welcome_msg && funnel.matched_welcome_msg.length);
          this.useUnmatchedWelcomeMessage = !!(funnel.unmatched_welcome_msg && funnel.unmatched_welcome_msg.length);
          this.buildCRMCampaignLists();
        }
      },
      error => {
        this.loading = false;
        this.handleError(error);
      }
    );
  }

  protected onSaveComplete(data) {
    this.reload();
  }

  canPasteStep() {
    return this.stepService.canPaste();
  }

  pasteStep() {
    if (this.stepsComponent) {
      this.stepsComponent.pasteStep();
    }
  }

}
