import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { NgxSmartModalService } from "ngx-smart-modal";
import * as moment from "moment-timezone";
import { steps } from "../../step-data";
import { CampaignProduct, StepCategoryLabels } from "../../../_models";
import {
  Breadcrumb,
  OfferTypeLabels,
  OfferTypes,
  StepDateTypes,
  StepPopupActions,
  StepStatusLabels,
  StepStatuses,
} from "../../../_models/steps";
import {
  AlertService,
  CampaignService,
  FunnelInputService,
  FunnelService,
  FunnelStepService,
} from "../../../_services";
import { Subject } from "rxjs";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { getTruncatedProductName } from "../../step-data";

@Component({
  selector: "app-offer-details",
  templateUrl: "./offer-details.component.html",
  styleUrls: ["./offer-details.component.scss"],
})
export class OfferDetailsComponent implements OnInit {
  steps = steps;
  isTableVisible: boolean = true;
  showThreeDotMenu: boolean = false;
  offer: any;
  offerId: number;
  offerType: OfferTypes;
  offerName: string;
  selectedDate: StepDateTypes = StepDateTypes.LastModified;
  tableDateTypes = StepDateTypes;
  newStepName: string;
  selectedStepId: number;
  tableData: any[];
  offerSteps: any[];
  offerPreviewsLoading: boolean;
  offerStepDetails: any[];
  isTableLoading: boolean = false;
  hoveredStepIndex: string | null = null;
  isLoading: boolean = false;
  StepPopupActions = StepPopupActions;
  popupTitle: string = "";
  breadcrumbs: Breadcrumb[] = [];
  popupData = {
    name: "",
    is_survey: false,
    offer_id: null,
    step_id: null,
    is_step: true,
    offer_intent: null,
    offer_type: null,
    popupType: null,
    step_response: {},
  };
  stepStatuses = StepStatuses;
  stepStatusLabels = StepStatusLabels;
  stepPopupTitleMap = {
    [StepPopupActions.Update]: "Rename Step",
    [StepPopupActions.Duplicate]: "Duplicate Step",
    [StepPopupActions.Delete]: "Delete Step",
  };
  selectedBrandId: string | number = null;
  selectedProduct: CampaignProduct = {} as CampaignProduct;
  campaigns: any[] = null;
  campaignsLoading: boolean = true;
  protected _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    protected router: Router,
    private funnelService: FunnelService,
    private funnelStepService: FunnelStepService,
    private campaignService: CampaignService,
    public ngxSmartModalService: NgxSmartModalService,
    protected alertService: AlertService,
    private sanitizer: DomSanitizer,
    protected inputService: FunnelInputService
  ) {
    this.route.params.subscribe((params) => {
      this.offerId = params["offer_id"];
      this.offerType = Number(params["offer_type"]);
      this.offerName = OfferTypeLabels[this.offerType];
    });
  }

  ngOnInit() {
    this.isTableLoading = true;
    this.funnelStepService.setFunnelId(this.offerId);
    this.generateBreadcrumbs();
    this.getCampaigns();
    this.fetchOfferSteps();
  }

  fetchOfferSteps() {
    this.offerPreviewsLoading = true;
    this.funnelService.get(this.offerId).subscribe(
      (response: any) => {
        if (response) {
          this.offer = response;
          this.breadcrumbs[1].label = response.slug;

          this.funnelStepService.getOfferSteps().subscribe(
            (response) => {
              if (response.length) {
                this.offerSteps = response;
              } else {
                this.offerSteps = [];
                this.isTableLoading = false;
                return;
              }

              this.offerSteps.forEach((step) => {
                step.loading = true;
              });
              this.populateTableData();
              this.getPreviews();
            },
            (error) => {
              this.offerPreviewsLoading = false;
              this.isTableLoading = false;
              this.alertService.error(error);
            }
          );
        }
      },
      (error) => {
        this.isTableLoading = false;
        this.offerPreviewsLoading = false;
        this.alertService.error(error);
      }
    );
  }

  populateTableData() {
    this.tableData = this.offerSteps.map((item: any) => {
      this.isTableLoading = false;
      const lastModifiedMoment = moment(item.last_modified).tz("EST");
      const lastModifiedObject = {
        date: lastModifiedMoment.format("DD MMM, YYYY"),
        time: lastModifiedMoment.format("HH:mm:ss z"),
      };

      const stepInput = this.getStepInput(item.id)
 
      return {
        id: item.id,
        stepName: item.name,
        status: stepInput.enabled
          ? StepStatuses.Enabled
          : !stepInput.enabled
          ? StepStatuses.Disabled
          : StepStatuses.Draft,
        type: StepCategoryLabels[item.category],
        stepInput: stepInput,
        lastModified: lastModifiedObject,
        visitors: item.visitors || 0,
        category: item.category,
        clicks: item.clicks || 0,
        takeRates:
          item.clicks && item.visitors
            ? (item.clicks / item.visitors) * 100
            : 0,
        averageTime: item.averageTime || "--", // not getting this from backend
        usages: item.path_names.length
          ? `${item.path_names.length} ${
              item.path_names.length > 1 ? "Paths" : "Path"
            }`
          : "0 Paths",
      };
    });
  }

  getPreviews() {
    let completedRequests = 0;
    const totalSteps = this.offerSteps.length;
    const productId = (this.selectedProduct && this.selectedProduct.id) || null;
    this.offerPreviewsLoading = true;

    this.offerSteps.forEach((step) => {
      const steps = this.offer.steps.filter(
        (offer_step) => offer_step.id === step.id
      );

      this.funnelStepService
        .preview(this.selectedBrandId, steps[0], productId)
        .subscribe(
          (data: string) => {
            step.sanitizedUrl = this.getSanitizedUrl(data);
            step.url = data;
            step.loading = false;
            completedRequests++;
            if (completedRequests === totalSteps) {
              this.offerPreviewsLoading = false;
            }
          },
          (error) => {
            this.alertService.error(error);
            step.loading = false;
          }
        );
    });
  }

  getCampaigns() {
    this.campaignService.list({}).subscribe(
      (data) => {
        this.campaigns = data.results;
        this.selectedBrandId = this.campaigns[0].id;
        this.campaignsLoading = false;
      },
      (error) => {
        this.campaignsLoading = false;
        this.alertService.error(error);
      }
    );
  }

  updateBrand() {
    this.getPreviews();
  }

  updateProduct() {
    this.getPreviews();
  }

  toggleStepStatus(stepInputId: number, hasInputStep: boolean = false, isEnabled?: boolean): void {
    if (!hasInputStep) {
      const stepInput = this.getStepInput(stepInputId);
      this.toggleStatus(stepInput.id, stepInput.enabled);
    } else {
      this.toggleStatus(stepInputId, isEnabled);
    }
  }
  
  toggleStatus(id: number, currentStatus: boolean): void {
    this.inputService.patch(id, { enabled: !currentStatus }).subscribe(
      () => {
        this.fetchOfferSteps();
        this.alertService.success('Step status updated successfully', true);
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  isStepEnabled(stepId: number): boolean {
    const stepInput = this.getStepInput(stepId);
    return stepInput.enabled;
  }

  getStepInput(stepId: number) {
    const offerStep = this.offer.offer_steps.find(({ id }) => id === stepId);
    if (offerStep) {
      return offerStep.inputs.find(({ has_response_step }) => has_response_step);
    }
  }

  generateBreadcrumbs() {
    this.breadcrumbs = [
      {
        label:
          this.offerName.charAt(0).toUpperCase() +
          this.offerName.slice(1) +
          " Offers",
        url: ["steps", this.offerName],
      },
      {
        label: "",
      },
    ];
  }

  openPopup(
    popupType: number,
    stepId?: number | string,
    stepName?: string
  ): void {
    this.popupTitle = this.stepPopupTitleMap[popupType] || "";
    const stepResponse = this.offerSteps.find((step) => step.id === stepId);
    this.popupData = {
      ...this.popupData,
      name: stepName || "",
      step_id: stepId || null,
      offer_id: this.offerId || null,
      step_response: stepResponse || {},
      popupType: popupType,
    };
    this.ngxSmartModalService.getModal("stepPopup").open();
  }

  closeStepPopup() {
    this.ngxSmartModalService.getModal("stepPopup").close();
  }

  saveStep(): void {
    this.isTableLoading = true;
    this.fetchOfferSteps();
    this.ngxSmartModalService.getModal("stepPopup").close();
  }

  openProductSelector() {
    this.ngxSmartModalService.getModal("selectProductsDialog").open();
  }

  onProductSelected(product: CampaignProduct) {
    if (product) {
      this.selectedProduct = product;
      this.getPreviews();
    }
    this.ngxSmartModalService.getModal("selectProductsDialog").close();
  }

  navigate(url: any[]) {
    this.router.navigate(url);
  }

  navigateToStepBuilder(category: number, stepId: number) {
    this.router.navigate([
      "steps",
      this.offerName,
      this.offerId,
      "category",
      this.offerType,
      category,
      "step",
      stepId,
    ]);
  }

  openPreview(stepId: number) {
    const step = this.offerSteps.find((step) => step.id === stepId);
    if (step && step.url) {
      window.open(step.url, "_blank");
    } else {
      this.alertService.error(
        "Please wait while we generate the preview link, then u can try again."
      );
    }
  }

  toggleTable() {
    this.isTableVisible = !this.isTableVisible;
  }

  closeOtherMenus(currentItem: any): void {
    this.tableData.forEach((item) => {
      if (item.id !== currentItem.id) {
        item.showThreeDotMenu = false;
      }
    });
  }

  closeOutside() {
    this.tableData.forEach((item) => {
      item.showThreeDotMenu = false;
    });
  }

  toggleThreeDotMenu(item: any): void {
    this.closeOtherMenus(item);
    item.showThreeDotMenu = !item.showThreeDotMenu;
  }

  closeThreeDotMenu(item: any) {
    item.showThreeDotMenu = false;
  }

  setHoveredStep(id: string) {
    this.hoveredStepIndex = id;
  }
  clearHoveredStep() {
    this.hoveredStepIndex = null;
  }

  navigateToStepTemplates() {
    this.router.navigate([
      "steps",
      this.offerName,
      this.offerId,
      "category",
      this.offerType,
    ]);
  }

  private getSanitizedUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  get destroy$() {
    return this._destroy$.asObservable();
  }

  getProductName(productName: string): string {
    return getTruncatedProductName(productName);
  }

  popupIconMap: Partial<Record<StepPopupActions, string>> = {
    [StepPopupActions.Delete]: '/assets/stepAssets/delete-icon.svg',
    [StepPopupActions.Duplicate]: '/assets/stepAssets/duplicate.svg',
    [StepPopupActions.Update]: '/assets/stepAssets/rename.svg',
  };
}
