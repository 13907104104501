import { BaseModel, AccountBillingStatus, Plan, ChargeStatus } from './index';

export enum InvoiceStatus {
  Pending = 0,
  Unpaid = 1,
  Paid = 2,
  Declined = 3,
  Cancelled = 4,
  CarriedForward = 5,
  PaymentPending = 6
}

export const InvoiceStatusLabels = [
  'Pending',   // 0
  'Payment Due', // 1
  'Paid',      // 2
  'Declined',  // 3
  'Cancelled', // 4
  'Carried Forward', // 5
  'Payment Pending' // 6
];

export enum TransactionType {
  Charge = 1,
  Refund = 2,
  Chargeback = 3,
  ExternalPayment = 4,
  UsageCharge = 5,
  ExternalRefund = 6,
  SubscriptionAdjustment = 7,
}

export const TransactionTypeLabels = [
  '',           // 0
  'Charge',     // 1
  'Refund',     // 2
  'Chargeback', // 3
  'External Payment', // 4
  'Usage Charge', // 5
  'External Refund', // 6
  'Subscription Adjustment', // 7
];

export enum FeeType {
  Setup = 1,
  CustomWork = 2
}

export const FeeTypeLabels = [
  '',           // 0
  'Setup',      // 1
  'Custom Work' // 2
];

export enum InvoiceChargeType {
  Full = 'full',
  PastBalance = 'past',
  Custom = 'custom'
}

export interface Transaction extends BaseModel {
  amount: number;
  type: TransactionType;
  created: string;
  status: ChargeStatus;
  is_refundable: boolean;
  amount_refunded: number;
  refundable_amount: number;
}

export interface InvoiceItem extends BaseModel {
  amount: string;
  type: FeeType;
  description: string;
  status: InvoiceStatus;
  created: string;
}

interface InvoiceBase extends BaseModel {
  sessions: number;
  sessions_with_action: number;
  inbound_local_calls: number;
  inbound_local_usage: number;
  inbound_tollfree_calls: number;
  inbound_tollfree_usage: number;
  outbound_calls: number;
  outbound_usage: number;
  inbound_sip_calls: number;
  inbound_sip_usage: number;
  outbound_sip_calls: number;
  outbound_sip_usage: number;
  recordings: number;
  recordings_usage: number;
  stored_recordings: number;
  stored_recordings_usage: number;
  speech_recognition_usage: number;
  transcriptions: number;
  transcriptions_usage: number;
  sms_outbound: number;
  mms_outbound: number;
  sms_inbound: number;
  mms_inbound: number;
  transaction_count: number;
  transaction_amount: number;
  handoffs: number;
}

export interface DailyInvoice extends InvoiceBase {
  campaign: string | number;
  billing_status: AccountBillingStatus;
  invoice_date: string;
}

export interface Invoice extends InvoiceBase {
  billable_sessions: number;
  billable_sessions_with_action: number;
  billable_inbound_local_calls: number;
  billable_inbound_local_usage: number;
  billable_inbound_tollfree_calls: number;
  billable_inbound_tollfree_usage: number;
  billable_outbound_calls: number;
  billable_outbound_usage: number;
  billable_inbound_sip_calls: number;
  billable_inbound_sip_usage: number;
  billable_outbound_sip_calls: number;
  billable_outbound_sip_usage: number;
  billable_recordings: number;
  billable_recordings_usage: number;
  billable_stored_recordings: number;
  billable_stored_recordings_usage: number;
  billable_speech_recognition_usage: number;
  billable_transcriptions: number;
  billable_transcriptions_usage: number;
  billable_sms_outbound: number;
  billable_mms_outbound: number;
  billable_sms_inbound: number;
  billable_mms_inbound: number;
  subscription_rate: string;
  crm_action_fee: string;
  session_fee: string;
  local_number_rate: string;
  local_call_rate: string;
  tollfree_number_rate: string;
  tollfree_call_rate: string;
  sip_call_rate: string;
  call_recording_rate: string;
  sms_outbound_fee: string;
  mms_outbound_fee: string;
  sms_inbound_fee: string;
  mms_inbound_fee: string;
  additional_fee: string;
  local_numbers: number;
  tollfree_numbers: number;
  amount: string;
  amount_due: string;
  past_amount_due: string;
  amount_paid: string;
  amount_refunded: string;
  past_balance: string;
  start_date: string;
  last_date: string;
  end_date: string;
  status: InvoiceStatus;
  transactions: Transaction[];
  invoice_items: InvoiceItem[];
  plan_snapshot: Plan;
  usage_plan: Plan;
  billable_days: number;
  extra_sessions: number;
  extra_crm_actions: number;
  extra_local_numbers: number;
  extra_tollfree_numbers: number;
  is_recurring: boolean;
  settle_date: string;
  usage_amount: string;
  subscription_start_date: string;
  subscription_end_date: string;
  total_sessions: number;
  transaction_rate: string;
  transaction_fee: string;
  total_transaction_fee: string;
  billable_handoffs: number;
  plan_adjustment: number;
  last_subscription_start_date: string;
  last_subscription_end_date: string;
}
