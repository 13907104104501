import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import {
  StepButtonControlTypes,
  StepButtonShapeStyles,
  StepButtonShapes,
  StepButtonTypes,
  StepButtons,
  StepButtonsLayout,
  StepEditorIds,
  StepElements,
  getFunnelPathVariables,
  transparent,
  whiteColor,
} from "../../../_models";
import { FormArray, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { StepEmulatorService, StepTemplateService } from "../../../_services";
import {
  addDummyValues,
  fontFamilyFormats,
  fontSizes,
  initializeEditorStyles,
} from "../../step-data";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import * as tinymce from "tinymce";
import { FormControlStatus } from "../../../_forms";

@Component({
  selector: "step-button",
  templateUrl: "./step-button.component.html",
  styleUrls: ["./step-button.component.scss"],
  animations: [
    trigger("fadeIn", [
      state("void", style({ opacity: 0 })),
      transition(":enter", [animate("500ms ease-in", style({ opacity: 1 }))]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class StepButtonComponent implements OnInit, OnDestroy, OnChanges {
  @Input() layout: StepButtonsLayout;
  @Input() form: FormGroup;
  @Input() enableFormErrors: boolean;
  @Input() theme: string;
  @Input() useExternalButtons: boolean;
  stepButtons: StepButtons;
  stepElements = StepElements;
  buttonShapes = StepButtonShapes;
  buttonTypes = StepButtonTypes;
  buttonControlTypes = StepButtonControlTypes;
  stepEditorIds = StepEditorIds;
  private subscription: Subscription;
  funnelPathVariables: { value: string; text: string }[] = [];
  buttonStyles = [
    { value: this.buttonShapes.Flat, label: "Flat Button" },
    { value: this.buttonShapes.Modern, label: "Modern Button" },
    { value: this.buttonShapes.Pill, label: "Pill Button" },
    { value: this.buttonShapes.FlatOutline, label: "Flat Outline" },
    { value: this.buttonShapes.ModernOutline, label: "Modern Outline" },
    { value: this.buttonShapes.PillOutline, label: "Pill Outline" },
    { value: this.buttonShapes.TextOnly, label: "Text Only" },
  ];
  outlineShapes = [
    StepButtonShapes.FlatOutline,
    StepButtonShapes.ModernOutline,
    StepButtonShapes.PillOutline,
  ];
  confirmControl: FormGroup;
  backControl: FormGroup;

  constructor(
    protected stepService: StepTemplateService,
    private stepEmulatorService: StepEmulatorService
  ) {}

  ngOnInit() {
    this.subscription = this.stepEmulatorService.stepButtons$.subscribe(
      (data) => {
        this.stepButtons = data;
      }
    );
    this.funnelPathVariables = getFunnelPathVariables();
    this.applyTheme();

    if (this.useExternalButtons) {
      this.confirmControl = (this.form.get("button_styles") as FormGroup).get(
        "confirm"
      ) as FormGroup;
      this.backControl = (this.form.get("button_styles") as FormGroup).get(
        "back"
      ) as FormGroup;
    } else {
      const inputs = this.form.get("inputs") as FormArray;
      this.confirmControl = inputs.at(0) as FormGroup;
      this.backControl = inputs.at(1) as FormGroup;
    }

    this.initializeValues();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.theme && changes.theme.currentValue && this.stepButtons) {
      this.applyTheme();
    }
  }

  applyTheme() {
    const confirmButton = this.stepButtons.confirmButton,
      backButton = this.stepButtons.backButton;

    if (this.outlineShapes.includes(confirmButton.style)) {
      confirmButton.brandFont = this.theme;
      confirmButton.brandBorder = this.theme;
      confirmButton.brandBackground = whiteColor;
    } else if (confirmButton.style === StepButtonShapes.TextOnly) {
      confirmButton.brandFont = this.theme;
      confirmButton.brandBorder = transparent;
      confirmButton.brandBackground = transparent;
    } else {
      confirmButton.brandFont = whiteColor;
      confirmButton.brandBorder = whiteColor;
      confirmButton.brandBackground = this.theme;
    }

    if (this.outlineShapes.includes(backButton.style)) {
      backButton.brandFont = this.theme;
      backButton.brandBorder = this.theme;
      backButton.brandBackground = whiteColor;
    } else if (backButton.style === StepButtonShapes.TextOnly) {
      backButton.brandFont = this.theme;
      backButton.brandBorder = transparent;
      backButton.brandBackground = transparent;
    } else {
      backButton.brandFont = whiteColor;
      backButton.brandBorder = whiteColor;
      backButton.brandBackground = this.theme;
    }
  }

  initializeValues() {
    const inputs = this.form.get("inputs") as FormArray;
    const buttonStyles = this.form.get("button_styles") as FormGroup;
    const confirmStyles = buttonStyles.get("confirm") as FormGroup;
    const backStyles = buttonStyles.get("back") as FormGroup;

    const confirmButton = this.useExternalButtons
      ? confirmStyles.get("label").value
      : inputs.at(0).get("label").value;

    const backButton = this.useExternalButtons
      ? backStyles.get("label").value
      : inputs.at(1).get("label").value;

    this.stepButtons.confirmButton.content = addDummyValues(
      confirmButton,
      this.funnelPathVariables
    );
    this.stepButtons.backButton.content = addDummyValues(
      backButton,
      this.funnelPathVariables
    );

    const confirmStyle = this.useExternalButtons
      ? confirmStyles.get("style_type").value
      : inputs.at(0).get("button_styles").get("style_type").value;
    if (confirmStyle) this.stepButtons.confirmButton.style = confirmStyle;

    const backStyle = this.useExternalButtons
      ? backStyles.get("style_type").value
      : inputs.at(1).get("button_styles").get("style_type").value;
    if (backStyle) this.stepButtons.backButton.style = backStyle;

    this.changeButtonShape(
      this.stepButtons.confirmButton.style,
      StepButtonTypes.Confirm
    );
    this.changeButtonShape(
      this.stepButtons.backButton.style,
      StepButtonTypes.Back
    );

    const confirmControl = this.useExternalButtons
      ? confirmStyles.get("control_type").value
      : inputs.at(0).get("button_styles").get("control_type").value;
    if (confirmControl) this.stepButtons.confirmButton.type = confirmControl;

    const backControl = this.useExternalButtons
      ? backStyles.get("control_type").value
      : inputs.at(1).get("button_styles").get("control_type").value;
    if (backControl) this.stepButtons.backButton.type = backControl;

    this.changeButtonControl(
      this.stepButtons.confirmButton.type,
      StepButtonTypes.Confirm
    );
    this.changeButtonControl(
      this.stepButtons.backButton.type,
      StepButtonTypes.Back
    );

    const confirmSettings = this.useExternalButtons
      ? confirmStyles.get("style").value
      : inputs.at(0).get("button_styles").get("style").value;
    if (confirmSettings) {
      this.extractButtonStyles(confirmSettings, StepButtonTypes.Confirm);
    }

    const backSettings = this.useExternalButtons
      ? backStyles.get("style").value
      : inputs.at(1).get("button_styles").get("style").value;
    if (backSettings) {
      this.extractButtonStyles(backSettings, StepButtonTypes.Back);
    }
  }

  toggleElement(element: StepElements) {
    this.stepEmulatorService.toggleElement(element);
  }

  isElementVisible(element: StepElements): boolean {
    return this.stepEmulatorService.isElementVisible(element);
  }

  buttonEditor = {
    height: 140,
    resize: false,
    plugins: "line-height",
    toolbar:
      "bold italic underline strikethrough | fontsizeselect fontselect lineheightselect ",
    fontsize_formats: fontSizes,
    font_formats: fontFamilyFormats,
    auto_focus: this.stepEditorIds.ConfirmButton,
    setup: (editor: tinymce.Editor) => {
      initializeEditorStyles(editor, "24px", "'Open Sans', sans-serif");

      editor.on("Change KeyUp", () => {
        const content = editor.getContent();
        if (editor.id === StepEditorIds.ConfirmButton) {
          this.onConfirmButtonChange(content);
        } else if (editor.id === StepEditorIds.BackButton) {
          this.onBackButtonChange(content);
        }
      });
    },
  };

  onConfirmButtonChange(content: string) {
    const updatedContent = addDummyValues(content, this.funnelPathVariables);
    this.stepButtons.confirmButton.content = updatedContent;
  }

  onBackButtonChange(content: string) {
    const updatedContent = addDummyValues(content, this.funnelPathVariables);
    this.stepButtons.backButton.content = updatedContent;
  }

  addConfirmButtonVariable(event: any) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedValue = selectElement.value;
    const editorInstance = tinymce.get(this.stepEditorIds.ConfirmButton);
    if (editorInstance) {
      editorInstance.insertContent(selectedValue);
    }
    selectElement.selectedIndex = 0;
  }

  addBackButtonVariable(event: any) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedValue = selectElement.value;
    const editorInstance = tinymce.get(this.stepEditorIds.BackButton);
    if (editorInstance) {
      editorInstance.insertContent(selectedValue);
    }

    selectElement.selectedIndex = 0;
  }

  changeButtonShape(style: StepButtonShapes, button: StepButtonTypes) {
    let styles;
    switch (style) {
      case StepButtonShapes.Flat:
        styles = StepButtonShapeStyles[StepButtonShapes.Flat];
        break;
      case StepButtonShapes.Modern:
        styles = StepButtonShapeStyles[StepButtonShapes.Modern];
        break;
      case StepButtonShapes.Pill:
        styles = StepButtonShapeStyles[StepButtonShapes.Pill];
        break;
      case StepButtonShapes.FlatOutline:
        styles = StepButtonShapeStyles[StepButtonShapes.FlatOutline];
        break;
      case StepButtonShapes.ModernOutline:
        styles = StepButtonShapeStyles[StepButtonShapes.ModernOutline];
        break;
      case StepButtonShapes.PillOutline:
        styles = StepButtonShapeStyles[StepButtonShapes.PillOutline];
        break;
      case StepButtonShapes.TextOnly:
        styles = StepButtonShapeStyles[StepButtonShapes.TextOnly];
        break;
    }

    let brandFont, brandBorder, brandBackground;
    if (button === StepButtonTypes.Confirm) {
      /* Theme settings */
      if (this.outlineShapes.includes(+style)) {
        brandFont = this.theme;
        brandBorder = this.theme;
        brandBackground = whiteColor;
      } else if (+style === StepButtonShapes.TextOnly) {
        brandFont = this.theme;
        brandBorder = transparent;
        brandBackground = transparent;
      } else {
        brandFont = whiteColor;
        brandBorder = whiteColor;
        brandBackground = this.theme;
      }

      this.stepButtons.confirmButton = {
        ...this.stepButtons.confirmButton,
        ...styles,
        style: style,
        brandFont,
        brandBorder,
        brandBackground,
        shapesPopup: false,
      };
    } else if (button === StepButtonTypes.Back) {
      /* Theme settings */
      if (this.outlineShapes.includes(+style)) {
        brandFont = this.theme;
        brandBorder = this.theme;
        brandBackground = whiteColor;
      } else if (+style === StepButtonShapes.TextOnly) {
        brandFont = this.theme;
        brandBorder = transparent;
        brandBackground = transparent;
      } else {
        brandFont = whiteColor;
        brandBorder = whiteColor;
        brandBackground = this.theme;
      }

      this.stepButtons.backButton = {
        ...this.stepButtons.backButton,
        ...styles,
        style: style,
        brandFont,
        brandBorder,
        brandBackground,
        shapesPopup: false,
      };
    }
  }

  changeButtonControl(type: StepButtonControlTypes, button: StepButtonTypes) {
    if (type === StepButtonControlTypes.Theme) {
      if (button === StepButtonTypes.Confirm) {
        this.stepButtons.confirmButton = {
          ...this.stepButtons.confirmButton,
          type: type,
          controlPopup: false,
        };
      } else if (button === StepButtonTypes.Back) {
        this.stepButtons.backButton = {
          ...this.stepButtons.backButton,
          type: type,
          controlPopup: false,
        };
      }
    } else if (type === StepButtonControlTypes.Custom) {
      if (button === StepButtonTypes.Confirm) {
        this.stepButtons.confirmButton = {
          ...this.stepButtons.confirmButton,
          ...StepButtonShapeStyles[this.stepButtons.confirmButton.style],
          isFontDisabled: false,
          type: type,
          controlPopup: false,
        };
      } else if (button === StepButtonTypes.Back) {
        this.stepButtons.backButton = {
          ...this.stepButtons.backButton,
          ...StepButtonShapeStyles[this.stepButtons.backButton.style],
          isFontDisabled: false,
          type: type,
          controlPopup: false,
        };
      }
    }
  }

  extractButtonStyles(style: string, button: StepButtonTypes) {
    const colorMatch = style.match(/color:\s*([^;]+)/);
    const backgroundColorMatch = style.match(/background-color:\s*([^;]+)/);
    const borderColorMatch = style.match(/border-color:\s*([^;]+)/);
    const borderRadiusMatch = style.match(/border-radius:\s*([^;]+)/);

    if (colorMatch) {
      if (button === StepButtonTypes.Confirm) {
        this.stepButtons.confirmButton.fontColor = colorMatch[1].trim();
      } else if (button === StepButtonTypes.Back) {
        this.stepButtons.backButton.fontColor = colorMatch[1].trim();
      }
    }
    if (backgroundColorMatch) {
      if (button === StepButtonTypes.Confirm) {
        this.stepButtons.confirmButton.backgroundColor =
          backgroundColorMatch[1].trim();
      } else if (button === StepButtonTypes.Back) {
        this.stepButtons.backButton.backgroundColor =
          backgroundColorMatch[1].trim();
      }
    }
    if (borderColorMatch) {
      if (button === StepButtonTypes.Confirm) {
        this.stepButtons.confirmButton.borderColor = borderColorMatch[1].trim();
      } else if (button === StepButtonTypes.Back) {
        this.stepButtons.backButton.borderColor = borderColorMatch[1].trim();
      }
    }
    if (borderRadiusMatch) {
      if (button === StepButtonTypes.Confirm) {
        this.stepButtons.confirmButton.borderRadius =
          borderRadiusMatch[1].trim();
      } else if (button === StepButtonTypes.Back) {
        this.stepButtons.backButton.borderRadius = borderRadiusMatch[1].trim();
      }
    }
  }

  get isCustomConfirmButton() {
    return (
      this.stepButtons.confirmButton.type === StepButtonControlTypes.Custom
    );
  }

  get isCustomBackButton() {
    return this.stepButtons.backButton.type === StepButtonControlTypes.Custom;
  }

  get isConfirmFontDisabled() {
    return (
      this.stepButtons.confirmButton.type === StepButtonControlTypes.Theme &&
      (this.outlineShapes.includes(+this.stepButtons.confirmButton.style) ||
        this.stepButtons.confirmButton.style === StepButtonShapes.TextOnly)
    );
  }

  get isBackFontDisabled() {
    return (
      this.stepButtons.backButton.type === StepButtonControlTypes.Theme &&
      (this.outlineShapes.includes(+this.stepButtons.backButton.style) ||
        this.stepButtons.backButton.style === StepButtonShapes.TextOnly)
    );
  }

  get confirmInvalidity() {
    return (
      this.enableFormErrors &&
      this.confirmControl.controls.label.status === FormControlStatus.Invalid
    );
  }

  get backInvalidity() {
    return (
      this.enableFormErrors &&
      this.backControl.controls.label.status === FormControlStatus.Invalid
    );
  }

  openColorPicker(colorInput: any) {
    colorInput.click();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
