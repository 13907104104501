import {Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import { Location } from '@angular/common';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions, NgUploaderService } from 'ngx-uploader';
import { Router, ActivatedRoute } from '@angular/router';
import { Form } from '../_forms';
import { ImageService, AlertService, StorageService } from "../_services";
import {Point, Size, Rectangle, Image, ImageType} from '../_models';
import { takeUntil } from "rxjs/operators";
import {DomSanitizer} from '@angular/platform-browser';
import {NgxSmartModalService} from "ngx-smart-modal";

@Component({
  moduleId: module.id.toString(),
  selector: 'image-upload',
  templateUrl: 'image-upload.component.html',
  styleUrls: ['./image.component.css']
})
export class ImageUploadComponent extends Form implements OnInit {
  options: UploaderOptions;
  files = [];
  uploadInput: EventEmitter<UploadInput>;
  dragOver: boolean = false;

  private humanizeBytes: Function;
  private isCropBoxDragging: boolean = false;

  loading = false;
  thumbnailSize: Size;
  previewSize: Size;
  fileIndex = 0;
  imageWidthString: string = 'auto';
  imageHeightString: string = 'auto';
  previewSource: any = '';
  thumbnailPos: Point; //position representing top left corner of the thumbnail crop window
  @ViewChildren('preview_box') previewBoxes: QueryList<any>;

  @ViewChild('crop_box', { static: false }) cropBox;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  @Output('upload') uploadComplete: EventEmitter<any> = new EventEmitter<any>();
  @Output('cancel') uploadCancelled: EventEmitter<any> = new EventEmitter<any>();
  @Input('resourcetype') resourceType = 'Image';
  @Input('globalGallery') globalGallery = false;

  previewFiles: any = [];
  maxUploadSize: number = 1;
  uploaderService: NgUploaderService = new NgUploaderService();
  uploadedImages: File[] = [];
  cropImages: boolean = false;
  filesToIgnoreInCropping: File[] = [];

  constructor(
    protected router: Router,
    protected location: Location,
    private route: ActivatedRoute,
    private imageService: ImageService,
    protected alertService: AlertService,
    private storageService: StorageService,
    private sanitizer: DomSanitizer,
  ) {
    super(alertService, router, location);

    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
    this.title = 'Upload an Image File';
  }

  ngOnInit() {
    super.ngOnInit();

    this.imageService.getThumbnailSizes('product_image')
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: {}) => {
          let sizeType = 'medium';
          this.thumbnailSize = {width: data[sizeType][0], height: data[sizeType][1]};
        },
        error => {
          this.handleError(error);
        }
      );
    const allowedContentTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];
    if (this.resourceType === ImageType.Image) {
      allowedContentTypes.push('image/svg+xml');
    }
    this.options = {
      concurrency: 1,
      maxUploads: 10,
      allowedContentTypes: allowedContentTypes
    };
  }

  async onUploadOutput(output: UploadOutput) {
    if (output.type === 'allAddedToQueue') { // when all files added in queue
      // uncomment this if you want to auto upload files when added
      // const event: UploadInput = {
      //   type: 'uploadAll',
      //   url: '/upload',
      //   method: 'POST',
      //   data: { foo: 'bar' }
      // };
      // this.uploadInput.emit(event);
    }
    else if (output.type === 'addedToQueue'  && typeof output.file !== 'undefined') { // add file to array when added
      const imageProps: any = await this.imageService.getImageAttributes(output.file);
      if (imageProps.sizeInMB > this.maxUploadSize) {
        this.alertService.error(`Image size should be less than ${this.maxUploadSize}MB`)
        return
      }
      this.imageWidthString = 'auto';
      this.imageHeightString = 'auto';
      //scale the image so it borders the preview box horizontally or vertically
      let thumbnailAR = this.thumbnailSize.width / this.thumbnailSize.height;
      let imageAR = imageProps.width / imageProps.height;
      this.files.push({
        'id': output.file.id,
        'is_public': this.globalGallery,
        'file': output.file.nativeFile,
        'resourcetype': this.resourceType,
        'path': output.file.name, 'ppoi': '0.5x0.5',
        height: imageProps.height, width: imageProps.width,
      });

      if (imageAR > thumbnailAR) {
        this.imageHeightString = this.thumbnailSize.height.toString() + 'px';
        this.previewSize = {
          width: Math.round(this.thumbnailSize.height * imageAR),
          height: this.thumbnailSize.height,
        };
      }
      else {
        this.imageWidthString = this.thumbnailSize.width.toString() + 'px';
        this.previewSize = {
          width: this.thumbnailSize.width,
          height: Math.round(this.thumbnailSize.width / imageAR)
        };
      }
      this.previewSource = this.sanitizer.bypassSecurityTrustResourceUrl(imageProps.src);
      this.thumbnailPos = {
        x: Math.max(Math.floor((this.previewSize.width - this.thumbnailSize.width) / 2), 0),
        y: Math.max(Math.floor((this.previewSize.height - this.thumbnailSize.height) / 2), 0)
      };
      this.previewFiles.push({isCropBoxDragging: this.isCropBoxDragging, thumbnailSize: this.thumbnailSize,
        previewSize: this.previewSize, imageWidthString: this.imageWidthString, imageHeightString: this.imageHeightString,
        previewSource: this.previewSource, thumbnailPos: this.thumbnailPos});
    }
    else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      // update current data in files array for uploading file
      const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.files[index] = output.file;
      this.loading = true;
    }
    else if (output.type === 'removed') {
      // remove file from array when removed
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    }
    else if (output.type === 'dragOver') {
      this.dragOver = true;
    }
    else if (output.type === 'dragOut') {
      this.dragOver = false;
    }
    else if (output.type === 'drop') {
      this.dragOver = false;
    }
    else if (output.type === 'done') {
      if (output.file.responseStatus == 201) {
        this.alertService.success('Successfully uploaded new file: ' + output.file.name);
        this.uploadComplete.emit(output.file.response);
      }
      else {
        this.handleSubmitError({status: output.file.responseStatus, statusText: output.file.response, error: output.file.response});
        this.loading = false;
      }
    }
    else if (output.type === 'rejected') {
      this.alertService.error('Invalid file type.');
      this.loading = false;
    }
  }

  private updatePPOI(index) {
    if (this.files.length) {
      let x = (((2 * this.previewFiles[index].thumbnailPos.x) + this.previewFiles[index].thumbnailSize.width) /
        (2 * this.previewFiles[index].previewSize.width)).toFixed(2);
      let y = (((2 * this.previewFiles[index].thumbnailPos.y) + this.previewFiles[index].thumbnailSize.height) /
        (2 * this.previewFiles[index].previewSize.height)).toFixed(2);
      this.files[index].ppoi = x.toString() + 'x' + y.toString(); // set the ppoi
    }
  }

  postUploadProcess(images: Image[]) {
    if (!Array.isArray(images)) {
      images = [images];
    }
    this.imageService.postUploadProcess(this.files, images).then((result) => {
      this.alertService.success('Successfully uploaded new files');
      this.loading = false;
      this.uploadComplete.emit(result);
      this.previewFiles = [];
      this.files = [];
      this.fileInput.nativeElement.value = '';
    });
  }

  startUpload(): void {
    this.loading = true;
    this.imageService.create(this.files)
      .subscribe(
        (images: Image[]) => {
          this.postUploadProcess(images);
        },
        error => {
          this.handleSubmitError(error);
          this.loading = false;
        }
      );
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }

  startCropBoxDrag(index): void {
    console.log('drag start');
    this.previewFiles[index].isCropBoxDragging = true;
  }

  endCropBoxDrag(index): void {
    if (this.previewFiles[index].isCropBoxDragging) {
      console.log('drag end');
      this.previewFiles[index].isCropBoxDragging = false;
    }
  }

  dragCropBox($event: any, index): void {
    if (this.previewFiles[index].isCropBoxDragging) {
      let x = $event.clientX;
      let y = $event.clientY;
      let previewRect = this.previewBoxes.toArray()[index].nativeElement.getBoundingClientRect();
      let cropRect: Rectangle = {
        left: previewRect.left + this.previewFiles[index].thumbnailPos.x,
        top: previewRect.top + this.previewFiles[index].thumbnailPos.y,
        bottom: previewRect.top + this.previewFiles[index].thumbnailPos.y + this.previewFiles[index].thumbnailSize.height,
        right: previewRect.left + this.previewFiles[index].thumbnailPos.x + this.previewFiles[index].thumbnailSize.width
      };

      if (x < cropRect.left) {
        this.previewFiles[index].thumbnailPos.x = (x > previewRect.left) ? x - previewRect.left : 0;
      }
      else if (x > cropRect.right) {
        let right = (x < previewRect.right) ? x - previewRect.left : previewRect.width;
        this.previewFiles[index].thumbnailPos.x = right - this.previewFiles[index].thumbnailSize.width;
      }

      if (y < cropRect.top) {
        this.previewFiles[index].thumbnailPos.y = (y > previewRect.top) ? y - previewRect.top : 0;
      }
      else if (y > cropRect.bottom) {
        let bottom = (y < previewRect.bottom) ? y - previewRect.top : previewRect.height;
        this.previewFiles[index].thumbnailPos.y = bottom - this.previewFiles[index].thumbnailSize.height;
      }

      this.updatePPOI(index);
    }
  }

  cancel() {
    this.uploadCancelled.emit();
  }

  onImageUpload(output: UploadOutput) {
    this.files = [];
    this.previewFiles = [];
    if (output.file) {
      let fileSize = (output.file.size / 1024) / 1024
      if (fileSize > this.maxUploadSize) {
        this.alertService.error(`Image size should be less than ${this.maxUploadSize}MB`)
        return
      }
      if (output.file.type === 'image/gif' || output.file.type === 'image/svg+xml') {
        this.filesToIgnoreInCropping.push(output.file.nativeFile);
        return;
      }
      this.uploadedImages.push(output.file.nativeFile);
    }
    this.cropImages = output.type === 'allAddedToQueue' && this.uploadedImages.length > 0;
    if (output.type === 'allAddedToQueue' && !this.cropImages) {
      this.filesToIgnoreInCropping.forEach((image, index) => {
        this.onUploadOutput({type: 'addedToQueue', file: this.uploaderService.makeUploadFile(image, index)})
      })
      this.filesToIgnoreInCropping = [];
    }
  }

  onImageCropped(images: File[]) {
    this.uploadedImages = [];
    this.cropImages = false;
    this.filesToIgnoreInCropping.forEach(file => {
      images.push(file);
    })
    images.forEach((image, index) => {
      this.onUploadOutput({type: 'addedToQueue', file: this.uploaderService.makeUploadFile(image, index)})
    })
    this.filesToIgnoreInCropping = [];
  }
}
