import { Component, OnDestroy, OnInit } from "@angular/core";
import { CrudPagedListComponent } from "../../_directives";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AlertService,
  FunnelService,
  LoaderService,
  SideNavService,
  TemplateFolderService,
  UserService,
} from "../../_services";
import { Location } from "@angular/common";
import {
  Breadcrumb,
  OfferTypeLabels,
  OfferTypes,
  StepCategoryLabels,
  CustomStepCategoryEnum,
  StepPopupActions,
  User,
} from "../../_models";
import { NgxSmartModalService } from "ngx-smart-modal";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-step-template-folders",
  templateUrl: "./step-template-folders.component.html",
  styleUrls: ["./step-templates.component.scss"],
})
export class StepTemplateFolders
  extends CrudPagedListComponent
  implements OnInit, OnDestroy
{
  offerType: OfferTypes;
  offerName: string;
  stepType: CustomStepCategoryEnum;
  stepTypeName: string;
  funnelId = "";
  funnelName = "";
  folders: any;
  filteredFolders: any;
  breadcrumbs: Breadcrumb[] = [];
  stepPopupActions = StepPopupActions;
  popupTitle = "";
  popupData = {
    name: "",
    category: null,
    popupType: StepPopupActions.CreateFolder,
    folderId: null,
  };
  searchQuery = "";
  user: User;
  popupTitleMap = {
    [StepPopupActions.CreateFolder]: "Create a new Folder",
    [StepPopupActions.UpdateFolder]: "Rename Folder",
    [StepPopupActions.DeleteFolder]: "Delete Folder",
  };

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    private sideNav: SideNavService,
    public ngxSmartModalService: NgxSmartModalService,
    protected loader: LoaderService,
    protected funnelService: FunnelService,
    protected templateFolderService: TemplateFolderService,
    protected alertService: AlertService,
    protected userService: UserService
  ) {
    super(router, location, route, funnelService, alertService);
    this.sideNav.setShowSideNavToggle(true);
    this.sideNav.setSideNavExpanded(false);

    this.route.params.subscribe((params) => {
      this.funnelId = params["offer_id"];
      this.offerType = Number(params["offer_type"]);
      this.offerName = OfferTypeLabels[this.offerType];
      this.stepType = params["step_type"];
      this.stepTypeName = StepCategoryLabels[this.stepType];
    });
  }

  ngOnInit() {
    this.userService
      .getCurrent()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (user: User) => {
          this.user = user;
        },
        (error) => {
          this.handleError(error);
        }
      );

    this.fetchTemplateFolders();
    if (this.funnelId) {
      this.fetchFunnel();
    }
    this.generateBreadcrumbs();
  }

  fetchTemplateFolders() {
    this.loader.show();
    this.templateFolderService.list({ category: this.stepType }).subscribe(
      (response) => {
        this.folders = response.results;
        this.filteredFolders = this.folders.map((folder) => ({...folder, showThreeDotMenu: false}))
        this.loader.hide();
      },
      (error) => {
        this.loader.hide();
        this.alertService.error(error);
      }
    );
  }

  fetchFunnel() {
    this.funnelService.list({}, this.funnelId).subscribe(
      (response) => {
        this.funnelName = response.slug;
        this.breadcrumbs[1].label = response.slug;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  generateBreadcrumbs() {
    if (this.offerType === OfferTypes.Survey) {
      this.breadcrumbs = [
        {
          label:
            this.offerName.charAt(0).toUpperCase() + this.offerName.slice(1),
          url: ["steps", "survey"],
        },
        {
          label: "Choose a Template",
        },
      ];
    } else {
      this.breadcrumbs = [
        {
          label:
            this.offerName.charAt(0).toUpperCase() +
            this.offerName.slice(1) +
            " Offers",
          url: ["steps", this.offerName],
        },
        {
          label: "",
          url: [
            "steps",
            this.offerName,
            this.offerType,
            "details",
            this.funnelId,
          ],
        },
        {
          label: "Choose a Template",
        },
      ];
    }
  }

  saveTemplate(): void {
    this.fetchTemplateFolders();
    this.ngxSmartModalService.getModal("templatePopup").close();
  }

  openPopup(
    e: MouseEvent,
    popupType: StepPopupActions,
    folder?: any
  ): void {
    this.popupTitle = this.popupTitleMap[popupType] || "";

    this.popupData = {
      ...this.popupData,
      name: folder && folder.name || "",
      popupType: popupType,
      category: this.stepType,
      folderId: folder && folder.id || null,
    };

    if (folder) {
      this.closeThreeDotMenu(e, folder)
    } 
    
    this.ngxSmartModalService.getModal("templatePopup").open();
  }

  closePopup() {
    this.ngxSmartModalService.getModal("templatePopup").close();
  }

  selectFolder(folderId: string | number) {
    if (this.funnelId) {
      this.router.navigate([
        "steps",
        this.offerName,
        this.funnelId,
        "category",
        this.offerType,
        "folders",
        this.stepType,
        "folder",
        folderId,
      ]);
    } else {
      this.router.navigate([
        "steps",
        this.offerName,
        "category",
        this.offerType,
        "folders",
        this.stepType,
        "folder",
        folderId,
      ]);
    }
  }

  searchData() {
    if (this.searchQuery) {
      this.filteredFolders = this.folders.filter((template) =>
        template.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    } else {
      this.filteredFolders = this.folders;
    }
  }

  navigate(url: any[]) {
    this.router.navigate(url);
  }

  ngOnDestroy(): void {
    this.sideNav.setShowSideNavToggle(true);
    this.sideNav.setSideNavExpanded(true);
  }

  closeOtherMenus(currentItem: any): void {
    this.filteredFolders.forEach((folder) => {
      if (folder.id !== currentItem.id) {
        folder.showThreeDotMenu = false;
      }
    });
  }

  toggleThreeDotMenu(e: MouseEvent, item: any): void {
    e.stopPropagation();
    this.closeOtherMenus(item);
    item.showThreeDotMenu = !item.showThreeDotMenu;
  }

  closeOutsideMenu() {
    if (this.filteredFolders && this.filteredFolders.length) {     
      this.filteredFolders.forEach((folder) => {
        folder.showThreeDotMenu = false;
      });
    }
  }

  closeThreeDotMenu(e: MouseEvent, item: any) {
    e.stopPropagation();
    item.showThreeDotMenu = false;
  }
  popupIconMap: Partial<Record<StepPopupActions, string>> = {
    [StepPopupActions.DeleteFolder]: '/assets/stepAssets/delete-icon.svg',
    [StepPopupActions.UpdateFolder]: '/assets/stepAssets/rename.svg',
    [StepPopupActions.CreateFolder]: '/assets/stepAssets/edit-small.svg',
  };
}
