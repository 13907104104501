import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { NgxSmartModalService } from "ngx-smart-modal";
import { OffersAbstractComponent } from "./offers-abstract.component";
import {
  AlertService,
  CampaignService,
  FunnelService,
  LoaderService,
  StepTemplateService,
} from "../../_services";
import { OfferIntents, OfferTypes } from "../../../app/_models";
import { OfferTypeOptions } from "../step-data";

@Component({
  selector: "upsell-offers",
  templateUrl: "./../steps.component.html",
  styleUrls: ["./../steps.component.scss"],
})
export class UpsellOffersComponent
  extends OffersAbstractComponent
  implements OnInit
{
  stepsTitle = "Upsell Offers";
  offerType = OfferTypes.Upsell;
  offerTypeOptions = OfferTypeOptions;

  constructor(
    protected router: Router,
    protected location: Location,
    protected alertService: AlertService,
    protected funnelService: FunnelService,
    protected stepService: StepTemplateService,
    protected campaignService: CampaignService,
    protected loader: LoaderService,
    public ngxSmartModalService: NgxSmartModalService,
    protected route: ActivatedRoute
  ) {
    super(
      router,
      location,
      alertService,
      funnelService,
      stepService,
      campaignService,
      loader,
      ngxSmartModalService,
      route
    );
    this.popupData.offer_intent = OfferIntents.CancelOrder;
  }

  navigateToStepTemplates(offerId?: number) {
    this.router.navigate([
      "steps",
      "upsell",
      offerId,
      "category",
      this.offerType,
    ]);
  }

  navigateToViewDetails(offerId?: number) {
    this.router.navigate([
      "steps",
      "upsell",
      this.offerType,
      "details",
      offerId,
    ]);
  }
}
