import { NgModule } from "@angular/core";
import { FieldErrorsComponent, FormErrorsComponent } from "../_forms";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import {
  AlertComponent,
  PagerComponent,
  HelpComponent,
  DualListSortableComponent,
  ProjectableEditorComponent,
  ClickOutsideDirective,
} from "../_directives";
import {
  GlobalGalleryComponent,
  ImageComponent,
  ImageCropComponent,
  ImageGalleryComponent,
  ImageUploadComponent,
} from "../image";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AlertService, ImageService } from "../_services";
import { AngularDualListBoxModule } from "angular-dual-listbox";
import { DndModule } from "@beyerleinf/ngx-dnd";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgxUploaderModule } from "ngx-uploader";
import { NgxSmartModalModule } from "ngx-smart-modal";
import {
  CampaignProductComponent,
  CampaignProductEditComponent,
  CampaignProductSelectComponent,
  CampaignProductSelectPaginatedComponent,
  CampaignProductTestOrderComponent,
} from "../campaign-product";
import { RouterModule } from "@angular/router";
import { CollapsibleModule } from "angular2-collapsible";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { CRMCampaignFormComponent } from "../crm-campaign";
import {
  ProductBasicFieldsComponent,
  ProductMarketplaceFieldsComponent,
  ProductPathFieldsComponent,
  ProductWidgetsComponent,
} from "../product";
import { ProductSubtextComponent } from "../product-subtext";
import {
  WidgetComponent,
  WidgetFormComponent,
  WidgetPreviewFormComponent,
  WidgetTableComponent,
} from "../widget";
import { CycleComponent, CycleFormComponent } from "../cycle";
import { VariantComponent, VariantFormComponent } from "../variant";
import {
  ExchangeableProductsSetComponent,
  ExchangeableProductsSetEditComponent,
  ExchangeableProductsSetNewComponent,
} from "../exchangeable-products-set";
import {
  ExchangeableProductFieldsComponent,
  UpsaleProductFieldsComponent,
} from "../exchangeable-product";
import {
  SalesPhraseComponent,
  SalesPhraseFormComponent,
} from "../sales-phrase";
import { ColorPickerModule } from "ngx-color-picker";

@NgModule({
  declarations: [
    AlertComponent,
    DualListSortableComponent,
    FormErrorsComponent,
    FieldErrorsComponent,
    ClickOutsideDirective,
    HelpComponent,
    ImageGalleryComponent,
    ImageUploadComponent,
    PagerComponent,
    ProjectableEditorComponent,
    ImageComponent,
    ImageCropComponent,
    GlobalGalleryComponent,
    CampaignProductSelectComponent,
    CampaignProductEditComponent,
    CRMCampaignFormComponent,
    CampaignProductTestOrderComponent,
    ProductBasicFieldsComponent,
    ProductSubtextComponent,
    ProductPathFieldsComponent,
    ProductMarketplaceFieldsComponent,
    ProductWidgetsComponent,
    WidgetComponent,
    WidgetFormComponent,
    WidgetTableComponent,
    WidgetPreviewFormComponent,
    CycleComponent,
    CycleFormComponent,
    VariantComponent,
    VariantFormComponent,
    ExchangeableProductsSetComponent,
    ExchangeableProductsSetNewComponent,
    ExchangeableProductsSetEditComponent,
    ExchangeableProductFieldsComponent,
    UpsaleProductFieldsComponent,
    SalesPhraseComponent,
    SalesPhraseFormComponent,
    CampaignProductSelectPaginatedComponent,
    CampaignProductComponent,
  ],
  exports: [
    AlertComponent,
    DualListSortableComponent,
    FormErrorsComponent,
    FieldErrorsComponent,
    ClickOutsideDirective,
    HelpComponent,
    ImageGalleryComponent,
    ImageUploadComponent,
    PagerComponent,
    ProjectableEditorComponent,
    ImageComponent,
    ImageCropComponent,
    GlobalGalleryComponent,
    CampaignProductSelectComponent,
    CampaignProductEditComponent,
    CRMCampaignFormComponent,
    CampaignProductTestOrderComponent,
    ProductBasicFieldsComponent,
    ProductSubtextComponent,
    ProductPathFieldsComponent,
    ProductMarketplaceFieldsComponent,
    ProductWidgetsComponent,
    WidgetComponent,
    WidgetFormComponent,
    WidgetTableComponent,
    WidgetPreviewFormComponent,
    CycleComponent,
    CycleFormComponent,
    VariantComponent,
    VariantFormComponent,
    ExchangeableProductsSetComponent,
    ExchangeableProductsSetNewComponent,
    ExchangeableProductsSetEditComponent,
    ExchangeableProductFieldsComponent,
    UpsaleProductFieldsComponent,
    SalesPhraseComponent,
    SalesPhraseFormComponent,
    CampaignProductSelectPaginatedComponent,
    CampaignProductComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule,
    NgbModule,
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    CollapsibleModule,
    AngularDualListBoxModule,
    DndModule,
    NgxDatatableModule,
    ColorPickerModule,
    ImageCropperModule,
    NgxUploaderModule,
    NgxSmartModalModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [ImageService, AlertService],
  bootstrap: [],
})
export class SharedModule {}
