import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PaginationService} from "./pagination.service";
import {ClipboardService} from "./clipboard.service";
import {CampaignProduct, Product, Funnel} from "../_models";
import {BehaviorSubject, Observable, from} from 'rxjs';
import {copyToClipboard} from "../_helpers/functions";
import {mergeMap, tap} from "rxjs/operators";

@Injectable()
export class FunnelService extends PaginationService {
  copiedFunnel: BehaviorSubject<any> = new BehaviorSubject(null);
  route = 'funnels';

  constructor(
    protected http: HttpClient,
    protected clipboardService: ClipboardService
  ) {
    super(http, 'funnels');
  }

  listOffers(offerType?: number, offerIntent?: number): Observable<any> {
    let params = new HttpParams();
    if (offerType !== undefined) {
      params = params.set("offer_type", offerType.toString());
    }

    if(offerIntent !== undefined) {
      params = params.set("offer_intent!", offerIntent.toString());
    }

    params = params.set("resourcetype", "ProductFunnel");
    return this.http.get(`${this.route}/offer_list/`, { params });
  }

  copy(id: string | number) {
    this.clipboardService.copy('funnel', id.toString());
  }

  canPaste() : boolean {
    return this.clipboardService.canPaste('funnel');
  }

  paste() {
    return this.http.post(`${this.route}/${this.clipboardService.paste('funnel')}/paste/`, {});
  }

  copyProducts(products: Product[]) {
    this.clipboardService.copy('products', products);
  }

  canPasteProducts() : boolean {
    return this.clipboardService.canPaste('products');
  }

  pasteProducts() {
    return this.clipboardService.paste('products');
  }

  preview(campaignId: string | number, data: any) {
    const funnel: Funnel = data;

    return this.http.post(this.route + '/' + funnel.id.toString() + '/preview/', {campaign: campaignId});
  }

  publish(id: string | number) {
    return this.http.post(this.route + '/' + id.toString() + '/publish/', {});
  }

  getHierarchy() {
    return this.http.get(this.route + '/hierarchy/');
  }

  createTemplate(id: string | number, isPublic: boolean = false) {
    return this.http.post(this.route + '/' + id.toString() + '/template/', {is_public: isPublic});
  }

  clone(id: string | number) {
    return this.http.post(this.route + '/' + id.toString() + '/generate/', {});
  }

  add_products(id: string | number, data) {
    return this.http.post(this.route + '/' + id.toString() + '/add_products/', data)
  }

  remove_product(id: string | number, data) {
    return this.http.post(this.route + '/' + id.toString() + '/remove_product/', data)
  }

  copyToClipboard(id: string | number) {
    return this.http.post(this.route + '/' + id.toString() + '/copy/', {}).pipe(
      tap(data => {
        copyToClipboard(JSON.stringify(data));
      })
    );
  }

  pasteFromClipboard() {
    return from(navigator.clipboard.readText()).pipe(
      mergeMap((text) => {
        return this.create(JSON.parse(text));
      }),
    )
  }

  previewStep(campaignId: string | number, stepData: any) {
    return this.http.post(
      "funnel_steps/preview/",
      Object.assign(stepData, { campaign: campaignId })
    );
  }
}
