import { ImageGalleryComponent } from "../../../../../image/image-gallery.component";
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder } from "@angular/forms";
import { Location } from "@angular/common";
import { NgxSmartModalService } from "ngx-smart-modal";
import {
  StepEmulatorService,
  StepTemplateService,
  AlertService,
  ImageService,
} from "../../../../../_services";
import { Image, StepMedia } from "../../../../../_models";
import { Subscription } from "rxjs";

@Component({
  selector: "step-product-images",
  templateUrl: "./step-product-images.component.html",
  styleUrls: ["./../step-fixed-images.component.scss"],
})
export class StepProductImagesComponent
  extends ImageGalleryComponent
  implements OnInit, OnDestroy
{
  @Output() stepEmitImagesData = new EventEmitter<StepMedia>();
  stepMedia: StepMedia;
  private subscription: Subscription;

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected stepService: StepTemplateService,
    protected stepEmulatorService: StepEmulatorService,
    protected imageService: ImageService,
    protected alertService: AlertService,
    protected formBuilder: FormBuilder,
    protected modalService: NgxSmartModalService
  ) {
    super(
      router,
      location,
      route,
      imageService,
      alertService,
      formBuilder,
      modalService
    );
  }

  ngOnInit() {
    this.pageSize = 14;
    this.subscription = this.stepEmulatorService.stepMedia$.subscribe(
      (data) => {
        this.stepMedia = data;
      }
    );

    super.ngOnInit();
  }

  updateStepMedia() {
    this.stepEmitImagesData.emit();
  }

  isImageSelected(image: Image): boolean {
    return this.stepMedia.fixedImage.productImage && this.stepMedia.fixedImage.productImage.id === image.id;
  }

  selectImage(image: Image): void {
    this.stepMedia.fixedImage.productImage = image;
    this.updateStepMedia();
  }

  removeProductImage() {
    this.stepMedia.fixedImage.productImage = null;
    this.updateStepMedia();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
