import {
  BaseModel,
  Action,
  AudioFile,
  IconFile,
  ContactType,
  PhoneCarrierType,
  SipRegion,
  CardType,
} from './index';
import {
  SessionType,
  SessionActionResult,
} from './session-enums'

export enum FunnelInputTypeEnum {
  Choice = 0,
  Text = 1,
  Phone = 2,
  Item = 3,
  ActiveItem = 4,
  Product = 5,
  FAQs = 6,
  BillingAddress = 7,
  ShippingAddress = 8,
  ItemStatus = 9,
  InputActionStatus = 10,
  Radio = 11,
  Select = 12,
  NextSelectedItem = 13,  //deprecated
  RadioSelect = 14,
  Hidden = 15,
  EnterProductFunnel = 16,
  PaymentInfo = 17,
  Link = 18,
  EnterTroubleshooter = 19,
  ContactCallCenter = 20,
  SelectCampaign = 21,
  MatchCustomer = 22,
  Date = 23,
  Checkout = 24,
  CheckoutConfirm = 25,
  RepeatActionHoldoff = 26,
  ShipmentTracking = 27,

  // system child types
  ChangeQuantity = 1001,
  ChangeInterval = 1002,
  Cancel = 1003,
  NoActiveItems = 1004,
  ChangeProduct = 1005,
  Return = 1006,
  Billing = 1007,
  AltChildExit = 1008,
  PathNotFound = 1009,
  NoActiveCampaigns = 1010,
  CustomerNotFound = 1011,
  RebillItem = 1012,
  ShipmentReturnLabel = 1013,
  ShipmentReturnQRCode = 1014,
}

export let FunnelInputTypeLabels = {};
FunnelInputTypeLabels[FunnelInputTypeEnum.Choice] = 'Choice - Buttons';
FunnelInputTypeLabels[FunnelInputTypeEnum.Radio] = 'Choice - Radio Buttons';
FunnelInputTypeLabels[FunnelInputTypeEnum.Select] = 'Choice - Select Box';
FunnelInputTypeLabels[FunnelInputTypeEnum.Text] = 'Text Input';
FunnelInputTypeLabels[FunnelInputTypeEnum.Date] = 'Date';
FunnelInputTypeLabels[FunnelInputTypeEnum.Phone] = 'Phone Number';
FunnelInputTypeLabels[FunnelInputTypeEnum.Item] = 'Order Item Selection';
FunnelInputTypeLabels[FunnelInputTypeEnum.ActiveItem] = 'Active Order Item Selection';
FunnelInputTypeLabels[FunnelInputTypeEnum.Product] = 'Product Info';
FunnelInputTypeLabels[FunnelInputTypeEnum.FAQs] = 'FAQs';
FunnelInputTypeLabels[FunnelInputTypeEnum.BillingAddress] = 'Update Billing Address';
FunnelInputTypeLabels[FunnelInputTypeEnum.ShippingAddress] = 'Update Shipping Address';
FunnelInputTypeLabels[FunnelInputTypeEnum.ItemStatus] = 'Order Item Status';
FunnelInputTypeLabels[FunnelInputTypeEnum.InputActionStatus] = 'Input Action Status';
FunnelInputTypeLabels[FunnelInputTypeEnum.ChangeQuantity] = 'Change Item Quantity';
FunnelInputTypeLabels[FunnelInputTypeEnum.ChangeInterval] = 'Change Item Interval';
FunnelInputTypeLabels[FunnelInputTypeEnum.Cancel] = 'Cancel Item';
FunnelInputTypeLabels[FunnelInputTypeEnum.NoActiveItems] = 'No Active Items';
FunnelInputTypeLabels[FunnelInputTypeEnum.ChangeProduct] = 'Change Product';
FunnelInputTypeLabels[FunnelInputTypeEnum.Return] = 'Return Item';
FunnelInputTypeLabels[FunnelInputTypeEnum.RadioSelect] = 'Choice - Radio for Desktop, Select for Mobile';
FunnelInputTypeLabels[FunnelInputTypeEnum.Hidden] = 'Hidden';
FunnelInputTypeLabels[FunnelInputTypeEnum.Billing] = 'Billing';
FunnelInputTypeLabels[FunnelInputTypeEnum.EnterProductFunnel] = 'Enter Product Path';
FunnelInputTypeLabels[FunnelInputTypeEnum.PaymentInfo] = 'Update Payment Info';
FunnelInputTypeLabels[FunnelInputTypeEnum.Link] = 'Choice - Link';
FunnelInputTypeLabels[FunnelInputTypeEnum.EnterTroubleshooter] = 'Enter Troubleshooter';
FunnelInputTypeLabels[FunnelInputTypeEnum.AltChildExit] = 'Alternate Child Exit';
FunnelInputTypeLabels[FunnelInputTypeEnum.PathNotFound] = 'Path Not Found';
FunnelInputTypeLabels[FunnelInputTypeEnum.ContactCallCenter] = 'Contact Call Center';
FunnelInputTypeLabels[FunnelInputTypeEnum.SelectCampaign] = 'Brand Selection';
FunnelInputTypeLabels[FunnelInputTypeEnum.NoActiveCampaigns] = 'No Active Brands';
FunnelInputTypeLabels[FunnelInputTypeEnum.MatchCustomer] = 'Match Customer';
FunnelInputTypeLabels[FunnelInputTypeEnum.CustomerNotFound] = 'Customer Not Found';
FunnelInputTypeLabels[FunnelInputTypeEnum.Checkout] = 'Checkout';
FunnelInputTypeLabels[FunnelInputTypeEnum.CheckoutConfirm] = 'Checkout Confirmation';
FunnelInputTypeLabels[FunnelInputTypeEnum.RebillItem] = 'Rebill Item Selection';
FunnelInputTypeLabels[FunnelInputTypeEnum.RepeatActionHoldoff] = 'Repeat Action Holdoff';
FunnelInputTypeLabels[FunnelInputTypeEnum.ShipmentTracking] = 'Shipment Tracking';
FunnelInputTypeLabels[FunnelInputTypeEnum.ShipmentReturnLabel] = 'Shipment Return Label';
FunnelInputTypeLabels[FunnelInputTypeEnum.ShipmentReturnQRCode] = 'Shipment Return QR Code';

export const FunnelInputSystemTypes = [
  FunnelInputTypeEnum.Item,
  FunnelInputTypeEnum.ActiveItem,
  FunnelInputTypeEnum.Product,
  FunnelInputTypeEnum.FAQs,
  FunnelInputTypeEnum.BillingAddress,
  FunnelInputTypeEnum.ShippingAddress,
  FunnelInputTypeEnum.ItemStatus,
  FunnelInputTypeEnum.InputActionStatus,
  FunnelInputTypeEnum.Hidden,
  FunnelInputTypeEnum.EnterProductFunnel,
  FunnelInputTypeEnum.PaymentInfo,
  FunnelInputTypeEnum.EnterTroubleshooter,
  FunnelInputTypeEnum.ContactCallCenter,
  FunnelInputTypeEnum.SelectCampaign,
  FunnelInputTypeEnum.MatchCustomer,
  FunnelInputTypeEnum.Checkout,
  FunnelInputTypeEnum.CheckoutConfirm,
  FunnelInputTypeEnum.RebillItem,
  FunnelInputTypeEnum.RepeatActionHoldoff,
  FunnelInputTypeEnum.ShipmentTracking
];

//system input types that allow child inputs
export let FunnelSystemInputChildTypes = {};
FunnelSystemInputChildTypes[FunnelInputTypeEnum.Item] = [FunnelInputTypeEnum.NoActiveItems];
FunnelSystemInputChildTypes[FunnelInputTypeEnum.ActiveItem] = [FunnelInputTypeEnum.NoActiveItems];
FunnelSystemInputChildTypes[FunnelInputTypeEnum.ContactCallCenter] = [FunnelInputTypeEnum.NoActiveItems];
FunnelSystemInputChildTypes[FunnelInputTypeEnum.RepeatActionHoldoff] = [FunnelInputTypeEnum.NoActiveItems];
FunnelSystemInputChildTypes[FunnelInputTypeEnum.SelectCampaign] = [FunnelInputTypeEnum.NoActiveCampaigns];
FunnelSystemInputChildTypes[FunnelInputTypeEnum.MatchCustomer] = [FunnelInputTypeEnum.CustomerNotFound];
FunnelSystemInputChildTypes[FunnelInputTypeEnum.ItemStatus] = [
  FunnelInputTypeEnum.ChangeQuantity,
  FunnelInputTypeEnum.ChangeInterval,
  FunnelInputTypeEnum.Cancel,
  FunnelInputTypeEnum.ChangeProduct,
  FunnelInputTypeEnum.Return,
  FunnelInputTypeEnum.Billing,
  FunnelInputTypeEnum.ShipmentReturnLabel,
  FunnelInputTypeEnum.ShipmentReturnQRCode,
];
FunnelSystemInputChildTypes[FunnelInputTypeEnum.EnterProductFunnel] = [
  FunnelInputTypeEnum.AltChildExit,
  FunnelInputTypeEnum.PathNotFound
];
FunnelSystemInputChildTypes[FunnelInputTypeEnum.EnterTroubleshooter] = [
  FunnelInputTypeEnum.AltChildExit,
  FunnelInputTypeEnum.PathNotFound
];
FunnelSystemInputChildTypes[FunnelInputTypeEnum.InputActionStatus] = [
  FunnelInputTypeEnum.ShipmentReturnLabel,
  FunnelInputTypeEnum.ShipmentReturnQRCode,
];

export function getFunnelItemTypeLabel(stepType: FunnelInputTypeEnum, inputType: FunnelInputTypeEnum,
                                       currentLabel?: string) {
  const defaultSystemLabel = currentLabel || 'Continue';
  let label = currentLabel || '';

  if (FunnelInputSystemTypes.indexOf(stepType) !== -1) {
    switch (stepType)
    {
      case FunnelInputTypeEnum.BillingAddress:
      case FunnelInputTypeEnum.ShippingAddress:
      case FunnelInputTypeEnum.PaymentInfo:
        if (inputType === stepType) {
          label = currentLabel || 'Save';
        }
        break;

      case FunnelInputTypeEnum.ActiveItem:
      case FunnelInputTypeEnum.Item:
      case FunnelInputTypeEnum.RebillItem:
        label = (inputType === stepType) ? defaultSystemLabel : 'No Items Found';
        break;

      case FunnelInputTypeEnum.ContactCallCenter:
        label = (inputType === stepType) ? defaultSystemLabel : 'Call Center Closed';
        break;

      case FunnelInputTypeEnum.RepeatActionHoldoff:
        label = (inputType === stepType) ? 'Repeating Action' : 'No Repeating Action';
        break;

      case FunnelInputTypeEnum.SelectCampaign:
        label = (inputType === stepType) ? defaultSystemLabel : 'No Brands Found';
        break;

      case FunnelInputTypeEnum.MatchCustomer:
        label = (inputType === stepType) ? defaultSystemLabel : 'No Customer Found';
        break;

      case FunnelInputTypeEnum.Checkout:
        if (inputType === stepType) {
          label = currentLabel || 'Process Order';
        }
        break;

      case FunnelInputTypeEnum.EnterTroubleshooter:
        switch (inputType) {
          case FunnelInputTypeEnum.EnterTroubleshooter:
            label = 'Troubleshooter Passed';
            break;

          case FunnelInputTypeEnum.AltChildExit:
            label = 'Troubleshooter Failed';
            break;

          case FunnelInputTypeEnum.PathNotFound:
            label = 'No Troubleshooter Found';
            break;
        }
        break;

      case FunnelInputTypeEnum.EnterProductFunnel:
        switch (inputType) {
          case FunnelInputTypeEnum.EnterProductFunnel:
            label = 'Path Exit';
            break;

          case FunnelInputTypeEnum.AltChildExit:
            label = 'Alternate Path Exit';
            break;

          case FunnelInputTypeEnum.PathNotFound:
            label = 'No Path Found';
            break;
        }
        break;

      case FunnelInputTypeEnum.InputActionStatus:
        switch (inputType) {
          case FunnelInputTypeEnum.ShipmentReturnLabel:
          case FunnelInputTypeEnum.ShipmentReturnQRCode:
            if (!label) {
              label = FunnelInputTypeLabels[inputType];
            }
            break;

          default:
            label = (inputType === stepType) ? defaultSystemLabel : FunnelInputTypeLabels[inputType];
            break
        }
        break;

      default:
        label = (inputType === stepType) ? defaultSystemLabel : FunnelInputTypeLabels[inputType];
        break;
    }
  }

  return label;
}

export enum FunnelItemStatus {
  InActive = -1,
  SubscriptionActive = 0,
  SubscriptionInTrial = 1,
  ShippingHold = 2,
  ShippingProcessing = 3,
  OneTimePurchaseShipped = 4,
  OneTimePurchaseInTrial = 5,
  SubscriptionHold = 6,
  InTrialHold = 7,
  RebillHold = 8,
  RebillActive = 9,
  SubscriptionStopped = 10,
  RecycleFailed = 11,
  InTrialRebillHold = 12,
  RMAPending = 13,
}

export const AvailableFunnelItemStatuses = [
  {id: FunnelItemStatus.SubscriptionActive, text: 'Subscription Active'},
  {id: FunnelItemStatus.SubscriptionInTrial, text: 'Subscription In Trial'},
  {id: FunnelItemStatus.OneTimePurchaseShipped, text: 'One-Time Purchase Shipped'},
  {id: FunnelItemStatus.OneTimePurchaseInTrial, text: 'One-Time Purchase In Trial'},
  {id: FunnelItemStatus.ShippingProcessing, text: 'Shipping Processing'},
  {id: FunnelItemStatus.ShippingHold, text: 'Shipping Hold'},
  {id: FunnelItemStatus.SubscriptionHold, text: 'Subscription Hold'},
  {id: FunnelItemStatus.InTrialHold, text: 'In Trial Hold'},
  {id: FunnelItemStatus.InActive, text: 'Inactive'},
  {id: FunnelItemStatus.RebillHold, text: 'Rebill Hold'},
  {id: FunnelItemStatus.RebillActive, text: 'Rebill Active'},
  {id: FunnelItemStatus.SubscriptionStopped, text: 'Subscription Stopped'},
  {id: FunnelItemStatus.RecycleFailed, text: 'Recycle Failed'},
  {id: FunnelItemStatus.InTrialRebillHold, text: 'In Trial Rebill Hold'},
  {id: FunnelItemStatus.RMAPending, text: 'Return Pending'},
];

export const AvailableActionHoldoffs = [
  {id: SessionActionResult.Downsell, text: 'Refund'},
  {id: SessionActionResult.ReturnExtension, text: 'Return Extension'},
  {id: SessionActionResult.Revision, text: 'Revision'},
  {id: SessionActionResult.SubscriptionAdjust, text: 'Subscription Adjustment'},
  {id: SessionActionResult.SubscriptionDownsell, text: 'Subscription Discount'},
  {id: SessionActionResult.SubscriptionPause, text: 'Subscription Pause'},
  {id: SessionActionResult.TrialExtension, text: 'Trial Extension'},
  {id: SessionActionResult.Upsell, text: 'Upsell'},
];

export enum FunnelFulfillmentType {
  Shippable = 1,
  NotShippable = 2
}

interface BaseFunnelInput extends BaseModel {
  label: string;
  help: string;
  next_step: string | number;
  matched_step_key: string;
  type: FunnelInputTypeEnum;
  category: StepCategory;
  product_funnels: string[] | number[];
  step: string | number;
  builder_id?: string | number;
  hide_if_invalid: boolean;
  is_alt_child_exit: boolean;
  autopick_next_step: string | number;
}

export interface FunnelInput extends BaseFunnelInput {
  icon: IconFile;
  values: string[];
  actions: Action[];
  classes: string[];
}

export interface FunnelStepGroup extends BaseModel {
  name: string;
  classes: string[];
  lifeline: string | number;
  lifeline_enabled: boolean;
  error_lifeline: string | number;
  exit_lifeline: string | number;
}

interface BaseFunnelStep extends BaseModel {
  name: string;
  slug: string;
  label: string;
  help: string;
  next_step: string | number; // only used as a default when creating system steps
  voice_prompt: string;
  voice_file: AudioFile;
  sms_prompt: string;
  num_digits: number;
  forward_to_call_center: boolean;
  call_center_phone: string;
  allow_undo: boolean;
  show_step_link: boolean;
  type: FunnelInputTypeEnum;
  category: StepCategory;
  faq_topic: string | number | null;
  banner: string;
  subtitle: string;
  content: string;
  style: string;
  lifeline: string | number;
  lifeline_enabled: boolean;
  group: string | number;
  contact_type: ContactType;
  enhanced_mode: boolean;
  enhanced_content: string;
  call_center_sip_uri: string;
  call_center_sip_secure: boolean;
  call_center_sip_refer: boolean;
  call_center_sip_username: string;
  call_center_sip_password: string;
  call_center_sip_region: SipRegion;
  fail_if_any_input_invalid: boolean;
  invalid_message: string;
  error_lifeline: string | number;
  exit_lifeline: string | number;
  hide_status: boolean;
  enhanced_content_components?: string;
  mobile_icon_button_type?: number;
  use_bootstrap_spacing?: boolean;
}

export enum FunnelStepMobileIconButtonType {
  TILE = 1,
  BOX = 2,
}

export let FunnelStepMobileIconButtonTypeLabels = {};
FunnelStepMobileIconButtonTypeLabels[FunnelStepMobileIconButtonType.TILE] = 'Tile - Buttons';
FunnelStepMobileIconButtonTypeLabels[FunnelStepMobileIconButtonType.BOX] = 'Box - Buttons';


export function getFunnelStepMobileIconButtonTypeOptions() {
  let items = [];

  Object.keys(FunnelStepMobileIconButtonTypeLabels).forEach(key => {
    items.push({value: key, text: FunnelStepMobileIconButtonTypeLabels[key]})
  });

  return items;
}


export interface FunnelStep extends BaseFunnelStep {
  funnel: string | number;
  is_first_step: boolean;
  inputs: FunnelInput[];
  item_statuses: FunnelItemStatus[];
  campaign_products: string[] | number[];
  classes: string[];
  is_popup: boolean;
  action_holdoffs: SessionActionResult[];
}

export enum FunnelType {
  Product = 'ProductFunnel',
  Visual = 'VisualFunnel',
  Voice = 'VoiceFunnel',
  SMS = 'SMSFunnel',
  Troubleshooter = 'Troubleshooter',
  Lifeline = 'Lifeline',
  Survey = 'Survey',
  Hybrid = 'HybridCampaignFunnel'
}

export const FunnelTypeLabels = {
  ProductFunnel: 'Product',
  VisualFunnel: 'Visual',
  VoiceFunnel: 'Voice',
  SMSFunnel: 'SMS',
  Troubleshooter: 'Troubleshooter',
  Lifeline: 'Lifeline',
  Survey: 'Survey',
  HybridCampaignFunnel: 'Hybrid'
};

export enum CancelItemsSelection {
  ShowAll = 1,
  ShowNone = 2,
  UpsellsOnly = 3,
  UpsellsOnlyIfUpsell = 4
}

interface BaseFunnel extends BaseModel {
  resourcetype: FunnelType;
  name: string;
  first_step: string | number;
  is_visual: boolean;
  is_voice: boolean;
  is_sms: boolean;
  is_after_hours: boolean;
  campaign_products: string[] | number[];
  use_product_filter: boolean;
  is_3ds: boolean;
  item_statuses: FunnelItemStatus[];
  voice_type: string;
  matched_verify_msg: string;
  unmatched_verify_msg: string;
  match_msg: string;
  matched_welcome_msg: string;
  unmatched_welcome_msg: string;
  thank_you_msg: string;
  lifeline_timeout: number;
  lifeline_undo_count: number;
  lifeline: string | number;
  error_lifeline: string | number;
  exit_lifeline: string | number;
  exit_survey: string | number;
  auto_select_single_item: boolean;
  is_test: boolean;
  hidden_fees_label: string;
  carrier_type: PhoneCarrierType;
  is_modified: boolean;
  cancel_items_selection: CancelItemsSelection;
  return_items_selection: CancelItemsSelection;
  is_public: boolean;
  is_template: boolean;
  merchant_ids: string[];
  min_billing_cycle: number;
  fulfillment_type: FunnelFulfillmentType;
  session_type: SessionType;
  match_required: boolean;
  states: string[];
  crm_campaigns: string[] | number[];
  use_crm_campaign_filter: boolean;
  cc_types: CardType[];
  aff_ids: string[];
}

export interface Funnel extends BaseFunnel {
  steps: FunnelStep[];
  campaign: string | number;
  campaigns: string[] | number[];
  products: string[] | number[];
  children: BaseFunnel[];
}

export interface FunnelNode extends Funnel{
  children: FunnelNode[];
}

export interface FunnelRelationship {
  funnel_id: string | number;
  parent_id: string | number;
}

export const FunnelVariables = {
  first_name: 'Customer First Name',
  brand_name: 'Brand Name',
  'price|currency': 'Sale Price',
  'shipping_price|currency': 'Sale Shipping Price',
  'total_price|currency': 'Sale Total Price (price + shipping)',
  'original_price|currency': 'Original Price',
  'original_shipping_price|currency': 'Original Shipping Price',
  'discount|currency': 'Discount Amount',
  'discount_price|currency': 'Discounted Sale Price',
  discount_percent: 'Discount Percent from Current Price',
  product_name: 'Product Name',
  next_bill_date: 'Next Bill Date',
  billing_interval_days: 'Billing Interval (in days)',
  return_by_date: 'Return By Date',
  rma_number: 'RMA Number',
  offer_terms: 'Offer Terms',
  'restock_fee|currency': 'Restocking Fee',
  'refund|currency': 'Refund Amount',
  product_image: 'Product Default Image',
  product_tagged_image: 'Product Tagged Image',
  upsale_name: 'Upsale Product Name',
  'upsale_price|currency': 'Upsale Product Price',
  'upsale_shipping_price|currency': 'Upsale Product Shipping Price',
  'upsale_total_price|currency': 'Upsale Product Total Price',
  upsale_quantity: 'Upsale Product Quantity',
  upsale_image: 'Upsale Product Image',
  full_name: 'Customer Full Name',
  customer_id: 'Customer ID',
  order_id: 'Order ID',
  email: 'Customer Email',
  'unit_price|currency': 'Unit Price',
  'retail_price|currency': 'Retail Price',
  'retail_unit_price|currency': 'Retail Unit Price',
  'savings|currency': 'Savings',
  'unit_savings|currency': 'Unit Savings',
  campaign_product_id: 'Campaign Product ID',
  return_days: 'Return Days Remaining',
  exchange_days: 'Exchange Days Remaining',
  support_email: 'Support Email',
  support_phone: 'Support Phone Number',
  offer_name: 'Offer Name',
  phone_number: 'Customer Phone Number',
  'order_shipping_price|currency': 'Order Shipping Price',
  total_discount_percent: 'Discount Percent from Original Price',
  order_create_date: 'Order Create Date',
  'rebill_amount|currency': 'Rebill Amount',
  tracking_number: 'Tracking Number',
};

export function getFunnelPathVariables() {
  let items = [];

  Object.keys(FunnelVariables).forEach(key => {
    items.push({value: '{' + key + '}', text: FunnelVariables[key]})
  });

  return items;
}

export interface FunnelHistory extends BaseFunnel {
  created: string;
}

export interface FunnelStepHistory extends BaseFunnelStep {
  funnel: FunnelHistory;
  created: string;
}

export interface FunnelInputHistory extends BaseFunnelInput {

}

export enum FunnelStepSaveType  {
  SaveAndStay = 'saveAndStay',
  Save= 'save',
  Cancel= 'cancel'
}

export function getExitLabel(type: FunnelType) {
  let label = 'Exit path (normal exit)';

  switch (type) {
    case FunnelType.Troubleshooter:
      label = 'Exit troubleshooter (pass)';
      break;

    case FunnelType.Lifeline:
      label = 'Accept lifeline and end session'
      break;
  }

  return label;
}

export function getAlternateExitLabel(type: FunnelType) {
  let label = 'Exit path (alternate exit)';

  switch (type) {
    case FunnelType.Troubleshooter:
      label = 'Exit troubleshooter (fail)';
      break;

    case FunnelType.Lifeline:
      label = 'Close lifeline and continue session'
      break;
  }

  return label;
}

export enum CustomStepCategoryEnum {
  // custom step categories
  Custom = 2000,
  Reasons = 2001,
  OrderOptions = 2002,
  DiscountFutureOrder = 2003,
  RefundOrder = 2004,
  ReturnOrderRMA= 2005,
  UpsellOrder = 2006,
  PauseSub = 2007,
  OfferDetails = 2008,
  ReviseOrder = 2009,
  NeedMoreHelp = 2010,
  AdjustShippingFrequency = 2011,
  ReturnPolicy = 2012,
  RefundWithDiscount = 2013,
  DiscountWithAdjustShippingFrequency = 2014,
  ReActivateSubscription = 2015,
  ReActivateSubscriptionWithDiscount = 2016,
  Survey = 2017,
}

export type StepCategory = FunnelInputTypeEnum | CustomStepCategoryEnum
export let StepCategoryLabels = Object.assign({}, FunnelInputTypeLabels);
StepCategoryLabels[CustomStepCategoryEnum.Custom] = 'Custom';
StepCategoryLabels[CustomStepCategoryEnum.Reasons] = 'Reasons';
StepCategoryLabels[CustomStepCategoryEnum.OrderOptions] = 'Order Options';
StepCategoryLabels[CustomStepCategoryEnum.DiscountFutureOrder] = 'Discount Future Order';
StepCategoryLabels[CustomStepCategoryEnum.RefundOrder] = 'Refund Order';
StepCategoryLabels[CustomStepCategoryEnum.ReturnOrderRMA] = 'RMA Downsell';
StepCategoryLabels[CustomStepCategoryEnum.UpsellOrder] = 'Upsell Order';
StepCategoryLabels[CustomStepCategoryEnum.PauseSub] = 'Pause Sub';
StepCategoryLabels[CustomStepCategoryEnum.OfferDetails] = 'Offer Details/Info';
StepCategoryLabels[CustomStepCategoryEnum.ReviseOrder] = 'Revise Order';
StepCategoryLabels[CustomStepCategoryEnum.NeedMoreHelp] = 'Need More Help';
StepCategoryLabels[CustomStepCategoryEnum.AdjustShippingFrequency] = 'Adjust Shipping Frequency';
StepCategoryLabels[FunnelInputTypeEnum.RepeatActionHoldoff] = 'Tagging Customer For Repeating Action';
StepCategoryLabels[FunnelInputTypeEnum.ActiveItem] = 'Item Selection';
StepCategoryLabels[FunnelInputTypeEnum.Item] = 'Item Selection';
StepCategoryLabels[FunnelInputTypeEnum.Checkout] = 'Marketplace';
StepCategoryLabels[FunnelInputTypeEnum.CheckoutConfirm] = 'Marketplace';
StepCategoryLabels[FunnelInputTypeEnum.Product] = 'Marketplace';
StepCategoryLabels[FunnelInputTypeEnum.ContactCallCenter] = 'Life Line Step';
StepCategoryLabels[FunnelInputTypeEnum.EnterProductFunnel] = 'Sub Path';
StepCategoryLabels[FunnelInputTypeEnum.EnterTroubleshooter] = 'Troubleshooter';
StepCategoryLabels[FunnelInputTypeEnum.FAQs] = 'FAQs';
StepCategoryLabels[FunnelInputTypeEnum.ItemStatus] = 'Order Status';
StepCategoryLabels[FunnelInputTypeEnum.BillingAddress] = 'Update Info';
StepCategoryLabels[FunnelInputTypeEnum.ShippingAddress] = 'Update Info';
StepCategoryLabels[FunnelInputTypeEnum.PaymentInfo] = 'Update Info';
StepCategoryLabels[FunnelInputTypeEnum.MatchCustomer] = 'Match and Verify';
StepCategoryLabels[FunnelInputTypeEnum.InputActionStatus] = 'Response Step';
StepCategoryLabels[CustomStepCategoryEnum.ReturnPolicy] = 'Policy/Instruction';
StepCategoryLabels[CustomStepCategoryEnum.RefundWithDiscount] = 'Refund with Discount';
StepCategoryLabels[CustomStepCategoryEnum.DiscountWithAdjustShippingFrequency] = 'Discount with Adjust Shipping Frequency';
StepCategoryLabels[CustomStepCategoryEnum.ReActivateSubscription] = 'Reactivate Subscription';
StepCategoryLabels[CustomStepCategoryEnum.ReActivateSubscriptionWithDiscount] = 'Reactivate Subscription with Discount';
StepCategoryLabels[CustomStepCategoryEnum.Survey] = 'Survey';


