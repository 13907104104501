import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { AlertService, FunnelService } from "../../_services";
import { CrudSavePopupComponent } from "../../_directives";
import { FormBuilder, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { StepPopupActions } from "../../_models";
import { offerData } from "../step-data";

@Component({
  moduleId: module.id.toString(),
  selector: "offer-popup",
  templateUrl: "./popup.component.html",
  styleUrls: ["./../steps.component.scss"],
})
export class OfferPopupComponent
  extends CrudSavePopupComponent
  implements OnInit
{
  ngModelOptions = { standalone: true };
  StepPopupActions = StepPopupActions;
  title = 'offer'

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected formBuilder: FormBuilder,
    protected funnelService: FunnelService,
    protected alertService: AlertService
  ) {
    super(router, location, route, funnelService, alertService);
    this.objectName = "Offer";
    this.isNew = false;
    this.isPartial = true;
  }

  ngOnInit() {
    this.setForm(
      this.formBuilder.group({
        name: [null, Validators.required],
        offer_intent: [null, Validators.required],
      })
    );

    super.ngOnInit();
  }

  onSubmit() {
    this.submitted = true;

    if (
      this.form.valid ||
      this.inputData.popupType === StepPopupActions.Delete
    ) {
      this.loading = true;
      const { popupType, offer_id, ...formData } = this.getFormData();

      if (popupType === StepPopupActions.Create) {
        this.funnelService.create(formData).subscribe(
          (data) => {
            if (this.showSuccessMessage) {
              this.alertService.success(
                `${this.objectName} Created Successfully.`,
                true
              );
            }

            this.canDeactivateService.setFormDirty(false);
            this.onSaveComplete(data);
          },
          (error) => {
            this.handleSubmitError(error);
            this.loading = false;
          }
        );
      } else {
        let operation: Observable<Object>;

        if (popupType === StepPopupActions.Update) {
          operation = this.funnelService.update(offer_id, formData);
        } else if (popupType === StepPopupActions.Duplicate) {
          operation = this.funnelService.clone(offer_id);
        } else if (popupType === StepPopupActions.Delete) {
          operation = this.funnelService.delete(offer_id);
        }

        const operationTitle =
          popupType === StepPopupActions.Update
            ? "Updated"
            : popupType === StepPopupActions.Duplicate
            ? "Duplicated"
            : popupType === StepPopupActions.Delete
            ? "Deleted"
            : "";

        operation.subscribe(
          (data) => {
            if (this.showSuccessMessage) {
              this.alertService.success(
                `${this.objectName} ${operationTitle} Successfully.`,
                true
              );
            }

            this.canDeactivateService.setFormDirty(false);
            this.onSaveComplete(data);
          },
          (error) => {
            this.handleSubmitError(error);
            this.loading = false;
          }
        );
      }
    }
  }

  protected getFormData() {
    const data = {
      ...offerData,
      ...this.form.value,
      offer_id: this.inputData.offer_id,
      offer_type: this.inputData.offer_type,
      offer_intent: this.form.value.offer_intent,
      popupType: this.inputData.popupType,
    };
    return data;
  }

  popupTypeTextMap: Partial<Record<StepPopupActions, string>> = {
    [StepPopupActions.Delete || StepPopupActions.DeleteFolder]: 'Delete',
    [StepPopupActions.Duplicate]: 'Duplicate',
    [StepPopupActions.Update]: 'Rename'
  };
}
