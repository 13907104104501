import {
  CustomStepCategoryEnum,
  StepDiscountSettingTypes,
  StepDiscountTypes,
  StepDurationUnits,
  StepElementTypes,
  StepTimeOptionTypes,
} from "../../../app/_models";

const generateTextComponent = (
  labelTitle: string,
  labelSubtitle: string,
  contentTitle: string,
  contentSubtitle: string,
  banner = false
) => ({
  type: StepElementTypes.Textual,
  layout: {
    banner,
    label: {
      title: labelTitle,
      subtitle: labelSubtitle,
    },
    content: {
      title: contentTitle,
      subtitle: contentSubtitle,
    },
  },
});

const generateMediaComponent = (url: string = "", enabled = true) => ({
  type: StepElementTypes.Media,
  layout: {
    title: "Add Media",
    subtitle: "JPEG, PNG, GIF and video links are supported.",
    placeholderImage: url,
    enabled,
  },
});

const generateButtonLabelsComponent = (
  confirmTitle: string,
  backTitle: string
) => ({
  type: StepElementTypes.Buttons,
  layout: {
    title: "Custom button labels",
    subtitle:
      "Add your own custom button labels to make your flow even more intuitive to use.",
    confirmTitle,
    backTitle,
  },
});

const generateSurveyResponses = () => ({
  type: StepElementTypes.SurveyOptions,
  layout: {
    title: "Survey response options",
    items: [
      { reason: "Cost Factor", category: "Price" },
      { reason: "Its Not Working", category: "Technical issue" },
      { reason: "Too Much Product", category: "Product issue" },
      { reason: "Order Wrong Item", category: "Unwanted" },
    ],
  },
});

const generateResponseScreenComponent = () => ({
  type: StepElementTypes.ResponseScreen,
  layout: {
    title: "Response screen",
    subtitle: "Add a response screen to make your flow.........lorem ipsum",
    responseTitle: {
      title: "Response title",
      subtitle: "Tell your customer Lorem ipsum dollar amet........",
    },
    response: {
      title: "Response",
      subtitle: "Let your customer know that Lorem ipsum dollar amet......",
    },
  },
});

export const stepLayout = {
  /* SURVEYS */
  [CustomStepCategoryEnum.CancelOrderSurvey]: [
    generateTextComponent(
      "Survey title",
      "",
      "Survey introduction",
      "A short introduction to be displayed to users wishing to leave."
    ),
    generateMediaComponent("/assets/stepAssets/image-placeholder.svg", false),
    generateSurveyResponses(),
    generateButtonLabelsComponent("Next Button", "Back Button"),
  ],
  [CustomStepCategoryEnum.CancelSubSurvey]: [
    generateTextComponent(
      "Survey title",
      "",
      "Survey introduction",
      "A short introduction to be displayed to users wishing to leave."
    ),
    generateMediaComponent("/assets/stepAssets/image-placeholder.svg", false),
    generateSurveyResponses(),
    generateButtonLabelsComponent("Next Button", "Back Button"),
  ],
  [CustomStepCategoryEnum.CancelTrialSurvey]: [
    generateTextComponent(
      "Survey title",
      "",
      "Survey introduction",
      "A short introduction to be displayed to users wishing to leave."
    ),
    generateMediaComponent("/assets/stepAssets/image-placeholder.svg", false),
    generateSurveyResponses(),
    generateButtonLabelsComponent("Next Button", "Back Button"),
  ],
  [CustomStepCategoryEnum.ReturnSurvey]: [
    generateTextComponent(
      "Survey title",
      "",
      "Survey introduction",
      "A short introduction to be displayed to users wishing to leave."
    ),
    generateMediaComponent("/assets/stepAssets/image-placeholder.svg", false),
    generateSurveyResponses(),
    generateButtonLabelsComponent("Next Button", "Back Button"),
  ],
  [CustomStepCategoryEnum.Survey]: [
    generateTextComponent(
      "Survey title",
      "",
      "Survey introduction",
      "A short introduction to be displayed to users wishing to leave."
    ),
    generateMediaComponent("/assets/stepAssets/image-placeholder.svg", false),
    generateSurveyResponses(),
    generateButtonLabelsComponent("Next Button", "Back Button"),
  ],

  /* DOWNSELLS */
  [CustomStepCategoryEnum.PercentageDownsell]: [
    generateTextComponent(
      "Downsell title",
      "Tell your customer Lorem ipsum dollar amet........",
      "Offer introduction",
      "Let your customer know that your offer is worth staying for. They mean a lot to you.",
      true
    ),
    {
      type: StepElementTypes.Discount,
      layout: {
        title: "Offer a discount",
        subtitle: "Example: 10%.",
        inputTitle: "Discount percentage",
        type: StepDiscountTypes.Percentage,
      },
    },
    generateMediaComponent("/assets/stepAssets/discount-image.png"),
    generateButtonLabelsComponent("Accept offer button", "Reject offer button"),
    generateResponseScreenComponent(),
  ],
  [CustomStepCategoryEnum.FixedAmountDownsell]: [
    generateTextComponent(
      "Downsell title",
      "Tell your customer Lorem ipsum dollar amet........",
      "Offer introduction",
      "Let your customer know that your offer is worth staying for. They mean a lot to you.",
      true
    ),
    {
      type: StepElementTypes.Discount,
      layout: {
        title: "Offer a discount",
        subtitle: "Example: $10.",
        inputTitle: "Discount Amount",
        type: StepDiscountTypes.Amount,
      },
    },
    generateMediaComponent("/assets/stepAssets/discount-image.png"),
    generateButtonLabelsComponent("Accept offer button", "Reject offer button"),
    generateResponseScreenComponent(),
  ],
  [CustomStepCategoryEnum.PauseSub]: [
    generateTextComponent(
      "Downsell title",
      "Tell your customer Lorem ipsum dollar amet........",
      "Offer introduction",
      "Let your customer know that your offer is worth staying for. They mean a lot to you.",
      true
    ),
    generateMediaComponent("/assets/stepAssets/pause-image.png"),
    {
      type: StepElementTypes.TimeDelay,
      layout: {
        title: "Offer a subscription pause",
        subtitle: "Example: Pause for 1 month.",
      },
    },
    generateButtonLabelsComponent("Accept offer button", "Reject offer button"),
    generateResponseScreenComponent(),
  ],
  [CustomStepCategoryEnum.RefundWithDiscount]: [
    generateTextComponent(
      "Downsell title",
      "Tell your customer Lorem ipsum dollar amet........",
      "Offer introduction",
      "Let your customer know that your offer is worth staying for. They mean a lot to you.",
      true
    ),
    generateMediaComponent("/assets/stepAssets/discount-image.png"),
    {
      type: StepElementTypes.DiscountSettings,
      layout: {
        title: "Discount Settings",
        subtitle: "Lorem ipsum",
        type: StepDiscountSettingTypes.DiscountRefund,
      },
    },
    generateButtonLabelsComponent("Accept offer button", "Reject offer button"),
    generateResponseScreenComponent(),
  ],
  [CustomStepCategoryEnum.DiscountFutureOrder]: [
    generateTextComponent(
      "Downsell title",
      "Tell your customer Lorem ipsum dollar amet........",
      "Offer introduction",
      "Let your customer know that your offer is worth staying for. They mean a lot to you.",
      true
    ),
    generateMediaComponent("/assets/stepAssets/discount-image.png"),
    {
      type: StepElementTypes.DiscountSettings,
      layout: {
        title: "Discount Settings",
        subtitle: "Lorem ipsum",
        type: StepDiscountSettingTypes.FutureOrder,
      },
    },
    generateButtonLabelsComponent("Accept offer button", "Reject offer button"),
    generateResponseScreenComponent(),
  ],
  [CustomStepCategoryEnum.PauseSurvey]: [
    generateTextComponent(
      "Downsell title",
      "",
      "Offer introduction",
      "A short introduction to be displayed to users wishing to pause subscription.",
      true
    ),
    generateMediaComponent("/assets/stepAssets/pause-image.png"),
    {
      type: StepElementTypes.TimeOptions,
      layout: {
        title: "Pause response options",
        subtitle:
          "Control the pause duration by asking users to select from available pause interval options.",
        type: StepTimeOptionTypes.PauseOptions,
        items: [
          { value: 10, unit: StepDurationUnits.Days },
          { value: 15, unit: StepDurationUnits.Days },
          { value: 30, unit: StepDurationUnits.Days },
          { value: 60, unit: StepDurationUnits.Days },
        ],
      },
    },
    generateButtonLabelsComponent("Confirm button", "Back button"),
    generateResponseScreenComponent(),
  ],
  [CustomStepCategoryEnum.AdjustShippingFrequency]: [
    generateTextComponent(
      "Downsell title",
      "",
      "Offer introduction",
      "A short introduction to be displayed to users wishing to pause subscription.",
      true
    ),
    generateMediaComponent("/assets/stepAssets/image-placeholder.svg", false),
    {
      type: StepElementTypes.TimeOptions,
      layout: {
        title: "Frequency response options",
        subtitle:
          "Control the shipping frequency duration by asking users to select from available frequency interval options.",
        type: StepTimeOptionTypes.ShippingFreq,
        items: [
          { value: 2, unit: StepDurationUnits.Weeks },
          { value: 4, unit: StepDurationUnits.Weeks },
          { value: 6, unit: StepDurationUnits.Weeks },
          { value: 8, unit: StepDurationUnits.Weeks },
        ],
      },
    },
    generateButtonLabelsComponent("Confirm button", "Back button"),
    generateResponseScreenComponent(),
  ],
  [CustomStepCategoryEnum.ExtendTrial]: [
    generateTextComponent(
      "Downsell title",
      "Tell your customer Lorem ipsum dollar amet........",
      "Offer introduction",
      "Tell your customer the offer available to them. Use our offer text or create your own.",
      true
    ),
    generateMediaComponent("/assets/stepAssets/free-trial.png"),
    {
      type: StepElementTypes.TimeDelay,
      layout: {
        title: "Offer a trial extension",
        subtitle: "Example: Extend your trial by 7 days",
      },
    },
    generateButtonLabelsComponent("Accept offer button", "Reject offer button"),
    generateResponseScreenComponent(),
  ],
  [CustomStepCategoryEnum.Testimonial]: [
    generateTextComponent(
      "Downsell title",
      "Lorem ipsum dollar amet........",
      "Your customer testimonial",
      "A short customer testimonial for the users to show the benefits ......"
    ),
    generateMediaComponent("/assets/stepAssets/testimonial.png"),
    generateButtonLabelsComponent("Continue button", "Cancel button"),
    generateResponseScreenComponent(),
  ],
  [CustomStepCategoryEnum.LossAversion]: [
    generateTextComponent(
      "Downsell title",
      "Lorem ipsum dollar amet........",
      "Downsell introduction",
      "A short introduction to be displayed to users wishing to pause subscription.",
      true
    ),
    generateMediaComponent("/assets/stepAssets/image-placeholder.svg"),
    generateButtonLabelsComponent("Accept offer button", "Reject offer button"),
    generateResponseScreenComponent(),
  ],
  [CustomStepCategoryEnum.OfferDetails]: [
    generateTextComponent(
      "Offer title",
      "Tell your customer Lorem ipsum dollar amet........",
      "Offer introduction",
      "A short introduction to be displayed to users wishing to leave.",
      true
    ),
    generateMediaComponent("/assets/stepAssets/image-placeholder.svg"),
    generateButtonLabelsComponent("Accept offer button", "Reject offer button"),
    generateResponseScreenComponent(),
  ],
  [CustomStepCategoryEnum.ScheduleSupport]: [
    generateTextComponent(
      "Support title",
      "",
      "Write step introduction",
      "A short introduction to be displayed to users",
      true
    ),
    generateMediaComponent("/assets/stepAssets/image-placeholder.svg"),
    generateButtonLabelsComponent("Accept offer button", "Reject offer button"),
    generateResponseScreenComponent(),
  ],
  [CustomStepCategoryEnum.SwapProduct]: [
    generateTextComponent(
      "Offer title",
      "Tell your customer the offer available to them. Use our offer text or create your own.",
      "Offer introduction",
      "Let your customer know that your offer is worth staying for. They mean a lot to you.",
      true
    ),
    generateMediaComponent("/assets/stepAssets/discount-image.png"),
    { type: StepElementTypes.Exchangeables },
    generateButtonLabelsComponent("Accept offer button", "Reject offer button"),
    generateResponseScreenComponent(),
  ],
  [CustomStepCategoryEnum.ReturnOrderRMA]: [
    generateTextComponent(
      "Offer title",
      "Tell your customer the offer available to them. Use our offer text or create your own.",
      "Offer introduction",
      "Let your customer know that your offer is worth staying for. They mean a lot to you.",
      true
    ),
    generateMediaComponent("/assets/stepAssets/discount-image.png"),
    {
      type: StepElementTypes.DiscountSettings,
      layout: {
        title: "Discount Settings",
        subtitle: "Lorem ipsum",
      },
    },
    generateButtonLabelsComponent("Accept offer button", "Reject offer button"),
    generateResponseScreenComponent(),
  ],

  /* UPSELLS */
  [CustomStepCategoryEnum.UpsellOrder]: [
    generateTextComponent(
      "Upsell title",
      "Tell your customer Lorem ipsum dollar amet........",
      "Offer introduction",
      "Let your customer know that your offer is worth staying for. They mean a lot to you.",
      true
    ),
    generateMediaComponent("/assets/stepAssets/discount-image.png"),
    {
      type: StepElementTypes.DiscountSettings,
      layout: {
        title: "Discount Settings",
        subtitle: "Lorem ipsum",
      },
    },
    generateButtonLabelsComponent("Next Button", "Back Button"),
    generateResponseScreenComponent(),
  ],
  [CustomStepCategoryEnum.ReActivateSubscriptionWithDiscount]: [
    generateTextComponent(
      "Upsell title",
      "Tell your customer Lorem ipsum dollar amet........",
      "Offer introduction",
      "Let your customer know that your offer is worth staying for. They mean a lot to you.",
      true
    ),
    generateMediaComponent("/assets/stepAssets/discount-image.png"),
    {
      type: StepElementTypes.DiscountSettings,
      layout: {
        title: "Discount Settings",
        subtitle: "Lorem ipsum",
      },
    },
    generateButtonLabelsComponent("Next Button", "Back Button"),
    generateResponseScreenComponent(),
  ],
};
