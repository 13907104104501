import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CrudService} from "./crud.service";
import {ClipboardService} from "./clipboard.service";
import {plainText} from "../_helpers/functions"; // defined this way to avoid circular dependency
import {FunnelStep, CampaignProduct} from "../_models";
import {Observable} from "rxjs";
import {map} from 'rxjs/operators'

@Injectable()
export class FunnelStepService extends CrudService {
  constructor(
    protected http: HttpClient,
    protected clipboardService: ClipboardService
  ) {
    super(http, 'funnel');
  }

  setFunnelId(id: string | number) {
    this.route = 'funnel/' + id.toString() + '/steps';
  }

  copy(id: string | number) {
    this.http.post(this.route + '/' + id.toString() + '/copy/', {}).subscribe(
      data => {
        this.clipboardService.copy('funnel_step', data);
      }
    );
  }

  canPaste() : boolean {
    return this.clipboardService.canPaste('funnel_step');
  }

  paste() {
    return this.http.post(this.route + '/paste/', this.clipboardService.paste('funnel_step'));
  }

  copyProducts(products: CampaignProduct[]) {
    this.clipboardService.copy('campaign_products', products);
  }

  canPasteProducts() : boolean {
    return this.clipboardService.canPaste('campaign_products');
  }

  pasteProducts() {
    return this.clipboardService.paste('campaign_products');
  }

  listAll(queryParams?: any): Observable<any> {
    let options = {};

    if (queryParams) {
      options['params'] = new HttpParams({fromObject: queryParams});
    }

    return this.http.get<any>('funnel_steps/', options);
  }

  getGlobalJumpSteps(includeAll: boolean, queryParams?: any): Observable<FunnelStep[]> {
    return this.listAll(queryParams).pipe(map((steps: FunnelStep[]) => {
      let stepList: FunnelStep[] = [];
      let jumpSteps = {};

      // build a list of steps that we can jump to from an internal link
      steps.forEach((step: FunnelStep) => {
        if ((includeAll || step.show_step_link) && !(step.slug in jumpSteps)) {
          jumpSteps[step.slug] = step;
        }
      });

      Object.values(jumpSteps).forEach((step: FunnelStep) => {
        stepList.push(step);
      });

      // sort the list alphabetically
      stepList.sort((a, b): number => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });

      return stepList;
    }));
  }

  getGlobalJumpLinks(relative: boolean, queryParams?: any): Observable<any> {
    const prefix = relative ? '/step/' : '{campaign_url}start/';

    return this.getGlobalJumpSteps(false, queryParams).pipe(map((steps: FunnelStep[]) => {
      let stepList = relative ? [] : [{title: 'Main Menu', value: '{campaign_url}'}];

      steps.forEach((step: FunnelStep) => {
        stepList.push({title: step.name, value: prefix + encodeURIComponent(step.slug)});
      });

      return stepList;
    }));
  }

  getGlobalJumpVariables(queryParams?: any): Observable<any> {
    const prefix = '{campaign_url}start/';

    return this.getGlobalJumpSteps(false, queryParams).pipe(map((steps: FunnelStep[]) => {
      let stepList = [{text: 'Brand URL (Main Menu)', value: '{campaign_url}'}];

      steps.forEach((step: FunnelStep) => {
        stepList.push({text: 'Brand URL: ' + step.name, value: prefix + encodeURIComponent(step.slug)});
      });

      return stepList;
    }));
  }

  static getStepName(step: FunnelStep) {
    if (step.name && step.name.length) {
      return step.name;
    }

    return plainText(step.label || step.voice_prompt || step.sms_prompt ||
      ((step.forward_to_call_center) ? 'Forward to call center' : 'Step ' + step.id.toString()));
  }

}
