import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, UserService} from '../_services';
import {Form} from '../_forms';
import {Funnel, FunnelType, User} from "../_models";

@Component({
  moduleId: module.id.toString(),
  selector: 'funnel-tree-actions',
  templateUrl: './funnel-tree-actions.component.html'
})
export class FunnelTreeActionsComponent extends Form implements OnInit{
  user: User;

  @Input('isTemplate') isTemplate = false;
  @Input('isPublishing') isPublishing = false;
  @Input('funnel') funnel: Funnel;
  @Output('publish') onPublish: EventEmitter<any> = new EventEmitter<any>();
  @Output('preview') onPreview: EventEmitter<any> = new EventEmitter<any>();
  @Output('copy') onCopy: EventEmitter<any> = new EventEmitter<any>();
  @Output('copyToClipboard') onCopyToClipboard: EventEmitter<any> = new EventEmitter<any>();
  @Output('clone') onClone: EventEmitter<any> = new EventEmitter<any>();
  @Output('saveTemplate') onSaveAsTemplate: EventEmitter<any> = new EventEmitter<any>();
  @Output('delete') onDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output('deleteUnassigned') onDeleteUnassigned: EventEmitter<any> = new EventEmitter<any>();

  constructor(protected router: Router,
              protected location: Location,
              protected alertService: AlertService,
              private userService: UserService
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    this.userService.getCurrent()
      .subscribe(
        (user: User) => {
          this.user = user;
        },
        error => {
          this.handleError(error);
        }
      );
  }

  get isProductFunnel() : boolean {
    return (this.funnel.resourcetype === FunnelType.Product);
  }

  get editable() : boolean {
    return !this.isTemplate || this.user.is_staff;
  }

}
