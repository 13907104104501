import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, AnonymousGuard } from './_guards';
import { LoginComponent } from './login';
import { RegisterComponent, RegisterVerifyComponent } from './register';
import { VisitorsComponent } from './visitors';
import { SubscriptionComponent, SubscriptionEditComponent } from "./subscription";
import { PaymentSourceComponent, CreditCardEditComponent, CreditCardNewComponent,
  BankAccountNewComponent, PlaidRedirectComponent } from "./payment-source";
import { AddressComponent, AddressEditComponent, AddressNewComponent } from "./address";
import { PhoneComponent, PhoneSearchComponent, PhoneNewComponent, PhoneEditComponent } from "./phone";
import { CRMComponent, CRMEditComponent, CRMNewComponent } from "./crm";
import {CampaignCategoryComponent, ProductCategoryComponent, WidgetCategoryComponent} from './category';
import {
  CampaignComponent, CampaignNewComponent, CampaignEditComponent, CampaignEmailComponent,
  CampaignPhoneComponent, CampaignVerifyComponent, CampaignFunnelsComponent,
  CampaignStylesComponent, CampaignTestComponent, CampaignProductsComponent, CampaignMarketplaceComponent,
  CampaignCallCenterComponent, CampaignCampaignsComponent
} from "./campaign";
import { ProductInfoSectionComponent, ProductInfoSectionNewComponent,
  ProductInfoSectionEditComponent } from './product';
import { CampaignProductComponent } from './campaign-product';
import { ImageComponent, ImageUploadComponent } from './image';
import { IconComponent } from './icon';
import { VisualFunnelComponent, FunnelNewComponent, FunnelEditComponent, ProductFunnelComponent,
  FunnelComponent} from "./funnel";
import { FunnelStepEditComponent, FunnelStepNewComponent } from './funnel-step';
import { FunnelInputEditComponent, FunnelInputNewComponent } from './funnel-input';
import { FunnelActionEditComponent, FunnelActionNewComponent } from './funnel-action';
import { StyleComponent, StyleNewComponent, StyleEditComponent } from './style';
import { AudioFileComponent, AudioFileUploadComponent } from "./audio-file";
import { EmailTemplateComponent, EmailTemplateNewComponent, EmailTemplateEditComponent } from './email-template';
import { AutoResponderComponent, AutoResponderNewComponent, AutoResponderEditComponent } from './autoresponder';
import { RegisterSuccessComponent } from './register-success';
import {
  PasswordChangeComponent, PasswordResetComponent, PasswordResetSuccessComponent,
  PasswordResetConfirmComponent, ResendActivationEmailComponent
} from './password-reset';
import {
  FaqsComponent,
  FaqTopicNewComponent,
  FaqTopicEditComponent,
  FaqNewComponent,
  FaqEditComponent
} from './faq';
import { TroubleshooterComponent } from "./troubleshooter";
import { SessionDetailComponent } from './session';
import { LifelineComponent } from "./lifeline";
import { SurveyComponent } from "./survey";
import {AccountComponent, AccountEmailBlacklistComponent, AccountSearchKeywordsComponent} from './account';
import { FunnelStepGroupComponent } from './funnel-step-group';
import { CampaignTrackingComponent } from "./campaign-tracking";
import { VoiceFunnelComponent } from "./voice-funnel";
import {
  FaqReportsComponent,
  PathPerformanceReportComponent,
  PerformanceReportComponent,
  RefundReportComponent,
  ReportsComponent,
  ChargebackReportComponent,
  SessionSummaryReportComponent,
  MarketplaceReportComponent,
  RetentionReportComponent, PaymentUpdaterReportComponent
} from './legacy-reports';
import { WidgetComponent } from './widget';
import { SalesPhraseComponent } from "./sales-phrase";
import { CanDeactivateGuard } from "./_guards/can-deactivate.guard";
import { CallCenterActionComponent } from "./call-center-action";
import { HybridFunnelComponent } from './hybrid-funnel';
import { HybridCampaignComponent, HybridCampaignNewComponent } from './hybrid-campaign';
import { EmailLogsContainerComponent, PhoneLogsContainerComponent, SmsLogsContainerComponent } from "./logs";
import { DesignTemplateComponent, DesignTemplateEditFormComponent } from './design-template';
import { PlanComponent, PlanNewComponent, PlanEditComponent } from "./plan";
import { InvoiceItemComponent } from './invoice-item';
import { InvoiceComponent, InvoiceDetailComponent } from './invoice';
import { CRMCampaignComponent } from './crm-campaign';
import { UserComponent, UserInviteAcceptComponent, UserApiKeyComponent, UserProxyKeyComponent } from "./user";
import { ProfilerEnableComponent, ProfilerDisableComponent } from './profiler';
import {PersonalInfoComponent} from "./personal-info";
import {ExchangeableProductsSetComponent, ExchangeableProductsSetNewComponent,
  ExchangeableProductsSetEditComponent} from './exchangeable-products-set';
import {RelatedProductSetCategoryEditComponent, RelatedProductSetCategoryNewComponent,
  RelatedProductSetCategoryComponent} from './related-product-set-category';
import {RelatedProductSetEditComponent, RelatedProductSetNewComponent} from './related-product-set';
import {FolderEditComponent, FolderNewComponent} from './folder';
import { DashboardComponent } from './dashboard/dashboard.component';
import {DevEnvComponent, DevEnvNewComponent} from './dev-env';
import {
  RefundReportV2Component,
  SubscriptionRetentionReportV2Component,
  ReturnRetentionReportV2Component,
  NewOrderRetentionReportV2Component,
  PaymentUpdaterReportV2Component,
  ChargebackReportV2Component,
  SPTouchedChargebackReportV2Component,
  ChatbotReportV2Component
} from './reports';
import { TicketSystemComponent, TicketSystemNewComponent, TicketSystemEditComponent } from './ticket-system';
import { ShippingSystemComponent, ShippingSystemNewComponent, ShippingSystemEditComponent } from './shipping-system';
import { SSOCompleteComponent } from './sso-complete/sso-complete.component';
import { SsoFlagGuard } from './sso-flag.guard';


const appRoutes: Routes = [
  { path: '', component: DashboardComponent, canActivate: [AuthGuard, SsoFlagGuard] },
  { path: 'user',
    children: [
      { path: 'login', component: LoginComponent, canActivate: [AnonymousGuard] },
      { path: 'resend/activation', component: ResendActivationEmailComponent, canActivate: [AnonymousGuard] },
      { path: 'register', component: RegisterComponent, canActivate: [AnonymousGuard], canDeactivate: [CanDeactivateGuard] },
      { path: 'register-success', component: RegisterSuccessComponent },
      { path: 'verify', component: RegisterVerifyComponent },
      { path: 'subscribe', component: SubscriptionComponent, canActivate: [AuthGuard], data: {adminOnly: true} },
      { path: 'subscribe/edit', component: SubscriptionEditComponent, canActivate: [AuthGuard], data: {adminOnly: true} }
    ]
  },
  { path: 'payment',
      children: [
        { path: 'sources', component: PaymentSourceComponent, canActivate: [AuthGuard] },
        { path: 'card', canActivate: [AuthGuard],
          children: [
            { path: '', component: PaymentSourceComponent, canActivate: [AuthGuard] },
            { path: 'edit/:id', component: CreditCardEditComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
            { path: 'new', component: CreditCardNewComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] }
          ]
        },
        { path: 'bank-account', canActivate: [AuthGuard],
          children: [
            { path: '', component: PaymentSourceComponent, canActivate: [AuthGuard] },
            { path: 'new', component: BankAccountNewComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] }
          ]
        },
      ]
  },
  { path: 'plaid/redirect', component: PlaidRedirectComponent, canActivate: [AuthGuard] },
  { path: 'addresses', component: AddressComponent, canActivate: [AuthGuard] },
  { path: 'address', canActivate: [AuthGuard],
    children: [
      { path: 'edit/:id', component: AddressEditComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
      { path: 'new', component: AddressNewComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] }
    ]
  },
  { path: 'visitors', component: VisitorsComponent, canActivate: [AuthGuard] },
  { path: 'phone', canActivate: [AuthGuard],
    children: [
      { path: '', component: PhoneComponent, canActivate: [AuthGuard] },
      { path: 'search', component: PhoneSearchComponent, canActivate: [AuthGuard] },
      { path: 'new/:type', component: PhoneNewComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
      { path: 'edit/:id', component: PhoneEditComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
    ]
  },
  { path: 'crms', component: CRMComponent, canActivate: [AuthGuard] },
  { path: 'crm', canActivate: [AuthGuard],
    children: [
      { path: 'new', component: CRMNewComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
      { path: 'edit/:id', component: CRMEditComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
    ]
  },
  { path: 'campaigns', component: CampaignComponent, canActivate: [AuthGuard], data: {breadcrumb: "Brands"} },
  { path: 'campaign', canActivate: [AuthGuard], data: {breadcrumb: 'Brand'},
    children: [
      { path: '', component: CampaignComponent, canActivate: [AuthGuard] },
      { path: 'categories', component: CampaignCategoryComponent, canActivate: [AuthGuard], data: {breadcrumb: 'Categories'} },
      { path: 'edit/:id', component: CampaignEditComponent, canActivate: [AuthGuard], data: {breadcrumb: 'Edit'}, canDeactivate: [CanDeactivateGuard] },
      { path: 'phone', data: {breadcrumb: ''},
        children: [
          { path: ':id', component: CampaignPhoneComponent, canActivate: [AuthGuard], data: {breadcrumb: 'Phone Setup'}},
        ]
      },
      { path: 'email/:id', component: CampaignEmailComponent, canActivate: [AuthGuard], data: {breadcrumb: 'Edit Email'}, canDeactivate: [CanDeactivateGuard] },
      { path: 'call-center/:id', component: CampaignCallCenterComponent, canActivate: [AuthGuard], data: {breadcrumb: 'Edit Call Center'}, canDeactivate: [CanDeactivateGuard] },
      { path: 'paths/:id', component: CampaignFunnelsComponent, canActivate: [AuthGuard], data: {breadcrumb: 'Edit Paths'}, canDeactivate: [CanDeactivateGuard] },
      { path: 'products/:id', component: CampaignProductsComponent, canActivate: [AuthGuard], data: {breadcrumb: 'Edit CRM Campaigns'}, canDeactivate: [CanDeactivateGuard] },
      { path: 'marketplace/:id', component: CampaignMarketplaceComponent, canActivate: [AuthGuard], data: {breadcrumb: 'Edit Marketplace'}, canDeactivate: [CanDeactivateGuard] },
      { path: 'styles/:id', component: CampaignStylesComponent, canActivate: [AuthGuard], data: {breadcrumb: 'Edit Themes'}, canDeactivate: [CanDeactivateGuard] },
      { path: 'campaigns/:id', component: CampaignCampaignsComponent, canActivate: [AuthGuard], data: {breadcrumb: 'Edit Brands'}, canDeactivate: [CanDeactivateGuard] },
      { path: 'test/:id', component: CampaignTestComponent, canActivate: [AuthGuard], data: {breadcrumb: 'Edit Test'}, canDeactivate: [CanDeactivateGuard] },
      { path: 'new', component: CampaignNewComponent, canActivate: [AuthGuard], data: {breadcrumb: 'Add new'}, canDeactivate: [CanDeactivateGuard] },
      { path: 'verify', component: CampaignVerifyComponent, data: {breadcrumb: 'Verify'} },
    ]
  },
  { path: 'hybrid-campaigns', component: HybridCampaignComponent, canActivate: [AuthGuard], data: {breadcrumb: "Hybrid Brands"} },
  { path: 'hybrid-campaign', canActivate: [AuthGuard], data: {breadcrumb: 'Hybrid Brand'},
    children: [
      { path: '', component: HybridCampaignComponent, canActivate: [AuthGuard] },
      { path: 'new', component: HybridCampaignNewComponent, canActivate: [AuthGuard], data: {breadcrumb: 'Add new'}, canDeactivate: [CanDeactivateGuard] },
    ]
  },
  { path: 'product', canActivate: [AuthGuard], data: {breadcrumb: 'Products'},
    children: [
      { path: 'categories', component: ProductCategoryComponent, canActivate: [AuthGuard], data: {breadcrumb: 'Categories'} },
      { path: 'sections', component: ProductInfoSectionComponent, canActivate: [AuthGuard], data: {breadcrumb: 'Existing sections'} },
      { path: 'section', canActivate: [AuthGuard], data: {breadcrumb: 'Section'},
        children: [
          { path: 'new', component: ProductInfoSectionNewComponent, canActivate: [AuthGuard], data: {breadcrumb: 'Add'}, canDeactivate: [CanDeactivateGuard] },
          { path: 'edit/:id', component: ProductInfoSectionEditComponent, canActivate: [AuthGuard], data: {breadcrumb: 'Edit'}, canDeactivate: [CanDeactivateGuard] },
        ]
      },
    ]
  },
  { path: 'campaign-products', component: CampaignProductComponent, canActivate: [AuthGuard], data: {breadcrumb: "Existing products"} },
  { path: 'crm-campaigns', component: CRMCampaignComponent, canActivate: [AuthGuard], data: {breadcrumb: 'CRM Campaigns'} },
  { path: 'images', component: ImageComponent, canActivate: [AuthGuard] },
  { path: 'images/upload', component: ImageUploadComponent, canActivate: [AuthGuard] },
  { path: 'icons', component: IconComponent, canActivate: [AuthGuard] },
  { path: 'content', component: WidgetComponent, canActivate: [AuthGuard] },
  { path: 'styles', component: StyleComponent, canActivate: [AuthGuard] },
  { path: 'style', canActivate: [AuthGuard],
    children: [
      { path: 'new', component: StyleNewComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
      { path: 'edit/:id', component: StyleEditComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
    ]
  },
  { path: 'paths', component: FunnelComponent, canActivate: [AuthGuard] },
  { path: 'support-paths', component: VisualFunnelComponent, canActivate: [AuthGuard] },
  { path: 'subpaths', component: ProductFunnelComponent, canActivate: [AuthGuard] },
  { path: 'path', canActivate: [AuthGuard],
    children: [
      { path: 'new', component: FunnelNewComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
      { path: 'edit/:id', component: FunnelEditComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
      { path: ':funnel_id', canActivate: [AuthGuard],
        children: [
          { path: 'step', canActivate: [AuthGuard],
            children: [
              { path: 'new', component: FunnelStepNewComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
              { path: 'edit/:id', component: FunnelStepEditComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
              { path: ':step_id/input', canActivate: [AuthGuard],
                children: [
                  { path: 'new', component: FunnelInputNewComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
                  { path: 'edit/:id', component: FunnelInputEditComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
                ]
              },
            ]
          },
          {path: 'input/:input_id/action', canActivate: [AuthGuard],
            children: [
              { path: 'new', component: FunnelActionNewComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
              { path: 'edit/:id', component: FunnelActionEditComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
            ]
          },
        ]
      },
    ]
  },
  { path: 'audio-files', component: AudioFileComponent, canActivate: [AuthGuard] },
  { path: 'audio/upload', component: AudioFileUploadComponent, canActivate: [AuthGuard] },
  { path: 'email-templates', component: EmailTemplateComponent, canActivate: [AuthGuard] },
  { path: 'email-template', canActivate: [AuthGuard],
    children: [
      { path: 'new', component: EmailTemplateNewComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
      { path: 'edit/:id', component: EmailTemplateEditComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
    ]
  },
  { path: 'autoresponders', component: AutoResponderComponent, canActivate: [AuthGuard] },
  { path: 'autoresponder', canActivate: [AuthGuard],
    children: [
      { path: 'new', component: AutoResponderNewComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
      { path: 'edit/:id', component: AutoResponderEditComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
    ]
  },
  { path: 'password', canActivate: [AnonymousGuard],
    children: [
      { path: 'reset', canActivate: [AnonymousGuard],
        children: [
          { path: '', component: PasswordResetComponent, canActivate: [AnonymousGuard] },
          { path: 'success', component: PasswordResetSuccessComponent },
          { path: 'confirm', component: PasswordResetConfirmComponent },
        ]
      }
    ]
  },
  { path: 'password-change', component: PasswordChangeComponent, canActivate: [AuthGuard] },
  { path: 'faqs', component: FaqsComponent, canActivate: [AuthGuard] },
  { path: 'faq-topic', canActivate: [AuthGuard],
    children: [
      { path: 'new', component: FaqTopicNewComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
      { path: 'edit/:id', component: FaqTopicEditComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
    ]
  },
  { path: 'faq', canActivate: [AuthGuard],
    children: [
      { path: 'new', component: FaqNewComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
      { path: 'edit/:id', component: FaqEditComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
    ]
  },
  { path: 'troubleshooters', component: TroubleshooterComponent, canActivate: [AuthGuard] },
  { path: 'lifelines', component: LifelineComponent, canActivate: [AuthGuard] },
  { path: 'surveys', component: SurveyComponent, canActivate: [AuthGuard] },
  { path: 'phone-paths', component: VoiceFunnelComponent, canActivate: [AuthGuard] },
  { path: 'hybrid-paths', component: HybridFunnelComponent, canActivate: [AuthGuard] },
  { path: 'session/:id', component: SessionDetailComponent, canActivate: [AuthGuard] },
  { path: 'accounts', component: AccountComponent, canActivate: [AuthGuard] },
  { path: 'users', component: UserComponent, canActivate: [AuthGuard] },
  { path: 'api-keys', component: UserApiKeyComponent, canActivate: [AuthGuard] },
  { path: 'proxy-keys', component: UserProxyKeyComponent, canActivate: [AuthGuard], data: {adminOnly: true} },
  { path: 'invite-accept', component: UserInviteAcceptComponent, canActivate: [AnonymousGuard] },
  { path: 'email-blacklist/:id', component: AccountEmailBlacklistComponent, canActivate: [AuthGuard] },
  { path: 'path-step-groups', component: FunnelStepGroupComponent, canActivate: [AuthGuard] },
  { path: 'campaign/:campaign/tracking', component: CampaignTrackingComponent, canActivate: [AuthGuard] },
  { path: 'sales-phrases', component: SalesPhraseComponent, canActivate: [AuthGuard] },
  { path: 'call-center-actions', component: CallCenterActionComponent, canActivate: [AuthGuard] },
  { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard], data: {breadcrumb: "Reports"} },
  { path: 'faq-reports', component: FaqReportsComponent, canActivate: [AuthGuard], data: {breadcrumb: "Faq Reports"} },
  { path: 'report/:id', component: ReportsComponent, canActivate: [AuthGuard], data: {breadcrumb: "Reports"} },
  { path: 'phone-logs', component: PhoneLogsContainerComponent, canActivate: [AuthGuard],
    data: {adminOnly: true, breadcrumb: "Phone logs"} },
  { path: 'sms-logs', component: SmsLogsContainerComponent, canActivate: [AuthGuard],
    data: {adminOnly: true, breadcrumb: "SMS logs"} },
  { path: 'email-logs', component: EmailLogsContainerComponent, canActivate: [AuthGuard],
    data: {adminOnly: true, breadcrumb: "Email logs"} },
  { path: 'template', canActivate: [AuthGuard],
    children: [
      {path: '', component: DesignTemplateComponent, canActivate: [AuthGuard]},
      {path: 'new', component: DesignTemplateEditFormComponent, canActivate: [AuthGuard]},
      {path: 'edit/:id', component: DesignTemplateEditFormComponent, canActivate: [AuthGuard]},
      { path: 'categories', component: WidgetCategoryComponent, canActivate: [AuthGuard], data: {breadcrumb: 'Categories'} },
    ]
  },
  { path: 'plans', component: PlanComponent, canActivate: [AuthGuard], data: {adminOnly: true} },
  { path: 'plan', canActivate: [AuthGuard], data: {adminOnly: true},
    children: [
      { path: 'new', component: PlanNewComponent, canActivate: [AuthGuard], data: {adminOnly: true},
        canDeactivate: [CanDeactivateGuard] },
      { path: 'edit/:id', component: PlanEditComponent, canActivate: [AuthGuard], data: {adminOnly: true},
        canDeactivate: [CanDeactivateGuard] },
    ]
  },
  { path: 'invoice-items', component: InvoiceItemComponent, canActivate: [AuthGuard], data: {adminOnly: true} },
  { path: 'invoices', component: InvoiceComponent, canActivate: [AuthGuard]},
  { path: 'invoice/:id', component: InvoiceDetailComponent, canActivate: [AuthGuard] },
  { path: 'profiler', canActivate: [AuthGuard], data: {adminOnly: true},
    children: [
      { path: 'enable', component: ProfilerEnableComponent, canActivate: [AuthGuard], data: {adminOnly: true} },
      { path: 'disable', component: ProfilerDisableComponent, canActivate: [AuthGuard], data: {adminOnly: true} }
    ]
  },
  { path: 'performance-reports', canActivate: [AuthGuard], component: PerformanceReportComponent },
  { path: 'personal-info', canActivate: [AuthGuard], component: PersonalInfoComponent },
  { path: 'path-performance-reports', canActivate: [AuthGuard], component: PathPerformanceReportComponent },
  { path: 'refund-reports-legacy', canActivate: [AuthGuard], component: RefundReportComponent },
  { path: 'refund-reports', canActivate: [AuthGuard], component: RefundReportV2Component },
  { path: 'chatbot-reports', canActivate: [AuthGuard], component: ChatbotReportV2Component },
  { path: 'refund-reports/:id', canActivate: [AuthGuard], component: RefundReportV2Component },
  { path: 'chargeback-report-legacy', canActivate: [AuthGuard], component: ChargebackReportComponent },
  { path: 'chargeback-reports', canActivate: [AuthGuard], component: ChargebackReportV2Component },
  { path: 'chargeback-reports/:id', canActivate: [AuthGuard], component: ChargebackReportV2Component },
  { path: 'sp-touched-reports', canActivate: [AuthGuard], component: SPTouchedChargebackReportV2Component },
  { path: 'sp-touched-reports/:id', canActivate: [AuthGuard], component: SPTouchedChargebackReportV2Component },
  { path: 'session-summary-reports', canActivate: [AuthGuard], component: SessionSummaryReportComponent },
  { path: 'marketplace-reports', canActivate: [AuthGuard], component: MarketplaceReportComponent },
  { path: 'retention-reports-legacy', canActivate: [AuthGuard], component: RetentionReportComponent },
  { path: 'subscription-retention-reports', canActivate: [AuthGuard], component: SubscriptionRetentionReportV2Component },
  { path: 'subscription-retention-reports/:id', canActivate: [AuthGuard], component: SubscriptionRetentionReportV2Component },
  { path: 'return-retention-reports', canActivate: [AuthGuard], component: ReturnRetentionReportV2Component },
  { path: 'return-retention-reports/:id', canActivate: [AuthGuard], component: ReturnRetentionReportV2Component },
  { path: 'new-order-retention-reports', canActivate: [AuthGuard], component: NewOrderRetentionReportV2Component },
  { path: 'new-order-retention-reports/:id', canActivate: [AuthGuard], component: NewOrderRetentionReportV2Component },
  { path: 'payment-updater-reports-legacy', canActivate: [AuthGuard], component: PaymentUpdaterReportComponent },
  { path: 'payment-updater-reports', canActivate: [AuthGuard], component: PaymentUpdaterReportV2Component },
  { path: 'payment-updater-reports/:id', canActivate: [AuthGuard], component: PaymentUpdaterReportV2Component },
  { path: 'faq-lifeline-keywords/:id', component: AccountSearchKeywordsComponent, canActivate: [AuthGuard] },
  { path: 'exchangeable-products-set', canActivate: [AuthGuard], data: {adminOnly: false},
    children: [
      { path: '', component: ExchangeableProductsSetComponent, canActivate: [AuthGuard] },
      { path: 'new', component: ExchangeableProductsSetNewComponent, canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard] },
      { path: 'edit/:id', component: ExchangeableProductsSetEditComponent, canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard] },
    ]
  },
  { path: 'exchangeable-category', canActivate: [AuthGuard], data: {adminOnly: false},
    children: [
      { path: '', component: RelatedProductSetCategoryComponent, canActivate: [AuthGuard] },
      { path: 'new', component: RelatedProductSetCategoryNewComponent, canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard] },
      { path: 'edit/:id', component: RelatedProductSetCategoryEditComponent, canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard] },
    ]
  },
  { path: 'exchangeable-set-type', canActivate: [AuthGuard], data: {adminOnly: false},
    children: [
      { path: 'new', component: RelatedProductSetNewComponent, canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard] },
      { path: 'edit/:id', component: RelatedProductSetEditComponent, canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard] },
    ]
  },
  { path: 'folder', canActivate: [AuthGuard],
    children: [
      { path: 'new', component: FolderNewComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
      { path: 'edit/:id', component: FolderEditComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
    ]
  },
  { path: 'dev-env', canActivate: [AuthGuard], data: {adminOnly: true},
    children: [
      { path: '', component: DevEnvComponent, canActivate: [AuthGuard], data: {adminOnly: true} },
      { path: 'new', component: DevEnvNewComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard], data: {adminOnly: true} },
    ]
  },
  { path: 'sso-complete', component: SSOCompleteComponent, canActivate: [AuthGuard] },
  { path: 'ticket-system', canActivate: [AuthGuard],
    children: [
      {path: '', component: TicketSystemComponent, canActivate: [AuthGuard]},
      {path: 'new', component: TicketSystemNewComponent, canActivate: [AuthGuard]},
      {path: 'edit/:id', component: TicketSystemEditComponent, canActivate: [AuthGuard]},
    ]
  },
  { path: 'shipping-system', canActivate: [AuthGuard],
    children: [
      {path: '', component: ShippingSystemComponent, canActivate: [AuthGuard]},
      {path: 'new', component: ShippingSystemNewComponent, canActivate: [AuthGuard]},
      {path: 'edit/:id', component: ShippingSystemEditComponent, canActivate: [AuthGuard]},
    ]
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes, { scrollPositionRestoration: "enabled" });
