import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AlertService, ProductService} from '../_services';
import {ImageSize, Product, RelatedProduct, Variant, VariantOption} from "../_models";
import {Form} from '../_forms';
import {NgxSmartModalService} from "ngx-smart-modal";
import {getProductImageUrl} from "../_helpers";
import {takeUntil} from "rxjs/operators";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './variant.component.html',
  selector: 'variants',
  styleUrls: [
    '../image/image.component.css'
  ]
})
export class VariantComponent extends Form implements OnInit, OnChanges {
  variantOptions: VariantOption[] = [];
  selectedVariantIndex: number;
  selectedVariant: Variant;
  variants: Variant[] = [];

  @Input('product') product: Product;
  @Input('relatedProduct') relatedProduct: RelatedProduct;
  @Input('isSelection') isSelection = false;
  @Input('selectedVariant') initialSelectedVariant: Variant = null;
  @Output('selectVariantEvent') onSelectVariantEvent = new EventEmitter<Variant>();
  @Output('cancel') onCancel: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    protected router: Router,
    protected location: Location,
    private modalService: NgxSmartModalService,
    protected alertService: AlertService,
    protected productService: ProductService
  ) {
    super(alertService, router, location);
  }

  ngOnInit() {
    this.updateForm();
    super.ngOnInit();
  }

  ngOnChanges(): void {
    this.updateForm();
  }

  private updateForm() {
    let options = [];

    if (this.product) {
      if (this.product.variants && this.product.variants.length) {
        options = this.product.variants[0].options;
        this.variants = this.product.variants;
      }

      this.variantOptions = options;
    }
    else if (this.relatedProduct) {
      this.loading = true;
      this.productService.getVariants(this.relatedProduct.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe((variants: Variant[]) => {
            this.variants = variants;

            if (variants && variants.length) {
              options = variants[0].options;
            }

            this.variantOptions = options;
            this.loading = false;
          },
          error => {
            this.handleError(error);
            this.variantOptions = [];
            this.loading = false;
          }
        );
    }
  }

  getVariantImageUrl(variant: Variant) {
    if (variant.image) {
      if (variant.image.file) {
        if (variant.image.file[ImageSize.original]) {
          return variant.image.file[ImageSize.original];
        } else if (typeof variant.image.file === 'string'){
          return variant.image.file;
        }
      }
    }
    return getProductImageUrl(this.product, ImageSize.original);
  }

  edit(variantIndex: number) {
    this.selectedVariantIndex = variantIndex;
    this.selectedVariant = this.product.variants[this.selectedVariantIndex];
    this.openEditDialog();
  }

  protected openEditDialog() {
    this.modalService.getModal('variantDialog').open();
  }

  onSaveVariant(variant: Variant) {
    this.modalService.getModal('variantDialog').close();
    if (variant.image && this.selectedVariant['selectedImage']) {
      if (typeof variant.image !== 'object') {
        variant.image = {id: variant.image } as any;
      }
      variant.image.file = this.selectedVariant['selectedImage'];
    }
    Object.assign(this.product.variants[this.selectedVariantIndex], variant);
    this.selectedVariant = null;
  }

  select(variant: Variant, event) {
    event.preventDefault();
    this.onSelectVariantEvent.emit(variant);
  }

  isSelected(variant: Variant) {
    return this.initialSelectedVariant && (this.initialSelectedVariant.id === variant.id);
  }

  cancel() {
    this.onCancel.emit();
  }
}
