import {Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {AlertService, ChatbotReportService, CRMService, PhoneCallService, SessionService, UserService, StorageService, DashboardService} from "../_services";
import {Form} from '../_forms';
import {
  ActionType,
  ActionTypeLabels,
  AvailableFunnelItemStatuses,
  BillingCycleTypeEnum,
  CampaignProduct,
  CRM,
  Customer,
  CustomStepCategoryEnum,
  DiscountTypeEnum,
  FaqSearchHistory,
  FulfillmentStatus,
  FulfillmentStatusLabels,
  FulfillmentTypeEnum,
  FunnelInputTypeEnum,
  FunnelItemStatus,
  ImageSize,
  Item,
  ItemStatusLabels,
  LifelineTypeLabels,
  Order,
  Pager,
  PhoneCallAction,
  PhoneCallInput,
  PhoneCallResultLabels,
  PhoneCarrierTypeLabels,
  ProductTypeEnum,
  RevenueType,
  RevenueTypeLabels,
  Session,
  SessionAction,
  SessionActionResult,
  SessionActionResultLabels,
  SessionActionStatusLabels,
  SessionBaseAction,
  SessionBaseInput,
  SessionEvent,
  SessionFaq,
  SessionFaqStatus,
  SessionFaqStatusLabels,
  SessionInput,
  SessionInputStatus,
  SessionInputStatusLabels,
  SessionMatchMethod,
  SessionMatchMethodLabels,
  SessionResult,
  SessionResultLabels,
  SessionSourceLabels,
  SessionStatusLabels,
  SessionTypeLabels,
  TrialTypeEnum,
  User
} from '../_models';
import {
  convertSecondsToSessionTime,
  formatISOTimestamp,
  formatMoney,
  getCRMCustomerHtml,
  getCRMOrderHtml,
  getItemProductName,
  getProductImageUrl
} from '../_helpers';
import {NgxSmartModalComponent, NgxSmartModalService} from 'ngx-smart-modal';
import {config} from "../../config/config";
import {expand, takeUntil, reduce} from 'rxjs/operators';
import {forkJoin, EMPTY} from 'rxjs';
import { cloneDeep } from 'lodash';
import { ChatbotPopupFields } from '../reports/utils/constants';
import * as moment from 'moment';
import { Moment } from "moment";

@Component({
  moduleId: module.id.toString(),
  templateUrl: './session-detail.component.html',
  styleUrls: ['./session.component.css'],
  selector: 'session-detail'
})
export class SessionDetailComponent extends Form implements OnInit, OnChanges {
  @Input('sessionId') sessionId: string | number = null;
  @Input('reportsReq') reportsReq;
  @Input('filter') sessionfilters;
  @Input('isDashboardSession') isDashboardSession: boolean;
  @ViewChild('childSessionModal', { static: false }) childSessionModal: NgxSmartModalComponent;
  session: Session = null;
  sessionInputs: SessionInput[] = [];
  selectedItem: Item | null = null;
  selectedOrder: Order | null = null;
  crms: CRM[];
  user: User;
  inputsLoading: boolean = false;
  childSessions: Session[];
  childSession: Session;
  timezone;
  currentUser: User;
  maxDate = moment();
  filterData;
  date: { startDate: Moment; endDate: Moment };
  queryParams = { account_id: null, page: 1, page_size: config.maxPageSize };

  expandedQuestionRows: { [key: number]: boolean } = {};
  expandedAnswerRows: { [key: number]: boolean } = {};
  private crmTypes: {} = {};
  public languages = {
    'en': 'English',
    'es': 'Spanish',
    'fr': 'French',
    'de': 'German',
    'pt': 'Portuguese',
    'ru': 'Russian',
    'zh-CN': 'Chinese',
    'ko': 'Korean',
    'ja': 'Japanese',
    'hi': 'Hindi'
  };

  chatbotTableData: any[] = [];
  pageCount = 1;
  page = 1;
  pageSize = config.defaultPageSize;
  totalResultCount = 0;
  selectedRows: any = [];
  rowsPerPageOptions = [
    {value: 25, label: "25"},
    {value: 50, label: "50"},
    {value: 100, label: "100"},
    {value: 200, label: "200"}
  ];

  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected sessionService: SessionService,
    protected alertService: AlertService,
    private modalService: NgxSmartModalService,
    private crmService: CRMService,
    private userService: UserService,
    private phoneCallService: PhoneCallService,
    protected reportsService: ChatbotReportService,
    protected storageService: StorageService,
    protected dashboardService: DashboardService,
  ) {
    super(alertService, router, location);
    this.title = 'Session Information';
  }

  ngOnInit() {
    super.ngOnInit();
    this.filterData = this.dashboardService.getFilter();
    this.timezone = this.filterData.timezone;
    this.currentUser = this.storageService.get("currentUser");
    this.queryParams.account_id = this.currentUser.account.id;
    this.crmService.list({page: 1, page_size: config.maxPageSize})
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (pager: Pager) => {
          this.crms = pager.results;
          this.crms.forEach((crm: CRM) => {
            this.crmTypes[crm.id.toString()] = crm.type;
          });
        },
        error => {
          this.handleError(error);
        }
      );

    this.userService.getCurrent()
      .subscribe(
        (user: User) => {
          this.user = user;
        },
        error => {
          this.handleError(error);
        }
      );

    if (!this.sessionId) {
      this.route.params.pipe(takeUntil(this.destroy$)).subscribe(
        (params) => {
          this.sessionId = params['id'];
          this.updateData();
        },
        error => {
          this.handleError(error);
        }
      );
    }
  }

  ngOnChanges() {
    this.updateData()
  }

  updateData() {
    if (this.session && this.session.id === this.sessionId) {
      return;
    }
    this.session = null;

    this.sessionService.get(this.sessionId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((session: Session) => {
        this.session = session;
        this.updateChatbotReportReq()
        this.queryData();
        if (this.session.recording_url) {
          if (this.session.recording_url.startsWith('api-')) {
            this.session.recording_url = this.session.recording_url.replace('api-', 'https://')
          } else {
            this.session.recording_url = this.session.recording_url.replace('app', 'us')
          }
        }
        if (this.session) {
          forkJoin([this.sessionService.getFaqsData(this.session.id),
          this.sessionService.getChildrenData(this.session.id)
          ])
            .pipe(takeUntil(this.destroy$))
            .subscribe((data: [{session_faqs: SessionFaq[], faqs_search_phrases: FaqSearchHistory[]}, Session[]]) => {
              this.session.session_faqs = data[0].session_faqs
              this.session.faqs_search_phrases = data[0].faqs_search_phrases
              this.childSessions = data[1]
            })
          this.getInputs();
        }
      }, error => {this.handleError(error)});
  }

  public queryData(selectedList?: string[]) {
    this.reportsService
      .getFilteredReport({
        ...this.reportsReq,
        page: this.page,
        page_size: this.pageSize,
      })
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data: Pager) => {
          this.pageCount = data.num_pages;
          this.page = data.page;
          this.chatbotTableData = data.results

          if (this.page === 1) {
            this.totalResultCount = data.count;
          }
          this.expandedAnswerRows = {}
          this.expandedQuestionRows = {}
        },
        (error) => {
        }
      );
  }
  initializeDate() {
    if (this.isDashboardSession) {
      this.date = {
        startDate: this.filterData.start_date,
        endDate: this.filterData.end_date,
      };
    } else {
      this.date = {
        startDate: this.sessionfilters.date_joined__gte      ,
        endDate: this.sessionfilters.date_joined__lt,
      };
    }
    this.maxDate = this.date.endDate;
  }

  updateChatbotReportReq() {
    const commonFields = {
      fields: cloneDeep(ChatbotPopupFields),
      filters: { chatbot_session_id: this.session.id },
    };

    if (this.reportsReq) {
      this.reportsReq = {
        ...this.reportsReq,
        ...commonFields,
      };
    } else {
      this.initializeDate();
      this.reportsReq = {
        accounts: [this.queryParams.account_id],
        customer_mode: false,
        date_filter_type: 'session',
        timezone: this.timezone,
        date_range: this.date,
        ...commonFields,
      };
    }
  }

  appendLastInput() {
    if (this.session.step_snapshot) {
      this.sessionInputs = this.sessionInputs.concat([{
        id: null,
        session: this.session.id,
        step_snapshot: this.session.step_snapshot,
        status: SessionInputStatus.Normal,
        input_snapshot: {
          id: null,
          label: this.getResult(),
          type: FunnelInputTypeEnum.Hidden,
          category: CustomStepCategoryEnum.Custom,
          help: null,
          step: null,
          next_step: null,
          matched_step_key: null,
          product_funnels: [],
          hide_if_invalid: false,
          is_alt_child_exit: false,
          autopick_next_step: null
        },
        item: null,
        new_item: null,
        autopick: false,
        session_actions: [],
        executed_session_actions: [],
        can_undo: false,
        undo_step: null,
        input_data: null,
        created: this.session.last_update,
        cancel_reason: null,
        autopick_reason: null,
        lifeline_status: null,
        message: null,
      }]);
    }
    this.inputsLoading = false;
  }
  getInputs() {
    if (!this.session) { return; }
    this.sessionInputs = [];
    this.inputsLoading = true;
    // make the first call
    this.sessionService.getInputs(this.session.id, {page: 1, page_size: config.defaultPageSize})
      .pipe(
        expand((data: Pager) => {
          this.sessionInputs = this.sessionInputs.concat(data.results);
          if (data.page < data.num_pages) {
            return this.sessionService.getInputs(this.session.id, {page: data.page + 1, page_size: config.defaultPageSize});
          } else {
            return EMPTY;
          }
        }),
        reduce((acc, response) => acc.concat(response), [])
      )
      .subscribe((data: Pager[]) => {
        this.appendLastInput();
      }, error => { this.handleError(error); });
  }

  getStartTime() {
    const dt = new Date(this.session.date_joined);

    return dt.toLocaleString();
  }

  getResult() {
    return (this.session.result >= 0) ? SessionResultLabels[this.session.result] : 'Unknown';
  }

  getCustomerName(input) {
    return input.customer_name || 'Customer not matched';
  }

  getSessionSource(input) {
    return input.session_source !== null ? SessionSourceLabels[input.session_source] : '';
  }
  getChatbotSessionId(input) {
    return input.chatbot_session_id || '';
  }

  getQuestionAt(input) {
    return this.formatTime(input.question_at);
  }

  getAnswerAt(input) {
    return this.formatTime(input.answer_at);
  }

  toggleExpandQuestion(rowIndex: number) {
    this.expandedQuestionRows[rowIndex] = !this.expandedQuestionRows[rowIndex];
  }

  toggleExpandAnswer(rowIndex: number) {
    this.expandedAnswerRows[rowIndex] = !this.expandedAnswerRows[rowIndex];
  }

  isQuestionRowExpanded(rowIndex: number): boolean {
    return !!this.expandedQuestionRows[rowIndex];
  }

  isAnswerRowExpanded(rowIndex: number): boolean {
    return !!this.expandedAnswerRows[rowIndex];
  }

  truncateText(text: string, charLimit: number = 30): string {
    if (text.length > charLimit) {
      return text.slice(0, charLimit) + '...';
    }
    return text;
  }

  getTruncatedQuestion(row: any): string {
    return this.truncateText(row.question);
  }

  getTruncatedAnswer(row: any): string {
    return this.truncateText(row.answer);
  }

  shouldShowMore(text: string, charLimit: number = 30): boolean {
    return text.length > charLimit;
  }

  getQuestion(row: any, rowIndex: number): string {
    const question = row.question;
    if (this.isQuestionRowExpanded(rowIndex)) {
      return question + ' <a href="javascript:void(0);" (click)="toggleExpand(' + rowIndex + '); $event.preventDefault()">Show less</a>';
    } else {
      const truncatedQuestion = this.truncateText(question);
      return truncatedQuestion + ' <a href="javascript:void(0);" (click)="toggleExpand(' + rowIndex + '); $event.preventDefault()">Show more</a>';
    }
  }

  getAnswer(row: any, rowIndex: number): string {
    const answer = row.answer;
    if (this.isAnswerRowExpanded(rowIndex)) {
      return answer + ' <a href="javascript:void(0);" (click)="toggleExpand(' + rowIndex + '); $event.preventDefault()">Show less</a>';
    } else {
      const truncatedAnswer = this.truncateText(answer);
      return truncatedAnswer + ' <a href="javascript:void(0);" (click)="toggleExpand(' + rowIndex + '); $event.preventDefault()">Show more</a>';
    }
  }

  getIsSuccess(input) {
    return input.is_success || '';
  }

  formatDate(input) {
    if (!input) return '';
    const date = new Date(input);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  }

  formatTime(input) {
    if (!input) return '';
    const date = new Date(input);
    return date.toLocaleTimeString();
  }

  isLifeline() {
    return [
      SessionResult.LifelineExit,
      SessionResult.Error,
      SessionResult.LifelineTimeout,
      SessionResult.LifelineUndo,
      SessionResult.LifelineError,
      SessionResult.LifelineSearch,
      SessionResult.LifelineNoMatch,
    ].indexOf(this.session.result) !== -1;
  }

  getLifelineType() {
    return LifelineTypeLabels[this.session.lifeline_type];
  }

  isMatched() {
    return this.session.matched_by !== SessionMatchMethod.NotMatched;
  }

  getMatchMethod() {
    return SessionMatchMethodLabels[this.session.matched_by];
  }

  getSource() {
    let source = SessionSourceLabels[this.session.source];

    if (this.session.phone_call) {
      source += ' (' + PhoneCarrierTypeLabels[this.session.phone_call.carrier_type] + ')'
    }

    ['sid1', 'sid2', 'sid3'].forEach(key => {
      const value = this.session[key];

      if (value && value.length) {
        source += ', ' + key + ' = ' + value
      }
    });

    return source;
  }

  getStatus() {
    return SessionStatusLabels[this.session.status];
  }

  getSessionType() {
    return SessionTypeLabels[this.session.type];
  }

  getDevice() {
    let device = '';

    if (this.session.device_info) {
      device = this.session.device_info.device_type;

      if (this.session.device_info.mobile_vendor && this.session.device_info.mobile_vendor.length) {
        device += ' (' + this.session.device_info.mobile_vendor;

        if (this.session.device_info.mobile_model) {
          device += ' ' + this.session.device_info.mobile_model;
        }

        device += ')'
      }
    }

    return device;
  }

  getBrowser() {
    let browser = '';

    if (this.session.device_info) {
      browser = this.session.device_info.browser_name;

      if (this.session.device_info.browser_full_version && this.session.device_info.browser_full_version.length) {
        browser += ' ' + this.session.device_info.browser_full_version;
      }
    }

    return browser;
  }

  getOperatingSystem() {
    let os = '';

    if (this.session.device_info) {
      os = this.session.device_info.os_name;

      if (this.session.device_info.os_version && this.session.device_info.os_version.length) {
        os += ' ' + this.session.device_info.os_version;
      }
    }

    return os;
  }

  getActionType(sa: SessionBaseAction) {
    if (sa.action_snapshot) {
      return ActionTypeLabels[sa.action_snapshot.resourcetype];
    }
    return '';
  }

  getActionStatus(sa: SessionAction) {
    return SessionActionStatusLabels[sa.status];
  }

  getActionResult(sa: SessionAction) {
    return SessionActionResultLabels[sa.result];
  }

  hasRevenue(sa: SessionAction) {
    return sa.revenue_type !== RevenueType.None;
  }

  getRevenue(sa: SessionAction) {
    return formatMoney(sa.revenue);
  }

  getRevenueType(sa: SessionAction) {
    return RevenueTypeLabels[sa.revenue_type];
  }

  getActions(input: SessionInput) {
    return input.executed_session_actions.filter(
      (sa: SessionAction) => sa.action_snapshot && sa.action_snapshot.resourcetype !== ActionType.ExecuteDelayed);
  }

  getPhoneActions(input: PhoneCallInput) {
    return input.executed_phone_call_actions.filter(
      (pa: PhoneCallAction) => pa.action_snapshot.resourcetype !== ActionType.ExecuteDelayed);
  }

  getStep(input: SessionInput) {
    if (input.step_snapshot) {
      return input.step_snapshot.name && input.step_snapshot.name.length ?
        input.step_snapshot.name : input.step_snapshot.label;
    }

    if (this.isLifelineInput(input)) {
      return 'Contact';
    }

    return '';
  }

  getPhoneStep(input: PhoneCallInput) {
    if (input.step_snapshot.forward_to_call_center) {
      return 'Forward To ' +
        (input.step_snapshot.call_center_phone ? input.step_snapshot.call_center_phone :
          this.session.campaign.support_phone)
    }

    if (input.step_snapshot.name && input.step_snapshot.name.length) {
      return input.step_snapshot.name;
    }

    if (input.step_snapshot.voice_file) {
      return input.step_snapshot.voice_file.path +
        '<audio src="' + input.step_snapshot.voice_file.file + '" controls></audio>'
    }

    return input.step_snapshot.voice_prompt
  }

  getStepTime(input: SessionBaseInput) {
    return input.created ? formatISOTimestamp(input.created) : '';
  }

  getStepType(input: SessionInput) {
    return input.step_snapshot.type ? FunnelInputTypeEnum[input.step_snapshot.type] : '';
  }

  getEventTime(event: SessionEvent) {
    return formatISOTimestamp(event.created);
  }

  getNextSessionInput(input: SessionInput) {
    if (!input || !this.sessionInputs) {
      return null
    }
    let nextInputs = this.sessionInputs.filter((item: SessionInput) => {return Number(item.id) > Number(input.id)})
    if (nextInputs.length > 0) {
      return nextInputs[0]
    }
    return null
  }


  getInputText(input: SessionInput) {
    let nextInput = this.getNextSessionInput(input)
    if (input && input.input_snapshot && nextInput && nextInput.step_snapshot) {
      if (input.input_snapshot.type === FunnelInputTypeEnum.PathNotFound &&
        nextInput.step_snapshot.type === FunnelInputTypeEnum.ItemStatus) {
        return 'Displayed Order Status'
      }
    }
    if (input.status === SessionInputStatus.Normal || input.status === SessionInputStatus.ExitChildFunnel) {
      return input.input_snapshot.label;
    }

    return SessionInputStatusLabels[input.status];
  }

  getItemStatus(input: SessionInput) {
    let status = '';

    if (input.item && input.funnel_item_status !== null) {
      if (input.funnel_item_status !== FunnelItemStatus.InActive) {
        const funnelItem = AvailableFunnelItemStatuses.find(item => item.id === input.funnel_item_status)
        status = funnelItem.text;
      }
      else if (input.item_status !== null && input.fulfillment_status !== null) {
        if (input.item.billing_cycle_type === BillingCycleTypeEnum.Recurring) {
          status = ItemStatusLabels[input.item_status];
        }
        else {
          if ([FulfillmentStatus.Shipped, FulfillmentStatus.Delivered, FulfillmentStatus.None].indexOf(
            input.fulfillment_status) === -1) {
            status = FulfillmentStatusLabels[input.fulfillment_status]
          } else if (input.item.product && input.item.product.fulfillment_type === FulfillmentTypeEnum.NoShipment) {
            status = 'Not shippable'
          }
          else {
            status = 'Past Return Date';
          }
        }
      }
    }

    return status;
  }

  getItemImageUrl(item: Item, size: ImageSize = ImageSize.small) {
    if ((item.product.id === item.campaign_product.product_id) &&
      item.campaign_product.image && item.campaign_product.image.file
    ) {
      return item.campaign_product.image.file[ImageSize.small]
    }

    return getProductImageUrl(item.product, ImageSize.small);
  }

  getTotalRevenue() {
    return formatMoney(this.session.revenue);
  }

  getPhoneActionStatusMessages(pa: PhoneCallAction) {
    let messages = [];
    const resourceType = pa.action_snapshot.resourcetype;

    if (resourceType === ActionType.SMS) {
      messages.push(pa.action_snapshot.message)
    }

    return messages;
  }

  getActionStatusMessages(input: SessionInput, sa: SessionAction) {
    let messages = [];
    if (!sa.action_snapshot) {
      return messages;
    }
    const resourceType = sa.action_snapshot.resourcetype;

    if (sa.message && sa.message.length) {
      messages.push(sa.message);
    } else if (resourceType === ActionType.BillingCycle || resourceType === ActionType.BillingCycleHistory) {
      if (sa.action_snapshot.billing_interval_days) {
        messages.push('Billing interval set to ' + sa.action_snapshot.billing_interval_days.toString() + ' days');
      }

      if (input.input_data && 'next_bill_date' in input.input_data) {
        const nextBillDateStr = input.input_data['next_bill_date'];

        if (nextBillDateStr && nextBillDateStr.length) {
          const nextBillDate = new Date(nextBillDateStr);

          messages.push('Next bill date set to ' +
            nextBillDate.toLocaleDateString('en-US', {timeZone: 'UTC'}));
        }
      }
    } else if (resourceType === ActionType.Discount || resourceType === ActionType.DiscountHistory) {
      const discountPrice = input.item.recurring_price;

      if (sa.action_snapshot.discount_type === DiscountTypeEnum.Fixed) {
        messages.push('$' + formatMoney(sa.action_snapshot.discount) + ' discount applied');
      } else if (sa.action_snapshot.discount_type === DiscountTypeEnum.Percent) {
        messages.push(sa.action_snapshot.discount.toString() + '% discount applied');
      } else /* if (sa.action_snapshot.discount_type === DiscountTypeEnum.Index) */ {
        messages.push('Price set to discount index ' + sa.action_snapshot.discount_index.toString());
      }

      messages.push('Price set to $' + formatMoney(discountPrice));

      if (sa.action_snapshot.shipping_price) {
        messages.push('Shipping price set to $' + formatMoney(input.item.recurring_shipping_price));
      }
    } else if (resourceType === ActionType.Refund || resourceType === ActionType.RefundHistory) {
      messages.push('$' + formatMoney(sa.refund_amount) + ' refund issued');
    } else if (resourceType === ActionType.RMA || resourceType === ActionType.RMAHistory) {
      if (sa.result === SessionActionResult.RMAExchange) {
        if (sa.refund_amount > 0.00000001) {
          messages.push('RMA issued for exchange and $' + formatMoney(sa.refund_amount) + ' refund');
        } else {
          messages.push('RMA issued for exchange');
        }
      } else {
        messages.push('RMA issued for $' + formatMoney(sa.refund_amount) + ' refund');
      }
    } else if (resourceType === ActionType.Cancel || resourceType === ActionType.CancelHistory) {
      messages.push('Cancelled item')
    } else if (resourceType === ActionType.ChangeQuantity || resourceType === ActionType.ChangeQuantityHistory) {
      const new_item = input.new_item ? input.new_item : input.item;

      messages.push('Changed item quantity to ' + new_item.quantity.toString())

      if (input.new_item) {
        messages.push('Created new order ' + new_item.order.order_id);
      }
    } else if (resourceType === ActionType.ChangeProduct || resourceType === ActionType.ChangeProductHistory) {
      const new_item = input.new_item ? input.new_item : input.item;

      messages.push('Changed item to ' + new_item.name);
      messages.push('Quantity = ' + new_item.quantity + ', Price = $' + formatMoney(new_item.price));

      if (input.new_item) {
        messages.push('Created new order ' + new_item.order.order_id);
      }
    } else if (resourceType === ActionType.ChangeVariant || resourceType === ActionType.ChangeVariantHistory) {
      const new_item = input.new_item ? input.new_item : input.item;

      messages.push('Changed variants for ' + new_item.name);
      messages.push('Price = $' + formatMoney(new_item.price));

      if (input.new_item) {
        messages.push('Created new order ' + new_item.order.order_id);
      }
    } else if (resourceType === ActionType.ExtendReturn || resourceType === ActionType.ExtendReturnHistory) {
      messages.push(`Extended return date by ${input.item.extra_return_days} days`);
    }

    return messages;
  }

  viewCampaignProduct(item: Item) {
    this.selectedItem = item;
    this.modalService.getModal('viewItemDialog').open();
  }

  onCloseItemDialog() {
    this.selectedItem = null;
  }

  copyTestOrder(order: Order) {
    this.selectedOrder = order;
    this.modalService.getModal('copyTestOrderDialog').open();
  }

  getProductType(campaignProduct: CampaignProduct) {
    let productTypes = {};

    productTypes[ProductTypeEnum.Offer] = 'Offer';
    productTypes[ProductTypeEnum.Upsell] = 'Upsell';
    productTypes[ProductTypeEnum.LinkedUpsell] = 'Linked Upsell';

    return productTypes[campaignProduct.product_type];
  }

  getBillingCycleType(campaignProduct: CampaignProduct) {
    let billingCycleTypes = {};

    billingCycleTypes[BillingCycleTypeEnum.OneTime] = 'One Time';
    billingCycleTypes[BillingCycleTypeEnum.Recurring] = 'Recurring';
    billingCycleTypes[BillingCycleTypeEnum.MultiPay] = 'Multi-Pay';

    return billingCycleTypes[campaignProduct.billing_cycle_type];
  }

  getTrialType(campaignProduct: CampaignProduct) {
    let trialTypes = {};

    trialTypes[TrialTypeEnum.None] = 'No Trial';
    trialTypes[TrialTypeEnum.Standard] = 'Standard';
    trialTypes[TrialTypeEnum.Delayed] = 'Delayed';
    trialTypes[TrialTypeEnum.Accelerated] = 'Accelerated';

    return trialTypes[campaignProduct.trial_type];
  }

  getCRMCustomerHtml(customer: Customer) {
    return getCRMCustomerHtml(customer.customer_id, this.crmTypes[customer.crm]);
  }

  getCRMOrderHtml(order: Order) {
    return getCRMOrderHtml(order.order_id, order.actual_order_id, this.crmTypes[order.crm]);
  }

  getPhoneCallStartTime() {
    const dt = new Date(this.session.phone_call.created);

    return dt.toLocaleString();
  }

  getPhoneType() {
    return PhoneCarrierTypeLabels[this.session.phone_call.carrier_type];
  }

  getPhoneCallResult() {
    return PhoneCallResultLabels[this.session.phone_call.result];
  }

  getPhoneRecording() {
    this.phoneCallService.getRecordingUrl(this.session.phone_call)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (url: string) => {
          window.open(url, '_blank');
        },
        error => {
          this.handleError(error);
        }
    );
  }

  getLanguage(code: string) {
    if (code in this.languages) {
      return this.languages[code];
    }
    return '';
  }

  getPath(input: SessionInput) {
    if (input.step_snapshot && input.step_snapshot.funnel) {
      return input.step_snapshot.funnel.name;
    }

    if (this.isLifelineInput(input)) {
      return 'Lifeline';
    }

    return '';
  }

  isErrored(input: SessionInput) {
    return input.status === SessionInputStatus.Error
  }

  isLifelineInput(input: SessionInput) {
    return !!input.lifeline_status
  }

  getSessionFaqStatus(status: SessionFaqStatus) {
    return SessionFaqStatusLabels[status]
  }

  getCustomer(child: Session){
    return `${child.first_name} ${child.last_name}`
  }

  getChildStatus(child: Session) {
    if(child.status)
    return SessionStatusLabels[child.status];
  }

  openChildDetails(child: Session){
    this.childSession = child;
    this.childSessionModal.open();
  }

  getSessionTime(session: Session) {
    return convertSecondsToSessionTime(session.session_time)
  }

  protected readonly ImageSize = ImageSize;
  protected readonly getItemProductName = getItemProductName;
}
