import { Component, Input, OnInit } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import {
  StepResponseCategory,
  StepSurveyOptionsLayout,
  StepPopupActions,
  StepElements,
  CustomStepCategoryEnum,
  ExecutionTypeEnum,
  FunnelInputTypeEnum,
} from "../../../_models";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  CancelReasonCategoriesService,
  StepEmulatorService,
} from "../../../_services";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { FormControlStatus } from "../../../_forms";
import { surveyResourceMapping } from "../../step-data";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "step-survey-options",
  templateUrl: "./step-survey-options.component.html",
  styleUrls: ["./step-survey-options.component.scss"],
  animations: [
    trigger("fadeInOut", [
      state("void", style({ opacity: 0 })),
      transition(":enter", [animate("500ms ease-in", style({ opacity: 1 }))]),
      transition(":leave", [animate("300ms ease-out", style({ opacity: 0 }))]),
    ]),
  ],
})
export class StepSurveyOptionsComponent implements OnInit {
  @Input() layout: StepSurveyOptionsLayout;
  @Input() form: FormGroup;
  @Input() enableFormErrors: boolean;
  @Input() templateId: string;
  @Input() stepId: string;
  stepType: CustomStepCategoryEnum;
  stepElements = StepElements;
  selectedResponse: Partial<StepResponseCategory> = {};
  isEditing: boolean;
  editIndex: number;
  addingNewResponse: boolean = false;
  newResponse: string = "";
  categoriesLoading: boolean = true;
  addingCategory: boolean = false;
  cancelReasonCategories: any[] = [];
  popupTitle = "";
  popupData = {
    category_id: null,
    is_category: true,
    popupType: StepPopupActions.Delete,
  };

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private route: ActivatedRoute,
    protected cancelReasonCategoriesService: CancelReasonCategoriesService,
    protected stepEmulatorService: StepEmulatorService,
    protected formBuilder: FormBuilder
  ) {
    this.route.params.subscribe((params) => {
      this.stepType = parseInt(params["step_type"]);
    });
  }

  ngOnInit() {
    this.getCancelReasonCategories();
  }

  getCancelReasonCategories(): void {
    this.categoriesLoading = true;
    this.cancelReasonCategoriesService.list().subscribe(
      (categories: any) => {
        this.categoriesLoading = false;
        this.cancelReasonCategories = categories.results;
        const categoryMap = {};
        categories.results.forEach((category: any) => {
          categoryMap[category.name] = category.id;
        });

        if (!this.templateId && !this.stepId) {
          const resourcetype = surveyResourceMapping[this.stepType],
            cancel_subscription =
              this.stepType === CustomStepCategoryEnum.CancelSubSurvey ||
              this.stepType === CustomStepCategoryEnum.CancelTrialSurvey;

          const formGroups = this.layout.items
            .map((item) => {
              const categoryId = categoryMap[item.category];
              if (categoryId) {
                return this.formBuilder.group({
                  label: [item.reason, Validators.required],
                  help: null,
                  next_step: null,
                  type: FunnelInputTypeEnum.Radio,
                  values: [[]],
                  actions: this.formBuilder.array([
                    this.formBuilder.group({
                      execution_type: ExecutionTypeEnum.ValidationOnly,
                      resourcetype,
                      cancel_subscription,
                      reason: [item.reason, Validators.required],
                    }),
                  ]),
                  icon: null,
                  hide_if_invalid: true,
                  product_funnels: [[]],
                  classes: [[]],
                  is_alt_child_exit: false,
                  matched_step_key: null,
                  autopick_next_step: null,
                  reason_category: [categoryId, Validators.required],
                  requires_active_item: true,
                  requires_item: true,
                  requires_customer: true,
                });
              }
              return null;
            })
            .filter((group) => group !== null);

          this.form.setControl(
            "inputs",
            this.formBuilder.array(formGroups, Validators.required)
          );
        }
      },
      (error) => {
        this.categoriesLoading = false;
        console.error("Error fetching cancel reason categories:", error);
      }
    );
  }

  getCancelCategoryName(input: FormGroup): string {
    const reason_category = input.get("reason_category");

    if (reason_category) {
      const category = this.cancelReasonCategories.find(
        (x) => x.id === reason_category.value
      );
      return category ? category.name : null;
    } else return null;
  }

  cancelAddingNewResponse() {
    this.newResponse = "";
    this.addingNewResponse = false;
  }

  addNewOption(newResponse: string) {
    if (newResponse.trim() !== "") {
      this.addingCategory = true;
      const newCategoryData = {
        name: newResponse.trim(),
        is_global: false,
      };

      this.cancelReasonCategoriesService.create(newCategoryData).subscribe(
        () => {
          this.addingCategory = false;
          this.getCancelReasonCategories();
        },
        (error) => {
          this.addingCategory = false;
          console.error("Error adding new category:", error);
        }
      );

      this.newResponse = "";
    }
  }

  onDragStart(event: DragEvent, index: number): void {
    event.dataTransfer.setData("text/plain", index.toString());
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDrop(event: DragEvent, toIndex: number): void {
    event.preventDefault();

    const draggedIndex = Number(event.dataTransfer.getData("text/plain"));

    if (!isNaN(draggedIndex) && draggedIndex !== toIndex) {
      const inputsArray = this.form.get("inputs") as FormArray;
      const draggedItem = inputsArray.at(draggedIndex);

      // Remove the dragged item from its original position
      inputsArray.removeAt(draggedIndex);

      // Insert the dragged item at the new position
      inputsArray.insert(toIndex, draggedItem);
    }
  }

  editResponse(index: number) {
    this.isEditing = true;
    this.editIndex = index;

    const inputs = this.form.get("inputs") as FormArray;

    this.selectedResponse = {
      reason: inputs.at(index).get("label").value,
      categoryId: inputs.at(index).get("reason_category").value,
    };
    this.ngxSmartModalService.getModal("popup").open();
  }

  saveResponse() {
    if (!this.selectedResponse.categoryId || !this.selectedResponse.reason) {
      return;
    }

    const categoryObject = this.cancelReasonCategories.find(
      (category) => category.id === this.selectedResponse.categoryId
    );

    if (this.isEditing) {
      const inputs = this.form.get("inputs") as FormArray;

      inputs.at(this.editIndex).patchValue({
        label: this.selectedResponse.reason,
        reason_category: this.selectedResponse.categoryId,
      });
    } else if (categoryObject) {
      const response: StepResponseCategory = {
        id: this.selectedResponse.id || null,
        reason: this.selectedResponse.reason,
        category: categoryObject.name,
        categoryId: categoryObject.id,
      };

      const resourcetype = surveyResourceMapping[this.stepType],
        cancel_subscription =
          this.stepType === CustomStepCategoryEnum.CancelSubSurvey ||
          this.stepType === CustomStepCategoryEnum.CancelTrialSurvey;

      const inputsArray = this.form.get("inputs") as FormArray;
      const formGroup = this.formBuilder.group({
        label: [response.reason, Validators.required],
        help: null,
        next_step: null,
        type: FunnelInputTypeEnum.Radio,
        values: [[]],
        actions: this.formBuilder.array([
          this.formBuilder.group({
            execution_type: ExecutionTypeEnum.Delayed,
            resourcetype,
            cancel_subscription,
            reason: [response.reason, Validators.required],
          }),
        ]),
        icon: null,
        hide_if_invalid: true,
        product_funnels: [[]],
        classes: [[]],
        is_alt_child_exit: false,
        matched_step_key: null,
        autopick_next_step: null,
        reason_category: [response.categoryId, Validators.required],
        requires_active_item: true,
        requires_item: true,
        requires_customer: true,
      });

      inputsArray.push(formGroup);
    }

    this.selectedResponse = {};
    this.editIndex = null;
    this.isEditing = false;
    this.ngxSmartModalService.getModal("popup").close();
  }

  cancelResponse() {
    this.isEditing = false;
    this.selectedResponse = {};
    this.ngxSmartModalService.getModal("popup").close();
  }

  deleteResponse(index: number) {
    const inputsArray = this.form.get("inputs") as FormArray;

    if (index >= 0 && index < inputsArray.length) {
      inputsArray.removeAt(index);
    }
  }

  deleteCategory(categoryId: string | number): void {
    this.popupData.category_id = categoryId;
    this.popupTitle = "Delete Category";
    this.popupData.is_category = true;
    this.ngxSmartModalService.getModal("confirmationModal").open();
  }

  confirmDeleteCategory(): void {
    if (this.popupData.category_id) {
      this.getCancelReasonCategories();
      this.ngxSmartModalService.getModal("confirmationModal").close();
    }
  }

  cancelDeleteCategory(): void {
    this.ngxSmartModalService.getModal("confirmationModal").close();
  }

  toggleElement(element: StepElements) {
    this.stepEmulatorService.toggleElement(element);
  }

  isElementVisible(element: StepElements): boolean {
    return this.stepEmulatorService.isElementVisible(element);
  }

  get inputControls() {
    return (this.form.get("inputs") as FormArray).controls;
  }

  get inputsLength(): number {
    const inputs = this.form.get("inputs") as FormArray;
    return inputs ? inputs.length : 0;
  }

  get inputsInvalidity() {
    return (
      this.enableFormErrors &&
      this.form.controls.inputs.status === FormControlStatus.Invalid
    );
  }
}
