import { Component, Input, OnInit } from "@angular/core";
import {
  StepDiscountLayout,
  StepDiscountTypes,
  StepElements,
} from "../../../_models/steps";
import { FormArray, FormGroup } from "@angular/forms";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { FormControlStatus } from "../../../_forms";
import { StepEmulatorService } from "../../../_services";

@Component({
  selector: "step-discount",
  templateUrl: "./step-discount.component.html",
  styleUrls: ["./step-discount.component.scss"],
  animations: [
    trigger("fadeInOut", [
      state("void", style({ opacity: 0 })),
      transition(":enter", [animate("500ms ease-in", style({ opacity: 1 }))]),
      transition(":leave", [animate("300ms ease-out", style({ opacity: 0 }))]),
    ]),
  ],
})
export class StepDiscountComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() layout: StepDiscountLayout;
  @Input() enableFormErrors: boolean;
  action: FormGroup;
  stepElements = StepElements;
  discountTypes = StepDiscountTypes;

  constructor(private stepEmulatorService: StepEmulatorService) {}

  ngOnInit() {
    const inputs = this.form.get("inputs") as FormArray;
    const actions = inputs.at(0).get("actions") as FormArray;
    this.action = actions.at(0) as FormGroup;
  }

  toggleElement(element: StepElements) {
    this.stepEmulatorService.toggleElement(element);
  }

  isElementVisible(element: StepElements): boolean {
    return this.stepEmulatorService.isElementVisible(element);
  }

  get isPercent() {
    return this.layout.type === StepDiscountTypes.Percentage;
  }

  get isAmount() {
    return this.layout.type === StepDiscountTypes.Amount;
  }

  get discountInvalidity() {
    return (
      this.enableFormErrors && this.action.status === FormControlStatus.Invalid
    );
  }
}
