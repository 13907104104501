import {ErrorHandler, NgModule} from '@angular/core';
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ColorPickerModule } from 'ngx-color-picker';
import {AlertComponent, PagerComponent, HelpComponent, ProjectableEditorComponent,
  DualListSortableComponent} from './_directives';
import { AuthGuard, AnonymousGuard } from './_guards';
import { JwtInterceptor, TinymceConfig } from './_helpers';
import {
  AlertService,
  AuthenticationService,
  UserService,
  RegionService,
  AddressService,
  PhoneService,
  PaymentSourceService,
  CRMService,
  CampaignCategoryService,
  CampaignService,
  ProductCategoryService,
  ProductService,
  ImageService,
  FunnelService,
  FunnelStepService,
  FunnelInputService,
  StyleService,
  FunnelActionService,
  AudioFileService,
  EmailTemplateService,
  AutoResponderService,
  CampaignProductService,
  CRMCampaignService,
  StorageService,
  FaqService,
  FaqTopicService,
  OrderService,
  IconFileService,
  ClipboardService,
  ProductInfoSectionService,
  SessionService,
  AccountService,
  FunnelStepGroupService,
  CampaignTrackingService,
  CampaignDomainService,
  WidgetService,
  SalesPhraseService,
  CanDeactivateService,
  CallCenterActionService,
  GuiService,
  CallLogsService,
  SmsLogsService,
  MailLogsService,
  ReportsConfigService,
  ReportsService,
  PlanService,
  InvoiceService,
  InvoiceItemService,
  InvoiceTransactionService,
  DailyInvoiceService,
  WidgetCategoryService,
  CustomerService,
  SipCredentialService,
  VariantService,
  FaqReportsService,
  CycleService,
  HeartbeatService,
  PerformanceReportService,
  PersonalInfoService,
  PathPerformanceReportService,
  NotificationService,
  RefundReportService,
  ChatbotReportService,
  ExchangeableProductsSetService,
  RelatedProductSetCategoryService,
  RelatedProductSetService,
  FulfillmentService,
  SessionSummaryReportService,
  ApiKeyService,
  ProxyKeyService,
  FolderService,
  PusherService,
  ChargebackReportService,
  MerchantService,
  MarketplaceReportService,
  RetentionReportService,
  PhoneCallService,
  PaymentUpdaterReportService,
  DevEnvService,
  TicketSystemService,
  ShippingSystemService
} from './_services';
import { SessionResultsComponent } from './session-results';
import { LoginComponent } from './login';
import { RegisterSuccessComponent } from './register-success';
import { RegisterComponent, RegisterVerifyComponent } from './register';
import { VisitorsComponent } from './visitors';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldErrorsComponent, FormErrorsComponent } from "./_forms";
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SubscriptionComponent, SubscriptionEditComponent } from './subscription';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '../environments/environment';
import { PaymentSourceComponent, CreditCardEditComponent, BankAccountNewComponent, BankAccountNewFieldsComponent,
  BankAccountVerifyFormComponent, CreditCardNewComponent, CreditCardNewFieldsComponent,
  BankAccountNewFormComponent, PlaidRedirectComponent } from './payment-source';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { AddressComponent, AddressEditComponent, AddressNewComponent, AddressFieldsComponent } from './address';
import { PhoneComponent, PhoneSearchComponent, PhoneNewComponent, PhoneEditComponent } from './phone';
import {
  CRMComponent, CRMEditComponent, CRMNewComponent, CRMConfigComponent, CRMTrackingComponent,
  CRMCopyOrderComponent, CrmChargebackConfigComponent
} from './crm';
import { CampaignCategoryComponent, CampaignCategoryFormComponent, ProductCategoryComponent,
  ProductCategoryFormComponent, WidgetCategoryComponent, WidgetCategoryFormComponent } from './category';
import {
  CampaignComponent,
  CampaignNewComponent,
  CampaignEditComponent,
  CampaignPhoneComponent,
  CampaignEmailComponent,
  CampaignVerifyComponent,
  CampaignFunnelsComponent,
  CampaignStylesComponent,
  CampaignMenuComponent,
  CampaignTestComponent,
  CampaignProductsComponent,
  CampaignLinksComponent,
  CampaignMarketplaceComponent,
  CampaignCallCenterComponent,
  CampaignCampaignsComponent
} from './campaign';
import { ProductEditComponent, ProductInfoSectionComponent, ProductMarketplaceFieldsComponent,
  ProductInfoSectionNewComponent, ProductInfoSectionEditComponent, ProductBasicFieldsComponent,
  ProductPathFieldsComponent, ProductWidgetsComponent } from './product';
import {
  CampaignProductComponent, CampaignProductEditComponent, CampaignProductTestOrderComponent,
  CampaignProductSelectComponent, CampaignProductSelectPaginatedComponent
} from './campaign-product';
import { HeaderComponent } from './header';
import { NgxUploaderModule } from 'ngx-uploader';
import { TouchEventModule } from 'ng2-events/lib/touch';
import { OutsideEventModule } from "ng2-events/lib/outside";
import { DndModule } from '@beyerleinf/ngx-dnd';
import {
  ImageComponent,
  ImageUploadComponent,
  ImageGalleryComponent,
  GlobalGalleryComponent,
  ImageCropComponent
} from './image';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxGraphModule } from './ngx-graph';
import { FunnelComponent, FunnelEditComponent, FunnelNewComponent, FunnelStepsComponent, FunnelTreeActionsComponent,
  FunnelPreviewFormComponent, VisualFunnelComponent, ProductFunnelComponent } from './funnel';
import { FunnelStepEditComponent, FunnelStepNewComponent, FunnelStepPreviewFormComponent } from './funnel-step';
import { FunnelInputEditComponent, FunnelInputNewComponent, FunnelInputFieldsComponent } from './funnel-input';
import { FunnelActionEditComponent, FunnelActionNewComponent, FunnelActionFieldsComponent } from './funnel-action';
import {
  StyleComponent,
  StyleNewComponent,
  StyleEditComponent,
  StyleUploadComponent,
  StylePreviewFormComponent
} from './style';
import { EditorModule } from '@tinymce/tinymce-angular';
import { AudioFileComponent, AudioFileUploadComponent } from './audio-file';
import { EmailTemplateComponent, EmailTemplateNewComponent, EmailTemplateEditComponent } from './email-template';
import { AutoResponderComponent, AutoResponderNewComponent, AutoResponderEditComponent,
  AutoResponderResponseComponent, AutoresponderTriggersComponent } from './autoresponder';
import { SidebarComponent } from './sidebar';
import {
  PasswordChangeComponent, PasswordResetComponent, PasswordResetSuccessComponent,
  PasswordResetConfirmComponent, ResendActivationEmailComponent
} from './password-reset';
import {
  FaqsComponent,
  FaqNewComponent,
  FaqEditComponent,
  FaqTopicNewComponent,
  FaqTopicEditComponent,
} from './faq';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { IconComponent, IconUploadComponent } from './icon';
import { CollapsibleModule } from 'angular2-collapsible';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { TroubleshooterComponent } from './troubleshooter';
import { SessionComponent, SessionDetailComponent, ChatbotSessionDetailComponent } from './session';
import { SurveyComponent } from './survey';
import { LifelineComponent } from './lifeline';
import { NgxSmartModalModule } from "ngx-smart-modal";
import {AccountComponent, AccountEmailBlacklistComponent, AccountFormComponent,
  AccountSearchKeywordsComponent} from './account';
import { FunnelStepGroupComponent, FunnelStepGroupFormComponent } from './funnel-step-group';
import { ExchangeableProductFieldsComponent, UpsaleProductFieldsComponent } from './exchangeable-product';
import { CampaignTrackingComponent, CampaignTrackingFormComponent } from './campaign-tracking';
import { VoiceFunnelComponent } from './voice-funnel';
import { BreadcrumbModule } from "angular-crumbs";
import { CRMCampaignComponent, CRMCampaignFormComponent } from './crm-campaign';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import {
  ReportTableComponent,
  ReportsComponent,
  ReportsConfigComponent,
  FaqReportsComponent,
  PerformanceReportComponent,
  PathPerformanceReportComponent,
  RefundReportComponent,
  SessionSummaryReportComponent,
  ChargebackReportComponent,
  MarketplaceReportComponent,
  RetentionReportComponent,
  PaymentUpdaterReportComponent
} from './legacy-reports';
import { WidgetComponent, WidgetFormComponent, WidgetTableComponent, WidgetPreviewFormComponent } from './widget';
import { SalesPhraseComponent, SalesPhraseFormComponent } from './sales-phrase';
import { CanDeactivateGuard } from "./_guards/can-deactivate.guard";
import { CallCenterActionComponent, CallCenterActionFormComponent } from './call-center-action';
import { HybridFunnelComponent } from './hybrid-funnel';
import { HybridCampaignComponent, HybridCampaignNewComponent } from './hybrid-campaign';
import { CampaignDomainComponent, CampaignDomainFormComponent, CampaignDomainPhoneComponent,
  CampaignDomainFunnelsComponent} from './campaign-domain';
import {
  PhoneLogsContainerComponent, SmsLogsContainerComponent, PhoneLogsComponent, SmsLogsComponent, EmailLogsComponent,
  EmailLogsContainerComponent
} from './logs';
import { PreviewFormComponent } from './preview';
import { WebBuilderComponent } from './web-builder';
import { DesignTemplateComponent, TemplateGridComponent, TemplateSettingComponent,
  DesignTemplateEditFormComponent} from './design-template';
import { NgSelectModule } from '@ng-select/ng-select';
import * as Sentry from "@sentry/browser";
import { SentryErrorHandler } from "./_helpers";
import { PlanComponent, PlanNewComponent, PlanEditComponent } from './plan';
import { InvoiceItemComponent, InvoiceItemFormComponent, InvoiceItemsChargeComponent } from './invoice-item';
import { InvoiceComponent, InvoiceDetailComponent, InvoiceChargeComponent, InvoiceSplitComponent } from './invoice';
import { CustomerComponent } from './customer';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { SipCredentialFormComponent } from "./sip";
import { VariantComponent, VariantFormComponent } from './variant';
import { UserComponent, UserInviteComponent, UserInviteAcceptComponent, UserApiKeyComponent,
  UserProxyKeyComponent} from './user';
import { ProfilerEnableComponent, ProfilerDisableComponent } from './profiler';
import { MatTreeModule } from '@angular/material/tree';
import { MatExpansionModule } from '@angular/material/expansion';
import { CycleComponent, CycleFormComponent } from './cycle';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxPlaidLinkModule } from "ngx-plaid-link";
import { PersonalInfoComponent } from "./personal-info";
import { NotificationComponent } from './notification/notification.component';
import {ExchangeableProductsSetComponent, ExchangeableProductsSetEditComponent,
  ExchangeableProductsSetNewComponent} from './exchangeable-products-set';
import {
  RelatedProductSetCategoryComponent,
  RelatedProductSetCategoryEditComponent,
  RelatedProductSetCategoryNewComponent
} from './related-product-set-category';
import {RelatedProductSetEditComponent, RelatedProductSetNewComponent} from './related-product-set';
import {FolderComponent, FolderEditComponent, FolderNewComponent} from './folder';
import { MerchantComponent } from './merchant';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OverviewComponent } from './dashboard/overview/overview.component';
import { RetentionComponent } from './dashboard/retention/retention.component';
import { MarketplaceComponent } from './dashboard/marketplace/marketplace.component';
import { DashboardPhoneComponent } from './dashboard/phone/phone.component';
import { DoughnutChartComponent } from "./dashboard/data-visualization/doughnut-chart/doughnut-chart.component";
import { DataTableComponent } from "./dashboard/data-visualization/data-table/data-table.component";
import { ProgressChartComponent } from './dashboard/data-visualization/progress-chart/progress-chart.component';
import { LoaderComponent } from './loader/loader.component';
import { DashboardFilterBarComponent } from './dashboard/dashboard-filter-bar/dashboard-filter-bar.component';
import { ProductSubtextComponent } from "./product-subtext";
import { BarChartComponent } from "./dashboard/data-visualization/bar-chart/bar-chart.component";
import { LineGraphComponent } from "./dashboard/data-visualization/line-graph/line-graph.component";
import { DashboardOverviewReportComponent } from "./dashboard/reports/overview-report/overview-report.component";
import { DashboardRetentionReportComponent } from "./dashboard/reports/retention-report/retention-report.component";
import { DashboardMarketplaceReportComponent } from "./dashboard/reports/marketplace-report/marketplace-report.component";
import { CustomMultiSelectComponent } from "./custom-multi-select/custom-multi-select.component";
import { DevEnvNewComponent, DevEnvComponent } from "./dev-env";
import { ClickOutsideDirective } from "./_directives/click-outside.directive";
import { IframeDocumentationComponent } from "./iframe-documentation";
import { TicketSystemComponent, TicketSystemNewComponent, TicketSystemEditComponent,
  TicketSystemFieldsComponent} from './ticket-system';
import { SSOCompleteComponent } from './sso-complete/sso-complete.component';
import { SideNavService } from './_services/sidenav.service';
import { ChargebackReportV2Component, NewOrderRetentionReportV2Component, PaymentUpdaterReportV2Component,
  RefundReportV2Component, ReportTableV2Component, ReturnRetentionReportV2Component,
  SPTouchedChargebackReportV2Component, SubscriptionRetentionReportV2Component,
  ChatbotReportV2Component } from './reports';
import { ShippingSystemComponent, ShippingSystemNewComponent, ShippingSystemEditComponent,
  ShippingSystemFieldsComponent, ShippingSystemLabelFieldsComponent} from './shipping-system';
import { InvoiceTransactionComponent, InvoiceTransactionRefundComponent } from './invoice-transaction';

if (environment.sentryDsn) {
  Sentry.init({
    dsn: environment.sentryDsn,
    debug: environment.sentryDebugMode
  });
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    BrowserModule,
    NgxDatatableModule,
    FormsModule,
    NgSelectModule,
    RecaptchaModule.forRoot(),
    RecaptchaFormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgbModule,
    CreditCardDirectivesModule,
    NgxStripeModule.forRoot(environment.stripePublicKey),
    NgxUploaderModule,
    TouchEventModule,
    OutsideEventModule,
    DndModule,
    DragDropModule,
    NgxChartsModule,
    NgxGraphModule,
    BrowserAnimationsModule,
    EditorModule,
    NgxMaterialTimepickerModule,
    AngularDualListBoxModule,
    NgMultiSelectDropDownModule.forRoot(),
    CollapsibleModule,
    ChartsModule,
    MonacoEditorModule.forRoot(),
    NgxSmartModalModule.forRoot(),
    BreadcrumbModule,
    NgxDaterangepickerMd.forRoot(),
    ColorPickerModule,
    routing,
    MatTreeModule,
    MatExpansionModule,
    ImageCropperModule,
    NgxPlaidLinkModule,
  ],
  exports: [
    TouchEventModule,
    OutsideEventModule
  ],
  declarations: [
    AppComponent,
    AlertComponent,
    PagerComponent,
    HelpComponent,
    ProjectableEditorComponent,
    FieldErrorsComponent,
    FormErrorsComponent,
    VisitorsComponent,
    LoginComponent,
    RegisterComponent,
    RegisterVerifyComponent,
    SubscriptionComponent,
    SubscriptionEditComponent,
    PaymentSourceComponent,
    CreditCardEditComponent,
    CreditCardNewComponent,
    CreditCardNewFieldsComponent,
    BankAccountNewComponent,
    BankAccountNewFieldsComponent,
    BankAccountNewFormComponent,
    BankAccountVerifyFormComponent,
    AddressComponent,
    AddressEditComponent,
    AddressNewComponent,
    AddressFieldsComponent,
    PhoneComponent,
    PhoneSearchComponent,
    PhoneNewComponent,
    PhoneEditComponent,
    CRMComponent,
    CRMEditComponent,
    CRMNewComponent,
    CRMConfigComponent,
    CRMTrackingComponent,
    CampaignCategoryComponent,
    CampaignCategoryFormComponent,
    CampaignComponent,
    CampaignNewComponent,
    CampaignEditComponent,
    CampaignPhoneComponent,
    CampaignEmailComponent,
    CampaignFunnelsComponent,
    CampaignStylesComponent,
    CampaignVerifyComponent,
    CampaignMenuComponent,
    ProductCategoryComponent,
    ProductCategoryFormComponent,
    ProductMarketplaceFieldsComponent,
    ProductBasicFieldsComponent,
    ProductPathFieldsComponent,
    ProductWidgetsComponent,
    ProductEditComponent,
    ProductInfoSectionComponent,
    ProductInfoSectionNewComponent,
    ProductInfoSectionEditComponent,
    CampaignProductComponent,
    CampaignProductEditComponent,
    CampaignProductSelectComponent,
    HeaderComponent,
    ImageComponent,
    ImageUploadComponent,
    ImageGalleryComponent,
    FunnelComponent,
    FunnelEditComponent,
    FunnelNewComponent,
    FunnelStepsComponent,
    FunnelStepEditComponent,
    FunnelStepNewComponent,
    FunnelInputFieldsComponent,
    FunnelInputEditComponent,
    FunnelInputNewComponent,
    FunnelActionFieldsComponent,
    FunnelActionEditComponent,
    FunnelActionNewComponent,
    StyleComponent,
    StyleNewComponent,
    StyleEditComponent,
    StyleUploadComponent,
    AudioFileComponent,
    AudioFileUploadComponent,
    EmailTemplateComponent,
    EmailTemplateNewComponent,
    EmailTemplateEditComponent,
    AutoResponderComponent,
    AutoResponderNewComponent,
    AutoResponderEditComponent,
    AutoResponderResponseComponent,
    SidebarComponent,
    RegisterSuccessComponent,
    PasswordChangeComponent,
    PasswordResetComponent,
    PasswordResetSuccessComponent,
    PasswordResetConfirmComponent,
    FaqsComponent,
    FaqNewComponent,
    FaqEditComponent,
    FaqTopicNewComponent,
    FaqTopicEditComponent,
    CampaignTestComponent,
    CampaignProductsComponent,
    IconComponent,
    IconUploadComponent,
    TroubleshooterComponent,
    SessionComponent,
    SessionDetailComponent,
    ChatbotSessionDetailComponent,
    SurveyComponent,
    LifelineComponent,
    CampaignProductComponent,
    AccountComponent,
    AccountFormComponent,
    FunnelStepGroupComponent,
    FunnelStepGroupFormComponent,
    ExchangeableProductFieldsComponent,
    UpsaleProductFieldsComponent,
    CampaignTrackingComponent,
    CampaignTrackingFormComponent,
    CampaignLinksComponent,
    VoiceFunnelComponent,
    AccountEmailBlacklistComponent,
    CRMCampaignComponent,
    CRMCampaignFormComponent,
    ReportsComponent,
    ReportTableComponent,
    WidgetComponent,
    WidgetFormComponent,
    WidgetTableComponent,
    CampaignMarketplaceComponent,
    SalesPhraseComponent,
    SalesPhraseFormComponent,
    ReportsConfigComponent,
    CampaignCallCenterComponent,
    CallCenterActionComponent,
    CallCenterActionFormComponent,
    CampaignCampaignsComponent,
    HybridFunnelComponent,
    HybridCampaignComponent,
    HybridCampaignNewComponent,
    PhoneLogsContainerComponent,
    SmsLogsContainerComponent,
    PhoneLogsComponent,
    SmsLogsComponent,
    EmailLogsComponent,
    EmailLogsContainerComponent,
    CampaignDomainComponent,
    CampaignDomainFormComponent,
    CampaignDomainPhoneComponent,
    CampaignDomainFunnelsComponent,
    GlobalGalleryComponent,
    PreviewFormComponent,
    FunnelPreviewFormComponent,
    FunnelStepPreviewFormComponent,
    WidgetPreviewFormComponent,
    WebBuilderComponent,
    DesignTemplateComponent,
    TemplateGridComponent,
    TemplateSettingComponent,
    DesignTemplateEditFormComponent,
    WidgetCategoryComponent,
    PlanComponent,
    PlanNewComponent,
    PlanEditComponent,
    WidgetCategoryFormComponent,
    InvoiceItemComponent,
    InvoiceItemFormComponent,
    InvoiceItemsChargeComponent,
    InvoiceComponent,
    InvoiceDetailComponent,
    InvoiceChargeComponent,
    InvoiceSplitComponent,
    CustomerComponent,
    SessionResultsComponent,
    CRMCopyOrderComponent,
    TermsAndConditionsComponent,
    SipCredentialFormComponent,
    VariantComponent,
    VariantFormComponent,
    FaqReportsComponent,
    UserComponent,
    UserInviteComponent,
    UserInviteAcceptComponent,
    VisualFunnelComponent,
    ProductFunnelComponent,
    ProfilerEnableComponent,
    ProfilerDisableComponent,
    CycleComponent,
    CycleFormComponent,
    StylePreviewFormComponent,
    ImageCropComponent,
    PlaidRedirectComponent,
    PerformanceReportComponent,
    PersonalInfoComponent,
    AutoresponderTriggersComponent,
    PathPerformanceReportComponent,
    AccountSearchKeywordsComponent,
    NotificationComponent,
    CampaignProductSelectPaginatedComponent,
    CampaignProductTestOrderComponent,
    RefundReportComponent,
    ResendActivationEmailComponent,
    ExchangeableProductsSetComponent,
    ExchangeableProductsSetNewComponent,
    ExchangeableProductsSetEditComponent,
    RelatedProductSetCategoryComponent,
    RelatedProductSetCategoryNewComponent,
    RelatedProductSetCategoryEditComponent,
    RelatedProductSetNewComponent,
    RelatedProductSetEditComponent,
    DualListSortableComponent,
    FolderComponent,
    FolderNewComponent,
    FolderEditComponent,
    ChargebackReportComponent,
    FunnelTreeActionsComponent,
    SessionSummaryReportComponent,
    UserApiKeyComponent,
    UserProxyKeyComponent,
    MerchantComponent,
    MarketplaceReportComponent,
    RetentionReportComponent,
    DashboardComponent,
    OverviewComponent,
    RetentionComponent,
    MarketplaceComponent,
    DashboardPhoneComponent,
    DoughnutChartComponent,
    DataTableComponent,
    ProgressChartComponent,
    LoaderComponent,
    DashboardFilterBarComponent,
    ProductSubtextComponent,
    BarChartComponent,
    PaymentUpdaterReportComponent,
    LineGraphComponent,
    DashboardOverviewReportComponent,
    DashboardRetentionReportComponent,
    DashboardMarketplaceReportComponent,
    CustomMultiSelectComponent,
    DevEnvComponent,
    DevEnvNewComponent,
    ClickOutsideDirective,
    CrmChargebackConfigComponent,
    IframeDocumentationComponent,
    ReportTableV2Component,
    PaymentUpdaterReportV2Component,
    ChargebackReportV2Component,
    SPTouchedChargebackReportV2Component,
    RefundReportV2Component,
    ChatbotReportV2Component,
    SubscriptionRetentionReportV2Component,
    ReturnRetentionReportV2Component,
    NewOrderRetentionReportV2Component,
    TicketSystemComponent,
    TicketSystemNewComponent,
    TicketSystemFieldsComponent,
    TicketSystemEditComponent,
    SSOCompleteComponent,
    ShippingSystemComponent,
    ShippingSystemNewComponent,
    ShippingSystemFieldsComponent,
    ShippingSystemLabelFieldsComponent,
    ShippingSystemEditComponent,
    InvoiceTransactionComponent,
    InvoiceTransactionRefundComponent,
  ],
  providers: [
    AuthGuard,
    AnonymousGuard,
    StorageService,
    ClipboardService,
    AlertService,
    RegionService,
    AuthenticationService,
    UserService,
    PaymentSourceService,
    AddressService,
    PhoneService,
    CRMService,
    CampaignCategoryService,
    CampaignService,
    ProductCategoryService,
    ProductService,
    ImageService,
    FunnelService,
    FunnelStepService,
    FunnelInputService,
    StyleService,
    FunnelActionService,
    AudioFileService,
    EmailTemplateService,
    AutoResponderService,
    CampaignProductService,
    CRMCampaignService,
    FaqService,
    FaqTopicService,
    OrderService,
    IconFileService,
    ProductInfoSectionService,
    SessionService,
    AccountService,
    FunnelStepGroupService,
    CampaignTrackingService,
    WidgetService,
    SalesPhraseService,
    CallCenterActionService,
    TinymceConfig,
    CanDeactivateGuard,
    CanDeactivateService,
    GuiService,
    SideNavService,
    CallLogsService,
    SmsLogsService,
    MailLogsService,
    ReportsConfigService,
    ReportsService,
    CampaignDomainService,
    WidgetCategoryService,
    PlanService,
    InvoiceService,
    InvoiceItemService,
    InvoiceTransactionService,
    DailyInvoiceService,
    CustomerService,
    SipCredentialService,
    VariantService,
    FaqReportsService,
    CycleService,
    PusherService,
    HeartbeatService,
    PerformanceReportService,
    PersonalInfoService,
    PathPerformanceReportService,
    NotificationService,
    RefundReportService,
    ChatbotReportService,
    ExchangeableProductsSetService,
    RelatedProductSetCategoryService,
    RelatedProductSetService,
    FolderService,
    FulfillmentService,
    ThemeService,
    ChargebackReportService,
    SessionSummaryReportService,
    ApiKeyService,
    ProxyKeyService,
    MerchantService,
    MarketplaceReportService,
    RetentionReportService,
    PhoneCallService,
    PaymentUpdaterReportService,
    DevEnvService,
    TicketSystemService,
    ShippingSystemService,
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.captchaKey } as RecaptchaSettings
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(
    private tinymce: TinymceConfig
  ) {
    this.tinymce.setDefaults(); //set defaults for tinymce editors
    this.tinymce.createPlugins(); //create custom plugins
  }
}
