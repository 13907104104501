import { Component, Input, OnInit } from "@angular/core";
import {
  StepDurationUnits,
  StepBasicLayout,
  StepElements,
} from "../../../_models";
import { FormArray, FormGroup } from "@angular/forms";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { StepEmulatorService } from "../../../_services";

@Component({
  selector: "step-time-delay",
  templateUrl: "./step-time-delay.component.html",
  styleUrls: ["./step-time-delay.component.scss"],
  animations: [
    trigger("fadeInOut", [
      state("void", style({ opacity: 0 })),
      transition(":enter", [animate("500ms ease-in", style({ opacity: 1 }))]),
      transition(":leave", [animate("300ms ease-out", style({ opacity: 0 }))]),
    ]),
  ],
})
export class StepTimeDelayComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() layout: StepBasicLayout;
  stepElements = StepElements;
  stepDurationUnits = StepDurationUnits;
  unit: StepDurationUnits = StepDurationUnits.Days;
  duration: number;

  constructor(protected stepEmulatorService: StepEmulatorService) {}

  ngOnInit() {
    const nextBillDateDelayControl = this.form.get([
      "inputs",
      0,
      "actions",
      0,
      "next_bill_date_delay",
    ]);
    if (nextBillDateDelayControl) {
      this.duration = nextBillDateDelayControl.value;
    }
  }

  updateTimeDelay() {
    (this.form.get("inputs") as FormArray)
      .at(0)
      .get("actions")
      .get("0")
      .patchValue({
        next_bill_date_delay:
          +this.unit === StepDurationUnits.Weeks
            ? Number(this.duration * 7)
            : Number(this.duration),
      });
  }

  toggleElement(element: StepElements) {
    this.stepEmulatorService.toggleElement(element);
  }

  isElementVisible(element: StepElements): boolean {
    return this.stepEmulatorService.isElementVisible(element);
  }
}
